import type { JSX } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Link } from 'Components/Buttons/Buttons';
import { GovernanceLibraryTable, GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { CREATE, GOVERNANCE } from 'Config/Paths';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';

import styles from './ActiveGovernanceTab.module.css';

export interface ActiveGovernanceTabProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItems: GovernanceVersion[];
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    openExternalUrl: (url: string) => void;
}

export const ActiveGovernanceTab = (props: ActiveGovernanceTabProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        governanceItems: props.governanceItems,
        openExternalUrl: props.openExternalUrl,
        governanceItemsAreManageable: true,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
    };

    return (
        <>
            <div className={styles.button}>
                <Link variant="primaryButton" to={`/${GOVERNANCE}/${CREATE}`} fontAwesomeImage={ICON_ADD_CREATE}>
                    Add New
                </Link>
            </div>
            <GovernanceLibraryTable {...governanceListingTableProps} />
        </>
    );
};
