import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, type JSX } from 'react';

import { CircularProgressIndicator } from 'Components/Indicator/CircularProgressIndicator';
import { Text } from 'Components/Text/Text';
import { ContentTriggerTooltip } from 'Components/Tooltips/ContentTriggerTooltip';

import { DDQStateGroup, assessmentsCompletedPercentage, assessmentsCompletedText, questionsCompletedPercentage, questionsCompletedText } from '../DueDiligenceQuestionnaire';
import styles from '../DueDiligenceQuestionnaire.module.css';

export interface DDQGroupTabProps {
    group: DDQStateGroup;
    selected: boolean;
    hideHorizontalRule: boolean;
    isThirdParty: boolean;
    onGroupTabSelected: () => void;
}

export const DDQGroupTab = (props: DDQGroupTabProps): JSX.Element => {
    const progressPercentage = props.isThirdParty ? questionsCompletedPercentage(props.group) : assessmentsCompletedPercentage(props.group);

    return (
        <Fragment>
            <button onClick={() => props.onGroupTabSelected()} className={props.selected ? `${styles.group} ${styles.selected}` : styles.group}>
                <Text noStyles>{props.group.isCustom ? props.group.name : props.group.id}</Text>
                <div className={styles.progress}>
                    <ContentTriggerTooltip
                        tooltipText={
                            <Fragment>
                                <div>{questionsCompletedText(props.group)}</div>
                                {!props.isThirdParty && <div>{assessmentsCompletedText(props.group)}</div>}
                            </Fragment>
                        }
                        testid={`${props.group.id}-progress`}
                    >
                        <div className={styles.progressIndicator}>{progressPercentage < 100 ? <CircularProgressIndicator percent={progressPercentage} /> : <FontAwesomeIcon icon={faCheck} />}</div>
                    </ContentTriggerTooltip>
                </div>
            </button>
            {!props.hideHorizontalRule && <hr />}
        </Fragment>
    );
};
