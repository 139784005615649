import styles from './Tooltips.module.css';

/** Common */
export const REFERENCE = 'An optional reference to an external system, such as an ID, ticket number, or URL.';

/** Actions */
export const ACTIONS_OWNER = 'The individual responsible for the action. Only users with access to Risk Management can own actions.';

/** Artificial Intelligence */
export const AI_GOVERNANCE_DESCRIPTION = 'Describe what the document should contain, such as the subject matter or domain.';
export const AI_GOVERNANCE_INPUT_FILE = 'An optional document that will be used as a reference to build on. Use this to enhance an existing document, as opposed to starting from scratch.';
export const AI_GOVERNANCE_REGULATION = 'Optionally select a compliance structure to incorporate the requirements into the document.';
export const AI_GOVERNANCE_TITLE = 'A name or title for the document that will be generated.';
export const AI_GOVERNANCE_TYPE = 'The type of governance document that will be generated.';

/** Issues and Exceptions */
export const ISSUE_SOURCE = 'The origin of the issue, such as "Audit" or "PenTest".';
export const EXCEPTIONS_COMPENSATING_CONTROLS = 'Any compensating or alternative controls that reduce the risk introduced by this exception.';

/** Third-Party Risk Management */
export const TPRM_CONTROL_ASSESSMENT_NOT_READY_EXPLANATION = 'The inherent risk questionnaire must be submitted before the third-party questionnaire / control assessment can be generated.';
export const TPRM_CONTROL_ASSESSMENT_ZERO_CONTROLS_EXPLANATION = 'The third-party questionnaire does not have any controls configured for the selected risk rating.';
export const TPRM_DDQ_NOT_READY_EXPLANATION = 'The inherent risk questionnaire must be submitted before the third-party questionnaire / control assessment can be generated.';
export const TPRM_DDQ_ZERO_QUESTIONS_EXPLANATION = 'The third-party questionnaire does not have any questions configured for the selected risk rating.';
export const TPRM_FINAL_REVIEW_NOT_READY_EXPLANATION = 'The inherent risk questionnaire must be submitted before the final review can be filled out.';
export const TPRM_IRQ_TARGET_COMPLETION_DATE_START_ASSESSMENT_MODAL = 'The third-party service manager will receive notifications when the inherent risk questionnaire target completion date is coming due. The default value for this field is based on the inherent risk questionnaire target completion date configured in Company Settings.';
export const TPRM_IRQ_TARGET_COMPLETION_DATE_UPDATE_SERVICE_MODAL = 'The third-party service manager will receive notifications when the inherent risk questionnaire target completion date is coming due.';
export const TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT = (
    <div className={styles.multipleLineTooltip}>
        <div>The most recent assessment for this third-party service has been submitted. The inherent risk questionnaire will be read-only until the next assessment is started.</div>
        <br />
        <div>A new assessment can be started via the Assessment Workflow section of the third-party service dashboard.</div>
    </div>
);
export const TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE = (
    <div className={styles.multipleLineTooltip}>
        <div>Because the third-party questionnaire is based on the selections made in the inherent risk questionnaire, the inherent risk questionnaire becomes read-only once submitted. After the current third-party service assessment is submitted and a new assessment is started, the inherent risk questionnaire can be edited again.</div>
        <br />
        <div>If changes to the inherent risk questionnaire are required for the current assessment, the assessment workflow may be restarted via the drop-down menu in the Assessment Workflow section of the third-party service dashboard.</div>
    </div>
);

// TODO: Clean these up. Make sure the wordings fit our standards, and remove any unnecessary tooltips.
export const TPRM_THIRD_PARTY_ADDRESS = 'The address of this third party.';
export const TPRM_THIRD_PARTY_CITY = 'The city in which this third party resides.';
export const TPRM_THIRD_PARTY_CONTACT_EMAIL = 'The email address of the contact at this third party.';
export const TPRM_THIRD_PARTY_CONTACT_NAME = 'The name of the contact at this third party.';
export const TPRM_THIRD_PARTY_CONTACT_PHONE_NUMBER = 'The phone number of the contact at this third party.';
export const TPRM_THIRD_PARTY_EIN = 'The unique identifier for the company, such as the EIN/TIN issued by the IRS.';
export const TPRM_THIRD_PARTY_MANAGER = 'The individual responsible for continuous oversight of the third party.';
export const TPRM_THIRD_PARTY_NAME = 'The full legal name of the third party.';
export const TPRM_THIRD_PARTY_WEBSITE = 'The website URL for the third party.';
export const TPRM_THIRD_PARTY_ZIP_CODE = 'The zip code in which this third party resides.';
