import type { JSX } from 'react';

import styles from './NavSubHeader.module.css';

interface NavSubHeaderProps {
    children: React.ReactNode;
}

export const NavSubHeader = (props: NavSubHeaderProps): JSX.Element => {
    return <div className={styles.navHeader}>{props.children}</div>;
};
