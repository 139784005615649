import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { type JSX, RefObject, useEffect, useRef } from 'react';

import { ChartDataItem, ChartDimensions, chartFont } from './charts.common';

export interface SegmentedProgressBarChartProps {
    name: string;
    data: ChartDataItem[];
    chartDimensions: ChartDimensions;
    onChartClick?: (item: string) => void;
    tooltipDirection?: 'bottom' | 'inside' | 'top' | 'left' | 'right';
}

export const SegmentedProgressBarChart = (props: SegmentedProgressBarChartProps): JSX.Element => {
    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);

    const chartRef = useRef<ReactECharts>(undefined) as RefObject<ReactECharts>;

    // This ensures that the chart does not re-render when a new onclick is created in the prop
    useEffect(() => {
        if (props.onChartClick !== undefined) {
            const instance = chartRef.current.getEchartsInstance();
            instance.on('click', (params) => {
                props.onChartClick!(params.seriesName!);
            });
        }
    }, [props]);

    const getOption = (info: Omit<SegmentedProgressBarChartProps, 'onChartClick'>, primaryFont: string): EChartsOption => ({
        aria: {
            show: true,
        },
        tooltip: {
            trigger: 'item',
            axisPointer: { show: false },
            formatter: (params: { marker: string; seriesName: string; data: number }) => {
                return `${info.name} <br/> ${params.marker}${params.seriesName} &emsp; <b>${params.data}</b>`;
            },
            position: props.tooltipDirection,
            confine: props.tooltipDirection !== undefined,
        },
        textStyle: {
            fontFamily: primaryFont,
        },
        grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        },
        legend: { show: false },
        xAxis: {
            type: 'value',
            show: false,
            axisPointer: {
                show: false,
            },
            max: 'dataMax',
        },
        yAxis: {
            type: 'category',
            data: [info.name],
            show: false,
            min: 'dataMin',
            max: 'dataMax',
            axisPointer: {
                show: false,
            },
        },
        series: createSeriesData(info.data),
    });

    const createSeriesData = (data: ChartDataItem[]) => {
        const newList = data.map((dataItem, i) => ({
            name: dataItem.name,
            type: 'bar',
            stack: 'value',
            // we cannot filter out all items with a 0 in them. and set border radius on first and last
            // if we did so adding items would append them to the end without a radius
            itemStyle: { borderRadius: i === 0 ? [100, 0, 0, 100] : 0, color: dataItem.color },
            emphasis: {
                focus: 'series',
            },

            data: [dataItem.value],
        }));

        // this loops through the options in reverse, get the first value without a 0 and sets the border radius for the last item
        for (let i = newList.length - 1; i >= 0; ) {
            if (newList[i].data[0] === 0) {
                i--;
            } else {
                newList[i].itemStyle.borderRadius = [0, 100, 100, 0];

                break;
            }
        }
        // this loops through the options, get the first value without a 0 and sets the border radius for the first item
        for (let i = 0; i < newList.length; ) {
            if (newList[i].data[0] === 0) {
                i++;
            } else {
                newList[i].itemStyle.borderRadius = [100, 0, 0, 100];

                break;
            }
        }
        return newList;
    };

    return <ReactECharts option={getOption({ ...props, data: props.data }, primaryFont)} style={{ height: props.chartDimensions.height, width: props.chartDimensions.width }} ref={chartRef} />;
};
