/**
 * TODO: Adjusting the browser width causes the content to get tucked behind the navigation menu. Fix that.
 */
import { type JSX, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { PageLayoutDashboardTabs } from 'Components/PageLayout/PageLayoutDashboard';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { DashboardType } from 'Models/Dashboards';
import { Effectiveness, effectivenessLabelAsNumber } from 'Models/OperationalControls';
import { RiskRating, ThirdPartyResponseWithServices, riskRatingLabelAsNumber } from 'Models/TPRM';

import { DashboardClosedExceptions } from './Components/DashboardsHistoricalExceptions/DashboardsHistoricalExceptions';
import { DashboardClosedIssues } from './Components/DashboardsHistoricalIssues/DashboardsHistoricalIssues';
import { DashboardsTrackingExceptions } from './Components/DashboardsTrackingExceptions/DashboardsTrackingExceptions';
import { DashboardOpenIssues } from './Components/DashboardsTrackingIssues/DashboardsTrackingIssues';
import { AssessmentsSchedule } from './OperationalControlsDashboard/AssessmentsSchedule/AssessmentsSchedule';
import { ComplianceRequirements } from './OperationalControlsDashboard/ComplianceRequirements/ComplianceRequirements';
import { ControlStrength } from './OperationalControlsDashboard/ControlStrength/ControlStrength';
import { ControlsOverTime } from './OperationalControlsDashboard/ControlsOverTime/ControlsOverTime';
import { useControlStrengthQuery, useCurrentComplianceSummaryQuery } from './OperationalControlsDashboard/OperationalControlsDashboard.hooks';
import { RequirementsOverview } from './OperationalControlsDashboard/RequirementsOverview/RequirementsOverview';
import { SelectedServiceDetails, ServicesDisplayModal } from './TPRMDashboard/ServicesDisplayModal/ServicesDisplayModal';
import { TPRMCompletedAssessmentsChart } from './TPRMDashboard/TPRMAssessmentsChart/TPRMCompletedAssessmentsChart';
import { TPRMUpcomingAssessmentsChart } from './TPRMDashboard/TPRMAssessmentsChart/TPRMUpcomingAssessmentsChart';
import { buildEffectivenessChartData, buildRiskRatingsChartData } from './TPRMDashboard/TPRMDashboard.helpers';
import { useThirdPartyServicesQuery } from './TPRMDashboard/TPRMDashboard.hooks';
import { TPRMDonutCharts } from './TPRMDashboard/TPRMDonutCharts/TPRMDonutCharts';
import { RiskPortfolioHistoryChart } from './TPRMDashboard/TPRMRiskPortfolioHistoryChart/TPRMRiskPortfolioHistoryChart';
import { TPRMRiskProgressCharts } from './TPRMDashboard/TPRMRiskProgressCharts/TPRMRiskProgressCharts';
import { TPRMServiceCallouts } from './TPRMDashboard/TPRMServiceCallouts/TPRMServiceCallouts';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface DashboardsProps {
    dashboardApi: DashboardApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    tprmApi: TPRMApi;
}

export const Dashboards = (props: DashboardsProps): JSX.Element => {
    const userContext = useContext(RBACContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [controlStrength, controlStrengthError] = useControlStrengthQuery(props.dashboardApi);
    const [complianceSummary, complianceSummaryError] = useCurrentComplianceSummaryQuery(props.dashboardApi);
    const { thirdPartyCount, thirdPartyServices, thirdPartyServicesOverdue, thirdPartyServicesError } = useThirdPartyServicesQuery(props.dashboardApi);
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();

    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();
    const [thirdPartyError, setThirdPartyError] = useState<string>();

    useEffect(() => {
        const getThirdParties = async (): Promise<void> => {
            try {
                const thirdPartyResponse = await props.tprmApi.getThirdParties();
                const thirdParties = thirdPartyResponse.data;

                setThirdParties(thirdParties);
            } catch (error) {
                setThirdPartyError(error.message);
            }
        };
        getThirdParties();
    }, [props.tprmApi]);

    const inherentRiskDonutChart = useMemo(() => {
        if (thirdPartyServices) {
            const scores: number[] = thirdPartyServices.map((service) => service.inherent_risk_score);
            return buildRiskRatingsChartData(scores, thirdPartyServices.length);
        }
    }, [thirdPartyServices]);

    const controlEffectivenessDonutChart = useMemo(() => {
        if (thirdPartyServices) {
            const scores = thirdPartyServices.map((service) => service.assessment_control_effectiveness);
            return buildEffectivenessChartData(scores, thirdPartyServices.length);
        }
    }, [thirdPartyServices]);

    const residualRiskDonutChart = useMemo(() => {
        if (thirdPartyServices) {
            const scores = thirdPartyServices.map((service) => service.assessment_residual_risk_score);
            return buildRiskRatingsChartData(scores, thirdPartyServices.length);
        }
    }, [thirdPartyServices]);

    const activeHash = (() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (Object.values(DashboardType).includes(strippedHash as DashboardType)) {
                return strippedHash;
            }
        }
        return DashboardType.OPERATIONAL_CONTROLS;
    })();

    const handleEffectivenessChartClick = (effectiveness: string): void => {
        const effectivenessScore = effectivenessLabelAsNumber(effectiveness);
        setServicesModalSubtitle(effectiveness);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            // Special logic needed here because INACTIVE and undefined should be considered equivalent.
            thirdPartyServices!
                .filter((service) => service.assessment_control_effectiveness === effectivenessScore || (effectivenessScore === Effectiveness.INACTIVE && service.assessment_control_effectiveness === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleInherentRiskChartClick = (targetRating: string): void => {
        const targetRatingScore = riskRatingLabelAsNumber(targetRating);
        setServicesModalSubtitle(targetRating);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            // Special logic is not _currently_ needed here, as the inherent risk score is always defined. However, the same special logic as below is included here to prevent future bugs if inherent risk scores are ever undefined in the future.
            thirdPartyServices!
                .filter((service) => service.inherent_risk_score === targetRatingScore || (targetRatingScore === RiskRating.INACTIVE && service.inherent_risk_score === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleOverdueAssessmentChartClick = () => {
        if (thirdPartyServicesOverdue) {
            setModalState(Modals.DISPLAY_SERVICES);
        }
    };

    const handleResidualRiskChartClick = (targetRating: string): void => {
        const targetRatingScore = riskRatingLabelAsNumber(targetRating);
        setServicesModalSubtitle(targetRating);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            // Special logic needed here because INACTIVE and undefined should be considered equivalent.
            thirdPartyServices!
                .filter((service) => service.assessment_residual_risk_score === targetRatingScore || (targetRatingScore === RiskRating.INACTIVE && service.assessment_residual_risk_score === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    const onTabSelect = (key?: string) => {
        if (key) {
            navigate(`${location.pathname}#${key}`, { replace: true });
        }
    };

    const overDueAssessmentServices: SelectedServiceDetails[] | undefined = thirdPartyServicesOverdue?.map((thirdPartyService) => {
        return {
            serviceId: thirdPartyService.id,
            serviceName: thirdPartyService.name,
            thirdPartyId: thirdPartyService.third_party_id,
            thirdPartyName: thirdPartyService.third_party_name,
        };
    });

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && overDueAssessmentServices && <ServicesDisplayModal hideModal={handleServicesModalClose} selectedServices={overDueAssessmentServices} subtitle={'Overdue Assessments'} />}
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            {userContext && userHasAuthorizedRole(userContext.roles, [Role.ADMIN, Role.REPORTING_USER]) ? (
                <PageLayoutDashboardTabs
                    headerTitle="Dashboards"
                    defaultTab={activeHash}
                    onTabChange={onTabSelect}
                    tabs={[
                        {
                            title: 'Control Automation',
                            dashboardType: DashboardType.OPERATIONAL_CONTROLS,
                            dashlets: [
                                {
                                    dashlets: [
                                        {
                                            title: 'Overall Control Effectiveness',
                                            content: <ControlStrength controlStrength={controlStrength} controlStrengthError={controlStrengthError} />,
                                        },
                                        {
                                            title: 'Compliance Requirements',
                                            content: <RequirementsOverview complianceSummary={complianceSummary} complianceSummaryError={complianceSummaryError} api={props.dashboardApi} />,
                                        },
                                    ],
                                },
                                {
                                    content: <ControlsOverTime dashboardApi={props.dashboardApi} controlStrength={controlStrength} controlStrengthError={controlStrengthError} />,
                                },
                                {
                                    title: 'Assessment Schedule',
                                    content: <AssessmentsSchedule api={props.dashboardApi} />,
                                },
                                {
                                    content: <ComplianceRequirements complianceSummary={complianceSummary} complianceSummaryError={complianceSummaryError} api={props.dashboardApi} />,
                                },
                                {
                                    dashlets: [
                                        {
                                            content: <DashboardClosedIssues issuesApi={props.issuesApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />,
                                        },
                                        {
                                            content: <DashboardOpenIssues issuesApi={props.issuesApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />,
                                        },
                                    ],
                                },
                                {
                                    dashlets: [
                                        {
                                            content: <DashboardClosedExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />,
                                        },
                                        {
                                            content: <DashboardsTrackingExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Third-Party Risk Management',
                            dashboardType: DashboardType.THIRD_PARTY,
                            dashlets: [
                                {
                                    dashlets: [
                                        {
                                            title: 'Total Third Parties',
                                            content: <TPRMServiceCallouts count={thirdPartyCount} onclick={() => navigate(`/${TPRM}/${THIRD_PARTIES}`)} />,
                                        },
                                        {
                                            title: 'Total Third-Party Services',
                                            content: <TPRMServiceCallouts count={thirdPartyServices?.length} onclick={() => navigate(`/${TPRM}/${SERVICES}`)} />,
                                        },
                                        {
                                            title: 'Overdue Assessments',
                                            content: <TPRMServiceCallouts count={thirdPartyServicesOverdue?.length} countHighlight={true} onclick={handleOverdueAssessmentChartClick} />,
                                        },
                                    ],
                                },
                                {
                                    dashlets: thirdPartyServicesError
                                        ? [
                                              {
                                                  title: 'Inherent Risk',
                                                  content: <Text color="white">{thirdPartyServicesError}</Text>,
                                              },
                                              {
                                                  title: 'Overall Control Effectiveness',
                                                  content: <Text color="white">{thirdPartyServicesError}</Text>,
                                              },
                                              {
                                                  title: 'Residual Risk',
                                                  content: <Text color="white">{thirdPartyServicesError}</Text>,
                                              },
                                          ]
                                        : [
                                              {
                                                  title: 'Inherent Risk',
                                                  content: <TPRMDonutCharts chartName="Inherent Risk" chartData={inherentRiskDonutChart} onClick={handleInherentRiskChartClick} />,
                                              },
                                              {
                                                  title: 'Overall Control Effectiveness',
                                                  content: <TPRMDonutCharts chartName="Control Effectiveness" chartData={controlEffectivenessDonutChart} onClick={handleEffectivenessChartClick} />,
                                              },
                                              {
                                                  title: 'Residual Risk',
                                                  content: <TPRMDonutCharts chartName="Residual Risk" chartData={residualRiskDonutChart} onClick={handleResidualRiskChartClick} />,
                                              },
                                          ],
                                },
                                {
                                    dashlets: [
                                        {
                                            content: <TPRMCompletedAssessmentsChart dashboardApi={props.dashboardApi} />,
                                        },
                                        {
                                            content: <TPRMUpcomingAssessmentsChart thirdPartyServices={thirdPartyServices} thirdPartyServicesError={thirdPartyServicesError} />,
                                        },
                                    ],
                                },
                                {
                                    dashlets: [
                                        {
                                            title: 'Assessments Not Scheduled',
                                            content: <TPRMRiskProgressCharts thirdPartyServices={thirdPartyServices} thirdPartyServicesError={thirdPartyServicesError} />,
                                        },
                                        {
                                            content: <RiskPortfolioHistoryChart dashboardApi={props.dashboardApi} />,
                                        },
                                    ],
                                },
                                {
                                    dashlets: [
                                        {
                                            content: <DashboardClosedIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />,
                                        },
                                        {
                                            content: <DashboardOpenIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />,
                                        },
                                    ],
                                },
                                {
                                    dashlets: [
                                        {
                                            content: <DashboardClosedExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />,
                                        },
                                        {
                                            content: <DashboardsTrackingExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ]}
                />
            ) : (
                <Text color="white">{UNAUTHORIZED_MESSAGE}</Text>
            )}
        </>
    );
};
