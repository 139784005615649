import { Fragment, type JSX } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';
import { ThirdPartyContact } from 'Models/TPRM';

import styles from './ThirdPartyContactModal.module.css';

export interface ThirdPartyContactModalProps {
    header: string;
    hideModal: () => void;
    thirdPartyContacts: ThirdPartyContact[];
}

export const ThirdPartyContactModal = (props: ThirdPartyContactModalProps): JSX.Element => {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.header} />
                {props.thirdPartyContacts.map((thirdPartyContact, index) => (
                    <Fragment key={index}>
                        <div className={styles.thirdPartyContactAttributeGroup}>
                            <div className={styles.thirdPartyContactAttribute}>
                                <Text noStyles={true}>{thirdPartyContact.name ? thirdPartyContact.name : '-'}</Text>
                            </div>
                            <div className={styles.thirdPartyContactAttributeEmail}>
                                <Text noStyles={true}>{thirdPartyContact.email_address ? thirdPartyContact.email_address : '-'}</Text>
                            </div>
                            <div className={styles.thirdPartyContactAttribute}>
                                <Text noStyles={true}>{thirdPartyContact.phone_number ? thirdPartyContact.phone_number : '-'}</Text>
                            </div>
                        </div>
                        {thirdPartyContact.additional_information && <Text>{thirdPartyContact.additional_information}</Text>}
                    </Fragment>
                ))}
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
