import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { SaveControlGroupFormFields, SaveControlGroupFromFieldsProps, useSaveControlGroup } from 'Components/OperationalControls/SaveControlGroup/SaveControlGroup';
import { ICON_CLOSE } from 'Config/Icons';

export interface CreateControlGroupModalProps {
    hideModal: () => void;
    onControlGroupCreated: () => void;
    controlsApi: ControlsApi;
    controlFramework: string;
}

export const CreateControlGroupModal = (props: CreateControlGroupModalProps) => {
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const { saveControlGroupStatus, saveControlGroupFormFieldsValues, error, saveControlGroup, handleChange } = useSaveControlGroup(props.controlFramework, props.controlsApi, {
        customControlGroupName: '',
        customControlGroupDescription: '',
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await saveControlGroup();
    };

    const { onControlGroupCreated } = props;

    useEffect(() => {
        switch (saveControlGroupStatus) {
            case 'saved':
                setSuccessMessage('Control group created.');
                onControlGroupCreated();
                break;
            case 'error':
                setFailureMessage(error?.message);
                break;
            default:
                setSuccessMessage(undefined);
                setFailureMessage(undefined);
        }
    }, [error, onControlGroupCreated, saveControlGroupStatus]);

    const saveControlGroupFormFieldsProps: SaveControlGroupFromFieldsProps = {
        saveControlGroupFormFieldsValues: saveControlGroupFormFieldsValues,
        handleChange: handleChange,
        disabled: saveControlGroupStatus === 'saved',
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && error && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Create Custom Control Group" />
                    <SaveControlGroupFormFields {...saveControlGroupFormFieldsProps} />
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={saveControlGroupStatus === 'saved'} isLoading={saveControlGroupStatus === 'saving'} loadingText="Saving...">
                            CREATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
