import { type JSX, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';

import styles from './DeleteAssociatedControlsModal.module.css';
export interface DeleteAssociatedControlsModalProps {
    performDelete: () => Promise<void>;
    associatedControls: string[];
    hideModal: () => void;
}
interface FormState {
    successMessage?: string;
    failureMessage?: string;
}

export const DeleteAssociatedControlsModal = (props: DeleteAssociatedControlsModalProps): JSX.Element => {
    const [isDeletingControls, setIsDeletingControls] = useState(false);
    const [formState, setFormState] = useState<FormState>({});
    const deleteControls = async (): Promise<void> => {
        setFormState({ successMessage: undefined, failureMessage: undefined });
        try {
            setIsDeletingControls(true);
            await props.performDelete();
            setFormState({
                successMessage: 'Controls unmapped.',
            });
        } catch (error) {
            setFormState({ failureMessage: error.message });
        } finally {
            setIsDeletingControls(false);
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {formState.successMessage && <Alert variant="success">{formState?.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState?.failureMessage}</Alert>}
                <ModalHeader text="Unmap Associated Controls" />
                {props.associatedControls === undefined || props.associatedControls.length === 0 ? (
                    <Text variant="Text3">No controls mapped.</Text>
                ) : (
                    <>
                        <Text variant="Text2">Are you sure you want to unmap the following controls?</Text>
                        <ul className={styles.controlsContainer}>
                            {props.associatedControls.map((control, index) => (
                                <Text key={index} variant="Text3" color="blue">
                                    <li>{control}</li>
                                </Text>
                            ))}
                        </ul>
                    </>
                )}
                <div className={styles.modalFormButtonContainer}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE} disabled={isDeletingControls}>
                        Close
                    </Button>
                    <Button variant="danger" fontAwesomeImage={ICON_DELETE_REMOVE} onClick={deleteControls} loadingText={'Deleting...'} isLoading={isDeletingControls} disabled={formState.successMessage ? true : false}>
                        Delete
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
