/*
	AWSFrameworkApi.ts -- AWS-specific interface used by Framework Dashboard to get control framework details
*/
import { ASSESSMENTS, CONFIGURATION, CONTROLS, CUSTOM, EVIDENCE, FRAMEWORKS, GROUPS, HISTORY, OPERATIONAL_CONTROLS, TARGET_EFFECTIVENESS, TOGGLE } from 'Config/Paths';
import { UpdateAssessmentConfigurationRequest, UpdateTargetEffectivenessRequest } from 'Models/Configuration';
import { AddEvidenceRequest, GetControlEvidenceResponse } from 'Models/ControlEvidence';
import { ControlHierarchyFramework } from 'Models/ControlHierarchy';
import { AllCustomControlsControlFrameworkResponse, Assessment, AssessmentHistory, ControlGroupResponse, DetailedControlFrameworkResponse, DetailedControlGroupResponse, OperationalControl, SaveControlGroupRequest, SaveControlRequest, UpdateAssessmentRequest } from 'Models/OperationalControls';
import { ResponseModel } from 'Models/ResponseModel';
import { ToggleControlsRequest } from 'Models/ToggleControls';
import { restApiDelete, restApiGet, restApiPost, restApiPut } from 'RestApiConnector';

import { ControlsApi } from './ControlsApi';

export class AWSControlsApi implements ControlsApi {
    //Operation Controls Hierarchy
    getControlFrameworks = (): Promise<ResponseModel<DetailedControlFrameworkResponse[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}`, {});
    };

    getControlFrameworkConfiguration = (control_framework: string): Promise<ResponseModel<DetailedControlFrameworkResponse>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}`, {});
    };

    updateFramework = (control_framework: string, control_configuration: UpdateAssessmentConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${CONFIGURATION}`, control_configuration);
    };

    deleteControlFrameworkConfiguration = (control_framework: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${CONFIGURATION}`);
    };

    getControlGroups = (control_framework: string): Promise<ResponseModel<ControlGroupResponse[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}`, {});
    };

    getControlGroupConfiguration = (control_framework: string, control_group_id: string): Promise<ResponseModel<DetailedControlGroupResponse>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}`);
    };

    updateControlGroupConfiguration = (control_framework: string, control_group_id: string, control_configuration: UpdateAssessmentConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONFIGURATION}`, control_configuration);
    };

    deleteControlGroupConfiguration = (control_framework: string, control_group_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONFIGURATION}`);
    };

    getControls = (control_framework: string, control_group_id: string, appliedFilters: { [key: string]: string[] }): Promise<ResponseModel<OperationalControl[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}`, appliedFilters);
    };

    getControlDetails = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<OperationalControl>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}`, {});
    };

    updateControlAssessmentConfiguration = (control_framework: string, control_group_id: string, control_id: string, control_configuration: UpdateAssessmentConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${CONFIGURATION}`, control_configuration);
    };

    deleteControlAssessmentConfiguration = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${CONFIGURATION}`);
    };

    //Custom Controls
    saveCustomControlGroup = (control_framework: string, save_control_group: SaveControlGroupRequest, control_group_id?: string): Promise<ResponseModel<DetailedControlGroupResponse>> => {
        return restApiPost(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}${control_group_id ? `/${control_group_id}` : ''}`, save_control_group);
    };

    saveCustomControl = (control_framework: string, control_group_id: string, save_control_request: SaveControlRequest, control_id?: string): Promise<ResponseModel<OperationalControl>> => {
        return restApiPost(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}${control_id ? `/${control_id}` : ''}`, save_control_request);
    };

    deleteControlGroup = (control_framework: string, control_group_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/`);
    };

    deleteControl = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}`);
    };

    getAllCustomControls = (): Promise<ResponseModel<AllCustomControlsControlFrameworkResponse[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${CUSTOM}`, {});
    };

    //Assessment
    updateAssessment = (control_framework: string, control_group_id: string, control_id: string, assessment: UpdateAssessmentRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${ASSESSMENTS}`, assessment);
    };

    getAssessmentHistory = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<AssessmentHistory[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${ASSESSMENTS}/${HISTORY}`, {});
    };

    getAssessmentDetails = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<Assessment>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${ASSESSMENTS}`, {});
    };

    //Evidence
    addEvidence = (control_framework: string, control_group_id: string, control_id: string, addEvidenceRequest: AddEvidenceRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${EVIDENCE}`, addEvidenceRequest);
    };

    getControlEvidence = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<GetControlEvidenceResponse>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${EVIDENCE}`, {});
    };

    //Supporting Calls
    getControlsHierarchy = (): Promise<ResponseModel<ControlHierarchyFramework[]>> => {
        return restApiGet(`/${OPERATIONAL_CONTROLS}`, {});
    };

    toggleControls = (toggleControlsRequest: ToggleControlsRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${OPERATIONAL_CONTROLS}/${TOGGLE}`, toggleControlsRequest);
    };

    //Target Effectiveness
    updateControlTargetEffectiveness = (control_framework: string, control_group_id: string, control_id: string, control_configuration: UpdateTargetEffectivenessRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${TARGET_EFFECTIVENESS}`, control_configuration);
    };

    updateControlGroupTargetEffectiveness = (control_framework: string, control_group_id: string, control_configuration: UpdateTargetEffectivenessRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${TARGET_EFFECTIVENESS}`, control_configuration);
    };

    updateFrameworkTargetEffectiveness = (control_framework: string, control_configuration: UpdateTargetEffectivenessRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${control_framework}/${TARGET_EFFECTIVENESS}`, control_configuration);
    };
}
