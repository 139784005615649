import { type JSX, useState } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';
import { ICON_DOWNLOAD } from 'Config/Icons';
import { saveFileFromApi } from 'Helpers/FileUtils';

import styles from '../ConfigureIntegration.module.css';
import { AlertState } from './AwsConfigSetupWizard';
import { AwsConfigSetupWizardButtonRow } from './AwsConfigSetupWizardButtonRow';

export interface AwsConfigSetupStepDownloadTemplateProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    hasPreviousStep: boolean;
    hasNextStep: boolean;
    setAlertState: (alertState: AlertState) => void;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
    hideModal: () => void;
}

export const AwsConfigSetupStepDownloadTemplate = (props: AwsConfigSetupStepDownloadTemplateProps): JSX.Element => {
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

    const downloadManagementPermissionsTemplate = async (): Promise<void> => {
        try {
            setDownloadInProgress(true);
            props.setAlertState(undefined);

            const fileDownload = (await props.externalIntegrationsApi.getManagementPermissionsTemplate()).data;
            saveFileFromApi('plaintext', fileDownload);
        } catch (error) {
            props.setAlertState({ variant: 'danger', message: `The template could not be downloaded. ${error.message}` });
        } finally {
            setDownloadInProgress(false);
        }
    };

    return (
        <>
            <Text>
                To allow data points to be gathered for your enabled AWS Config metrics, SummIT Security will deploy AWS resources across your organization. Before proceeding to the next step, download the CloudFormation template below and use it to{' '}
                <a className={styles.link} target="_blank" rel="noopener noreferrer" href="https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/cfn-console-create-stack.html">
                    deploy a stack
                </a>{' '}
                in your AWS Organizations{' '}
                <a className={styles.link} target="_blank" rel="noopener noreferrer" href="https://docs.aws.amazon.com/organizations/latest/userguide/orgs_getting-started_concepts.html">
                    management account
                </a>
                .
            </Text>
            <AwsConfigSetupWizardButtonRow {...props}>
                <div className={styles.buttonPadding}>
                    <Button variant="primary" onClick={downloadManagementPermissionsTemplate} fontAwesomeImage={ICON_DOWNLOAD} isLoading={downloadInProgress} loadingText="Downloading">
                        DOWNLOAD TEMPLATE
                    </Button>
                </div>
            </AwsConfigSetupWizardButtonRow>
        </>
    );
};
