import { type JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutHybridDashboard } from 'Components/PageLayout/PageLayoutHybridDashboard';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { Text } from 'Components/Text/Text';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment } from 'Models/RiskRegister';

import styles from './ViewRiskAssessment.module.css';
import { RiskListingCallout } from '../RiskListing/RiskListingCallout/RiskListingCallout';

export interface UrlParams {
    timestamp: string;
}

export interface ViewRiskAssessmentProps {
    riskRegisterApi: RiskRegisterApi;
    documentApi: DocumentApi;
    tagsApi: TagsApi;
}

export const ViewRiskAssessment = (props: ViewRiskAssessmentProps): JSX.Element => {
    const { timestamp } = useParams<keyof UrlParams>() as UrlParams;
    const [pageErrorMessage, setPageErrorMessage] = useState<string>();
    const [previousAssessment, setPreviousAssessment] = useState<RiskAssessment>();
    const [overallControlEffectiveness, setOverallControlEffectiveness] = useState<number>();
    const cachedData = useCachedData();
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);

    useEffect(() => {
        const getRiskAssessment = async (): Promise<void> => {
            try {
                const riskAssessmentResponse: ResponseModel<RiskAssessment> = await props.riskRegisterApi.getRiskAssessment(timestamp);
                setPreviousAssessment(riskAssessmentResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };
        getRiskAssessment();
    }, [props.riskRegisterApi, timestamp]);

    useEffect(() => {
        const fetchOverallControlEffectiveness = async (): Promise<void> => {
            try {
                const effectivenessResponse = await props.riskRegisterApi.getOverallControlEffectiveness();
                setOverallControlEffectiveness(effectivenessResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        fetchOverallControlEffectiveness();
    }, [props.riskRegisterApi]);

    if (pageErrorMessage) {
        return <Text>{pageErrorMessage}</Text>;
    }

    if (previousAssessment && overallControlEffectiveness !== undefined && getDisplayableTagsState.type === 'success') {
        return (
            <PageLayoutHybridDashboard
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${RISK_REGISTER}/${RISK_REVIEW}`}>Risk Reviews</BreadcrumbLink>
                        <BreadcrumbText>View Risk Review</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="View Risk Review"
                headerDashlets={[
                    {
                        title: 'Risk Review Details',
                        content: (
                            <>
                                <div className={styles.detailContainer}>
                                    <div className={styles.stat}>
                                        <Text noStyles variant="Header4" color="darkGray">
                                            Review Date
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {iso8601ToUsDateShort(previousAssessment.timestamp)}
                                        </Text>
                                    </div>
                                    <div className={styles.stat}>
                                        <Text noStyles variant="Header4" color="darkGray">
                                            Submitted By
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {getUserNameFromSubject(previousAssessment.submitted_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}
                                        </Text>
                                    </div>
                                    <div className={styles.stat}>
                                        <Text noStyles variant="Header4" color="darkGray">
                                            Category
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {previousAssessment.category.title}
                                        </Text>
                                    </div>
                                </div>
                                <div className={styles.assessmentSection}>
                                    <Text variant="Header4" color="darkGray">
                                        Comments
                                    </Text>
                                    <Text variant="Text3" color="white">
                                        {previousAssessment.comments}
                                    </Text>
                                </div>
                                <div className={styles.assessmentSection}>
                                    <Text variant="Header4" color="darkGray">
                                        Attached Files
                                    </Text>
                                    {previousAssessment!.documents?.map((document) => {
                                        return <UploadedFileAndState documentApi={props.documentApi} file={document} key={document.file_id} />;
                                    })}
                                </div>
                            </>
                        ),
                    },
                    {
                        dashlets: [
                            {
                                title: 'Inherent Risk',
                                content: <RiskListingCallout calloutType="total_inherent_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={previousAssessment.risk_histories} />,
                            },
                            {
                                title: 'Control Effectiveness',
                                titleTooltip: 'Risk-related control effectiveness is the average of all controls that are mapped to risks. Overall control effectiveness is the average of all controls in the system.',
                                content: <RiskListingCallout calloutType="control_environment_effectiveness" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={previousAssessment.risk_histories} />,
                            },
                            {
                                title: 'Current Residual Risk',
                                content: <RiskListingCallout calloutType="total_current_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={previousAssessment.risk_histories} />,
                            },
                            {
                                title: 'Target Residual Risk',
                                titleTooltip: 'The calculation of target residual risk takes all risks into account. If no target residual risk has been defined for a risk, then the current residual risk (if defined) is used.',
                                content: <RiskListingCallout calloutType="total_target_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={previousAssessment.risk_histories} />,
                            },
                        ],
                    },
                ]}
                body={[
                    {
                        title: 'Risk Listing',
                        content: <RiskTable type="assessment risks" assessmentTimestamp={previousAssessment.timestamp} riskHistories={previousAssessment.risk_histories} />,
                    },
                ]}
            />
        );
    } else return <Placeholder />;
};
