import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { TextBasedGovernanceVersion } from 'Models/Governance';

import styles from './ViewGovernanceLibraryDefinitionModal.module.css';

export interface ViewGovernanceLibraryDefinitionModalProps {
    hideModal: () => void;
    governance: TextBasedGovernanceVersion;
}

export const ViewGovernanceLibraryDefinitionModal = (props: ViewGovernanceLibraryDefinitionModalProps) => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.governance.title} />
                <div className={styles.commentText}>{props.governance.text}</div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
