import type { JSX } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';

import styles from './ServicesDisplayModal.module.css';

export interface SelectedServiceDetails {
    serviceId: string;
    serviceName: string;
    thirdPartyId: string;
    thirdPartyName: string;
}

export interface ServicesDisplayModalProps {
    hideModal: () => void;
    subtitle: string;
    selectedServices: SelectedServiceDetails[];
}

export const ServicesDisplayModal = (props: ServicesDisplayModalProps): JSX.Element => {
    const selectedServiceListing = props.selectedServices.map((service) => (
        <div className={styles.detailsContainer} key={service.serviceId}>
            <Link openInNewTab to={`/${TPRM}/${THIRD_PARTIES}/${service.thirdPartyId}/${SERVICES}/${service.serviceId}/${DASHBOARDS}`} size="sm">{`${service.thirdPartyName} - ${service.serviceName}`}</Link>
        </div>
    ));

    return <CollapsibleModal displayItems={selectedServiceListing} headerText={{ text: 'Third-Party Services', secondaryText: props.subtitle }} hideModal={props.hideModal} />;
};
