import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { type JSX, RefObject, useEffect, useRef } from 'react';

import { ChartAxis, ChartSeries, axisColor, chartColor, chartFont } from './charts.common';

export interface BarAndLineChartProps {
    onChartClick: (item: string) => void;
    xAxisLabels: string[];
    yAxisInfo: ChartAxis[];
    bar: ChartSeries;
    line?: ChartSeries;
}

export const BarAndLineChart = (props: BarAndLineChartProps): JSX.Element => {
    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);
    const labelColor = getComputedStyle(document.documentElement).getPropertyValue(chartColor);

    const chartRef = useRef<ReactECharts>(null) as RefObject<ReactECharts>;

    // This ensures that the chart does not re-render when a new onclick is created in the prop
    useEffect(() => {
        const instance = chartRef.current.getEchartsInstance();
        instance.on('click', (params) => {
            props.onChartClick(params.name);
        });
    }, [props]);

    const getOption = (info: Omit<BarAndLineChartProps, 'onChartClick'>, primaryFont: string, labelColor: string): EChartsOption => {
        if (info.yAxisInfo.length < 2) {
            info.yAxisInfo.push({ name: '', position: 'right' });
        }

        return {
            aria: {
                enabled: true,
                data: {
                    maxCount: 0,
                },
            },
            tooltip: {
                trigger: 'item',
            },

            legend: { show: false },
            textStyle: {
                fontFamily: primaryFont,
            },
            grid: {
                containLabel: true,
                left: '10%',
                right: '10%',
                top: '10%',
                bottom: '10%',
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: info.xAxisLabels,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: axisColor,
                        },
                    },
                    axisLabel: {
                        color: labelColor,
                    },
                },
            ],
            yAxis: info.yAxisInfo.map((yAxis) => ({
                type: 'value',
                name: yAxis.name,
                position: yAxis.position,
                min: yAxis.min,
                max: yAxis.max,
                minInterval: yAxis.minInterval,
                maxInterval: yAxis.maxInterval,
                interval: yAxis.interval,
                alignTicks: true,
                show: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: axisColor,
                    },
                },
                axisLabel: {
                    formatter: yAxis.labelFormatter,
                    color: labelColor,
                },
                nameLocation: 'middle',
                nameGap: 50,
                nameRotate: yAxis.position === 'left' ? 90 : 270,
                splitLine: {
                    lineStyle: {
                        color: axisColor,
                    },
                },
                nameTextStyle: {
                    color: labelColor,
                },
            })),
            series: [
                {
                    name: info.bar.name,
                    type: 'bar',
                    itemStyle: { borderRadius: [50, 50, 0, 0], color: info.bar.color },
                    data: [...info.bar.data],
                    barWidth: '10%',
                },
            ],
        };
    };

    return (
        <ReactECharts
            option={getOption(props, primaryFont, labelColor)}
            style={{
                height: '100%',
                width: '100%',
                minHeight: '300px',
                minWidth: '200px',
                maxHeight: '300px',
            }}
            ref={chartRef}
        />
    );
};
