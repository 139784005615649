import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { current, produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { Legend } from 'Components/Legend/Legend';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_ADD_CREATE, ICON_DELETE_REMOVE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { CONFIGURATION } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { controlComparator, sortGenericControlHierarchy } from 'Models/ControlHierarchy';
import { ControlText } from 'Models/OperationalControls';
import { ControlConfiguration, QuestionType, QuestionnaireConfigurationResponse, RiskRating, UpdateConfigurationRequest, UpdateControlConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';

import { Checkboxes, CheckboxesProps, CheckedStatus } from './Checkboxes/Checkboxes';
import styles from './DueDiligenceQuestionnaireConfiguration.module.css';
import { DueDiligenceQuestionnaireConfigurationRow } from './DueDiligenceQuestionnaireConfigurationRow/DueDiligenceQuestionnaireConfigurationRow';
import { CreateControlFrameworkModal } from './Modals/CreateControlFrameworkModal';
import { CreateControlGroupModal } from './Modals/CreateControlGroupModal';
import { CreateControlModal } from './Modals/CreateControlModal';
import { CreateQuestionModal } from './Modals/CreateQuestionModal';
import { DeleteControlFrameworkModal } from './Modals/DeleteControlFrameworkModal';
import { DeleteControlGroupModal } from './Modals/DeleteControlGroupModal';
import { DeleteControlModal } from './Modals/DeleteControlModal';
import { DeleteQuestionModal } from './Modals/DeleteQuestionModal';
import { UpdateControlFrameworkModal } from './Modals/UpdateControlFrameworkModal';
import { UpdateControlGroupModal } from './Modals/UpdateControlGroupModal';
import { UpdateControlModal } from './Modals/UpdateControlModal';
import { UpdateQuestionModal } from './Modals/UpdateQuestionModal';

export enum PageElements {
    CreateFrameworkModal,
    UpdateFrameworkModal,
    DeleteFrameworkModal,
    CreateGroupModal,
    UpdateGroupModal,
    DeleteGroupModal,
    CreateControlModal,
    UpdateControlModal,
    DeleteControlModal,
    CreateQuestionModal,
    UpdateQuestionModal,
    DeleteQuestionModal,
    None,
}

export interface QuestionnaireConfigurationProps {
    tprmApi: TPRMApi;
}

export interface Framework extends Checkable {
    framework: string;
    frameworkName: string;
    frameworkVersion: string;
    groups: Map<string, Group>;
    overflowItems?: OverflowMenuProps;
}

export interface Group extends Checkable {
    framework: string;
    groupId: string;
    groupName: string;
    groupDescription?: string;
    controls: Map<string, Control>;
    overflowItems?: OverflowMenuProps;
    displayText: string;
}

export interface Control extends Checkable {
    framework: string;
    groupId: string;
    controlId: string;
    controlText: ControlText[];
    controlName?: string;
    questions: Map<number, Question>;
    overflowItems?: OverflowMenuProps;
    displayText: string;
}
export interface Question extends Checkable {
    _type: QuestionType;
    text: string;
    riskRatings: RiskRating[];
    options?: string[];
    overflowItems?: OverflowMenuProps;
}

/**
 * Questions are updated and deleted by updating and deleting a parent control.
 * This interface represents the properties required to update or delete a question via a control.
 */
export interface SelectedQuestionWrapper {
    framework: string;
    groupId: string;
    controlId: string;
    selectedQuestionIndex: number;
    controlQuestions: Map<number, Question>;
}

/**
 * Represents an item in the operational control hierarchy.
 * Items can be CRUDed and have their risk rating checkboxes checked.
 */
type Item = Framework | Group | Control | SelectedQuestionWrapper;

/**
 * The required properties of a modal that CRUDS an item.
 */
export interface QuestionnaireConfigurationModal {
    tprmApi: TPRMApi;
    hideModal: () => void;
    onModalActionComplete: () => void;
}

/**
 * The key for getting an item out of the hierarchy.
 */
interface ItemKey {
    framework: string;
    group?: string;
    control?: string;
    questionIndex?: number;
}

/**
 * When a risk rating is checked, this interface will be delivered.
 *
 * It indicates the selected risk rating checkbox and whether or not it is currently checked.
 */
interface CheckedRiskRating {
    riskRating: RiskRating;
    checked: boolean;
}

/**
 * Properties required for risk rating checkboxes for an item in the hierarchy.
 */
interface Checkable {
    checkboxes?: CheckboxesProps;
}

/**
 * Add or remove a risk rating from a questions array of risk ratings.
 * @param question
 * @param checkedRiskRating
 * @param checked
 */
const updateRiskRatingsForQuestion = (question: Question, checkedRiskRating: RiskRating, checked: boolean): void => {
    const currentRiskRatings = [...question.riskRatings];
    let newRiskRatings;
    if (checked) {
        newRiskRatings = [...new Set([...currentRiskRatings, checkedRiskRating])];
    } else {
        newRiskRatings = currentRiskRatings.filter((riskRating) => riskRating !== checkedRiskRating);
    }
    question.riskRatings = newRiskRatings;
};

const buildQuestionCheckboxes = (riskRatings: RiskRating[]): CheckboxesProps => {
    return {
        low: riskRatings.includes(RiskRating.LOW) ? CheckedStatus.CHECKED : CheckedStatus.UNCHECKED,
        lowMod: riskRatings.includes(RiskRating.LOW_MODERATE) ? CheckedStatus.CHECKED : CheckedStatus.UNCHECKED,
        mod: riskRatings.includes(RiskRating.MODERATE) ? CheckedStatus.CHECKED : CheckedStatus.UNCHECKED,
        modHigh: riskRatings.includes(RiskRating.MODERATE_HIGH) ? CheckedStatus.CHECKED : CheckedStatus.UNCHECKED,
        high: riskRatings.includes(RiskRating.HIGH) ? CheckedStatus.CHECKED : CheckedStatus.UNCHECKED,
    };
};

/**
 * Builds checkboxes for a checkbox which is dependent on child checkboxes
 * @param checkable
 * @returns CheckboxesProps | undefined
 */
const buildCheckboxes = (checkable: Checkable[]): CheckboxesProps | undefined => {
    const checkboxesProps = checkable.reduce<CheckboxesProps | undefined>((previous, current, index) => {
        if (previous && current.checkboxes) {
            return {
                low: determineCheckedStatus(previous.low, current.checkboxes.low, index),
                lowMod: determineCheckedStatus(previous.lowMod, current.checkboxes.lowMod, index),
                mod: determineCheckedStatus(previous.mod, current.checkboxes.mod, index),
                modHigh: determineCheckedStatus(previous.modHigh, current.checkboxes.modHigh, index),
                high: determineCheckedStatus(previous.high, current.checkboxes.high, index),
            };
        } else if (previous && !current.checkboxes) {
            return previous;
        } else if (!previous && current.checkboxes) {
            return current.checkboxes;
        } else {
            return undefined;
        }
    }, undefined);
    return checkboxesProps;
};

/**
 * Determine the status of a parent checked box based on the checked status of a child checkbox
 *
 * @param parentStatus
 * @param childStatus
 * @param childIndex
 * @returns CheckedStatus
 */
const determineCheckedStatus = (parentStatus: CheckedStatus, childStatus: CheckedStatus, childIndex: number): CheckedStatus => {
    // Once a checkbox becomes indeterminate, it must remain indeterminate. If a checkbox is checked and a child is unchecked, it can only be indeterminate.
    if (parentStatus === CheckedStatus.INDETERMINATE || childStatus === CheckedStatus.INDETERMINATE || (parentStatus === CheckedStatus.CHECKED && childStatus !== CheckedStatus.CHECKED)) return CheckedStatus.INDETERMINATE;

    // Checked checkboxes will remain checked.
    if (parentStatus === CheckedStatus.CHECKED && childStatus === CheckedStatus.CHECKED) return CheckedStatus.CHECKED;

    // Checkboxes are assumed unchecked by default. A parent checkbox is checked if and only if all its children are checked.
    // Else if a child checkbox and the parent is currently unchecked, then the parent becomes indeterminate.
    // Else, the parent is unchecked.
    if (parentStatus === CheckedStatus.UNCHECKED && childStatus === CheckedStatus.CHECKED && childIndex === 0) {
        return CheckedStatus.CHECKED;
    } else if (childStatus === CheckedStatus.CHECKED) {
        return CheckedStatus.INDETERMINATE;
    } else {
        return CheckedStatus.UNCHECKED;
    }
};

/**
 * @param control
 * @returns A tuple with a control identifier (framework#group#control) and a control
 */
const identifierControlKeyValue = (control: Control): [string, UpdateConfigurationRequest] => {
    const controlToUpdate: UpdateControlConfigurationRequest = {
        questions: [
            ...Array.from(control!.questions.values()).map((question, index) => {
                return {
                    _type: question._type,
                    text: question.text,
                    mapped_risk_ratings: question.riskRatings,
                    options: question.options,
                };
            }),
        ],
    };

    return identifierMappedToRequest(controlToUpdate, control!.framework, control!.groupId, control!.controlId);
};

export const DueDiligenceQuestionnaireConfiguration = (props: QuestionnaireConfigurationProps) => {
    const [questionnaireConfigurationResponse, setQuestionnaireConfigurationResponse] = useState<QuestionnaireConfigurationResponse>();
    const [controlHierarchy, setControlHierarchy] = useState<Map<string, Framework>>(); // Contains the network response supplemented with details to support the UI.
    const [displayedElement, setDisplayedElement] = useState<PageElements>(PageElements.None); // The currently displayed element. For this page, this is only modals.

    /**
     * A key for looking up a particular item (framework, group, or control in the hierarchy).
     * The item is selected via an overflow menu which is defined via a closure when the controlHierarchy is updated.
     * A key is used, instead of the actual item to prevent a stale captured value.
     */
    const [selectedItemKey, setSelectedItemKey] = useState<ItemKey>();
    const [itemPresentedByModal, setItemPresentedByModal] = useState<Item>(); // Holds the actual item looked up in the map by the selectedItemKey and modified by CRUD modals.
    const [modalActionComplete, setModalActionComplete] = useState<boolean>(false); // Set to true when a modal action is completed.
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [zeroStateText, setZeroStateText] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [checkboxesDisabled, setCheckboxesDisabled] = useState<boolean>(false);

    /**
     * A list of common modal props that are in state since they only need to be initialized once
     */
    const [defaultModalProps] = useState<QuestionnaireConfigurationModal>({
        tprmApi: props.tprmApi,
        hideModal: () => {
            // Resetting these values dismiss the modal and return the screen to a state in which no item or item key is selected.
            setDisplayedElement(PageElements.None);
            setSelectedItemKey(undefined);
            setItemPresentedByModal(undefined);
        },
        onModalActionComplete: () => {
            setModalActionComplete(true);
        },
    });

    const getQuestionnaireConfigurationCallback = useCallback(async (): Promise<void> => {
        try {
            const wrappedQuestionnaireConfigurationResponse = await props.tprmApi.getQuestionnaireConfiguration();
            const questionnaireConfigurationResponse = wrappedQuestionnaireConfigurationResponse.data;

            sortGenericControlHierarchy(questionnaireConfigurationResponse.control_frameworks);

            setQuestionnaireConfigurationResponse(questionnaireConfigurationResponse);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [props.tprmApi]);

    /**
     * Network request to update controls. This should only be used for updating the risk rating of questions.
     *
     * Questions can only be updated via updating controls.
     * @param updates
     */
    const updateQuestionRiskRatingsForControls = async (updates: Map<string, UpdateConfigurationRequest>) => {
        setCheckboxesDisabled(true);
        try {
            await props.tprmApi.updateConfiguration(updates);
            showSuccessToast('Configuration updated.');
        } catch (error) {
            console.log(error);
            showFailureToast('An error occurred while updating configuration.');
        } finally {
            setCheckboxesDisabled(false);
        }
    };

    /**
     * Triggered on initial render. The tprmApi and callback should never change.
     */
    useEffect(() => {
        getQuestionnaireConfigurationCallback();
    }, [props.tprmApi, getQuestionnaireConfigurationCallback]);

    /**
     * Triggered only when modal action is complete and should only update when the modalActionComplete is false.
     *
     * When a modal CRUD action is complete, this side effect is triggered to update the questionnaire configuration.
     * modalActionComplete is set to true when the network call is completed. It is not impacted by the display status of the modal.
     */
    useEffect(() => {
        if (modalActionComplete) {
            getQuestionnaireConfigurationCallback();
            setModalActionComplete(false); // Prevents infinite loop of this side effect.
        }
    }, [props.tprmApi, getQuestionnaireConfigurationCallback, modalActionComplete]);

    /**
     * Triggered when the controlHierarchy, selectedItemKey, or itemPresentedByModal are changed.
     *
     * When there's a controlHierarchy, a selectedItemKey, and no itemPresented by the modal, an item is looked up from the controlHierarchy map and copied into itemPresentedByModal.
     * The item must be copied because it is modified by presenting modal. And if an item is deleted, the modal needs to retain the information for display purposes.
     * The selectedItemKey and itemPresentedByModal values are reset when a modal is dismissed/closed/hidden.
     */
    useEffect(() => {
        if (controlHierarchy && selectedItemKey && !itemPresentedByModal) {
            let item: Item | undefined = controlHierarchy.get(selectedItemKey.framework);

            if (selectedItemKey.group && item) {
                item = item.groups.get(selectedItemKey.group);
                if (selectedItemKey.control && item) {
                    item = item.controls.get(selectedItemKey.control);
                    if (item && selectedItemKey.questionIndex !== undefined) {
                        item = {
                            framework: item.framework,
                            groupId: item.groupId,
                            controlId: item.controlId,
                            selectedQuestionIndex: selectedItemKey.questionIndex,
                            controlQuestions: item.questions,
                        };
                    }
                }
            }
            if (item) {
                setItemPresentedByModal(cloneDeep(item));
            }
        }
    }, [controlHierarchy, selectedItemKey, itemPresentedByModal]);

    const moveQuestion = useCallback(
        async (direction: 'up' | 'down', control: ControlConfiguration, questionIndex: number): Promise<void> => {
            try {
                setCheckboxesDisabled(true);
                const newQuestions = [...control.questions];
                const neighborIndex = direction === 'up' ? questionIndex - 1 : questionIndex + 1;

                const temp = newQuestions[questionIndex];
                newQuestions[questionIndex] = newQuestions[neighborIndex];
                newQuestions[neighborIndex] = temp;

                const request: UpdateControlConfigurationRequest = {
                    questions: newQuestions,
                };

                await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, control.control_framework, control.control_group_id, control.control_id)]));
                await getQuestionnaireConfigurationCallback();
                showSuccessToast('Configuration updated.');
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTprmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            } finally {
                setCheckboxesDisabled(false);
            }
        },
        [props.tprmApi, getQuestionnaireConfigurationCallback]
    );

    /**
     * Triggered whenever a questionnaire response is retrieved from the network.
     */
    useEffect(() => {
        const getQuestionOverflowItems = (control: ControlConfiguration, questionIndex: number) => {
            const overflowItems = [
                {
                    text: 'Update Question',
                    onClickAction: () => {
                        setSelectedItemKey({
                            framework: control.control_framework,
                            group: control.control_group_id,
                            control: control.control_id,
                            questionIndex: questionIndex,
                        });
                        setDisplayedElement(PageElements.UpdateQuestionModal);
                    },
                    icon: ICON_EDIT_MODIFY_UPDATE,
                },
                {
                    text: 'Delete Question',
                    onClickAction: () => {
                        setSelectedItemKey({
                            framework: control.control_framework,
                            group: control.control_group_id,
                            control: control.control_id,
                            questionIndex: questionIndex,
                        });
                        setDisplayedElement(PageElements.DeleteQuestionModal);
                    },
                    icon: ICON_DELETE_REMOVE,
                },
            ];

            const hasNeighborAbove = questionIndex > 0;
            const hasNeighborBelow = questionIndex < control.questions.length - 1;

            if (hasNeighborAbove) {
                overflowItems.push({
                    text: 'Move Question Up',
                    onClickAction: () => moveQuestion('up', control, questionIndex),
                    icon: faArrowUp,
                });
            }

            if (hasNeighborBelow) {
                overflowItems.push({
                    text: 'Move Question Down',
                    onClickAction: () => moveQuestion('down', control, questionIndex),
                    icon: faArrowDown,
                });
            }

            return overflowItems;
        };

        const buildControlHierarchy = (questionnaireConfigurationResponse: QuestionnaireConfigurationResponse) => {
            const frameworks = new Map(
                questionnaireConfigurationResponse.control_frameworks.map((frameworkResponse) => {
                    const groups = new Map(
                        frameworkResponse.control_groups.map((groupResponse) => {
                            const controls = new Map(
                                groupResponse.controls.sort(controlComparator).map((controlResponse) => {
                                    const controlDisplayText = controlResponse.is_custom ? controlResponse.control_name! : `${controlResponse.control_id}. ${controlTextToString(controlResponse.control_text)}`;
                                    const questions = new Map(
                                        controlResponse.questions.map((questionResponse, index) => {
                                            const question: Question = {
                                                _type: questionResponse._type,
                                                text: questionResponse.text,
                                                riskRatings: questionResponse.mapped_risk_ratings,
                                                checkboxes: buildQuestionCheckboxes(questionResponse.mapped_risk_ratings),
                                                options: questionResponse.options,
                                                overflowItems: {
                                                    overflowItems: getQuestionOverflowItems(controlResponse, index),
                                                    accessibilityTitle: `${controlDisplayText} question ${index + 1} menu`,
                                                },
                                            };
                                            return [index, question];
                                        })
                                    );
                                    const control: Control = {
                                        framework: controlResponse.control_framework,
                                        groupId: controlResponse.control_group_id,
                                        controlId: controlResponse.control_id,
                                        controlText: controlResponse.control_text,
                                        controlName: controlResponse.control_name,
                                        questions: questions,
                                        checkboxes: buildCheckboxes(Array.from(questions.values())),
                                        overflowItems: {
                                            overflowItems: [
                                                {
                                                    text: 'Delete Control',
                                                    onClickAction: () => {
                                                        setSelectedItemKey({ framework: controlResponse.control_framework, group: controlResponse.control_group_id, control: controlResponse.control_id });
                                                        setDisplayedElement(PageElements.DeleteControlModal);
                                                    },
                                                    icon: ICON_DELETE_REMOVE,
                                                },
                                                {
                                                    text: 'Add Question',
                                                    onClickAction: () => {
                                                        setSelectedItemKey({ framework: controlResponse.control_framework, group: controlResponse.control_group_id, control: controlResponse.control_id });
                                                        setDisplayedElement(PageElements.CreateQuestionModal);
                                                    },
                                                    icon: ICON_ADD_CREATE,
                                                },
                                            ],
                                            accessibilityTitle: `${controlDisplayText} menu`,
                                        },
                                        displayText: controlDisplayText,
                                    };

                                    if (controlResponse.is_custom) {
                                        control.overflowItems?.overflowItems.push({
                                            text: 'Update Control',
                                            onClickAction: () => {
                                                setSelectedItemKey({ framework: controlResponse.control_framework, group: controlResponse.control_group_id, control: controlResponse.control_id });
                                                setDisplayedElement(PageElements.UpdateControlModal);
                                            },
                                            icon: ICON_EDIT_MODIFY_UPDATE,
                                        });
                                    }

                                    return [controlResponse.control_id, control];
                                })
                            );
                            const groupDisplayText = groupResponse.is_custom ? groupResponse.control_group_name : `${groupResponse.control_group_id}. ${groupResponse.control_group_name}`;
                            const group: Group = {
                                framework: groupResponse.control_framework,
                                groupId: groupResponse.control_group_id,
                                groupName: groupResponse.control_group_name,
                                groupDescription: groupResponse.control_group_description,
                                controls: controls,
                                checkboxes: buildCheckboxes(Array.from(controls.values())),
                                overflowItems: {
                                    overflowItems: [
                                        {
                                            text: 'Delete Group',
                                            onClickAction: () => {
                                                setSelectedItemKey({ framework: groupResponse.control_framework, group: groupResponse.control_group_id });
                                                setDisplayedElement(PageElements.DeleteGroupModal);
                                            },
                                            icon: ICON_DELETE_REMOVE,
                                        },
                                        {
                                            text: 'Add Control',
                                            onClickAction: () => {
                                                setSelectedItemKey({ framework: groupResponse.control_framework, group: groupResponse.control_group_id });
                                                setDisplayedElement(PageElements.CreateControlModal);
                                            },
                                            icon: ICON_ADD_CREATE,
                                        },
                                    ],
                                    accessibilityTitle: `${groupDisplayText} menu`,
                                },
                                displayText: groupDisplayText,
                            };

                            if (groupResponse.is_custom) {
                                group.overflowItems?.overflowItems.push({
                                    text: 'Update Group',
                                    onClickAction: () => {
                                        setSelectedItemKey({ framework: groupResponse.control_framework, group: groupResponse.control_group_id });
                                        setDisplayedElement(PageElements.UpdateGroupModal);
                                    },
                                    icon: ICON_EDIT_MODIFY_UPDATE,
                                });
                            }

                            return [groupResponse.control_group_id, group];
                        })
                    );

                    const framework: Framework = {
                        framework: frameworkResponse.control_framework,
                        frameworkName: frameworkResponse.control_framework_name,
                        frameworkVersion: frameworkResponse.control_framework_version,
                        groups: groups,
                        checkboxes: buildCheckboxes(Array.from(groups.values())),
                        overflowItems: {
                            overflowItems: [
                                {
                                    text: 'Delete Framework',
                                    onClickAction: () => {
                                        setSelectedItemKey({ framework: frameworkResponse.control_framework });
                                        setDisplayedElement(PageElements.DeleteFrameworkModal);
                                    },
                                    icon: ICON_DELETE_REMOVE,
                                },
                                {
                                    text: 'Add Group',
                                    onClickAction: () => {
                                        setSelectedItemKey({ framework: frameworkResponse.control_framework });
                                        setDisplayedElement(PageElements.CreateGroupModal);
                                    },
                                    icon: ICON_ADD_CREATE,
                                },
                            ],
                            accessibilityTitle: `${frameworkResponse.control_framework_name} menu`,
                        },
                    };

                    if (frameworkResponse.is_custom) {
                        framework.overflowItems?.overflowItems.push({
                            text: 'Update Framework',
                            onClickAction: () => {
                                setSelectedItemKey({ framework: frameworkResponse.control_framework });
                                setDisplayedElement(PageElements.UpdateFrameworkModal);
                            },
                            icon: ICON_EDIT_MODIFY_UPDATE,
                        });
                    }

                    return [frameworkResponse.control_framework, framework];
                })
            );

            setControlHierarchy(frameworks);
        };

        if (questionnaireConfigurationResponse) {
            buildControlHierarchy(questionnaireConfigurationResponse);
        }
    }, [questionnaireConfigurationResponse, moveQuestion]);

    // Checkbox handlers

    const questionChecked = (checkedQuestion: ItemKey & CheckedRiskRating) => {
        const updates: Map<string, UpdateConfigurationRequest> = new Map([]);
        const newState = produce(controlHierarchy, (draft: Map<string, Framework>) => {
            const framework = draft.get(checkedQuestion.framework);
            const group = framework!.groups.get(checkedQuestion.group!);
            const control = group!.controls.get(checkedQuestion.control!);
            const question = control!.questions.get(checkedQuestion.questionIndex!);

            updateRiskRatingsForQuestion(question!, checkedQuestion.riskRating, checkedQuestion.checked);
            question!.checkboxes = buildQuestionCheckboxes(question!.riskRatings);
            control!.checkboxes = buildCheckboxes(Array.from(control!.questions.values()));
            group!.checkboxes = buildCheckboxes(Array.from(group!.controls.values()));
            framework!.checkboxes = buildCheckboxes(Array.from(framework!.groups.values()));

            const [key, value] = identifierControlKeyValue(current(control!));

            updates.set(key, value);
        });

        updateQuestionRiskRatingsForControls(updates);

        setControlHierarchy(newState);
    };

    const controlChecked = (checkedControl: ItemKey & CheckedRiskRating) => {
        const updates: Map<string, UpdateConfigurationRequest> = new Map([]);
        const newState = produce(controlHierarchy, (draft: Map<string, Framework>) => {
            const framework = draft.get(checkedControl.framework);
            const group = framework!.groups.get(checkedControl.group!);
            const control = group!.controls.get(checkedControl.control!);

            control!.questions.forEach((question, index) => {
                updateRiskRatingsForQuestion(question, checkedControl.riskRating, checkedControl.checked);
                question.checkboxes = buildQuestionCheckboxes(question.riskRatings);
            });

            const [key, value] = identifierControlKeyValue(current(control!));

            updates.set(key, value);

            control!.checkboxes = buildCheckboxes(Array.from(control!.questions.values()));
            group!.checkboxes = buildCheckboxes(Array.from(group!.controls.values()));
            framework!.checkboxes = buildCheckboxes(Array.from(framework!.groups.values()));
        });
        updateQuestionRiskRatingsForControls(updates);

        setControlHierarchy(newState);
    };

    const groupChecked = (checkedGroup: ItemKey & CheckedRiskRating) => {
        const updates: Map<string, UpdateConfigurationRequest> = new Map([]);
        const newState = produce(controlHierarchy, (draft: Map<string, Framework>) => {
            const framework = draft.get(checkedGroup.framework);
            const group = framework!.groups.get(checkedGroup.group!);

            group!.controls.forEach((control) => {
                control.questions.forEach((question, index) => {
                    updateRiskRatingsForQuestion(question, checkedGroup.riskRating, checkedGroup.checked);
                    question.checkboxes = buildQuestionCheckboxes(question.riskRatings);
                });
                control.checkboxes = buildCheckboxes(Array.from(control.questions.values()));

                const [key, value] = identifierControlKeyValue(current(control!));

                updates.set(key, value);
            });

            group!.checkboxes = buildCheckboxes(Array.from(group!.controls.values()));
            framework!.checkboxes = buildCheckboxes(Array.from(framework!.groups.values()));
        });
        updateQuestionRiskRatingsForControls(updates);
        setControlHierarchy(newState);
    };

    const frameworkChecked = (checkedFramework: ItemKey & CheckedRiskRating) => {
        const updates: Map<string, UpdateConfigurationRequest> = new Map([]);
        const newState = produce(controlHierarchy, (draft: Map<string, Framework>) => {
            const framework = draft.get(checkedFramework.framework);

            framework!.groups.forEach((group) => {
                group.controls.forEach((control) => {
                    control.questions.forEach((question, index) => {
                        updateRiskRatingsForQuestion(question, checkedFramework.riskRating, checkedFramework.checked);
                        question.checkboxes = buildQuestionCheckboxes(question.riskRatings);
                    });

                    const [key, value] = identifierControlKeyValue(current(control!));

                    updates.set(key, value);

                    control.checkboxes = buildCheckboxes(Array.from(control.questions.values()));
                });

                group.checkboxes = buildCheckboxes(Array.from(group.controls.values()));
            });

            framework!.checkboxes = buildCheckboxes(Array.from(framework!.groups.values()));
        });
        updateQuestionRiskRatingsForControls(updates);
        setControlHierarchy(newState);
    };

    const handleRequestError = (error: Error): void => setZeroStateText(error.message);

    const showSuccessToast = (successMessage: string): void => {
        setSuccessMessage(successMessage);
    };

    const showFailureToast = (failureMessage: string): void => {
        setFailureMessage(failureMessage);
    };

    if (tprmAccessDenied) {
        return <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }
    if (zeroStateText) {
        return <Text>{zeroStateText}</Text>;
    }

    if (!controlHierarchy) {
        return <Placeholder />;
    }

    return (
        <RBACComponent roles={[Role.ADMIN]}>
            {successMessage && <TextToast variant="success" clearToast={() => setSuccessMessage(undefined)} autoHide text={successMessage} />}
            {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} autoHide text={failureMessage} />}
            {displayedElement === PageElements.CreateFrameworkModal && <CreateControlFrameworkModal {...defaultModalProps} />}
            {displayedElement === PageElements.UpdateFrameworkModal && itemPresentedByModal && <UpdateControlFrameworkModal {...defaultModalProps} originalFramework={itemPresentedByModal as Framework} />}
            {displayedElement === PageElements.DeleteFrameworkModal && itemPresentedByModal && <DeleteControlFrameworkModal {...defaultModalProps} framework={itemPresentedByModal as Framework} />}
            {displayedElement === PageElements.CreateGroupModal && itemPresentedByModal && <CreateControlGroupModal {...defaultModalProps} framework={itemPresentedByModal as Framework} />}
            {displayedElement === PageElements.UpdateGroupModal && itemPresentedByModal && <UpdateControlGroupModal {...defaultModalProps} originalGroup={itemPresentedByModal as Group} />}
            {displayedElement === PageElements.DeleteGroupModal && itemPresentedByModal && <DeleteControlGroupModal {...defaultModalProps} group={itemPresentedByModal as Group} />}
            {displayedElement === PageElements.CreateControlModal && itemPresentedByModal && <CreateControlModal {...defaultModalProps} group={itemPresentedByModal as Group} />}
            {displayedElement === PageElements.UpdateControlModal && itemPresentedByModal && <UpdateControlModal {...defaultModalProps} originalControl={itemPresentedByModal as Control} />}
            {displayedElement === PageElements.DeleteControlModal && itemPresentedByModal && <DeleteControlModal {...defaultModalProps} control={itemPresentedByModal as Control} />}
            {displayedElement === PageElements.CreateQuestionModal && itemPresentedByModal && <CreateQuestionModal {...defaultModalProps} control={itemPresentedByModal as Control} />}
            {displayedElement === PageElements.UpdateQuestionModal && itemPresentedByModal && <UpdateQuestionModal {...defaultModalProps} selectedQuestionWrapper={itemPresentedByModal as SelectedQuestionWrapper} />}
            {displayedElement === PageElements.DeleteQuestionModal && itemPresentedByModal && <DeleteQuestionModal {...defaultModalProps} selectedQuestionWrapper={itemPresentedByModal as SelectedQuestionWrapper} />}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${CONFIGURATION}`}>Settings</BreadcrumbLink>
                        <BreadcrumbText>Third-Party Questionnaire Configuration</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Third-Party Questionnaire Configuration"
                headerDescription="Third-party services are assessed within the context of one or more control frameworks. When a third-party questionnaire is created for a third-party service, it will contain all questions selected for the inherent risk rating of the third-party service, as configured in the matrix below. Changes to this configuration do not affect in-progress third-party questionnaires. If a row in the matrix does not contain checkboxes, then no questions have been added beneath that level of the control framework."
                headerButtons={
                    <Button variant="primary" onClick={() => setDisplayedElement(PageElements.CreateFrameworkModal)} fontAwesomeImage={ICON_ADD_CREATE}>
                        Create Framework
                    </Button>
                }
                body={[
                    {
                        content: (
                            <>
                                <Legend
                                    legendItems={[
                                        {
                                            icon: new URL('/checkbox_unchecked.PNG', import.meta.url).href,
                                            text: 'No questions will be used.',
                                        },
                                        {
                                            icon: new URL('/checkbox_indeterminant.PNG', import.meta.url).href,
                                            text: 'Some questions will be used.',
                                        },
                                        {
                                            icon: new URL('/checkbox_checked.PNG', import.meta.url).href,
                                            text: 'All questions will be used.',
                                        },
                                    ]}
                                />

                                <div className={styles.accordionHeaderContainer}>
                                    <Text variant="Text3" noStyles>
                                        Framework/Control
                                    </Text>
                                    <div className={styles.accordionRightContent}>
                                        <Text variant="Text3" noStyles>
                                            Low
                                        </Text>
                                        <Text variant="Text3" noStyles>
                                            Low/Moderate
                                        </Text>
                                        <Text variant="Text3" noStyles>
                                            Moderate
                                        </Text>
                                        <Text variant="Text3" noStyles>
                                            Moderate/High
                                        </Text>
                                        <Text variant="Text3" noStyles>
                                            High
                                        </Text>
                                    </div>
                                </div>

                                {Array.from(controlHierarchy.values()).map((framework: Framework, index: number) => (
                                    <Accordion key={index}>
                                        <DueDiligenceQuestionnaireConfigurationRow eventKey={framework.groups.size > 0 ? framework.frameworkName : undefined} key={framework.groups.size > 0 ? undefined : framework.frameworkName} level={0} accessibilityTitle={framework.frameworkName}>
                                            <div className={styles.accordionRow}>
                                                <div className={styles.control}>
                                                    <div data-testid="rowcontent">
                                                        <Text variant="Text3" noStyles>
                                                            {framework.frameworkName}
                                                        </Text>
                                                    </div>
                                                    {framework.overflowItems && <OverflowMenu {...framework.overflowItems} />}
                                                </div>
                                                <div className={styles.accordionRightContent}>{framework.checkboxes && <Checkboxes disabled={checkboxesDisabled} {...framework.checkboxes} onChange={(checked: boolean, riskRating: RiskRating) => frameworkChecked({ framework: framework.framework, riskRating: riskRating, checked: checked })} />}</div>
                                            </div>
                                        </DueDiligenceQuestionnaireConfigurationRow>
                                        <AccordionCollapse eventKey={framework.frameworkName} accordionFor={framework.frameworkName}>
                                            <Fragment>
                                                {Array.from(framework.groups.values()).map((group: Group, index: number) => (
                                                    <Accordion key={index}>
                                                        <DueDiligenceQuestionnaireConfigurationRow eventKey={group.controls.size > 0 ? group.groupId : undefined} key={group.controls.size > 0 ? undefined : group.groupId} level={1} accessibilityTitle={group.displayText}>
                                                            <div className={styles.accordionRow}>
                                                                <div className={styles.control}>
                                                                    <div data-testid="rowcontent">
                                                                        <Text variant="Text3" noStyles>
                                                                            {group.displayText}
                                                                        </Text>
                                                                    </div>
                                                                    {group.overflowItems && <OverflowMenu {...group.overflowItems} />}
                                                                </div>
                                                                <div className={styles.accordionRightContent}>{group.checkboxes && <Checkboxes disabled={checkboxesDisabled} {...group.checkboxes} onChange={(checked: boolean, riskRating: RiskRating) => groupChecked({ framework: group.framework, group: group.groupId, riskRating: riskRating, checked: checked })} />}</div>
                                                            </div>
                                                        </DueDiligenceQuestionnaireConfigurationRow>
                                                        <AccordionCollapse eventKey={group.groupId} accordionFor={group.groupId}>
                                                            <Fragment>
                                                                {Array.from(group.controls.values()).map((control: Control, index: number) => (
                                                                    <Accordion key={index}>
                                                                        <DueDiligenceQuestionnaireConfigurationRow eventKey={control.questions.size > 0 ? control.controlId : undefined} key={control.questions.size > 0 ? undefined : control.controlId} level={2} accessibilityTitle={control.displayText}>
                                                                            <div className={styles.accordionRow}>
                                                                                <div className={styles.control}>
                                                                                    <div data-testid="rowcontent">
                                                                                        <Text variant="Text3" noStyles>
                                                                                            {control.displayText}
                                                                                        </Text>
                                                                                    </div>
                                                                                    {control.overflowItems && <OverflowMenu {...control.overflowItems} />}
                                                                                </div>
                                                                                <div className={styles.accordionRightContent}>{control.checkboxes && <Checkboxes disabled={checkboxesDisabled} {...control.checkboxes} onChange={(checked: boolean, riskRating: RiskRating) => controlChecked({ framework: control.framework, group: control.groupId, control: control.controlId, riskRating: riskRating, checked: checked })} />}</div>
                                                                            </div>
                                                                        </DueDiligenceQuestionnaireConfigurationRow>
                                                                        <AccordionCollapse eventKey={control.controlId} accordionFor={control.controlId}>
                                                                            <Fragment>
                                                                                {Array.from(control.questions.values()).map((question: Question, index: number) => {
                                                                                    return (
                                                                                        <DueDiligenceQuestionnaireConfigurationRow key={index} level={3}>
                                                                                            <div className={styles.accordionRow}>
                                                                                                <div className={styles.question}>
                                                                                                    <div data-testid="questionPrefix" className={styles.questionPrefix}>
                                                                                                        <Text variant="Text3" noStyles>{`Question ${index + 1}:`}</Text>
                                                                                                    </div>
                                                                                                    <div data-testid="rowcontent" className={styles.question}>
                                                                                                        <Text variant="Text3" color="darkGray" noStyles>
                                                                                                            {question.text}
                                                                                                        </Text>
                                                                                                    </div>
                                                                                                    {question.overflowItems && <OverflowMenu {...question.overflowItems} />}
                                                                                                </div>
                                                                                                <div className={styles.accordionRightContent}>{question.checkboxes && <Checkboxes disabled={checkboxesDisabled} {...question.checkboxes} onChange={(checked: boolean, riskRating: RiskRating) => questionChecked({ framework: control.framework, group: control.groupId, control: control.controlId, questionIndex: index, riskRating: riskRating, checked: checked })} />}</div>
                                                                                            </div>
                                                                                        </DueDiligenceQuestionnaireConfigurationRow>
                                                                                    );
                                                                                })}
                                                                            </Fragment>
                                                                        </AccordionCollapse>
                                                                    </Accordion>
                                                                ))}
                                                            </Fragment>
                                                        </AccordionCollapse>
                                                    </Accordion>
                                                ))}
                                            </Fragment>
                                        </AccordionCollapse>
                                    </Accordion>
                                ))}
                            </>
                        ),
                    },
                ]}
            />
        </RBACComponent>
    );
};
