import { faFolder, faList } from '@fortawesome/free-solid-svg-icons';
import type { JSX } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedReference/UserDefinedReference';
import { ICON_ADD_CREATE, ICON_DELETE_REMOVE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { ThirdPartyListingSortProperty, ThirdPartyResponseWithServices } from 'Models/TPRM';

import styles from './ThirdPartyListingTableView.module.css';
import { ThirdPartyListingServiceListing } from '../ThirdPartyListingServiceListing/ThirdPartyListingServiceListing';

export interface ThirdPartyListingTableViewProps {
    thirdParties: ThirdPartyResponseWithServices[];
    setSortColumnAndDirection: (sortProperty: string, sortDirection: SortDirection) => void;
    selectedDeleteThirdParty: (thirdPartyId: string) => void;
    selectedModifyThirdParty: (thirdPartyId: string) => void;
    selectedCreateServiceForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewAllServicesForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewFolders: (thirdPartyId: string) => void;
    currentlySortedBy: string;
    currentSortDirection: SortDirection;
}

export const ThirdPartyListingTableView = (props: ThirdPartyListingTableViewProps) => {
    const cachedData = useCachedData();

    const headerValues: HeaderData[] = [
        { dataKey: ThirdPartyListingSortProperty.NAME, label: 'NAME' },
        { dataKey: ThirdPartyListingSortProperty.WEBSITE, label: 'WEBSITE' },
        { dataKey: ThirdPartyListingSortProperty.CREATED_TIME, label: 'DATE CREATED' },
        { dataKey: 'services', label: 'SERVICES', disableSort: true },
        { dataKey: ThirdPartyListingSortProperty.THIRD_PARTY_MANAGER_USER_ID, label: 'THIRD-PARTY MANAGER' },
    ];

    const tableRow = (thirdParty: ThirdPartyResponseWithServices, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Create a Service',
                    onClickAction: () => props.selectedCreateServiceForThirdParty(thirdParty),
                    icon: ICON_ADD_CREATE,
                },
                {
                    text: 'Update Third Party',
                    onClickAction: () => props.selectedModifyThirdParty(thirdParty.id),
                    icon: ICON_EDIT_MODIFY_UPDATE,
                },
                {
                    text: 'Manage Documents',
                    onClickAction: () => props.selectedViewFolders(thirdParty.id),
                    icon: faFolder,
                },
                {
                    text: 'Delete Third Party',
                    onClickAction: () => props.selectedDeleteThirdParty(thirdParty.id),
                    icon: ICON_DELETE_REMOVE,
                },
            ],
            accessibilityTitle: `${thirdParty.name} Menu`,
        };

        if (thirdParty.services.length > 0) {
            overflowMenuProps.overflowItems.splice(1, 0, {
                text: 'View Services',
                onClickAction: () => props.selectedViewAllServicesForThirdParty(thirdParty),
                icon: faList,
            });
        }

        const numberOfOpenIssues = countOpenIssues(thirdParty.issues);
        const numberOfOpenExceptions = countOpenExceptions(thirdParty.exceptions);

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{thirdParty.name}</Text>
                    <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${thirdParty.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                    <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `thirdPartyId=${thirdParty.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <UserDefinedReference reference={thirdParty.website} />
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateLong(thirdParty.created_time)}</Text>
                </TableCellDefaultText>
                <TableCell className={styles.servicesListCell}>
                    <ThirdPartyListingServiceListing thirdPartyId={thirdParty.id} services={thirdParty.services} />
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(thirdParty.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        setSortColumnAndDirection: props.setSortColumnAndDirection,
        currentSort: props.currentlySortedBy,
        currentSortDirection: props.currentSortDirection,
        tableIncludesOverflowMenu: true,
    };

    return (
        <div className={styles.riskTable}>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{props.thirdParties.map(tableRow)}</TableBody>
            </Table>
        </div>
    );
};
