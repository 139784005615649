import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { ActionHistoryTable, ActionHistoryTableProps } from 'Components/Actions/ActionHistoryTable/ActionHistoryTable';
import { Link } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { AssociatedControlsModal, AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { RiskAccordion } from 'Components/RiskRegister/RiskAccordion/RiskAccordion';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { ACTIONS, CONFIGURATION } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { Action, ActionHistoryResponse } from 'Models/Actions';
import { MappedRisksModal, MappedRisksModalProps } from 'Pages/ActionPlanning/ActionsListing/MappedRisksModal/MappedRisksModal';
import styles from 'Styles/DetailsPage.module.css';

import { ActionDetailsSnapshot } from './ActionDetailsSnapshot';
import { ActionHistoryModal } from './ActionHistoryModal';

export interface ActionsDetailsProps {
    documentApi: DocumentApi;
    actionsApi: ActionsApi;
    tagsApi: TagsApi;
}

interface UrlParams {
    action_id: string;
}

enum ActionTab {
    RISKS = 'RISKS',
    HISTORY = 'history',
}

enum Modals {
    AssociatedControlsModal,
    MappedRisksModal,
    HistoryModal,
    None,
}

export const ActionsDetails = (props: ActionsDetailsProps) => {
    const cachedData = useCachedData();
    const [action, setAction] = useState<Action>();
    const [actionHistory, setActionHistory] = useState<ActionHistoryResponse[]>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [selectedActionHistory, setSelectedActionHistory] = useState<ActionHistoryResponse>();
    const [selectedRiskIndex, setSelectedRiskIndex] = useState<number>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const params = useParams<keyof UrlParams>() as UrlParams;
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);
    const location = useLocation();

    const activeHash = (() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (Object.values(ActionTab).includes(strippedHash as ActionTab)) {
                return strippedHash;
            }
        }
        return ActionTab.RISKS;
    })();

    useEffect(() => {
        const getActionDetails = async (): Promise<void> => {
            try {
                const actionsResponse = await props.actionsApi.getActionDetails(params.action_id);
                setAction(actionsResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getActionDetails();
    }, [params.action_id, props.actionsApi]);

    useEffect(() => {
        const getActionHistories = async (): Promise<void> => {
            try {
                const historiesResponse = await props.actionsApi.getActionHistory(params.action_id);
                setActionHistory(historiesResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getActionHistories();
    }, [params.action_id, props.actionsApi]);

    const showAssociatedControlsModal = (index: number) => {
        setSelectedRiskIndex(index);
        setDisplayedModal(Modals.AssociatedControlsModal);
    };

    const showMappedRisksModal = (actionHistory: ActionHistoryResponse) => {
        setSelectedActionHistory(actionHistory);
        setDisplayedModal(Modals.MappedRisksModal);
    };

    const showHistoryModal = (actionHistory: ActionHistoryResponse) => {
        setSelectedActionHistory(actionHistory);
        setDisplayedModal(Modals.HistoryModal);
    };

    const associatedControlsModalProps: AssociatedControlsModalProps | undefined =
        action && selectedRiskIndex !== undefined
            ? {
                  hideModal: () => setDisplayedModal(Modals.None),
                  associatedControls: action.associated_risks[selectedRiskIndex].controls.map((controlMapping) => controlMapping.control),
              }
            : undefined;

    const mappedRisksModalProps: MappedRisksModalProps | undefined =
        selectedActionHistory !== undefined
            ? {
                  hideModal: () => setDisplayedModal(Modals.None),
                  associatedRisks: selectedActionHistory.associated_risks,
              }
            : undefined;

    if (action && actionHistory && getDisplayableTagsState.type === 'success') {
        const actionHistoryTableProps: ActionHistoryTableProps = {
            histories: actionHistory,
            onSelectHistory: showHistoryModal,
            showRisks: showMappedRisksModal,
        };

        return (
            <>
                {displayedModal === Modals.HistoryModal && selectedActionHistory && <ActionHistoryModal history={selectedActionHistory} documentApi={props.documentApi} hideModal={() => setDisplayedModal(Modals.None)} />}
                {displayedModal === Modals.AssociatedControlsModal && associatedControlsModalProps && <AssociatedControlsModal {...associatedControlsModalProps} />}
                {displayedModal === Modals.MappedRisksModal && mappedRisksModalProps && <MappedRisksModal {...mappedRisksModalProps} />}
                <PageLayoutDefault
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${ACTIONS}`}>Action Planning</BreadcrumbLink>
                            <BreadcrumbText>{action.title}</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle={action.title}
                    headerButtons={
                        <Link variant="primaryButton" to={`/${ACTIONS}/${params.action_id}/${CONFIGURATION}`} fontAwesomeImage={ICON_EDIT_MODIFY_UPDATE}>
                            Manage Action
                        </Link>
                    }
                    headerDescription={`Last updated: ${iso8601ToUsDateShort(action.last_updated)} by ${getUserNameFromSubject(action.last_updated_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}`}
                    body={[
                        {
                            title: 'Action Details',
                            content: <ActionDetailsSnapshot type="current" action={action} displayableTags={getDisplayableTagsState.data(action.tags)} documentApi={props.documentApi} />,
                        },
                        {
                            content: (
                                <PrimaryTabs defaultActiveTab={activeHash} removePadding transparent>
                                    <Tab eventKey={ActionTab.RISKS} title="Risks">
                                        <div className={styles.tabContent}>
                                            <RiskAccordion risks={action.associated_risks} showModal={(index) => showAssociatedControlsModal(index)} />
                                        </div>
                                    </Tab>
                                    <Tab eventKey={ActionTab.HISTORY} title="History">
                                        <div className={styles.tabContent} data-testid="issueHistoryTable">
                                            <ActionHistoryTable {...actionHistoryTableProps} />
                                        </div>
                                    </Tab>
                                </PrimaryTabs>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else if (getDisplayableTagsState.type === 'failure') {
        return <Text>{getDisplayableTagsState.message}</Text>;
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};
