import { faBan } from '@fortawesome/free-solid-svg-icons';
import { type JSX, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { UserNameFormat, getUserNameFromUser } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

import styles from './DeactivateUserModal.module.css';

export interface DeactivateUserModalProps {
    hideModal: () => void;
    userSelectedForDeactivation: UserResponse;
    usersApi: UsersApi;
    userDeactivated: () => void;
}

export const DeactivateUserModal = (props: DeactivateUserModalProps): JSX.Element => {
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

    const deactivateUser = async (): Promise<void> => {
        setIsDeactivating(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.usersApi.deactivateUser(props.userSelectedForDeactivation.cognito_subject);
            setSuccessMessage('User deactivated.');
            props.userDeactivated();
        } catch (error) {
            handleRequestError(error);
        } finally {
            setIsDeactivating(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isDeactivating) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Deactivate" />
                <div className={styles.deactivateText}>Deactivating a user will prevent the user from logging in or receiving notifications.</div>
                <div className={styles.deactivateText}>Are you sure you want to deactivate: {getUserNameFromUser(props.userSelectedForDeactivation, UserNameFormat.FIRST_SPACE_LAST)}?</div>

                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={hideModal} disabled={isDeactivating} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deactivateUser()} loadingText={'Deactivating...'} isLoading={isDeactivating} disabled={successMessage ? true : false} fontAwesomeImage={faBan}>
                        DEACTIVATE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
