import type { JSX } from 'react';
import { NavLink, useLocation } from 'react-router';

import { IconButton } from 'Components/Buttons/IconButton';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { ACTIONS, ALERTS, COMPLIANCE_REQUIREMENTS, DASHBOARDS, EXCEPTIONS, EXPORTS, GOVERNANCE, ISSUES, ISSUES_EXCEPTIONS, OPERATIONAL_CONTROLS, RISKS, RISK_REGISTER, RISK_REVIEW, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';

import styles from './Nav.module.css';
import { NavHeader } from './NavHeader/NavHeader';
import { NavLogo } from './NavLogo/NavLogo';
import { NavSubHeader } from './NavSubHeader/NavSubHeader';

export interface NavProps {
    setNavOpen: (open: boolean) => void;
}

export const Nav = (props: NavProps): JSX.Element => {
    const location = useLocation();

    const navLinkProps = (pathForNavItem: string) => {
        if (pathForNavItem === `/`) {
            if (location.pathname.startsWith(`/${OPERATIONAL_CONTROLS}`) || location.pathname === `/`) {
                return { to: '/', className: styles.SideMenuItemActive };
            } else {
                return { to: pathForNavItem, className: styles.SideMenuItem };
            }
        }

        // Exceptional cases where our real paths conflict with the paths "assigned" to menu items. Some refactoring may obviate the need for this section.
        if (location.pathname.startsWith(`/${ISSUES}`) || location.pathname.startsWith(`/${EXCEPTIONS}`)) {
            return { to: pathForNavItem, className: pathForNavItem === `/${ISSUES_EXCEPTIONS}` ? styles.SideMenuItemActive : styles.SideMenuItem };
        }
        if (location.pathname.startsWith(`/${TPRM}/${ISSUES}`) || location.pathname.startsWith(`/${TPRM}/${EXCEPTIONS}`)) {
            return { to: pathForNavItem, className: pathForNavItem === `/${TPRM}/${ISSUES_EXCEPTIONS}` ? styles.SideMenuItemActive : styles.SideMenuItem };
        }
        if (location.pathname.startsWith(`/${TPRM}/${THIRD_PARTIES}`) && location.pathname.includes(`/${SERVICES}`)) {
            return { to: pathForNavItem, className: pathForNavItem === `/${TPRM}/${SERVICES}` ? styles.SideMenuItemActive : styles.SideMenuItem };
        }

        // Default: highlight only the menu item whose path corresponds with the current location.
        if (location.pathname.startsWith(pathForNavItem)) {
            return { to: pathForNavItem, className: styles.SideMenuItemActive };
        } else {
            return { to: pathForNavItem, className: styles.SideMenuItem };
        }
    };

    const closeMenuUrl = new URL('/close-menu.svg', import.meta.url).href;
    return (
        <div className={styles.Nav}>
            <div className={styles.navContent}>
                <div className={styles.closeMenu}>
                    <div className={styles.closeMenuButton}>
                        <IconButton aria-label="Close Menu Navigation" onClick={() => props.setNavOpen(false)} iconImage={<img src={closeMenuUrl} alt="close-menu-navigation" />} />
                    </div>
                </div>
                <NavHeader />
                <div className={styles.scrollContainer}>
                    <RBACComponent roles={[Role.ADMIN, Role.REPORTING_USER]}>
                        <div className={styles.Section}>
                            <NavSubHeader>Reporting and Exports</NavSubHeader>
                            <NavLink {...navLinkProps(`/${DASHBOARDS}`)}>Dashboards</NavLink>
                            <NavLink {...navLinkProps(`/${EXPORTS}`)}>Exports</NavLink>
                        </div>
                    </RBACComponent>
                    <RBACComponent roles={[Role.ADMIN, Role.RISK_USER, Role.RISK_MANAGER]}>
                        <div className={styles.Section}>
                            <NavSubHeader>Risk Management</NavSubHeader>
                            <NavLink {...navLinkProps(`/${RISK_REGISTER}/${RISKS}`)}>Dynamic Risk Register</NavLink>
                            <RBACComponent roles={[Role.ADMIN, Role.RISK_MANAGER]}>
                                <NavLink {...navLinkProps(`/${RISK_REGISTER}/${RISK_REVIEW}`)}>Risk Review</NavLink>
                            </RBACComponent>
                            <NavLink {...navLinkProps(`/${ACTIONS}`)}>Action Planning</NavLink>
                        </div>
                    </RBACComponent>
                    <RBACComponent roles={[Role.ADMIN, Role.CONTROL_USER]}>
                        <div className={styles.Section}>
                            <NavSubHeader>Control Automation</NavSubHeader>
                            <NavLink {...navLinkProps(`/`)}>Operational Controls</NavLink>
                            <NavLink {...navLinkProps(`/${GOVERNANCE}`)}>Governance Integration</NavLink>
                            <NavLink {...navLinkProps(`/${COMPLIANCE_REQUIREMENTS}`)}>Compliance Requirements</NavLink>
                            <NavLink {...navLinkProps(`/${ISSUES_EXCEPTIONS}`)}>Issues and Exceptions</NavLink>
                            <NavLink {...navLinkProps(`/${ALERTS}`)}>Alerts and Limits</NavLink>
                        </div>
                    </RBACComponent>
                    <RBACComponent roles={[Role.ADMIN, Role.THIRD_PARTY_USER]}>
                        <div className={styles.Section}>
                            <NavSubHeader>Third-Party Risk Management</NavSubHeader>
                            <NavLink {...navLinkProps(`/${TPRM}/${THIRD_PARTIES}`)}>Manage Third Parties</NavLink>
                            <NavLink {...navLinkProps(`/${TPRM}/${SERVICES}`)}>Manage Third-Party Services</NavLink>
                            <NavLink {...navLinkProps(`/${TPRM}/${ISSUES_EXCEPTIONS}`)}>Issues and Exceptions</NavLink>
                        </div>
                    </RBACComponent>
                </div>
            </div>
            <div>
                <NavLogo />
            </div>
        </div>
    );
};
