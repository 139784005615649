/*
    FormFieldText.tsx - Component for text fields within an HTML form.
*/

import type { JSX } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import { Colors } from 'Components/Colors';
import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

interface FormFieldTextProps {
    color?: Colors;
    formFieldId: string;
    formFieldLabel?: string;
    formFieldType?: string;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: string;
    placeholder?: string;
    pattern?: string;
    handleChange?: any;
    value?: string | number;
    disabled?: boolean;
    invalidMessage?: string;
    max?: number;
    step?: number;
    min?: number;
    onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ariaLabel?: string;
}

export const FormFieldText = (props: FormFieldTextProps): JSX.Element => {
    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            {props.formFieldLabel && (
                <FormLabel color={props.color} required={props.required} hideRequiredSymbol={props.hideRequiredSymbol}>
                    {props.formFieldLabel}
                </FormLabel>
            )}
            {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            <FormControl name={props.formFieldId} className={styles.customFormControl} type={props.formFieldType} required={props.required} placeholder={props.placeholder} pattern={props.pattern} onChange={props.handleChange} value={props.value} disabled={props.disabled} onInput={props.onInput} max={props.max} min={props.min} step={props.step} aria-label={props.ariaLabel} />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
