import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { type JSX, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserMultiSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { MultipleControlMapping, MultipleControlMappingProps } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ICON_DELETE_REMOVE, ICON_DOWNLOAD, ICON_SAVE, ICON_SUBMIT } from 'Config/Icons';
import { ISSUES, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { ISSUE_SOURCE, REFERENCE } from 'Config/Tooltips';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument, submitRequestWithFiles } from 'Helpers/FileUtils';
import { getIssueDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { UploadedFile } from 'Models/Files';
import { ControlIssueResponse, CreateIssueRequest, IssuePriority, IssuePriorityOptions, IssueResponse, IssueStatus, IssuesExceptionsModule, ThirdPartyIssueResponse, UpdateIssueRequest } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponse } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './ManageIssueForm.module.css';

export type ManageIssueFormProps = { issuesApi: IssuesApi; documentApi: DocumentApi } & (
    | {
          type: 'creatingControlIssue';
          controls: OperationalControl[];
          preselectedControlId?: string;
      }
    | {
          type: 'editingControlIssue';
          issue: ControlIssueResponse;
          isClosingIssue: boolean;
          controls: OperationalControl[];
      }
    | {
          type: 'creatingThirdPartyIssue';
          thirdParties: ThirdPartyResponse[];
          preselectedThirdPartyId?: string;
          controls?: never; // This must be explicitly defined so that `props.controls` can be used in dependency arrays.
      }
    | {
          type: 'editingThirdPartyIssue';
          issue: ThirdPartyIssueResponse;
          isClosingIssue: boolean;
          thirdParty: ThirdPartyResponse;
          controls?: never; // This must be explicitly defined so that `props.controls` can be used in dependency arrays.
      }
);

type InProgressAction = 'saveAsDraft' | 'save' | 'close' | 'delete';

type CompletedAction =
    | {
          type: 'created';
          newIssue: IssueResponse;
      }
    | {
          type: 'updated';
          newIssue: IssueResponse;
      }
    | { type: 'deleted' };

type SubmissionState =
    | {
          request: 'waitingToSubmit';
      }
    | {
          request: 'inProgress';
          action: InProgressAction;
      }
    | {
          request: 'success';
          completedAction: CompletedAction;
          showToast: boolean;
      }
    | {
          request: 'failure';
          errorMessage: string;
          showToast: boolean;
      };

/**
 * Renders a page that can be used to create, update, or delete an issue in the Operational Controls module or in the TPRM module.
 * This component's parent (`CreateControlIssue`, `EditControlIssue`, `CreateThirdPartyIssue`, or `EditThirdPartyIssue`) is responsible for determining the type of issue being managed and for fetching the data necessary for rendering the page; this component is responsible for rendering the page and submitting create/update/delete network requests.
 */
export const ManageIssueForm = (props: ManageIssueFormProps): JSX.Element => {
    const cachedData = useCachedData();

    const [submissionState, setSubmissionState] = useState<SubmissionState>({ request: 'waitingToSubmit' });
    const issueWasDeleted = submissionState.request === 'success' && submissionState.completedAction.type === 'deleted';

    const existingIssue = props.type === 'editingControlIssue' || props.type === 'editingThirdPartyIssue' ? props.issue : undefined;
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false); // This is only used when the user is editing an existing issue.

    const [title, setTitle] = useState(existingIssue?.title);
    const [description, setDescription] = useState(existingIssue?.description);
    const [remediationPlan, setRemediationPlan] = useState(existingIssue?.remediation_plan);
    const [riskAssessment, setRiskAssessment] = useState(existingIssue?.risk_assessment);
    const [source, setSource] = useState(existingIssue?.source);
    const [owner, setOwner] = useState(cachedData.users.find((user) => user.cognito_subject === existingIssue?.owner_subject));
    const [delegates, setDelegates] = useState(existingIssue ? cachedData.users.filter((user) => existingIssue.delegates.includes(user.cognito_subject)) : []);
    const [priority, setPriority] = useState(existingIssue?.priority);
    const [reference, setReference] = useState(existingIssue?.reference);
    const [dueDateAsDate, setDueDateAsDate] = useState(existingIssue?.due_date ? new Date(existingIssue.due_date) : undefined);
    const [closureStatement, setClosureStatement] = useState<string>();

    const [files, onAddFiles, onRemoveFile] = useFileDragAndDrop();
    const [existingFiles, setExistingFiles] = useState<UploadedFile[]>(existingIssue?.files ?? []);
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>([]);

    const [impactedControls, setImpactedControls] = useState<string[]>(() => {
        switch (props.type) {
            case 'creatingControlIssue':
                return props.preselectedControlId ? [props.preselectedControlId] : [];
            case 'editingControlIssue':
                return props.issue.impacted_controls?.map((control) => control.identifier) ?? [];
            case 'creatingThirdPartyIssue':
                return [];
            case 'editingThirdPartyIssue':
                return [];
        }
    });

    const [impactedThirdParty, setImpactedThirdParty] = useState<string | undefined>(() => {
        switch (props.type) {
            case 'creatingControlIssue':
                return undefined;
            case 'editingControlIssue':
                return undefined;
            case 'creatingThirdPartyIssue':
                return props.preselectedThirdPartyId;
            case 'editingThirdPartyIssue':
                return props.issue.impacted_vendor;
        }
    });

    const multipleControlMappingProps: MultipleControlMappingProps | undefined = useMemo(
        () =>
            props.type === 'creatingControlIssue' || props.type === 'editingControlIssue'
                ? {
                      controls: props.controls,
                      handleControlChange: setImpactedControls,
                      currentMappedControlIdentifiers: impactedControls,
                  }
                : undefined,
        [impactedControls, props.controls, props.type]
    );

    // Derive from state/props some values that are used multiple times in the requests/JSX below.
    const { isCreatingNewIssue, isEditingDraft, issueType, headerText, isClosingIssue, impactedThirdPartyOptions } = (() => {
        switch (props.type) {
            case 'creatingControlIssue':
                return {
                    isCreatingNewIssue: true,
                    isEditingDraft: false,
                    issueType: IssuesExceptionsModule.CONTROLS,
                    headerText: 'Create Issue',
                    isClosingIssue: false,
                    impactedThirdPartyOptions: undefined,
                };
            case 'creatingThirdPartyIssue':
                return {
                    isCreatingNewIssue: true,
                    isEditingDraft: false,
                    issueType: IssuesExceptionsModule.TPRM,
                    headerText: 'Create Issue',
                    isClosingIssue: false,
                    impactedThirdPartyOptions: props.thirdParties.map((thirdParty) => ({ value: thirdParty.id, label: thirdParty.name })),
                };
            case 'editingControlIssue':
                return {
                    isCreatingNewIssue: false,
                    isEditingDraft: props.issue.status === IssueStatus.DRAFT_OPEN,
                    issueType: IssuesExceptionsModule.CONTROLS,
                    headerText: props.isClosingIssue ? 'Close Issue' : 'Manage Issue',
                    isClosingIssue: props.isClosingIssue,
                    impactedThirdPartyOptions: undefined,
                };
            case 'editingThirdPartyIssue':
                return {
                    isCreatingNewIssue: false,
                    isEditingDraft: props.issue.status === IssueStatus.DRAFT_OPEN,
                    issueType: IssuesExceptionsModule.TPRM,
                    headerText: props.isClosingIssue ? 'Close Issue' : 'Manage Issue',
                    isClosingIssue: props.isClosingIssue,
                    impactedThirdPartyOptions: [{ value: props.thirdParty.id, label: props.thirdParty.name }],
                };
        }
    })();

    const handleRequestError = (error: Error): void => {
        setSubmissionState({ request: 'failure', errorMessage: error.message, showToast: true });
    };

    const toast: JSX.Element = (() => {
        switch (submissionState.request) {
            case 'waitingToSubmit':
            case 'inProgress':
                return <></>;
            case 'failure':
                return submissionState.showToast ? <TextToast variant="failure" clearToast={() => setSubmissionState({ ...submissionState, showToast: false })} text={submissionState.errorMessage} /> : <></>;
            case 'success':
                switch (submissionState.completedAction.type) {
                    case 'created':
                    case 'updated':
                        return submissionState.showToast ? <LinkButtonToast variant="success" clearToast={() => setSubmissionState({ ...submissionState, showToast: false })} linkButtonText="View issue" linkButtonTo={`${issueType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : ''}/${ISSUES}/${submissionState.completedAction.newIssue.id}`} text="Issue saved." /> : <></>;
                    case 'deleted':
                        return <></>;
                }
        }
    })();

    const createIssue = async (createAsDraft: boolean): Promise<void> => {
        const validationFailureMessage = validateForm();
        if (validationFailureMessage) {
            setSubmissionState({ request: 'failure', errorMessage: validationFailureMessage, showToast: true });
            return;
        }

        setSubmissionState({ request: 'inProgress', action: createAsDraft ? 'saveAsDraft' : 'save' });

        try {
            if (owner && title && dueDateAsDate && description && source && priority && remediationPlan && riskAssessment) {
                await submitRequestWithFiles(
                    props.documentApi,
                    files.map((file) => ({ file })),
                    async (filesToBeUploaded) => {
                        const createIssueRequest: CreateIssueRequest = {
                            status: createAsDraft ? IssueStatus.DRAFT_OPEN : IssueStatus.OPEN,
                            owner_subject: owner.cognito_subject,
                            delegates: delegates.map((delegate) => delegate.cognito_subject),
                            title: title,
                            due_date: jsDateToIso8601(dueDateAsDate),
                            description: description,
                            source: source,
                            priority: priority,
                            remediation_plan: remediationPlan,
                            risk_assessment: riskAssessment,
                            reference: reference,
                            impacted_controls: impactedControls,
                            impacted_third_party: impactedThirdParty,
                            files: filesToBeUploaded,
                        };
                        const newIssue = (await props.issuesApi.createIssue(createIssueRequest)).data;
                        setSubmissionState({ request: 'success', completedAction: { type: 'created', newIssue: newIssue }, showToast: true });
                    }
                );
            } else {
                throw new Error('Validation Exception');
            }
        } catch (error) {
            handleRequestError(error);
        }
    };

    const updateIssue = async (status: IssueStatus): Promise<void> => {
        const validationFailureMessage = validateForm();
        if (validationFailureMessage) {
            setSubmissionState({ request: 'failure', errorMessage: validationFailureMessage, showToast: true });
            return;
        }

        const action: InProgressAction = (() => {
            switch (status) {
                case IssueStatus.DRAFT_OPEN:
                    return 'saveAsDraft';
                case IssueStatus.OPEN:
                    return 'save';
                case IssueStatus.CLOSED:
                    return 'close';
            }
        })();
        setSubmissionState({ request: 'inProgress', action: action });

        try {
            if (owner && title && dueDateAsDate && description && source && priority && remediationPlan && riskAssessment) {
                await submitRequestWithFiles(
                    props.documentApi,
                    files.map((file) => ({ file })),
                    async (filesToBeUploaded) => {
                        const updateIssueRequest: UpdateIssueRequest = {
                            owner_subject: owner.cognito_subject,
                            delegates: delegates.map((delegate) => delegate.cognito_subject),
                            title: title,
                            due_date: jsDateToIso8601(dueDateAsDate),
                            description: description,
                            source: source,
                            priority: priority,
                            remediation_plan: remediationPlan,
                            risk_assessment: riskAssessment,
                            reference: reference,
                            impacted_controls: impactedControls,
                            impacted_third_party: impactedThirdParty,
                            file_updates: { new_files: filesToBeUploaded, existing_files_to_delete: existingFilesToDelete },
                            ...(status === IssueStatus.CLOSED ? { status: status, closure_statement: closureStatement! } : { status: status }),
                        };

                        const newIssue = (await props.issuesApi.updateIssue(existingIssue!.id, updateIssueRequest)).data;
                        setSubmissionState({ request: 'success', completedAction: { type: 'updated', newIssue: newIssue }, showToast: true });
                    }
                );
            } else {
                throw new Error('Validation Exception');
            }
        } catch (error) {
            handleRequestError(error);
        }
    };

    const deleteIssue = async (): Promise<string> => {
        setSubmissionState({ request: 'inProgress', action: 'delete' });
        await props.issuesApi.deleteIssue(existingIssue!.id);
        setSubmissionState({ request: 'success', completedAction: { type: 'deleted' }, showToast: false });
        return 'Issue deleted.';
    };

    const deleteConfirmationModal = (() => {
        if (!showDeleteConfirmationModal || !(props.type === 'editingControlIssue' || props.type === 'editingThirdPartyIssue')) {
            return undefined;
        }

        const informationalText = (() => {
            switch (props.type) {
                case 'editingControlIssue':
                    return 'The issue will be unmapped from any mapped controls.';
                case 'editingThirdPartyIssue':
                    return 'The issue will be unmapped from the third party.';
            }
        })();

        return (
            <ConfirmationModal operationType="delete" headerText="Delete Issue" areYouSureText={`Are you sure you want to delete "${props.issue.title}"?`} performOperation={deleteIssue} hideModal={() => setShowDeleteConfirmationModal(false)}>
                <Text>{informationalText}</Text>
            </ConfirmationModal>
        );
    })();

    /**
     * @returns a validation failure message if validation fails, or `undefined` if validation passes.
     * Note: There are ways we could use TS / helper functions to improve this tedious form validation, but this will change anyway once we start using react-hook-form.
     */
    const validateForm = (): string | undefined => {
        if (!title) {
            return 'Title is required.';
        } else if (!owner) {
            return 'Owner is required.';
        } else if (!priority) {
            return 'Priority is required.';
        } else if (!source) {
            return 'Source is required.';
        } else if (!dueDateAsDate) {
            return 'Due date is required.';
        } else if (!description) {
            return 'Description is required.';
        } else if (!remediationPlan) {
            return 'Remediation plan is required.';
        } else if (!riskAssessment) {
            return 'Risk assessment is required.';
        } else if ((props.type === 'creatingThirdPartyIssue' || props.type === 'editingThirdPartyIssue') && !impactedThirdParty) {
            return 'Third party impacted is required.';
        } else if (isClosingIssue && !closureStatement) {
            return 'Closure statement is required.';
        }
    };

    const buttons = (() => {
        const inProgressAction = submissionState.request === 'inProgress' ? submissionState.action : undefined;
        const disabled = !!inProgressAction;

        if (isCreatingNewIssue) {
            return (
                <>
                    <Button disabled={disabled} onClick={() => createIssue(true)} fontAwesomeImage={ICON_SAVE} variant="secondary" isLoading={inProgressAction === 'saveAsDraft'} loadingText="Saving...">
                        Save as Draft
                    </Button>
                    <Button disabled={disabled} onClick={() => createIssue(false)} fontAwesomeImage={ICON_SUBMIT} variant="primary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                        Save and Open
                    </Button>
                </>
            );
        } else if (isEditingDraft) {
            return (
                <>
                    <div className={styles.deleteButton}>
                        <Button disabled={disabled} fontAwesomeImage={ICON_DELETE_REMOVE} onClick={() => setShowDeleteConfirmationModal(true)} variant="danger" isLoading={inProgressAction === 'delete'} loadingText="Deleting...">
                            Delete
                        </Button>
                    </div>
                    <Button disabled={disabled} onClick={() => updateIssue(IssueStatus.DRAFT_OPEN)} fontAwesomeImage={ICON_SAVE} variant="secondary" isLoading={inProgressAction === 'saveAsDraft'} loadingText="Saving...">
                        Save as Draft
                    </Button>
                    <Button disabled={disabled} onClick={() => updateIssue(IssueStatus.OPEN)} fontAwesomeImage={ICON_SUBMIT} variant="primary" isLoading={inProgressAction === 'save'} loadingText="Opening...">
                        Save and Open
                    </Button>
                </>
            );
        } else {
            // The user is either saving or closing the open issue.
            if (isClosingIssue) {
                return (
                    <Button disabled={disabled} onClick={() => updateIssue(IssueStatus.CLOSED)} fontAwesomeImage={faCheck} variant="primary" isLoading={inProgressAction === 'close'} loadingText="Closing...">
                        Close Issue
                    </Button>
                );
            } else {
                return (
                    <Button disabled={disabled} onClick={() => updateIssue(IssueStatus.OPEN)} fontAwesomeImage={ICON_SUBMIT} variant="primary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                        Save
                    </Button>
                );
            }
        }
    })();

    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Files',
        onAddFiles: onAddFiles,
        onRemoveFile: onRemoveFile,
        files: files,
    };

    const deleteFile = (file: UploadedFile) => {
        const existingFilesCopy: UploadedFile[] = [...existingFiles];
        existingFilesCopy.splice(existingFiles.indexOf(file), 1);
        setExistingFiles(existingFilesCopy);
        setExistingFilesToDelete([...existingFilesToDelete, file.file_id]);
    };

    const handleTextChange = (setState: (value: string) => void) => (event: React.FormEvent<HTMLInputElement>) => setState(event.currentTarget.value);

    return (
        <>
            {deleteConfirmationModal}
            {toast}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={issueType === IssuesExceptionsModule.CONTROLS ? `/${ISSUES_EXCEPTIONS}#issues` : `/${TPRM}/${ISSUES_EXCEPTIONS}#issues`}>Issues</BreadcrumbLink>
                        {existingIssue !== undefined && !issueWasDeleted && <BreadcrumbLink link={getIssueDetailsUrl(existingIssue.id, existingIssue.type)}>{submissionState.request === 'success' && submissionState.completedAction.type === 'updated' ? submissionState.completedAction.newIssue.title : existingIssue.title}</BreadcrumbLink>}
                        {existingIssue !== undefined && issueWasDeleted && <BreadcrumbText>{existingIssue.title}</BreadcrumbText>}
                        <BreadcrumbText>{headerText}</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={headerText}
                body={[
                    {
                        content: (
                            <Form>
                                <fieldset disabled={submissionState.request === 'success'}>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldText handleChange={handleTextChange(setTitle)} value={title || ''} formFieldId="title" formFieldLabel="Title" required />
                                        </div>
                                        {(props.type === 'creatingControlIssue' || props.type === 'editingControlIssue') && (
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldText value={reference || ''} handleChange={handleTextChange(setReference)} tooltip={REFERENCE} formFieldId="reference" formFieldLabel="Reference" />
                                            </div>
                                        )}
                                    </div>
                                    {(props.type === 'creatingThirdPartyIssue' || props.type === 'editingThirdPartyIssue') && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldSelect selectedOption={impactedThirdParty} disabled={props.type === 'editingThirdPartyIssue'} formFieldId="thirdParty" required formFieldLabel="Third Party Impacted" handleChange={(value: ChangeEventType) => setImpactedThirdParty(value as string)} options={impactedThirdPartyOptions} />
                                            </div>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldText value={reference || ''} handleChange={handleTextChange(setReference)} tooltip={REFERENCE} formFieldId="reference" formFieldLabel="Reference" />
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldUserSelect selectedUser={owner} formFieldId="owner" formFieldLabel="Owner" onUserSelected={(user: UserResponse) => setOwner(user)} required users={cachedData.users} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldUserMultiSelect selectedUsers={delegates} formFieldId="delegates" formFieldLabel="Delegates" onUsersSelected={(users) => setDelegates(users ?? [])} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldSelect selectedOption={priority} formFieldId="priority" required formFieldLabel="Priority" handleChange={(value: ChangeEventType) => setPriority(value as IssuePriority)} options={IssuePriorityOptions} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldText handleChange={handleTextChange(setSource)} value={source || ''} formFieldId="source" formFieldLabel="Source" required tooltip={ISSUE_SOURCE} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldDatePicker selected={dueDateAsDate} handleChange={setDueDateAsDate} required dateFormat="MM/dd/yyyy" formFieldId="dueDateAsDate" formFieldLabel="Due Date" placeholder={'MM/DD/YYYY'} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setDescription)} value={description} formFieldId="description" formFieldLabel="Description" rows={2} required />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setRemediationPlan)} value={remediationPlan} formFieldId="remediationPlan" formFieldLabel="Remediation Plan" rows={2} required />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setRiskAssessment)} value={riskAssessment} formFieldId="riskAssessment" required formFieldLabel="Risk Assessment" rows={2} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FileDragAndDrop {...fileDragAndDropProps} />
                                            {existingIssue && existingIssue.files.length > 0 && (
                                                <>
                                                    <VisualLabel>Existing Files</VisualLabel>
                                                    <Table>
                                                        <TableBody>
                                                            {[...existingFiles]
                                                                .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                                                .map((file) => (
                                                                    <TableRow key={file.file_id}>
                                                                        <TableCell>
                                                                            <Text noStyles>{file.filename}</Text>
                                                                        </TableCell>
                                                                        <TableOverflowCell>
                                                                            <div className={styles.overflowContainer}>
                                                                                <OverflowMenu
                                                                                    overflowItems={[
                                                                                        {
                                                                                            text: 'Download file',
                                                                                            onClickAction: () => downloadDocument(props.documentApi, file),
                                                                                            icon: ICON_DOWNLOAD,
                                                                                        },
                                                                                        {
                                                                                            text: 'Delete file',
                                                                                            onClickAction: () => deleteFile(file),
                                                                                            icon: ICON_DELETE_REMOVE,
                                                                                        },
                                                                                    ]}
                                                                                    accessibilityTitle={file.filename}
                                                                                />
                                                                            </div>
                                                                        </TableOverflowCell>
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {multipleControlMappingProps && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.controlMappingContainer}>
                                                <Text variant="Header2">Controls Impacted</Text>
                                                <MultipleControlMapping {...multipleControlMappingProps} />
                                            </div>
                                        </div>
                                    )}
                                    {isClosingIssue && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <div className={styles.propertyGroup}>
                                                    <div className={styles.propertyContainer}>
                                                        <FormFieldTextArea required value={closureStatement} handleChange={handleTextChange(setClosureStatement)} formFieldId="closureStatement" formFieldLabel="Closure Statement" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.buttonContainer}>{buttons}</div>
                                </fieldset>
                            </Form>
                        ),
                    },
                ]}
            />
        </>
    );
};
