import type { JSX } from 'react';

import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { ThirdPartyListingSortProperty } from 'Models/TPRM';
import { Filter, GroupOptionType, OptionType } from 'Models/Types/GlobalType';

import styles from './ThirdPartyListingBodyToolbar.module.css';

export interface ThirdPartyListingBodyToolbarProps {
    defaultSelectedOptions?: GroupOptionType[];
    filterThirdParties: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentlySortedBy: string;
}

const ThirdPartyListingSortOptions: OptionType[] = [
    {
        label: 'Name',
        value: ThirdPartyListingSortProperty.NAME,
    },
    {
        label: 'Date Created',
        value: ThirdPartyListingSortProperty.CREATED_TIME,
    },
    {
        label: 'Third-Party Manager',
        value: ThirdPartyListingSortProperty.THIRD_PARTY_MANAGER_USER_ID,
    },
    {
        label: 'Website',
        value: ThirdPartyListingSortProperty.WEBSITE,
    },
];

export const ThirdPartyListingBodyToolbar = (props: ThirdPartyListingBodyToolbarProps): JSX.Element => {
    const handleSortSelectionChange = (value: ChangeEventType): void => {
        const property = value as ThirdPartyListingSortProperty;
        if (property) {
            props.sortCardsBy(property, SortDirection.ASC);
        }
    };

    return (
        <div className={styles.filter}>
            <div className={styles.sortSelect}>
                <FormFieldSelect options={ThirdPartyListingSortOptions} handleChange={handleSortSelectionChange} formFieldId="sortThirdPartiesBy" formFieldLabel="Sort" selectedOption={props.currentlySortedBy} />
            </div>
            <div className={styles.filterSelect}>
                <UserFilter filterLabel="Filter by Third-Party Manager" onUsersSelected={props.filterThirdParties} userFilterId="vendor_manager_user_id" formFieldId="thirdPartyManagerFilter" />
            </div>
        </div>
    );
};
