import type { JSX } from 'react';

import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

export interface ServiceMultiOptionFilterProps {
    filterOptions: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];
    thirdPartyFilter: (selectedFilterOptions: Filter | Filter[]) => void;
}

export function ServiceMultiOptionFilter(props: ServiceMultiOptionFilterProps): JSX.Element {
    const handleSelectChange = (value: GroupOptionType[]): void => {
        const riskRatingFilterValues: (string | number)[] = [];
        const residualRiskFilterValues: (string | number)[] = [];
        const thirdPartyFilterValues: (string | number)[] = [];
        const assessmentDueDateFilterValues: (string | number)[] = [];

        value.forEach((filter) => {
            if (filter.groupId === ServiceListingSortFilterOptions.VENDOR_ID) {
                thirdPartyFilterValues.push(filter.value);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.INHERENT_RISK_SCORE) {
                riskRatingFilterValues.push(filter.value);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE) {
                //Trim residual risk prefix. See below.
                const trimmed = parseFloat((filter.value as string).split('-')[0]);
                residualRiskFilterValues.push(trimmed);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.DUE_DATE) {
                assessmentDueDateFilterValues.push(filter.value);
            }
        });

        const bothFilters: Filter[] = [
            {
                key: ServiceListingSortFilterOptions.INHERENT_RISK_SCORE,
                value: riskRatingFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE,
                value: residualRiskFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.VENDOR_ID,
                value: thirdPartyFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.DUE_DATE,
                value: assessmentDueDateFilterValues,
            },
        ];
        props.thirdPartyFilter(bothFilters);
    };

    // Since residual risk and inherent risk have the same value, a prefix is added to the residual risk values
    const filterOptions = props.filterOptions.map((groupOption) => {
        if (groupOption.label === 'Service Residual Risk') {
            groupOption.options.map((option) => {
                option.value = `${option.value}-residual`;
                return option;
            });
        }
        return groupOption;
    });

    return <FormFieldMultiOptionSelect options={filterOptions} defaultSelectedOptions={props.defaultSelectedOptions} formFieldLabel="Filter by Service Attributes" handleChange={handleSelectChange} accessibilityLabel="Service Filter" />;
}
