import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { ICON_CLOSE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskResponse, UpdateRiskScoresRequest, calculateAndFormatTotalImpactOrProbability, formatAverageWeightedEffectiveness, formatImpactOrProbability } from 'Models/RiskRegister';

import styles from './UpdateRiskScoresModal.module.css';

const placeholderText = 'Enter 0.1 - 5';

const FIELD_LABEL_IMPACT = 'Impact';
const FIELD_LABEL_PROBABILITY = 'Probability';

type SubmitState = { type: 'none' } | { type: 'submitting' } | { type: 'success' } | { type: 'error'; message: string };

export interface UpdateRiskScoresModalProps {
    hideModal: () => void;
    riskRegisterApi: RiskRegisterApi;
    risk: RiskResponse;
    onRiskScoresUpdated: () => void;
}

export const UpdateRiskScoresModal = (props: UpdateRiskScoresModalProps): JSX.Element => {
    const riskTitle = props.risk.title;
    const [inherentImpact, setInherentImpact] = useState(props.risk.inherent_risk_impact === undefined ? undefined : formatImpactOrProbability(props.risk.inherent_risk_impact));
    const [inherentProbability, setInherentProbability] = useState(props.risk.inherent_risk_probability === undefined ? undefined : formatImpactOrProbability(props.risk.inherent_risk_probability));
    const [currentImpact, setCurrentImpact] = useState(props.risk.current_risk_impact === undefined ? undefined : formatImpactOrProbability(props.risk.current_risk_impact));
    const [currentProbability, setCurrentProbability] = useState(props.risk.current_risk_probability === undefined ? undefined : formatImpactOrProbability(props.risk.current_risk_probability));
    const [targetImpact, setTargetImpact] = useState(props.risk.target_risk_impact === undefined ? undefined : formatImpactOrProbability(props.risk.target_risk_impact));
    const [targetProbability, setTargetProbability] = useState(props.risk.target_risk_probability === undefined ? undefined : formatImpactOrProbability(props.risk.target_risk_probability));

    // The comment is not auto-filled because in most/all cases, the user should be writing a new comment when updating scores. The user can still view the current comment in the History tab on the Risk Details page.
    const [comments, setComments] = useState<string>();

    const [submitState, setSubmitState] = useState<SubmitState>({ type: 'none' });

    const validateRequest = (request: UpdateRiskScoresRequest) => {
        const valuesAndLabels: [number | undefined, string][] = [
            [request.inherent_risk_impact, 'Inherent ' + FIELD_LABEL_IMPACT],
            [request.inherent_risk_probability, 'Inherent ' + FIELD_LABEL_PROBABILITY],
            [request.current_risk_impact, 'Current ' + FIELD_LABEL_IMPACT],
            [request.current_risk_probability, 'Current ' + FIELD_LABEL_PROBABILITY],
            [request.target_risk_impact, 'Target ' + FIELD_LABEL_IMPACT],
            [request.target_risk_probability, 'Target ' + FIELD_LABEL_PROBABILITY],
        ];

        valuesAndLabels.forEach((valueAndLabel) => {
            const [value, label] = valueAndLabel;
            if (value !== undefined && (value < 0.1 || value > 5)) {
                throw new ValidationError(`${label} must be between 0.1 and 5.`);
            }
        });

        if (request.inherent_risk_impact !== undefined && request.current_risk_impact !== undefined && request.current_risk_impact > request.inherent_risk_impact) {
            throw new ValidationError('Current Impact cannot be greater than Inherent Impact.');
        }

        if (request.inherent_risk_probability !== undefined && request.current_risk_probability !== undefined && request.current_risk_probability > request.inherent_risk_probability) {
            throw new ValidationError('Current Probability cannot be greater than Inherent Probability.');
        }
    };

    const submitRiskScores = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setSubmitState({ type: 'submitting' });
            const updateRiskRequest: UpdateRiskScoresRequest = {
                scores_comment: comments,
                inherent_risk_impact: inherentImpact ? Number(inherentImpact) : undefined,
                inherent_risk_probability: inherentProbability ? Number(inherentProbability) : undefined,
                current_risk_probability: currentProbability ? Number(currentProbability) : undefined,
                current_risk_impact: currentImpact ? Number(currentImpact) : undefined,
                target_risk_probability: targetProbability ? Number(targetProbability) : undefined,
                target_risk_impact: targetImpact ? Number(targetImpact) : undefined,
            };

            validateRequest(updateRiskRequest);

            await props.riskRegisterApi.updateRiskScores(props.risk.id, updateRiskRequest);
            setSubmitState({ type: 'success' });
            props.onRiskScoresUpdated();
        } catch (err) {
            setSubmitState({ type: 'error', message: err.message });
        }
    };

    const validateAndSetState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputCharacters = event.target.value;
        if (inputCharacters.length < 4) {
            if (event.target.name === 'initial_impact') {
                setInherentImpact(inputCharacters);
            } else if (event.target.name === 'initial_probability') {
                setInherentProbability(inputCharacters);
            } else if (event.target.name === 'current_impact') {
                setCurrentImpact(inputCharacters);
            } else if (event.target.name === 'current_probability') {
                setCurrentProbability(inputCharacters);
            } else if (event.target.name === 'target_impact') {
                setTargetImpact(inputCharacters);
            } else if (event.target.name === 'target_probability') {
                setTargetProbability(inputCharacters);
            }
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {submitState.type === 'success' && <Alert variant="success">Risk scores updated.</Alert>}
                {submitState.type === 'error' && <Alert variant="danger">{submitState.message}</Alert>}
                <ModalHeader text={'Update Risk Scores for ' + riskTitle} />
                <Form noValidate onSubmit={submitRiskScores}>
                    <div className={styles.riskHeaderContainer}>
                        <Text noStyles variant="Header2">
                            Inherent Risk
                        </Text>
                        <FormFieldTooltip text="The risk to an entity in the absence of any direct or focused actions by management to alter its severity." />
                    </div>
                    <div className={styles.impactProbability}>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_IMPACT} formFieldId="initial_impact" handleChange={validateAndSetState} value={inherentImpact ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_PROBABILITY} formFieldId="initial_probability" handleChange={validateAndSetState} value={inherentProbability ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.total}>
                            <FormLabel>Total Risk</FormLabel>
                            <Text noStyles color="darkBlue" variant="Text2">
                                {calculateAndFormatTotalImpactOrProbability(inherentImpact, inherentProbability)}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.riskHeaderContainer}>
                        <Text noStyles variant="Header2">
                            Average Weighted Effectiveness of Mitigating Controls
                        </Text>
                    </div>
                    <div className={styles.average}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(props.risk.control_environment_effectiveness))} />
                        <Text noStyles variant="Text2" color="blue">
                            {formatAverageWeightedEffectiveness(props.risk.control_environment_effectiveness)}
                        </Text>
                    </div>
                    <div className={styles.riskHeaderContainer}>
                        <Text noStyles variant="Header2">
                            Current Residual Risk
                        </Text>
                        <FormFieldTooltip text="The risk to an entity considering any direct or focused actions by management to alter its severity." />
                    </div>
                    <div className={styles.impactProbability}>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_IMPACT} formFieldId="current_impact" handleChange={validateAndSetState} value={currentImpact ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_PROBABILITY} formFieldId="current_probability" handleChange={validateAndSetState} value={currentProbability ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.total}>
                            <FormLabel>Total Risk</FormLabel>
                            <Text noStyles color="darkBlue" variant="Text2">
                                {calculateAndFormatTotalImpactOrProbability(currentImpact, currentProbability)}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.riskHeaderContainer}>
                        <Text noStyles variant="Header2">
                            Target Residual Risk
                        </Text>
                        <FormFieldTooltip text="The amount of risk that an entity prefers to assume in the pursuit of its strategy and business objectives, knowing that management will implement, or has implemented, direct or focused actions to alter the severity of the risk." />
                    </div>
                    <div className={styles.impactProbability}>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_IMPACT} formFieldId="target_impact" handleChange={validateAndSetState} value={targetImpact ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.impactProbabilityCell}>
                            <FormFieldText placeholder={placeholderText} formFieldType="number" formFieldLabel={FIELD_LABEL_PROBABILITY} formFieldId="target_probability" handleChange={validateAndSetState} value={targetProbability ?? ''} min={0.1} max={5} step={0.1} />
                        </div>
                        <div className={styles.total}>
                            <FormLabel>Total Risk</FormLabel>
                            <Text noStyles color="darkBlue" variant="Text2">
                                {calculateAndFormatTotalImpactOrProbability(targetImpact, targetProbability)}
                            </Text>
                        </div>
                    </div>
                    <FormFieldTextArea formFieldId="comments" formFieldLabel="Comments" handleChange={(event: React.FormEvent<HTMLInputElement>) => setComments(event.currentTarget.value)} value={comments ?? ''} />
                    <div className={styles.modalFormButtonContainer}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" isLoading={submitState.type === 'submitting'} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
