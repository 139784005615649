import type { JSX } from 'react';

import { Text } from 'Components/Text/Text';
import { RiskResponse } from 'Models/RiskRegister';

import styles from './RiskMappingListItem.module.css';

export interface RiskMappingListItemProps {
    riskMappingItem: RiskResponse;
    handleSelected: (value: RiskResponse, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    selected?: boolean;
}

export const RiskMappingListItem = (props: RiskMappingListItemProps): JSX.Element => {
    return (
        <div className={`${styles.text} ${props.selected && styles.selected}`} onClick={(e) => props.handleSelected(props.riskMappingItem, e)}>
            <Text variant="Text2" noStyles>
                {props.riskMappingItem.title} - {props.riskMappingItem.category.title}
            </Text>
            <Text variant="Text4" noStyles>
                {props.riskMappingItem.description}
            </Text>
        </div>
    );
};
