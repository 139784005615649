import { useCallback, useEffect, useState } from 'react';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Link } from 'Components/Buttons/Buttons';
import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { PageLayoutHybridDashboard } from 'Components/PageLayout/PageLayoutHybridDashboard';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { Text } from 'Components/Text/Text';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { CREATE, RISK_REGISTER } from 'Config/Paths';
import { useDisplayableTagsLookup, useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskCategoryResponse, RiskResponse } from 'Models/RiskRegister';
import { GroupOptionType } from 'Models/Types/GlobalType';

import styles from './RiskListing.module.css';
import { RiskListingCallout } from './RiskListingCallout/RiskListingCallout';

export interface RiskListingProps {
    riskRegisterApi: RiskRegisterApi;
    tagsApi: TagsApi;
}

export const ALL_RISKS = 'All Risks';

export const RiskListing = (props: RiskListingProps) => {
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [riskCategories, setRiskCategories] = useState<RiskCategoryResponse[]>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);
    const [overallControlEffectiveness, setOverallControlEffectiveness] = useState<number>();
    const [error, setError] = useState<Error>();
    const getTagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [selectedTagsFilter, setSelectedTagsFilter] = useState<string[]>([]);
    const [selectedRiskCategory, setSelectedRiskCategory] = useState<string>(ALL_RISKS);

    useEffect(() => {
        const fetchOverallControlEffectiveness = async (): Promise<void> => {
            try {
                const effectivenessResponse = await props.riskRegisterApi.getOverallControlEffectiveness();
                setOverallControlEffectiveness(effectivenessResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchOverallControlEffectiveness();
    }, [props.riskRegisterApi]);

    const getAllRisks = useCallback(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskResponse[]> = await props.riskRegisterApi.getAllRisks();
                setRisks(riskResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const getRiskCategories = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setRiskCategories([{ title: ALL_RISKS, id: ALL_RISKS, in_use: true }, ...response.data]);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getRisks();
        getRiskCategories();
    }, [props.riskRegisterApi]);

    useEffect(() => {
        getAllRisks();
    }, [getAllRisks]);

    const handleSelectChange = (value: ChangeEventType): void => {
        setSelectedRiskCategory(value as string);
    };

    if (error) {
        return <Text>{error.message}</Text>;
    }

    if (risks && riskCategories && overallControlEffectiveness !== undefined && getDisplayableTagsState.type === 'success' && getTagOptionsState.type === 'success') {
        const filteredRisks = risks.filter((risk) => selectedRiskCategory === ALL_RISKS || risk.category.id === selectedRiskCategory).filter((risk) => selectedTagsFilter.length === 0 || risk.tags.some((tag) => selectedTagsFilter.includes(tag)));

        return (
            <PageLayoutHybridDashboard
                headerTitle="Dynamic Risk Register"
                headerButtons={
                    <Link variant="primaryButton" to={`/${RISK_REGISTER}/${CREATE}`} fontAwesomeImage={ICON_ADD_CREATE}>
                        Create Risk
                    </Link>
                }
                headerFilters={
                    <>
                        <div className={styles.filterContainer}>
                            <FormFieldSelect options={riskCategories.map((category) => ({ label: category.title, value: category.id }))} formFieldId="selectedRiskCategory" formFieldLabel="Filter By Risk Category" handleChange={handleSelectChange} selectedOption={selectedRiskCategory} colorTheme="dark" />
                        </div>
                        <div className={styles.filterContainer}>
                            <FormFieldMultiOptionSelect
                                defaultSelectedOptions={getTagOptionsState.data
                                    .map((group) => group.options)
                                    .flat()
                                    .filter((option) => selectedTagsFilter.includes(option.value as string))}
                                formFieldLabel="Filter By Tags"
                                formFieldId="risk_tags"
                                handleChange={(value: GroupOptionType[]) => setSelectedTagsFilter(value.map((tag) => tag.value as string))}
                                options={getTagOptionsState.data}
                                accessibilityLabel="tag selection"
                                colorTheme="dark"
                            />
                        </div>
                    </>
                }
                headerDashlets={[
                    {
                        dashlets: [
                            {
                                title: 'Inherent Risk',
                                content: <RiskListingCallout calloutType="total_inherent_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={filteredRisks} />,
                            },
                            {
                                title: 'Control Effectiveness',
                                titleTooltip: 'Risk-related control effectiveness is the average of all controls that are mapped to risks. Overall control effectiveness is the average of all controls in the system.',
                                content: <RiskListingCallout calloutType="control_environment_effectiveness" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={filteredRisks} />,
                            },
                            {
                                title: 'Current Residual Risk',
                                content: <RiskListingCallout calloutType="total_current_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={filteredRisks} />,
                            },
                            {
                                title: 'Target Residual Risk',
                                titleTooltip: 'The calculation of target residual risk takes all risks into account. If no target residual risk has been defined for a risk, then the current residual risk (if defined) is used.',
                                content: <RiskListingCallout calloutType="total_target_risk" completeEnvironmentEffectivenessAverage={overallControlEffectiveness} risks={filteredRisks} />,
                            },
                        ],
                    },
                ]}
                body={[
                    {
                        title: 'Risk Listing',
                        content: <RiskTable type="current risks" risks={filteredRisks} getDisplayableTags={getDisplayableTagsState.data} />,
                    },
                ]}
            />
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else if (getDisplayableTagsState.type === 'failure') {
        return <Text>{getDisplayableTagsState.message}</Text>;
    } else {
        return <Placeholder />;
    }
};
