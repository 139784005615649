import { faGlobe, faShieldHalved, faSitemap, faTableCells } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Text } from 'Components/Text/Text';
import { ICON_VIEW } from 'Config/Icons';
import { ACTIONS, ASSESSMENTS, COMPLIANCE_REQUIREMENTS, EVIDENCE, EXCEPTIONS, EXPORTS, GOVERNANCE, ISSUES, OPERATIONAL_CONTROLS, RISKS, RISK_REVIEW, SERVICES, THIRD_PARTIES, TPRM, USERS } from 'Config/Paths';

import styles from './ExportListing.module.css';

export const ExportListing = (): JSX.Element => {
    return (
        <PageLayoutDefault
            headerTitle="Export Selection"
            body={[
                {
                    content: (
                        <>
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faGlobe} />
                                <Text variant="Header4" noStyles>
                                    Global Features
                                </Text>
                            </div>
                            <ExportRow title="Issues" description="View and/or download an export of all issues." url={`/${EXPORTS}/${ISSUES}`} />
                            <ExportRow title="Exceptions" description="View and/or download an export of all exceptions." url={`/${EXPORTS}/${EXCEPTIONS}`} />
                            <ExportRow title="Users" description="View and/or download an export of all users." url={`/${EXPORTS}/${USERS}`} />
                            <hr className={styles.hr} />
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faShieldHalved} />
                                <Text variant="Header4" noStyles>
                                    Control Automation
                                </Text>
                            </div>
                            <ExportRow title="Operational Controls" description="View and/or download an export of all operational controls." url={`/${EXPORTS}/${OPERATIONAL_CONTROLS}`} />
                            <ExportRow title="Control Evidence" description="View and/or download an export of all uploaded evidence for all operational controls." url={`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${EVIDENCE}`} />
                            <ExportRow title="Control Assessments" description="View and/or download an export of all in-progress and completed assessments for all operational controls." url={`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${ASSESSMENTS}`} />
                            <ExportRow title="Governance Integration" description="View and/or download an export of all standards, policies, and procedures." url={`/${EXPORTS}/${GOVERNANCE}`} />
                            <ExportRow title="Compliance Requirements" description="View and/or download an export of all compliance requirements." url={`/${EXPORTS}/${COMPLIANCE_REQUIREMENTS}`} />
                            <hr className={styles.hr} />
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faSitemap} />
                                <Text variant="Header4" noStyles>
                                    Third-Party Risk Management
                                </Text>
                            </div>
                            <ExportRow title="Third Parties" description="View and/or download an export of all third parties." url={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}`} />
                            <ExportRow title="Third-Party Services" description="View and/or download an export of all third-party services." url={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}/${SERVICES}`} />
                            <ExportRow title="Third-Party Service Assessments" description="View and/or download an export of all in-progress and completed assessments for all third-party services." url={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}/${SERVICES}/${ASSESSMENTS}`} />
                            <hr className={styles.hr} />
                            <div className={styles.icon}>
                                <FontAwesomeIcon icon={faTableCells} />
                                <Text variant="Header4" noStyles>
                                    Risk Management
                                </Text>
                            </div>
                            <ExportRow title="Risks" description="View and/or download an export of all risks." url={`/${EXPORTS}/${RISKS}`} />
                            <ExportRow title="Risk Reviews" description="View and/or download an export of all risk reviews." url={`/${EXPORTS}/${RISKS}/${RISK_REVIEW}`} />
                            <ExportRow title="Actions" description="View and/or download an export of all actions." url={`/${EXPORTS}/${ACTIONS}`} />
                        </>
                    ),
                },
            ]}
        />
    );
};

interface ExportRowProps {
    title: string;
    description: string;
    url: string;
}

/**
 * A helper component that renders a row for a specific kind of export.
 */
const ExportRow = (props: ExportRowProps) => {
    return (
        <div className={styles.settingRowContainer}>
            <div className={styles.settingDescription}>
                <Text variant="Header3">{props.title}</Text>
                <Text variant="Text3" noStyles>
                    {props.description}
                </Text>
            </div>
            <Link variant="secondaryButton" to={props.url} fontAwesomeImage={ICON_VIEW}>
                Preview
            </Link>
        </div>
    );
};
