import { type JSX, useMemo, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping, MultipleControlMappingProps } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { ICON_CLOSE } from 'Config/Icons';
import { IntegrationName, MetricName } from 'Models/ExternalIntegrations';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './MetricControlMappingModal.module.css';

export interface MetricControlMappingModalProps {
    controlMappingItems: OperationalControl[];
    currentMappedControlIdentifiers?: string[];
    externalIntegrationsApi: ExternalIntegrationsApi;
    hideModal: () => void;
    integrationMetricIdentifier: { integrationName: IntegrationName; metricName: MetricName };
    refreshIntegrations: () => void; // Ensures the list of Controls that the Metric is mapped to are updated in the UI after being changed.
    secondaryTitle?: string;
}

interface FormState {
    isMappingControl: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const MetricControlMappingModal = (props: MetricControlMappingModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({ isMappingControl: false });
    const [controlsToAssociate, setControlsToAssociate] = useState<string[]>(props.currentMappedControlIdentifiers ? props.currentMappedControlIdentifiers : []);

    const multipleControlMappingProps: MultipleControlMappingProps = useMemo(
        () => ({
            controls: props.controlMappingItems,
            handleControlChange: (controls: string[]) => setControlsToAssociate(controls),
            currentMappedControlIdentifiers: props.currentMappedControlIdentifiers,
        }),
        [props.controlMappingItems, props.currentMappedControlIdentifiers]
    );

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFormState({ isMappingControl: true, failureMessage: undefined, successMessage: undefined });
        try {
            await props.externalIntegrationsApi.setMetricControlMapping(props.integrationMetricIdentifier.integrationName, props.integrationMetricIdentifier.metricName, { control_mapping: controlsToAssociate });
            await props.refreshIntegrations();
            setFormState({ successMessage: 'Metric mapped.', isMappingControl: false });
        } catch (error) {
            setFormState({ failureMessage: error.message, isMappingControl: false });
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Map Metric to Controls" secondaryText={props.secondaryTitle} />
                    <MultipleControlMapping {...multipleControlMappingProps} />
                    <div className={styles.buttonRowContainer}>
                        <Button variant="secondary" onClick={props.hideModal} disabled={formState.isMappingControl} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" isLoading={formState.isMappingControl} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
