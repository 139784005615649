import { faArrowDown, faArrowUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton as MaterialIconButton } from '@mui/material';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';
import { ellipt } from 'Helpers/StringUtils';

import styles from './ReorderModal.module.css';

export interface ReorderModalProps<T> {
    /**
     * The title of the modal. For example, "Reorder Sections".
     */
    title: string;
    /**
     * The items to be reordered by the user.
     */
    items: T[];
    /**
     * Must return a unique ID for the given item. Used when mapping over the list of items to render text for them.
     */
    getItemId: (item: T) => string;
    /**
     * The description for a given item. For example, the name of a section.
     */
    getItemDescription: (item: T) => string;
    /**
     * Called with the reordered items when the user clicks the "CONFIRM" button.
     */
    onOrderSelected: (items: T[]) => void;
    /**
     * Called when the user clicks the "Close" button. Any unsaved changes will be lost.
     */
    onClose: () => void;
}

/**
 * A modal intended for reuse among configuration pages that allow the user to reorder items, e.g., when reordering sections for the IRQ configuration.
 * Displays text for each item, and allows the user to click "up" and "down" arrows to reorder the items.
 */
export const ReorderModal = <T,>(props: ReorderModalProps<T>) => {
    const [reorderedItems, setReorderedItems] = useState<T[]>(props.items);

    const moveUp = (index: number) => {
        const newItems = [...reorderedItems];
        [newItems[index - 1], newItems[index]] = [newItems[index], newItems[index - 1]];
        setReorderedItems(newItems);
    };

    const moveDown = (index: number) => {
        const newItems = [...reorderedItems];
        [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
        setReorderedItems(newItems);
    };

    return (
        <Modal show onHide={props.onClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <ModalHeader text={props.title} />
                <div className={styles.itemsToBeReorderedContainer}>
                    {reorderedItems.map((item, index) => {
                        const itemId = props.getItemId(item);
                        const itemDescription = props.getItemDescription(item);

                        return (
                            <div key={itemId} className={styles.itemToBeReordered}>
                                <MaterialIconButton disabled={index === 0} onClick={() => moveUp(index)}>
                                    <FontAwesomeIcon title={`Move up ${itemDescription}`} icon={faArrowUp} />
                                </MaterialIconButton>
                                <MaterialIconButton disabled={index === reorderedItems.length - 1} onClick={() => moveDown(index)}>
                                    <FontAwesomeIcon title={`Move down ${itemDescription}`} icon={faArrowDown} />
                                </MaterialIconButton>
                                <div className={styles.itemToBeReorderedText}>
                                    <Text variant="Text2" key={props.getItemId(item)} noStyles>
                                        {ellipt(itemDescription)}
                                    </Text>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.onClose} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            props.onOrderSelected(reorderedItems);
                            props.onClose();
                        }}
                        fontAwesomeImage={faCheck}
                    >
                        CONFIRM
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
