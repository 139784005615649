import { ReactElement, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { UserResponse } from 'Models/User';

import styles from './DeleteUserModal.module.css';

export interface DeleteUserModalProps {
    hideModal: () => void;
    userSelectedForDeletion: UserResponse;
    usersApi: UsersApi;
    userDeleted: () => void;
}

export const DeleteUserModal = (props: DeleteUserModalProps): ReactElement => {
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);

    const deleteUser = async (): Promise<void> => {
        setIsDeletingUser(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.usersApi.deleteUser(props.userSelectedForDeletion.cognito_subject);
            setSuccessMessage('User deleted.');
            props.userDeleted();
        } catch (error) {
            handleRequestError(error);
        } finally {
            setIsDeletingUser(false);
        }
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isDeletingUser) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Delete" />
                <div className={styles.deleteText}>
                    Are you sure you want to delete: {props.userSelectedForDeletion.first_name} {props.userSelectedForDeletion.last_name}?
                </div>

                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={hideModal} disabled={isDeletingUser} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteUser()} loadingText={'Deleting...'} isLoading={isDeletingUser} fontAwesomeImage={ICON_DELETE_REMOVE} disabled={successMessage ? true : false}>
                        Delete
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
