import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';

import styles from './AddOrUpdateTextModal.module.css';

export interface AddOrUpdateTextModalProps {
    /**
     * The title of the modal. For example, "Add Section".
     */
    modalTitle: string;
    /**
     * The label for the input field. For example, "Section Name".
     */
    fieldName: string;
    /**
     * The initial (i.e., existing) value for the thing being saved, if it exists already.
     */
    initialValue?: string;
    /**
     * Called with the text input by the user when the user clicks the "CONFIRM" button.
     * Allows the parent component to return a failure string if the text is invalid.
     * If a string is returned, it will be displayed as an error message and the modal will remain open; otherwise, the modal will automatically call `onClose`.
     */
    onConfirm: (text: string) => string | void;
    /**
     * Called when the user clicks the "Close" button, in which case any unsaved changes will be lost.
     * Also called automatically when the user confirms the text input and the input is valid.
     */
    onClose: () => void;
}

/**
 * A modal intended for reuse among configuration pages that allow the user to create/update text, e.g., when adding/updating a section/question/answer for the IRQ configuration.
 * Optionally takes an initial value, which the user can edit, and then tells its parent component when the user is ready to save a new value.
 */
export const AddOrUpdateTextModal = (props: AddOrUpdateTextModalProps) => {
    const [text, setText] = useState(props.initialValue ?? '');
    const [failureMessage, setFailureMessage] = useState<string>();

    return (
        <Modal show onHide={props.onClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text={props.modalTitle} />
                <div className={styles.newOptionTextField}>
                    <FormFieldText required formFieldLabel={props.fieldName} formFieldId={props.fieldName} handleChange={(event: React.FormEvent<HTMLInputElement>) => setText(event.currentTarget.value)} value={text} />
                </div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.onClose} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        disabled={text === ''}
                        onClick={() => {
                            setFailureMessage(undefined);
                            const failureMessage = props.onConfirm(text);

                            if (failureMessage) {
                                setFailureMessage(failureMessage);
                            } else {
                                props.onClose();
                            }
                        }}
                        fontAwesomeImage={faCheck}
                    >
                        CONFIRM
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
