import type { JSX } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getIssueDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { DashboardType } from 'Models/Dashboards';
import { ClosedIssue, IssueResponse, ThirdPartyIssueResponse } from 'Models/Issues';
import { ThirdPartyIdToNameMap, ThirdPartyResponse } from 'Models/TPRM';

import styles from './HistoricalIssuesDisplayModal.module.css';

export enum ClosedIssueModalDisplayType {
    OPEN,
    CLOSED,
    TOTAL,
}

export interface ClosedIssuesDisplayModalProps {
    hideModal: () => void;
    selectedIssue: IssueResponse[];
    date: string;
    modalType: ClosedIssueModalDisplayType;
    dashboardType: DashboardType;
    thirdParties?: ThirdPartyResponse[];
}

// TODO: rename this. It's not just for closed issues.
/**
 * For a specific month, displays one of the following (based on where the user clicked in the parent chart):
 * * The number of issues opened
 * * The number of issues closed
 * * The total number of issues in the system
 */
export const ClosedIssuesDisplayModal = (props: ClosedIssuesDisplayModalProps): JSX.Element => {
    const thirdPartyIdToNameMap: ThirdPartyIdToNameMap = new Map(props.thirdParties?.map((thirdParty: ThirdPartyResponse) => [thirdParty.id, thirdParty.name]));

    const displayHeaderText = () => {
        switch (props.modalType) {
            case ClosedIssueModalDisplayType.CLOSED:
                return 'Closed Issues';
            case ClosedIssueModalDisplayType.OPEN:
                return 'Open Issues';
            case ClosedIssueModalDisplayType.TOTAL:
                return 'Total Issues';
        }
    };

    const displayIssueDate = (issue: IssueResponse) => {
        switch (props.modalType) {
            case ClosedIssueModalDisplayType.CLOSED:
                return iso8601ToUsDateShort((issue as ClosedIssue).closed_timestamp!);
            case ClosedIssueModalDisplayType.OPEN:
                return iso8601ToUsDateShort(issue.created_timestamp);
            case ClosedIssueModalDisplayType.TOTAL:
                return iso8601ToUsDateShort(issue.created_timestamp);
        }
    };

    const sortIssuesByDateType = (issueA: IssueResponse, issueB: IssueResponse): number => {
        if (props.modalType === ClosedIssueModalDisplayType.CLOSED) {
            if ((issueA as ClosedIssue).closed_timestamp! > (issueB as ClosedIssue).closed_timestamp!) {
                return 1;
            } else if ((issueA as ClosedIssue).closed_timestamp! < (issueB as ClosedIssue).closed_timestamp!) {
                return -1;
            } else {
                return issueA.title.localeCompare(issueB.title, undefined, { numeric: true, sensitivity: 'base' });
            }
        } else {
            if (issueA.created_timestamp > issueB.created_timestamp) {
                return 1;
            } else if (issueA.created_timestamp < issueB.created_timestamp) {
                return -1;
            } else {
                return issueA.title.localeCompare(issueB.title, undefined, { numeric: true, sensitivity: 'base' });
            }
        }
    };

    const closedIssueListing = props.selectedIssue.sort(sortIssuesByDateType).map((issue) => (
        <div className={styles.detailsContainer} key={issue.created_timestamp}>
            {props.dashboardType === DashboardType.THIRD_PARTY && thirdPartyIdToNameMap.size > 0 && (
                <div className={styles.detailsThirdPartyName}>
                    <Text color="darkGray" noStyles={true}>
                        {thirdPartyIdToNameMap.get((issue as ThirdPartyIssueResponse).impacted_vendor)}
                    </Text>
                </div>
            )}
            <Link to={getIssueDetailsUrl(issue.id, issue.type)} size="sm" openInNewTab>
                {issue.title}
            </Link>
            <div className={styles.detailsDateContainer}>
                <Text color="darkGray" noStyles={true}>
                    {displayIssueDate(issue)}
                </Text>
            </div>
        </div>
    ));

    return <CollapsibleModal hideModal={props.hideModal} displayItems={closedIssueListing} headerText={{ text: displayHeaderText(), secondaryText: `${props.date}` }} />;
};
