/**
 * PageLayoutDefault: The default layout that most pages use, with a gray header and one or more white body sections.
 *  - Examples: Actions Listing, Action Details, Governance Listing, Governance Details.
 *
 * PageLayoutDefaultGridAndTable: A layout that uses the standard gray header, but with a grid and table layout instead of a series of body sections.
 *  - Examples: TPRM Third Party Listing, TPRM Third Party Service Listing.
 *
 * PageLayoutDashboard: A layout for full-page dashboards WITHOUT tabs.
 *  - Examples: TPRM Third Party Service Dashboard.
 *
 * PageLayoutDashboardTabs: A layout for full-page dashboards WITH tabs.
 *  - Examples: The overall "Dashboard" page.
 *
 * PageLayoutHybridDashboard: This is essentially a layout hybrid of PageLayoutDefault and PageLayoutDashboard. The header is a dashboard, while the body is the standard series of body sections.
 *  - Examples: Control Details, Risk Details.
 *
 * PageLayoutHybridDashboardGridAndTable: This is similar to PageLayoutHybridDashboard, but the content is a grid of cards and a table instead of a series of body sections.
 *  - Examples: Control Group Details. The Framework Details page manually implements this (dashboard header with grid and table body), since it has tabs which make it a unique layout.
 */

import type { JSX } from 'react';

import { Dashlet, DashletProps } from 'Components/PageLayout/Dashlet/Dashlet';
import dashletStyles from 'Components/PageLayout/Dashlet/Dashlet.module.css';
import { Text } from 'Components/Text/Text';

import styles from './PageLayoutHybridDashboard.module.css';

/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param headerButtons     - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param headerDescription - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 * @param headerFilters     - Drop-down menu filters placed in the header above the dashlets.
 * @param headerDashlets    - List of dashlets that comprise a dashboard within the header. Dashlets can be nested within one another to create a grid of dashlets.
 * @param body              - The page body is everything below the page header. It is the main content of the page, and is composed of one or more page body sections.
 */
interface PageLayoutDashboardHalfProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    headerButtons?: React.ReactNode;
    headerDescription?: React.ReactNode | string;
    headerFilters?: React.ReactNode;
    headerDashlets: DashletProps[];
    body: BodySectionProps[];
}
/**
 * This is essentially a layout hybrid of PageLayoutDefault and PageLayoutDashboard. The header is a dashboard, while the body is the standard series of body sections.
 * Note that the <Nav> and application <Header> components are static siblings of this and other PageLayout components and are neither implemented nor documented in this module.
 */
export const PageLayoutHybridDashboard = (props: PageLayoutDashboardHalfProps): JSX.Element => {
    return (
        <>
            <div className={styles.background}>
                <Header breadcrumb={props.headerBreadcrumb} title={props.headerTitle} buttons={props.headerButtons} description={props.headerDescription} filters={props.headerFilters} dashlets={props.headerDashlets} />
            </div>
            <Body pageBodySections={props.body} />
        </>
    );
};

/**
 * @param breadcrumb    - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param title         - The title of the page.
 * @param buttons       - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param description   - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 * @param filters       - Drop-down menu filters placed in the header above the dashlets.
 * @param dashlets      - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface HeaderProps {
    breadcrumb: React.ReactNode;
    title: string;
    buttons?: React.ReactNode;
    description?: React.ReactNode | string;
    filters?: React.ReactNode;
    dashlets: DashletProps[];
}
/**
 * The page header is the top section of the page, which contains some information that is common across all pages, such as the breadcrumb and title of the page.
 * WARNING: Do not confuse this with the overall application <Header> component, which is rendered above the "page."
 */
const Header = (props: HeaderProps): JSX.Element => {
    return (
        <div className={styles.pageHeaderContent}>
            {props.breadcrumb}
            <div className={styles.titleAndButtons}>
                <Text color="white" variant="Header1">
                    {props.title}
                </Text>
                <div className={styles.buttons}>{props.buttons}</div>
            </div>
            {typeof props.description === 'string' ? (
                <Text color="darkGray" variant="Header3">
                    {props.description}
                </Text>
            ) : (
                props.description
            )}
            <div className={styles.filters}>{props.filters}</div>
            <div className={dashletStyles.dashletAlignmentColumn}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} {...dashlet} />
                ))}
            </div>
        </div>
    );
};

/**
 * @param pageBodySections - The page body sections that comprise the page body.
 */
interface BodyProps {
    pageBodySections: BodySectionProps[];
}
/**
 * The page body is everything below the page header. It is the main content of the page, and is composed of one or more page body sections.
 */
const Body = (props: BodyProps): JSX.Element => {
    return (
        <div className={styles.pageBody}>
            {props.pageBodySections.map((pageBodySection, index) => (
                <BodySection key={index} {...pageBodySection} />
            ))}
        </div>
    );
};

/**
 * @param title     - The title of the page body section. It is useful to include this if there are multiple page body sections on the page to differentiate them, or if it provides more context to the page header. But if the page body section contains tabs (so the tab names would make this redundant), or if this would simply be the same as the page header, then it should be excluded.
 * @param buttons   - Non-form buttons that are right-justified but on the same vertical alignment as the body section title. NOTE: This should only be used when there are multiple body sections or tabs where non-form buttons are needed that only apply to one body section. Otherwise, non-form buttons go in the page header. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param content   - The content of the page body section.
 */
interface BodySectionProps {
    title?: string;
    buttons?: React.ReactNode;
    content: React.ReactNode;
}
/**
 * A page body section is a grouping of content within a page. It is the familiar white box with rounded corners. Usually there is one of these per page, but there can be multiple.
 */
const BodySection = (props: BodySectionProps): JSX.Element => {
    const buildTitleAndButtons = () => {
        if (props.title && props.buttons) {
            return (
                <>
                    <div className={styles.titleAndButtons}>
                        <Text noStyles variant="Header2">
                            {props.title}
                        </Text>
                        <div className={styles.buttons}>{props.buttons}</div>
                    </div>
                    <hr />
                </>
            );
        } else if (props.title) {
            return (
                <>
                    <Text noStyles variant="Header2">
                        {props.title}
                    </Text>
                    <hr />
                </>
            );
        }
    };

    return (
        <div className={styles.pageBodySection}>
            {buildTitleAndButtons()}
            {props.content}
        </div>
    );
};
