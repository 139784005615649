/**
 * This is the page that loads upon log in and when accessing the root ("/") URL.
 * It redirects users to a more appropriate page based on their role.
 */
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { NO_ROLE_MESSAGE } from 'Config/Errors';
import { DASHBOARDS, FRAMEWORKS, OPERATIONAL_CONTROLS, RISKS, RISK_REGISTER, THIRD_PARTIES, TPRM } from 'Config/Paths';

export const Landing = () => {
    const navigate = useNavigate();
    const rbacContext = useContext(RBACContext);

    useEffect(() => {
        if (rbacContext) {
            if (userHasAuthorizedRole(rbacContext.roles, [Role.ADMIN, Role.CONTROL_USER])) {
                navigate(`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}`);
            } else if (userHasAuthorizedRole(rbacContext.roles, [Role.REPORTING_USER])) {
                navigate(`/${DASHBOARDS}`);
            } else if (userHasAuthorizedRole(rbacContext.roles, [Role.RISK_MANAGER, Role.RISK_USER])) {
                navigate(`/${RISK_REGISTER}/${RISKS}`);
            } else if (userHasAuthorizedRole(rbacContext.roles, [Role.THIRD_PARTY_USER])) {
                navigate(`/${TPRM}/${THIRD_PARTIES}`);
            }
        }
    }, [navigate, rbacContext]);

    if (rbacContext && rbacContext.roles.length === 0) {
        return <Text>{NO_ROLE_MESSAGE}</Text>;
    } else {
        return <Placeholder />;
    }
};
