import type { JSX } from 'react';

import { IconButton } from 'Components/Buttons/IconButton';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { Text } from 'Components/Text/Text';
import { ICON_DELETE_REMOVE } from 'Config/Icons';
import { ScheduleFrequencyKeys, ScheduleFrequencySelectOptions } from 'Models/ScheduleFrequency';
import { ServiceAssessmentSchedule } from 'Models/TPRM';

import styles from './RiskRatingSchedule.module.css';

export interface RiskRatingScheduleProps {
    label: string;
    inherentRiskRating: keyof ServiceAssessmentSchedule;
    scheduleNumber?: string;
    scheduleFrequency?: ScheduleFrequencyKeys;
    disabled?: boolean;
    onChange: (inherentRiskRating: keyof ServiceAssessmentSchedule, scheduleNumber?: string, scheduleFrequency?: ScheduleFrequencyKeys) => void;
    onClear: (inherentRiskRating: keyof ServiceAssessmentSchedule) => void;
}

export const RiskRatingSchedule = ({ scheduleNumber = '', ...props }: RiskRatingScheduleProps): JSX.Element => {
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        props.onChange(props.inherentRiskRating, event.currentTarget.value, props.scheduleFrequency);
    };

    const handleSelectChange = (value: ChangeEventType): void => {
        props.onChange(props.inherentRiskRating, scheduleNumber, value as ScheduleFrequencyKeys);
    };

    return (
        <div>
            <Text variant="Header2">{props.label}</Text>
            <div className={styles.schedule}>
                <div className={styles.scheduleNumber}>
                    <FormFieldText formFieldId={`scheduleNumber-${props.inherentRiskRating}`} formFieldLabel="Frequency" required={false} tooltip="An integer representing how often the service assessment should be conducted." handleChange={handleChange} value={scheduleNumber} disabled={props.disabled} />
                </div>

                <div className={styles.scheduleFrequency}>
                    <FormFieldSelect options={ScheduleFrequencySelectOptions} handleChange={handleSelectChange} formFieldId={`scheduleFrequency-${props.inherentRiskRating}`} selectedOption={props.scheduleFrequency} formFieldLabel="Interval" tooltip={'The frequency (month, year, etc.) that the service assessment should be conducted.'} disabled={props.disabled} />
                </div>
                <div className={styles.clearIcon}>
                    <IconButton aria-label={`clear-${props.inherentRiskRating}`} fontAwesomeImage={ICON_DELETE_REMOVE} onClick={() => props.onClear(props.inherentRiskRating)} />
                </div>
            </div>
        </div>
    );
};
