import type { JSX } from 'react';

import { AlertsTable } from 'Components/Alerts/AlertsTable/AlertsTable';
import { Text } from 'Components/Text/Text';
import { AlertResponse, _NUMBER_OF_DAYS_OF_ALERTS } from 'Models/Alerts';

import styles from './AlertsSummaryTab.module.css';

export interface AlertsSummaryTabProps {
    alertResponseList: AlertResponse[];
}

export const AlertsSummaryTab = (props: AlertsSummaryTabProps): JSX.Element => {
    return (
        <>
            {props.alertResponseList.length === 0 && <Text>{`No alerts in the past ${_NUMBER_OF_DAYS_OF_ALERTS} days.`}</Text>}
            {props.alertResponseList.length > 0 && (
                <div className={styles.container}>
                    <Text>{`Alerts generated in the past ${_NUMBER_OF_DAYS_OF_ALERTS} days.`}</Text>
                    <AlertsTable alertsResponseList={props.alertResponseList} />
                </div>
            )}
        </>
    );
};
