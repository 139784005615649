import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { type JSX, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { Switch } from 'Components/Buttons/Switch';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';

import styles from './aiTprmServiceAssessmentModal.module.css';

interface AdvertiseAiTprmServiceAssessmentModalProps {
    hideModal: () => void;
}

/**
 * This informational modal is used when the Client does not have License.AI_TPRM_SERVICE_ASSESSMENT. It acts as an advertisement for the feature.
 */
export const AdvertiseAiTprmServiceAssessmentModal = (props: AdvertiseAiTprmServiceAssessmentModalProps): JSX.Element => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <ModalHeader text="SummIT Guide™" />
                <Text>Your organization is not licensed for SummIT Guide™. Please contact support@highpeakssolutions.com for assistance.</Text>
                <Text>SummIT Guide™ provides an automated assessment of responses to the Third-Party Questionnaire. Suggested control effectiveness ratings and corresponding explanations are populated in the "Additional Information" field for each control in the Control Assessment for your review.</Text>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

interface ConfirmAiTprmServiceAssessmentModalProps {
    aiTprmServiceAssessment: (includeClientResponses: boolean, includeThirdPartyResponses: boolean) => Promise<string>;
    hideModal: () => void;
}

/**
 * This confirmation modal is used when the Client does have License.AI_TPRM_SERVICE_ASSESSMENT. It describes what will happen if the user confirms the action and lays out a disclaimer for the output.
 */
export const ConfirmAiTprmServiceAssessmentModal = (props: ConfirmAiTprmServiceAssessmentModalProps): JSX.Element => {
    const [includeClientResponses, setIncludeClientResponses] = useState<boolean>(false);
    const [includeThirdPartyResponses, setIncludeThirdPartyResponses] = useState<boolean>(true);

    return (
        <ConfirmationModal buttonText="Create" buttonLoadingText="Creating..." buttonIcon={faCheck} operationType="nonDestructive" headerText="SummIT Guide™ Control Assessment Suggestions" areYouSureText={`Are you sure you want SummIT Guide™ to create control assessment suggestions?`} performOperation={() => props.aiTprmServiceAssessment(includeClientResponses, includeThirdPartyResponses)} hideModal={props.hideModal}>
            <Text>SummIT Guide™ provides an automated assessment of responses to the Third-Party Questionnaire. Suggested control effectiveness ratings and corresponding explanations are populated in the "Additional Information" field for each control in the Control Assessment for your review.</Text>
            <Text>This may take several minutes to complete, depending on the number and complexity of responses.</Text>
            <div className={styles.toggleButtonsContainer}>
                <div className={styles.toggleButton}>
                    <Switch checked={includeThirdPartyResponses} onChange={() => setIncludeThirdPartyResponses(!includeThirdPartyResponses)} />
                    <Text noStyles>Include "Third-Party Questionnaire" responses. This includes information submitted by the third party in the analysis performed by SummIT Guide™.</Text>
                </div>
                <div className={styles.toggleButton}>
                    <Switch checked={includeClientResponses} onChange={() => setIncludeClientResponses(!includeClientResponses)} />
                    <Text noStyles>Include "Control Assessment" responses. This includes information submitted by you or another user of your organization in the analysis performed by SummIT Guide™.</Text>
                </div>
            </div>
            <Text variant="Header3">Disclaimer</Text>
            <Text>Suggestions are created using generative AI. The information, suggestions, or content provided by this generative AI model is for informational purposes only. The content generated may not reflect the most current legal, technical, or professional standards.</Text>
            <Text>No Professional Advice: This tool does not constitute legal, medical, financial, or professional advice, and should not be relied upon as such. For legal, medical, financial, or other specialized advice, users should consult a qualified professional in the relevant field.</Text>
            <Text>No Warranties: The information provided is offered "as is," without any warranties of any kind, either express or implied. The creators, operators, and developers of this AI model make no representations or warranties about the accuracy, completeness, reliability, or suitability of the content for any purpose.</Text>
            <Text>Limitation of Liability: Under no circumstances will the creators, operators, or developers of this AI model be liable for any damages, losses, or claims arising from the use of the content generated by this AI, including but not limited to direct, indirect, incidental, punitive, or consequential damages.</Text>
            <Text>User Responsibility: Users are responsible for verifying any information or content generated by this AI model and should use their own judgment before acting on any information provided.</Text>
        </ConfirmationModal>
    );
};
