import { JSX } from 'react';

import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

export interface UsersExportProps {
    disableVirtualization?: boolean;
}

export const UsersExport = (props: UsersExportProps): JSX.Element => {
    const cachedData = useCachedData();

    const columns: GridColumn<UserResponse>[] = [
        { field: 'cognito_subject', headerName: 'Name', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.LAST_COMMA_FIRST) },
        { field: 'email_address', headerName: 'Email Address', width: 300 },
        { field: 'department', headerName: 'Department', width: 300 },
        {
            field: 'roles',
            headerName: 'Roles',
            width: 300,
            valueGetter: (value, row) => row.roles.sort().join(', ').toUpperCase(),
        },
        { field: 'status', headerName: 'Status', width: 300, valueGetter: (value) => value.toUpperCase() },
        { field: 'mfa_required', headerName: 'MFA', width: 300, valueGetter: (value) => (value === true ? 'ENABLED' : 'DISABLED') },
    ];

    return (
        <PageLayoutDefault
            headerBreadcrumb={
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                    <BreadcrumbText>Users</BreadcrumbText>
                </Breadcrumb>
            }
            headerTitle="Users Export"
            body={[{ content: <DataGrid columns={columns} getRowId={(user: UserResponse) => user.cognito_subject} rows={cachedData.users} title="Users Export Preview" fileName="Users Export" disableVirtualization={props.disableVirtualization} /> }]}
        />
    );
};
