import { type JSX, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import { PublicDDQAuthApi } from 'Api/Auth/PublicDDQ/PublicDDQAuthApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { Text } from 'Components/Text/Text';
import { DDQ, PUBLIC, TPRM } from 'Config/Paths';
import { BasicAuthHandler } from 'Helpers/Auth/BasicAuth/BasicAuthHandler';
import { validateEmail, validatePassword } from 'Helpers/InputValidation';
import { WindowNavigator } from 'Helpers/WindowNavigator';
import { ValidationError } from 'Models/ErrorTypes';
import { PublicDDQRegistrationRequest } from 'Models/TPRM';

import styles from './VSCLogin.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
    client_id: string;
}

export interface VSCLoginProps {
    publicDDQAuthApi: PublicDDQAuthApi;
    basicAuthHandler: BasicAuthHandler;
    windowNavigator: WindowNavigator;
}

interface FormState {
    isSaving: boolean;
    authorized?: boolean;
    failureMessage?: string;
}

interface FormFieldsState {
    email: string;
    password: string;
    confirmPassword: string;
}

export const INVALID_DDQ_ERROR = 'INVALID_DDQ';

export const VSCLogin = (props: VSCLoginProps): JSX.Element => {
    const { client_id, third_party_id, service_id } = useParams<keyof UrlParams>() as UrlParams;
    const [showLoginForm, setShowLoginForm] = useState<boolean>(true); // When false, show registration form instead.
    const [formState, setFormState] = useState<FormState>({ isSaving: false });
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({ email: '', password: '', confirmPassword: '' });

    const login = async () => {
        try {
            await props.basicAuthHandler.login(formFieldsState.email, formFieldsState.password, client_id, third_party_id, service_id);
            props.windowNavigator.navigateTo(`/${PUBLIC}/${TPRM}/${DDQ}`);
        } catch (error) {
            setFormState({ isSaving: false, failureMessage: error.message });
        }
    };

    const handleRegister = async () => {
        setFormState({ isSaving: true });

        const request: PublicDDQRegistrationRequest = {
            email_address: formFieldsState.email,
            password: formFieldsState.password,
            password_confirm: formFieldsState.confirmPassword,
        };

        try {
            validate();
            await props.publicDDQAuthApi.registerForDDQ(request, client_id, third_party_id, service_id);
            login();
        } catch (error) {
            setFormState({ isSaving: false, failureMessage: error.message });
        }
    };

    const handleLogin = () => {
        setFormState({ isSaving: true });
        try {
            login();
        } catch (error) {
            setFormState({ isSaving: false, failureMessage: error.message });
        }
    };

    const validate = () => {
        if (!validateEmail(formFieldsState.email)) {
            throw new ValidationError('Invalid email address.');
        }

        if (!validatePassword(formFieldsState.password)) {
            throw new ValidationError('Invalid password. Password must be between 8 and 128 characters and contain at least three of the following: a lower-case letter, an upper-case letter, a number, a special character.');
        }

        if (formFieldsState.password !== formFieldsState.confirmPassword) {
            throw new ValidationError('The password and confirm password fields do not match.');
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (showLoginForm) {
            handleLogin();
        } else {
            handleRegister();
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const renderInstructions = (): JSX.Element => {
        return (
            <>
                <Text>If this is your first time accessing this questionnaire, use the "REGISTER" option below to set a password.</Text>
                <Text>If you forget your password, contact the individual listed in your email invitation to reset your password.</Text>
                <Text color="red">At the discretion of the individual performing the password reset, all existing progress on the questionnaire may also be cleared.</Text>
                <Text>Once the questionnaire is complete, your access is revoked.</Text>
            </>
        );
    };

    if (showLoginForm === true) {
        return (
            <>
                <PageBackground color="blueMountains">
                    <div className={styles.header}>
                        <PageContent>
                            <Text color="white" variant="Header1">
                                Third-Party Log In
                            </Text>
                        </PageContent>
                    </div>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                            <Text variant="Header2">Log In</Text>
                            {renderInstructions()}
                            <Form noValidate onSubmit={onSubmit}>
                                <div>
                                    <FormFieldText value={formFieldsState.email} formFieldId="email" formFieldLabel="Email" handleChange={handleChange} required />
                                </div>
                                <div>
                                    <FormFieldText formFieldType="password" value={formFieldsState.password} formFieldId="password" formFieldLabel="Password" handleChange={handleChange} required />
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <div>
                                        <Text noStyles>Don't have an account?</Text>
                                        <Button onClick={() => setShowLoginForm(false)} size="sm" variant="linkText">
                                            Register
                                        </Button>
                                    </div>
                                    <Button variant="submit" isLoading={formState.isSaving} loadingText="Logging in...">
                                        Log In
                                    </Button>
                                </div>
                            </Form>
                        </PageCell>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else {
        return (
            <>
                <PageBackground color="blueMountains">
                    <div className={styles.header}>
                        <PageContent>
                            <Text color="white" variant="Header1">
                                Third-Party Registration
                            </Text>
                        </PageContent>
                    </div>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                            <Text variant="Header2">Register</Text>
                            {renderInstructions()}
                            <Form noValidate onSubmit={onSubmit}>
                                <div>
                                    <FormFieldText value={formFieldsState.email} formFieldId="email" formFieldLabel="Email" handleChange={handleChange} required tooltip="Registration is limited to individuals who receive an email invitation regarding completion of this questionnaire." />
                                </div>
                                <div>
                                    <FormFieldText formFieldType="password" value={formFieldsState.password} formFieldId="password" formFieldLabel="Password" handleChange={handleChange} required tooltip="Password must be between 8 and 128 characters and contain at least three of the following: a lower-case letter, an upper-case letter, a number, a special character." />
                                </div>
                                <div>
                                    <FormFieldText formFieldType="password" value={formFieldsState.confirmPassword} formFieldId="confirmPassword" formFieldLabel="Confirm Password" handleChange={handleChange} required />
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <div>
                                        <Text noStyles>Already have an account?</Text>
                                        <Button onClick={() => setShowLoginForm(true)} size="sm" variant="linkText">
                                            Log In
                                        </Button>
                                    </div>
                                    <Button variant="submit" isLoading={formState.isSaving} loadingText="Registering...">
                                        Register
                                    </Button>
                                </div>
                            </Form>
                        </PageCell>
                    </PageContent>
                </PageBackground>
            </>
        );
    }
};
