import { Fragment, type JSX, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ComplianceRequirementsApi } from 'Api/ComplianceRequirements/ComplianceRequirementsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ComplianceRequirementStatus } from 'Components/ComplianceRequirements/ComplianceRequirementStatus';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { COMPLIANCE_REQUIREMENTS } from 'Config/Paths';
import { makeComplianceRequirementHumanReadable } from 'Helpers/ComplianceRequirementFormatter/ComplianceRequirementFormatter';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { ComplianceRequirementStatusValues, ComplianceRequirementWithAllControlStatusesResponse, ControlSetDetails, UpdateAssignedControlRequest, titleCaseRequirementStatus } from 'Models/ComplianceRequirements';

import { DeleteAssociatedControlsModal, DeleteAssociatedControlsModalProps } from './DeleteAssociatedControlsModal/DeleteAssociatedControlsModal';
import { ModifyControlRow, ModifyControlRowProps } from './ModifyControlRow/ModifyControlRow';
import { ModifyRequirementAssociatedControlsModal, ModifyRequirementAssociatedControlsModalProps } from './ModifyRequirementAssociatedControlsModal/ModifyRequirementAssociatedControlsModal';
import styles from './RequirementDetails.module.css';

export interface RequirementDetailsProps {
    complianceRequirementsApi: ComplianceRequirementsApi;
    controlsApi: ControlsApi;
}

export interface PathParams {
    regulationName: string;
    requirement: string;
}

enum Modals {
    ModifyRequirementAssociatedControlsModal,
    DeleteRequirementAssociatedControlsModal,
    None,
}

export const RequirementDetails = (props: RequirementDetailsProps): JSX.Element => {
    const [error, setError] = useState<string>();
    const [requirementDetails, setRequirementDetails] = useState<ComplianceRequirementWithAllControlStatusesResponse>();
    const [showModal, setShowModal] = useState<Modals>(Modals.None);
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);
    const [selectedControlSet, setSelectedControlSet] = useState<string[]>([]);
    const [selectedControlSetIndex, setSelectedControlSetIndex] = useState<number>();
    const { regulationName, requirement } = useParams<keyof PathParams>() as PathParams;
    const hideModal = () => setShowModal(Modals.None);

    const getRequirementDetails = useCallback(async (): Promise<void> => {
        try {
            const requirementDetailsResponse = await props.complianceRequirementsApi.getRequirementDetails(regulationName, requirement);
            setRequirementDetails(requirementDetailsResponse.data);
        } catch (error) {
            setError(error.message);
        }
    }, [props.complianceRequirementsApi, regulationName, requirement]);

    //returns control IDs
    const mappedControlsDetailsToUpdateRequest = (): UpdateAssignedControlRequest => {
        return {
            assigned_control_sets: requirementDetails!.assigned_control_sets.map((list) => list.control_list.map((control) => control.control.identifier)),
        };
    };

    const addControlSet = async (controls: string[]): Promise<void> => {
        const request = mappedControlsDetailsToUpdateRequest();
        request.assigned_control_sets.push(controls);
        await props.complianceRequirementsApi.updateComplianceRequirementControls(regulationName, requirement, request);
        await getRequirementDetails();
    };

    const modifyControlSet = async (controls: string[]): Promise<void> => {
        const request = mappedControlsDetailsToUpdateRequest();
        request.assigned_control_sets.splice(selectedControlSetIndex!, 1, controls);
        await props.complianceRequirementsApi.updateComplianceRequirementControls(regulationName, requirement, request);
        await getRequirementDetails();
    };

    const deleteControlSet = async (): Promise<void> => {
        const request = mappedControlsDetailsToUpdateRequest();
        request.assigned_control_sets.splice(selectedControlSetIndex!, 1);
        await props.complianceRequirementsApi.updateComplianceRequirementControls(regulationName, requirement, request);
        await getRequirementDetails();
    };

    useEffect(() => {
        getRequirementDetails();
    }, [getRequirementDetails]);

    const openModifyControlSetModal = (selectedControlSet: string[], index: number | undefined) => {
        setSelectedControlSet(selectedControlSet);
        setSelectedControlSetIndex(index);
        setShowModal(Modals.ModifyRequirementAssociatedControlsModal);
    };

    const openDeleteControlSetModal = (selectedControlSet: string[], index: number) => {
        setSelectedControlSet(selectedControlSet);
        setSelectedControlSetIndex(index);
        setShowModal(Modals.DeleteRequirementAssociatedControlsModal);
    };

    const regulatoryGroup = (): JSX.Element[] => {
        return requirementDetails!.assigned_control_sets.map((list, index) => {
            const modifyControlRowProps: ModifyControlRowProps = {
                mappedControlSet: list,
                requirementName: makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier),
                openModifyControlSetModal: (controlSet) => openModifyControlSetModal(controlSet, index),
                openDeleteControlSetModal: (controlSet) => openDeleteControlSetModal(controlSet, index),
            };
            if (index === 0) {
                return <ModifyControlRow {...modifyControlRowProps} key={index} />;
            } else {
                return (
                    <Fragment key={index}>
                        <Text variant="Text2" noStyles>
                            AND
                        </Text>
                        <ModifyControlRow {...modifyControlRowProps} />
                    </Fragment>
                );
            }
        });
    };

    /**
     * Count the number of unique Control IDs.
     * @returns number
     */
    const countUniqueControlIds = (controlSetDetailsList: ControlSetDetails[]): number => {
        const uniqueControlIds = new Set();
        controlSetDetailsList.forEach((controlSetDetails) => {
            controlSetDetails.control_list.forEach((operationalControlWithStatus) => {
                uniqueControlIds.add(operationalControlWithStatus.control.identifier);
            });
        });
        return uniqueControlIds.size;
    };

    const requirementStatusTooltip = (): JSX.Element => {
        if (requirementDetails!.assigned_control_sets.length > 0) {
            if (requirementDetails!.overall_status === ComplianceRequirementStatusValues.MET) {
                return <GenericTooltip text={`${makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier)} has a status of MET because each control set has a status of MET.`}></GenericTooltip>;
            } else if (requirementDetails!.overall_status === ComplianceRequirementStatusValues.NOT_MET) {
                return <GenericTooltip text={`${makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier)} has a status of NOT MET because at least one control set has a status of NOT MET.`}></GenericTooltip>;
            } else if (requirementDetails!.overall_status === undefined) {
                return <GenericTooltip text={`${makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier)} will have a status once each control set has a status.`}></GenericTooltip>;
            }
        }
        return <GenericTooltip text={`${makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier)} will have a status once a control set has been added and has a status.`}></GenericTooltip>;
    };

    const modifyRequirementAssociatedControlModalProps: ModifyRequirementAssociatedControlsModalProps | undefined = controlMappingItems
        ? {
              hideModal: hideModal,
              saveControlSet: selectedControlSet.length === 0 ? addControlSet : modifyControlSet,
              currentMappedControlIdentifiers: selectedControlSet,
              controlMappingItems: controlMappingItems,
              controlMappingItemsError: controlMappingItemsError,
              updateRequirementDetails: getRequirementDetails,
          }
        : undefined;

    const deleteAssociatedControlsModalProps: DeleteAssociatedControlsModalProps = {
        performDelete: deleteControlSet,
        hideModal: () => setShowModal(Modals.None),
        associatedControls: selectedControlSet,
    };

    if (requirementDetails && modifyRequirementAssociatedControlModalProps) {
        return (
            <>
                {showModal === Modals.ModifyRequirementAssociatedControlsModal && <ModifyRequirementAssociatedControlsModal {...modifyRequirementAssociatedControlModalProps} />}
                {showModal === Modals.DeleteRequirementAssociatedControlsModal && <DeleteAssociatedControlsModal {...deleteAssociatedControlsModalProps} />}
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${COMPLIANCE_REQUIREMENTS}#${regulationName}`}>Compliance Requirements</BreadcrumbLink>
                            <BreadcrumbText>{`${regulationName} ${requirement}`}</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue" noStyles>
                                {makeComplianceRequirementHumanReadable(requirementDetails!.regulation, requirementDetails!.identifier)}
                            </Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            <ModalHeader text="Requirement Overview" />
                            <Text variant="Header4" color="darkBlue" toUppercase>
                                Overall Requirement Status
                            </Text>
                            <div className={styles.overviewRating}>
                                {requirementDetails.overall_status === undefined ? (
                                    <>
                                        <Text variant="Header2" color="blue" noStyles>
                                            <span className={styles.iconOutcomeContainer}>-</span>
                                            Awaiting Assessments
                                        </Text>
                                        <div className={styles.tooltip}>{requirementStatusTooltip()}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.iconOutcomeContainer}>
                                            <ComplianceRequirementStatus status={requirementDetails.overall_status} />
                                        </div>
                                        <Text variant="Header2" color="blue" noStyles>
                                            {titleCaseRequirementStatus(requirementDetails.overall_status)}
                                        </Text>
                                        <div className={styles.tooltip}>{requirementStatusTooltip()}</div>
                                    </>
                                )}
                            </div>
                            <>
                                {requirementDetails.parent_context && (
                                    <>
                                        <Text variant="Header4" color="darkBlue" toUppercase>
                                            parent requirement text
                                        </Text>
                                        <Text color="blue">{requirementDetails.parent_context}</Text>
                                    </>
                                )}
                                <Text variant="Header4" color="darkBlue" toUppercase>
                                    requirement text
                                </Text>
                                <Text color="blue" variant="Text3">
                                    {requirementDetails.text}
                                </Text>
                            </>
                            <div className={styles.controlsDetails}>
                                <div>
                                    <Text variant="Header4" color="darkBlue" toUppercase>
                                        number of controls mapped
                                    </Text>
                                    <Text color="blue" variant="Text3" noStyles>
                                        {countUniqueControlIds(requirementDetails.assigned_control_sets)}
                                    </Text>
                                </div>
                                <div>
                                    <Text variant="Header4" color="darkBlue" toUppercase>
                                        last updated
                                    </Text>
                                    {requirementDetails.last_updated ? (
                                        <Text color="blue" variant="Text3" noStyles>
                                            {iso8601ToUsDateShort(requirementDetails.last_updated)}
                                        </Text>
                                    ) : (
                                        <Text color="blue" variant="Text3" noStyles>
                                            N/A
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </PageCell>
                        <div className={styles.textButtonRow}>
                            <Button variant="primary" onClick={() => openModifyControlSetModal([], undefined)} fontAwesomeImage={ICON_ADD_CREATE}>
                                add control set
                            </Button>
                        </div>
                        {regulatoryGroup()}
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
