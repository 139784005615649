import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { sample } from 'lodash-es';
import { type JSX, useCallback, useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { TagsApi } from 'Api/Tags/TagsApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { COLOR_PALETTE, ColorPicker } from 'Components/ColorPicker/ColorPicker';
import { ClickAwayTextbox } from 'Components/FormField/ClickAwayTextbox/ClickAwayTextbox';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ICON_DELETE_REMOVE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { ResponseModel } from 'Models/ResponseModel';
import { TagCategoryRequest, TagCategoryResponse } from 'Models/Tags';

import styles from './ManageTagsModal.module.css';

type SubmitState = { type: 'none' } | { type: 'submitting' } | { type: 'success'; message?: string } | { type: 'failure'; message?: string };
type EditState = { type: 'none' } | { type: 'creating new tag'; text: string; errorMessage?: string } | { type: 'creating new category'; text: string; errorMessage?: string } | { type: 'editing tag'; tagIndex: number; text: string; errorMessage?: string } | { type: 'editing category'; categoryIndex: number; text: string; errorMessage?: string };

export interface ManageTagsModalProps {
    tagsApi: TagsApi;
    hideModal: () => void;
}

export const ManageTagsModal = (props: ManageTagsModalProps): JSX.Element => {
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>();
    const [tagCategories, setTagCategories] = useState<TagCategoryRequest[]>();
    const [editState, setEditState] = useState<EditState>({ type: 'none' });
    const [submitState, setSubmitState] = useState<SubmitState>({ type: 'none' });
    const [editOccurredSinceLastLoad, setEditOccurredSinceLastLoad] = useState(false);

    const getTags = useCallback(async (): Promise<void> => {
        try {
            const tagResponse: ResponseModel<TagCategoryResponse> = await props.tagsApi.getTags();
            const categories = tagResponse.data.category_set;

            categories.sort((a, b) => (a.title > b.title ? 1 : -1));
            categories.forEach((category) => category.tags.sort((a, b) => (a.title > b.title ? 1 : -1)));

            setTagCategories(categories);
            setEditOccurredSinceLastLoad(false);
        } catch (error) {
            console.log(error);
            setLoadingErrorOccurred(true);
        }
    }, [props.tagsApi]);

    useEffect(() => {
        getTags();
    }, [getTags]);

    if (!tagCategories) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {editState.type !== 'none' && editState.errorMessage && <Alert variant="danger">{editState.errorMessage}</Alert>}
                    {(submitState.type === 'success' || submitState.type === 'failure') && submitState.message && <Alert variant={submitState.type === 'success' ? 'success' : 'danger'}>{submitState.message}</Alert>}
                    <ModalHeader text="Tags" secondaryText="Manage tags to label and organize actions and risks." />
                    <div className={styles.placeholder}>
                        <Placeholder />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    if (loadingErrorOccurred) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {editState.type !== 'none' && editState.errorMessage && <Alert variant="danger">{editState.errorMessage}</Alert>}
                    {(submitState.type === 'success' || submitState.type === 'failure') && submitState.message && <Alert variant={submitState.type === 'success' ? 'success' : 'danger'}>{submitState.message}</Alert>}
                    <ModalHeader text="Tags" secondaryText="Manage tags to label and organize actions and risks." />
                    <Text>{GENERIC_ERROR_MESSAGE}</Text>
                    <div className={styles.buttonContainer}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handleTextChange = (value: string) => {
        switch (editState.type) {
            case 'none':
                break;
            case 'creating new tag':
            case 'creating new category':
            case 'editing tag':
            case 'editing category':
                setEditState({ ...editState, text: value });
                setEditOccurredSinceLastLoad(true);
                break;
        }
    };

    const saveText = () => {
        // Don't save anything if the user left the textbox blank.
        switch (editState.type) {
            case 'none':
                break;
            case 'creating new tag':
            case 'creating new category':
            case 'editing tag':
            case 'editing category':
                if (!editState.text) {
                    setEditState({ type: 'none' });
                    return;
                }
        }

        switch (editState.type) {
            case 'none':
                break;
            case 'creating new tag':
                if (tagCategories[selectedCategoryIndex!].tags.map((tag) => tag.title).includes(editState.text)) {
                    setEditState({ ...editState, errorMessage: 'A tag with that name already exists in this category.' });
                } else {
                    setTagCategories(tagCategories.map((category, index) => (index === selectedCategoryIndex ? { ...category, tags: [...category.tags, { title: editState.text }] } : category)));
                    setEditState({ type: 'creating new tag', text: '' });
                }
                break;
            case 'creating new category':
                if (tagCategories.map((category) => category.title).includes(editState.text)) {
                    setEditState({ ...editState, errorMessage: 'A category with that name already exists.' });
                } else {
                    const categoriesCount = tagCategories.length;

                    const categoryColors = tagCategories.map((category) => category.color);
                    const unusedPredefinedColors = COLOR_PALETTE.filter((color) => !categoryColors.includes(color));
                    const suggestedColor = sample(unusedPredefinedColors) ?? '#000000';

                    if (categoriesCount === 0) {
                        setTagCategories([{ title: editState.text, color: suggestedColor, tags: [] }]);
                        setSelectedCategoryIndex(0);
                        setEditState({ type: 'none' });
                    } else {
                        setTagCategories([...tagCategories, { title: editState.text, color: suggestedColor, tags: [] }]);
                        setSelectedCategoryIndex(categoriesCount);
                        setEditState({ type: 'none' });
                    }
                }
                break;
            case 'editing tag':
                const conflictingTagExists = tagCategories[selectedCategoryIndex!].tags.find((tag, index) => editState.text === tag.title && editState.tagIndex !== index) !== undefined;
                if (conflictingTagExists) {
                    setEditState({ ...editState, errorMessage: 'A tag with that name already exists in this category.' });
                } else {
                    setTagCategories(tagCategories.map((category, i) => (i === selectedCategoryIndex ? { ...category, tags: category.tags.map((tag, e) => (e === editState.tagIndex ? { ...tag, title: editState.text } : tag)) } : category)));
                    setEditState({ type: 'none' });
                }
                break;
            case 'editing category':
                const conflictingCategoryExists = tagCategories.find((category, index) => editState.text === category.title && editState.categoryIndex !== index) !== undefined;
                if (conflictingCategoryExists) {
                    setEditState({ ...editState, errorMessage: 'A category with that name already exists.' });
                } else {
                    setTagCategories(tagCategories.map((category, i) => (i === editState.categoryIndex ? { ...category, title: editState.text } : category)));
                    setEditState({ type: 'none' });
                }
                break;
        }
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            saveText();
        }
    };

    const updateCategoryColor = (newColor: string, index: number) => {
        setTagCategories(tagCategories.map((category, i) => (index === i ? { ...category, color: newColor } : category)));
        setEditOccurredSinceLastLoad(true);
    };

    const deleteTagCategory = (index: number) => {
        setTagCategories(tagCategories.filter((category, categoryIndex) => index !== categoryIndex));
        if (selectedCategoryIndex !== undefined) {
            if (index === selectedCategoryIndex) {
                setSelectedCategoryIndex(undefined);
            } else if (index < selectedCategoryIndex) {
                setSelectedCategoryIndex(selectedCategoryIndex - 1);
            }
        }

        setEditOccurredSinceLastLoad(true);
    };

    const deleteTag = (indexClicked: number) => {
        setTagCategories(
            tagCategories.map((category, index) =>
                index === selectedCategoryIndex
                    ? {
                          ...category,
                          tags: category.tags.filter((tag, tagIndex) => tagIndex !== indexClicked),
                      }
                    : category
            )
        );
        setEditOccurredSinceLastLoad(true);
    };

    const handlePotentialCloseModal = () => {
        if (editOccurredSinceLastLoad) {
            const confirmAlert = window.confirm(`Are you sure you want to close? \r\n\r\n All changes to tags and tag categories will be lost.`);

            if (confirmAlert === false) {
                return;
            }
        }
        props.hideModal();
    };

    const submitUpdateTagsRequest = async (): Promise<void> => {
        setSubmitState({ type: 'submitting' });
        try {
            await props.tagsApi.updateTags({ category_set: tagCategories });
            await getTags();
            // Un-select the category, if one is selected, because data may be rearranged or missing after re-fetching data.
            setSelectedCategoryIndex(undefined);
            setSubmitState({ type: 'success', message: 'Tags saved.' });
        } catch (error) {
            setSubmitState({ type: 'failure', message: error.message });
        }
    };

    return (
        <Modal show onHide={handlePotentialCloseModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <fieldset disabled={submitState.type === 'submitting'}>
                    {editState.type !== 'none' && editState.errorMessage && <Alert variant="danger">{editState.errorMessage}</Alert>}
                    {(submitState.type === 'success' || submitState.type === 'failure') && submitState.message && <Alert variant={submitState.type === 'success' ? 'success' : 'danger'}>{submitState.message}</Alert>}
                    <ModalHeader text="Tags" secondaryText="Manage tags to label and organize actions and risks." />
                    <div className={styles.tagContainer}>
                        <div className={styles.allTagCategories}>
                            <Text variant="Text2">Tag Categories</Text>
                            {tagCategories.map((category, index) => {
                                return (
                                    <div key={category.title} className={selectedCategoryIndex === index ? styles.tagCategoryActive : styles.tagCategory}>
                                        <ColorPicker accessibilityTitle={`Choose color for ${category.title} tags`} onColorChange={(color) => updateCategoryColor(color, index)} initialColor={category.color} />
                                        <div className={styles.link}>
                                            {editState.type === 'editing category' && editState.categoryIndex === index ? (
                                                <ClickAwayTextbox autoFocus key={index} onClickAway={saveText} formFieldId={index.toString()} handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTextChange(event.currentTarget.value)} onInput={handleInputKeyDown} value={editState.text} />
                                            ) : (
                                                <Button variant="linkText" onClick={() => setSelectedCategoryIndex(index)} size="lg">
                                                    {category.title}
                                                </Button>
                                            )}
                                        </div>
                                        <OverflowMenu
                                            accessibilityTitle={`${category.title} more options`}
                                            overflowItems={[
                                                {
                                                    onClickAction: () => setEditState({ type: 'editing category', categoryIndex: index, text: tagCategories[index].title }),
                                                    text: 'Edit Tag Category Name',
                                                    icon: ICON_EDIT_MODIFY_UPDATE,
                                                },
                                                {
                                                    onClickAction: () => deleteTagCategory(index),
                                                    text: 'Delete Tag Category',
                                                    icon: ICON_DELETE_REMOVE,
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            })}
                            {editState.type === 'creating new category' ? <ClickAwayTextbox autoFocus value={editState.text} onClickAway={saveText} formFieldId="new category input" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTextChange(event.currentTarget.value)} onInput={handleInputKeyDown} /> : <Button variant="linkText" size="lg" onClick={() => setEditState({ type: 'creating new category', text: '' })}>{`+ New Category`}</Button>}
                        </div>
                        <div className={styles.allTagsContainer}>
                            {selectedCategoryIndex !== undefined && (
                                <>
                                    <Text variant="Text2">{selectedCategoryIndex !== undefined ? `${tagCategories[selectedCategoryIndex].title} Tags` : 'Tags'}</Text>
                                    {tagCategories[selectedCategoryIndex].tags.map((tag, index) => {
                                        return (
                                            <div className={styles.tag} key={tag.title}>
                                                <div className={styles.link}>{editState.type === 'editing tag' && editState.tagIndex === index ? <ClickAwayTextbox autoFocus key={index} onClickAway={saveText} formFieldId={index.toString()} handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTextChange(event.currentTarget.value)} onInput={handleInputKeyDown} value={editState.text} /> : <Text variant="Text3">{tag.title}</Text>}</div>
                                                <OverflowMenu
                                                    accessibilityTitle={`${tag.title} more options`}
                                                    overflowItems={[
                                                        {
                                                            onClickAction: () => setEditState({ type: 'editing tag', tagIndex: index, text: tagCategories[selectedCategoryIndex].tags[index].title }),
                                                            text: 'Edit Tag Name',
                                                            icon: ICON_EDIT_MODIFY_UPDATE,
                                                        },
                                                        {
                                                            onClickAction: () => deleteTag(index),
                                                            text: 'Delete Tag',
                                                            icon: ICON_DELETE_REMOVE,
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        );
                                    })}
                                    {editState.type === 'creating new tag' ? <ClickAwayTextbox autoFocus value={editState.text} onClickAway={saveText} formFieldId="new tag text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTextChange(event.currentTarget.value)} onInput={handleInputKeyDown} /> : <Button variant="linkText" size="lg" onClick={() => setEditState({ type: 'creating new tag', text: '' })}>{`+ New Tag`}</Button>}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button variant="secondary" onClick={handlePotentialCloseModal} disabled={submitState.type === 'submitting'} fontAwesomeImage={faClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={submitUpdateTagsRequest} fontAwesomeImage={faCheck} isLoading={submitState.type === 'submitting'} loadingText="Submitting...">
                            Save
                        </Button>
                    </div>
                </fieldset>
            </Modal.Body>
        </Modal>
    );
};
