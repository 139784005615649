import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, IconButton as MaterialIconButton, Menu, MenuItem } from '@mui/material';
import { type JSX, useState } from 'react';

import { Text } from 'Components/Text/Text';
import { ICON_OVERFLOW } from 'Config/Icons';

import styles from './Button.module.css';

const DOT_COLOR_DEFAULT = 'black';
export interface OverflowItem {
    text: string;
    onClickAction: () => void;
    icon?: IconProp;
}

export interface OverflowMenuProps {
    overflowItems: OverflowItem[];
    overflowDotsColor?: 'black' | 'darkGray' | 'white';
    accessibilityTitle: string; //Passed to the FontAwesomeIcon which enables accessibility on the overflow button.
    disabled?: boolean;
}

export const OverflowMenu: React.FC<OverflowMenuProps> = ({ overflowItems, overflowDotsColor = DOT_COLOR_DEFAULT, accessibilityTitle, disabled = false }: OverflowMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const menuItemHandleClick = (overflowItem: OverflowItem): void => {
        setAnchorEl(null);
        overflowItem.onClickAction();
    };

    const overflowItem = (overflowItem: OverflowItem, index: number): JSX.Element => {
        return (
            <MenuItem onClick={() => menuItemHandleClick(overflowItem)} key={index}>
                {overflowItem.icon && (
                    <ListItemIcon sx={{ color: 'var(--hps-dark-blue)', paddingRight: '5px' }}>
                        <FontAwesomeIcon icon={overflowItem.icon} size="2x" />
                    </ListItemIcon>
                )}
                <Text noStyles={true}>{overflowItem.text}</Text>
            </MenuItem>
        );
    };

    const iconCssClassName = (() => {
        switch (overflowDotsColor) {
            case 'black':
                return styles.overflowDots;
            case 'darkGray':
                return styles.overflowDotsDarkGray;
            case 'white':
                return styles.overflowDotsWhite;
        }
    })();

    return (
        <>
            <MaterialIconButton disabled={disabled} onClick={handleClick}>
                <FontAwesomeIcon title={accessibilityTitle} icon={ICON_OVERFLOW} className={iconCssClassName} />
            </MaterialIconButton>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                disableScrollLock
                open={anchorEl !== null}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div data-testid={anchorEl === null ? undefined : 'openedOverflowMenu'}>{overflowItems.map(overflowItem)}</div>
            </Menu>
        </>
    );
};
