import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { CreateControlGroupConfigurationRequest } from 'Models/TPRM';

import { Framework, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface CreateControlGroupModalProps extends QuestionnaireConfigurationModal {
    framework: Framework;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    groupToCreate?: CreateControlGroupConfigurationRequest;
    complete: boolean;
}

export const CreateControlGroupModal = (props: CreateControlGroupModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<Partial<CreateControlGroupConfigurationRequest>>({ control_group_name: '', control_group_description: '' });
    const [isValid, setIsValid] = useState<boolean>();

    useEffect(() => {
        if (formFieldsState?.control_group_name && formFieldsState.control_group_description) {
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState]);

    useEffect(() => {
        const createGroup = async (request: CreateControlGroupConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.createControlGroupConfiguration(props.framework.framework, request);
                setFormState({ isLoading: false, successMessage: 'Control group created.', complete: true });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.groupToCreate && isValid) {
            createGroup(formState.groupToCreate);
        }
    }, [props.tprmApi, formState.groupToCreate, isValid, props.framework, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.control_group_name && formFieldsState.control_group_description) {
            const groupToCreate: CreateControlGroupConfigurationRequest = {
                control_group_name: formFieldsState.control_group_name,
                control_group_description: formFieldsState.control_group_description,
            };
            setFormState({ isLoading: false, groupToCreate: groupToCreate, complete: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Create Group Configuration" />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_group_name} formFieldId="control_group_name" formFieldLabel="Name" required handleChange={handleChange} tooltip="The name used to identify this control group." />
                    </div>
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_group_description} formFieldId="control_group_description" formFieldLabel="Description" required handleChange={handleChange} tooltip="A description of what is assessed by the controls in this control group." />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={formState.isLoading || !isValid || formState.complete} isLoading={formState.isLoading} loadingText="Creating...">
                            CREATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
