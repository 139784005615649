import { type JSX, ReactNode } from 'react';

import styles from './PageBackground.module.css';

export interface PageBackgroundProps {
    children: ReactNode;
    color: 'grey' | 'blueMountains' | 'white';
}

/** DEPRECATED. Do not implement this on anything new. Use the PageLayout/ components. */
export const PageBackground = (props: PageBackgroundProps): JSX.Element => {
    let backgroundClass = '';

    switch (props.color) {
        case 'grey':
            backgroundClass = styles.greyBackground;
            break;
        case 'blueMountains':
            backgroundClass = styles.blueMountainBackground;
            break;
        case 'white':
            backgroundClass = styles.whiteBackground;
            break;
    }

    return <div className={backgroundClass}>{props.children}</div>;
};
