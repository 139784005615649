import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { type JSX, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { Switch } from 'Components/Buttons/Switch';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';
import { ResetAndNotifyThirdPartyRequest, Service, ThirdPartyContact } from 'Models/TPRM';

import styles from './ConfirmResetAndNotifyThirdPartyModal.module.css';

export interface ConfirmResetAndNotifyThirdPartyModalProps {
    tprmApi: TPRMApi;
    service: Service;
    hideModal: () => void;
    questionnaireHasQuestions: boolean;
}

/**
 * Present a modal to confirm resetting the Due Diligence Questionnaire and which Third-Party Service contacts should have their credentials reset and be sent notification emails.
 */
export const ConfirmResetAndNotifyThirdPartyModal = (props: ConfirmResetAndNotifyThirdPartyModalProps): JSX.Element => {
    const [resetDdq, setResetDdq] = useState<boolean>(true);
    // Keep track of which Third-Party Service contacts should receive the DDQ email. By default, all Third-Party Service contacts with a valid email address are selected.
    const [selectedThirdPartyContacts, setSelectedThirdPartyContacts] = useState<ThirdPartyContact[]>(props.service.vendor_contacts.filter((thirdPartyContact) => thirdPartyContact.email_address !== undefined));

    const sendNotification = async (): Promise<string> => {
        const resetAndNotifyThirdPartyRequest: ResetAndNotifyThirdPartyRequest = { reset_ddq: resetDdq, third_party_contacts: selectedThirdPartyContacts };
        await props.tprmApi.resetAndNotifyThirdParty(props.service.vendor_id, props.service.id, resetAndNotifyThirdPartyRequest);
        return 'Third-party service contact(s) notified.';
    };

    if (props.questionnaireHasQuestions) {
        return (
            <ConfirmationModal operationType="nonDestructive" headerText="Notify Third-Party Service Contact(s)" areYouSureText="Are you sure you want to notify the third-party service contact(s)?" buttonText="NOTIFY" buttonLoadingText="Notifying..." buttonIcon={faEnvelope} performOperation={sendNotification} hideModal={props.hideModal}>
                <Text>{`This will send an email inviting the third-party service contact(s) to register and complete the third-party questionnaire for "${props.service.vendor_name} - ${props.service.name}".`}</Text>
                <Text>If the third-party service contact(s) already have access to the third-party questionnaire, this will delete their existing credentials and allow them to re-register.</Text>
                <>
                    {props.service.vendor_contacts
                        .filter((thirdPartyContact) => thirdPartyContact.email_address !== undefined)
                        .map((thirdPartyContact, index) => (
                            <div key={index} className={styles.contactInfo}>
                                <Checkbox
                                    checked={selectedThirdPartyContacts.includes(thirdPartyContact)}
                                    color="default"
                                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                        const isChecked = event.currentTarget.checked;
                                        setSelectedThirdPartyContacts((prevSelectedThirdPartyContacts) => {
                                            if (isChecked) {
                                                return [...prevSelectedThirdPartyContacts, thirdPartyContact];
                                            } else {
                                                return prevSelectedThirdPartyContacts.filter((contact) => contact !== thirdPartyContact);
                                            }
                                        });
                                    }}
                                />
                                <Text noStyles={true}>{thirdPartyContact.email_address}</Text>
                            </div>
                        ))}
                </>

                <Switch checked={resetDdq} name="Reset Questionnaire" onChange={(event: React.FormEvent<HTMLInputElement>): void => setResetDdq(!resetDdq)} />
                <Text noStyles={true}>Clear all progress on the third-party questionnaire and control assessment (recommended).</Text>
                <div className={resetDdq ? styles.hideWarning : undefined}>
                    <Text noStyles variant="Text3" color="red">
                        You selected to keep existing progress on the third-party questionnaire and control assessment. Due to the risk of social engineering, High Peaks Solutions strongly recommends confirming the identities of third-party service contacts using an out-of-band channel before resetting their credentials.
                    </Text>
                </div>
            </ConfirmationModal>
        );
    } else {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <ModalHeader text="Unable to Send Notification" />
                    <Text>The third-party questionnaire does not have any questions configured for the selected risk rating.</Text>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="primary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
};
