import type { JSX } from 'react';

import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { Text } from 'Components/Text/Text';

import styles from './NotFound.module.css';

export const NotFound = (): JSX.Element => {
    return (
        <PageBackground color="blueMountains">
            <PageContent>
                <div className={styles.centerText}>
                    <Text color="white" variant="Text1">
                        Page not found.
                    </Text>
                </div>
            </PageContent>
        </PageBackground>
    );
};
