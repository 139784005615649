import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { UpdateControlConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';

import { Control, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface UpdateControlModalProps extends QuestionnaireConfigurationModal {
    originalControl: Control;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    controlUpdates?: UpdateControlConfigurationRequest;
    complete: boolean;
}

export const UpdateControlModal = (props: UpdateControlModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<UpdateControlConfigurationRequest>({ control_name: props.originalControl.controlName, control_text: props.originalControl.controlText });
    const [isValid, setIsValid] = useState<boolean>();

    useEffect(() => {
        if (formFieldsState?.control_name && formFieldsState.control_text) {
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState]);

    useEffect(() => {
        const updateControl = async (request: UpdateControlConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, props.originalControl.framework, props.originalControl.groupId, props.originalControl.controlId)]));
                setFormState({ isLoading: false, successMessage: 'Control updated.', complete: false });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.controlUpdates && isValid) {
            updateControl(formState.controlUpdates);
        }
    }, [props.tprmApi, formState.controlUpdates, isValid, props.originalControl, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.control_name && formFieldsState.control_text) {
            const controlUpdates: UpdateControlConfigurationRequest = {
                control_name: props.originalControl.controlName !== formFieldsState.control_name ? formFieldsState.control_name : undefined,
                control_text: props.originalControl.controlText !== formFieldsState.control_text ? formFieldsState.control_text : undefined,
            };
            setFormState({ isLoading: false, controlUpdates: controlUpdates, complete: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event.currentTarget.name === 'control_text') {
            setFormFieldsState({
                ...formFieldsState,
                control_text: [{ text: event.currentTarget.value, children: [] }],
            });
        } else {
            setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Update Control Configuration" />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_name} formFieldId="control_name" formFieldLabel="Name" required handleChange={handleChange} tooltip="The name used to identify this control." />
                    </div>
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_text?.[0]?.text} formFieldId="control_text" formFieldLabel="Text" required handleChange={handleChange} tooltip="A description of what is assessed by this control." />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={formState.isLoading || !isValid || formState.complete} isLoading={formState.isLoading} loadingText="Updating...">
                            UPDATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
