import type { JSX } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, Props } from 'react-select';

import { OptionType } from 'Models/Types/GlobalType';

import styles from '../FormField.module.css';

type OptionTypes = string | number | Array<string | number> | undefined | null;
export type ChangeEventType = string | number | Array<string | number> | undefined;

export interface FormFieldSelectDarkProps {
    formFieldId: string;
    formFieldLabel: string;
    required?: boolean;
    tooltip?: string | JSX.Element;
    invalidMessage?: string;
    handleChange?: (value: ChangeEventType, formFieldId: string) => void;
    selectedOption?: OptionTypes;
    disabled?: boolean;
    options?: OptionType[];
    selectMultiple?: boolean;
    badge?: string;
}

/**
 * Deprecated. In the future, usages of this component will be replaced by usages of `FormFieldSelect`. If you need a dark select field, use that component instead of this one.
 */
export const FormFieldSelectDark = (props: FormFieldSelectDarkProps): JSX.Element => {
    const convertSelectedOption = (selectedOption: OptionTypes): OptionType | OptionType[] | undefined | null => {
        if (selectedOption === undefined || selectedOption === null) {
            return selectedOption;
        }

        if (typeof selectedOption === 'string' || typeof selectedOption === 'number') {
            return props.options?.find((option) => option.value === selectedOption);
        }
        return props.options?.filter((option) => selectedOption?.includes(option.value));
    };

    const handleChange = (value: OnChangeValue<OptionType, true | false>): void => {
        if (props.handleChange) {
            if (Array.isArray(value)) {
                props.handleChange(
                    value.map((option) => option.value),
                    props.formFieldId
                );
            } else if (value && 'value' in value) {
                props.handleChange(value['value'], props.formFieldId);
            } else {
                props.handleChange(undefined, props.formFieldId);
            }
        }
    };

    const selectProps: Props<OptionType, true | false> = {
        options: props.options,
        onChange: handleChange,
        isMulti: props.selectMultiple,
        value: convertSelectedOption(props.selectedOption),
        isDisabled: props.disabled,
        inputId: props.formFieldId,
        placeholder: props.formFieldLabel,
    };
    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <Select
                {...selectProps}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: 'transparent',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: 'var(--hps-white)',
                        backgroundColor: 'var(--hps-blue)',
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: 'var(--hps-white)',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: 'var(--hps-white)',
                    }),
                    placeholder: (styles) => ({
                        ...styles,
                        color: 'var(--hps-white)',
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
