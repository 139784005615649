import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { RiskExportRow } from 'Models/Exports';
import { OperationalControl } from 'Models/OperationalControls';

export interface RisksExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const RisksExport = (props: RisksExportProps): JSX.Element => {
    const [risks, setRisks] = useState<RiskExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse = await props.exportsApi.getRisksExport();
                setRisks(riskResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getRisks();
    }, [props.exportsApi]);

    const columns: GridColumn<RiskExportRow>[] = [
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        dateColumn('created_timestamp', 'Open Date'),
        { field: 'created_by', headerName: 'Created By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('last_updated_timestamp', 'Last Updated'),
        { field: 'last_updated_by', headerName: 'Last Updated By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'category', headerName: 'Category', width: 300 },
        { field: 'control_environment_effectiveness', headerName: 'Control Environment Effectiveness', width: 300, type: 'number', headerAlign: 'left' },
        {
            field: 'controls',
            headerName: 'Controls',
            width: 300,
            valueGetter: (_value, row) =>
                row.controls
                    .sort(controlComparator)
                    .map((control: OperationalControl) => getHumanReadableControlIdFromControl(control))
                    .join(', '),
        },
        { field: 'tags', headerName: 'Tags', width: 300, valueGetter: (_value, row) => row.tags.sort().join(', ') },
        { field: 'treatment_plan_status', headerName: 'Treatment Plan Status', width: 300 },
        { field: 'scores_comment', headerName: 'Scores Comment', width: 300 },
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'reference', headerName: 'Reference', width: 300 },
        { field: 'strategy', headerName: 'Strategy', width: 300 },
        { field: 'treatment_plan_description', headerName: 'Treatment Plan Description', width: 300 },
        { field: 'inherent_risk_probability', headerName: 'Inherent Risk Probability', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'inherent_risk_impact', headerName: 'Inherent Risk Impact', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'total_inherent_risk', headerName: 'Total Inherent Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'current_risk_probability', headerName: 'Current Risk Probability', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'current_risk_impact', headerName: 'Current Risk Impact', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'total_current_risk', headerName: 'Total Current Risk', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'target_risk_probability', headerName: 'Target Risk Probability', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'target_risk_impact', headerName: 'Target Risk Impact', width: 300, type: 'number', headerAlign: 'left' },
        { field: 'total_target_risk', headerName: 'Total Target Risk', width: 300, type: 'number', headerAlign: 'left' },
        {
            field: 'associated_actions',
            headerName: 'Associated Actions',
            width: 300,
            valueGetter: (_value, row) => row.associated_actions.sort().join(', '),
        },
    ];

    if (risks) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Risks</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Risks Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(risk: RiskExportRow) => risk.id} rows={risks} title="Risks Export Preview" fileName="Risks Export" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
