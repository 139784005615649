import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';
import { validateLimitValue } from 'Helpers/Limits';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName, MetricName } from 'Models/ExternalIntegrations';
import { CreateMetricLimitRequest, LimitType, MetricAlertPreference, MetricAlertPreferenceSelectOptions, MetricLimitDescription } from 'Models/Limits';
import { OptionType } from 'Models/Types/GlobalType';

import styles from './CreateMetricsLimitModal.module.css';
import { MetricOptionsTooltip } from '../MetricOptionsTooltip';

export interface CreateMetricsLimitModalProps {
    hideModal: () => void;
    alertsLimitsApi: AlertsLimitsApi;
    onLimitCreated: () => void;
    controlIdentifier: string;
    createMetricIntegrationOptions: MetricOptions[];
}

interface FormFieldsState {
    limitValue?: number;
    integration?: IntegrationName;
    metricName?: MetricName;
    alertPreference?: MetricAlertPreference;
}

export interface MetricOptions {
    integrationName: IntegrationName;
    metricNames: MetricName[];
}

export const CreateMetricsLimitModal = (props: CreateMetricsLimitModalProps) => {
    const [isCreatingLimit, setIsCreatingLimit] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({});

    const createLimit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);
        setIsCreatingLimit(true);
        try {
            validateForm();

            const createLimitRequest: CreateMetricLimitRequest = {
                entity_id: props.controlIdentifier,
                integration_name: formFieldsState.integration!,
                metric_name: formFieldsState.metricName!,
                alert_limit: formFieldsState.limitValue!,
                enabled: true,
                alert_preference: formFieldsState.alertPreference!,
                _type: LimitType.METRIC,
            };
            await props.alertsLimitsApi.createLimit(createLimitRequest);
            props.onLimitCreated();
            setSuccessMessage('Limit created.');
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsCreatingLimit(false);
        }
    };

    const createIntegrationOptions = (): OptionType[] => {
        const integrationSelectOptions: OptionType[] = [];
        props.createMetricIntegrationOptions.forEach((integration) => {
            integrationSelectOptions.push({ value: integration.integrationName, label: integration.integrationName });
        });
        return integrationSelectOptions;
    };

    const createMetricOptions = (): OptionType[] => {
        const metricSelectOptions: OptionType[] = [];
        props.createMetricIntegrationOptions.forEach((integration) => {
            if (formFieldsState.integration === integration.integrationName) {
                integration.metricNames.forEach((metric) => {
                    metricSelectOptions.push({ value: metric, label: metric });
                });
            }
        });

        return metricSelectOptions;
    };

    const validateForm = (): void => {
        if (!formFieldsState.integration || !formFieldsState.metricName || !formFieldsState.alertPreference || formFieldsState.limitValue === undefined || formFieldsState.limitValue.toString().trim().length === 0) {
            throw new ValidationError('Please select an Integration, Metric, Alert Preference and Limit Value.');
        }

        validateLimitValue(formFieldsState.metricName, formFieldsState.limitValue);
    };

    const addMetricDescription = (): string => {
        if (formFieldsState.metricName === undefined) {
            return '';
        } else {
            // MetricName and MetricLimitDescription have the same keys, so this converts the metricName variable (which is of type MetricName) to its enum key, so the corresponding value in MetricLimitDescription can be obtained.
            const metricNameEnumKey = Object.keys(MetricName)[Object.values(MetricName).indexOf(formFieldsState.metricName)];
            return MetricLimitDescription[metricNameEnumKey as keyof typeof MetricLimitDescription];
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isCreatingLimit) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={createLimit}>
                    <ModalHeader text="Create a Metric Limit" />
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={createIntegrationOptions()} handleChange={handleSelectChange} formFieldId="integration" formFieldLabel="Integration Name" tooltip="Select an integration to apply a limit." required />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={createMetricOptions()} handleChange={handleSelectChange} formFieldId="metricName" formFieldLabel="Metric Name" tooltip="Select a metric to apply a limit." disabled={!formFieldsState.integration} required />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={MetricAlertPreferenceSelectOptions} handleChange={handleSelectChange} formFieldId="alertPreference" formFieldLabel="Alert Preference" tooltip={MetricOptionsTooltip} required />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldText formFieldType="number" handleChange={handleChange} formFieldId="limitValue" formFieldLabel="Limit Value" required tooltip="Set a numeric limit that determines when an alert is generated." invalidMessage="Please enter a valid number." />
                    </div>
                    {formFieldsState.metricName && (
                        <div className={styles.metricDescription}>
                            <Text>{addMetricDescription()}</Text>
                        </div>
                    )}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} disabled={isCreatingLimit} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={successMessage ? true : false} isLoading={isCreatingLimit} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
