import { TableHead } from '@mui/material';
import type { JSX } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE } from 'Config/Icons';
import { AwsConfigMisconfigurationDetailResponse } from 'Models/ExternalIntegrations';

import styles from '../ConfigureIntegration.module.css';

export interface AwsConfigMisconfigurationInfoProps {
    misconfigurationDetails: AwsConfigMisconfigurationDetailResponse[];
    hideModal: () => void;
}

export const AwsConfigMisconfigurationInfo = (props: AwsConfigMisconfigurationInfoProps): JSX.Element => {
    // Organizes misconfiguration details so that organization-level details come first, followed by account and account-region details, which are grouped by account.
    const misconfigurationInfo: JSX.Element = (() => {
        // Groups organization-level misconfigurations.
        const organizationMisconfigurationDetails: string[] = [];

        // Groups account and account-region misconfigurations under the account ID.
        const accountRegionMisconfigurationDetails = new Map<string, [string[], Map<string, string[]>]>();

        // For each misconfiguration detail, determine whether it applies to the organization, a specific account, or a specific region in a specific account.
        props.misconfigurationDetails.forEach((detail) => {
            if (detail.region && detail.account_id) {
                let accountMisconfiguration = accountRegionMisconfigurationDetails.get(detail.account_id);
                if (accountMisconfiguration === undefined) {
                    accountMisconfiguration = [[], new Map<string, string[]>()];
                    accountRegionMisconfigurationDetails.set(detail.account_id, accountMisconfiguration);
                }

                let regionMisconfiguration = accountMisconfiguration[1].get(detail.region);
                if (regionMisconfiguration === undefined) {
                    regionMisconfiguration = [];
                    accountMisconfiguration[1].set(detail.region, regionMisconfiguration);
                }

                regionMisconfiguration.push(detail.description);
            } else if (detail.account_id) {
                let accountMisconfiguration = accountRegionMisconfigurationDetails.get(detail.account_id);
                if (accountMisconfiguration === undefined) {
                    accountMisconfiguration = [[], new Map<string, string[]>()];
                    accountRegionMisconfigurationDetails.set(detail.account_id, accountMisconfiguration);
                }

                accountMisconfiguration[0].push(detail.description);
            } else {
                organizationMisconfigurationDetails.push(detail.description);
            }
        });

        const organizationInfo = organizationMisconfigurationDetails.map((str) => ['---', '---', str]);

        // For each account ID, map to a list of account-level details followed by a list of region-specific details.
        const accountAndRegionInfo = Array.from(accountRegionMisconfigurationDetails.entries()).flatMap(([accountId, accountRegionMisconfiguration]) => {
            const accountInfo = accountRegionMisconfiguration[0].map((str) => [accountId, '---', str]);
            const regionInfo = Array.from(accountRegionMisconfiguration[1].entries()).flatMap(([region, regionMisconfiguration]) => {
                return regionMisconfiguration.map((str) => [accountId, region, str]);
            });

            return accountInfo.concat(regionInfo);
        });

        const info = organizationInfo.concat(accountAndRegionInfo);

        return (
            <>
                <Text>Setup misconfiguration was detected. To correct this misconfiguration, you can manually perform the necessary changes in the accounts and regions listed below, or you can perform the automated setup of your AWS environment.</Text>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text variant="Text4" noStyles>
                                    Account ID
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text variant="Text4" noStyles>
                                    Region
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text variant="Text4" noStyles>
                                    Error
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {info.map(([accountId, region, error], index) => (
                            <TableRow key={index}>
                                <TableCellDefaultText>
                                    <div className={styles.noWrap}>
                                        <Text>{accountId}</Text>
                                    </div>
                                </TableCellDefaultText>
                                <TableCellDefaultText>
                                    <div className={styles.noWrap}>
                                        <Text>{region}</Text>
                                    </div>
                                </TableCellDefaultText>
                                <TableCellDefaultText>
                                    <Text>{error}</Text>
                                </TableCellDefaultText>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    })();

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text="Configuration Errors" />
                <div>
                    {misconfigurationInfo}
                    <div className={styles.buttonRowContainer}>
                        <div className={styles.buttonPadding}>
                            <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
