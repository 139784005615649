import { type JSX, useCallback, useEffect, useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { AlertDashlet } from 'Components/Alerts/AlertDashlet/AlertDashlet';
import { Button, Link } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { ControlCard } from 'Components/ControlCard/ControlCard';
import { ControlBarData, ControlBarGraph } from 'Components/Graphs/ControlBarGraph';
import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { AssociatedControlGroup, AssociatedControlGroupsModal, AssociatedControlGroupsModalProps } from 'Components/Modal/AssociatedControlGroupModal/AssociatedControlGroupModal';
import { RelatedControlAssessmentsModal, RelatedControlAssessmentsModalProps } from 'Components/Modal/RelatedControlAssessmentsModal/RelatedControlAssessmentsModal';
import { Dashlet } from 'Components/PageLayout/Dashlet/Dashlet';
import { GridAndTable } from 'Components/PageLayout/GridAndTable/GridAndTable';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { ICON_ADD_CREATE, ICON_SETTINGS } from 'Config/Icons';
import { CONFIGURATION, FRAMEWORKS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { controlGroupComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControlId, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { AlertRequest, AlertResponse } from 'Models/Alerts';
import { AllCustomControlsControlFrameworkResponse, ControlEffectivenessProgressBarVariantAndPercentage, ControlGroupResponse, DetailedControlFrameworkResponse, Effectiveness, effectivenessAsString, effectivenessLabelAsNumber, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import { ControlGroupListView } from './ControlGroupListView/ControlGroupListView';
import { CreateControlGroupModal, CreateControlGroupModalProps } from './CreateControlGroupModal/CreateControlGroupModal';
import { CustomControlReport } from './CustomControlReport/CustomControlReport';
import styles from './FrameworkSummary.module.css';

export enum Modals {
    CreateControlGroupModal,
    ComingDueAssessmentModal,
    RecentlyCompletedAssessmentModal,
    OverdueAssessmentModal,
    DisplayControlGroupByEffectiveness,
    None,
}
export interface FrameworkSummaryProps {
    framework: DetailedControlFrameworkResponse;
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export const FrameworkSummary = (props: FrameworkSummaryProps): JSX.Element => {
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [controlGroupResponse, setControlGroupResponse] = useState<ControlGroupResponse[]>();
    const [controlBarGraphProps, setControlBarGraphProps] = useState<ControlBarData>({ fail: 0, inactive: 0, moderate: 0, robust: 0, strong: 0, weak: 0 });
    const [allCustomControls, setAllCustomControls] = useState<AllCustomControlsControlFrameworkResponse[]>();
    const [alertsResponse, setAlertsResponse] = useState<AlertResponse[]>();
    const [controlGroupsByEffectiveness, setControlGroupsByEffectiveness] = useState<AssociatedControlGroup[]>();

    const getControlGroups = useCallback(() => {
        const getControlGroups = async (): Promise<void> => {
            try {
                const controlGroupsResponse = await props.controlsApi.getControlGroups(props.framework.control_framework);
                if (controlGroupsResponse) {
                    const controlGroupsSorted = controlGroupsResponse.data.sort(controlGroupComparator);
                    setControlGroupResponse(controlGroupsSorted);
                    addControlsToGraph(controlGroupsSorted);
                }
            } catch (err) {
                handleRequestError(err);
            }
        };
        getControlGroups();
    }, [props.controlsApi, props.framework.control_framework]);

    const getCustomControls = useCallback(() => {
        const getCustomControls = async (): Promise<void> => {
            try {
                const allCustomControlsResponse = await props.controlsApi.getAllCustomControls();
                if (allCustomControlsResponse) {
                    // localeCompare() uses "natural sorting" otherwise IDs including numeric values will be sorted like: 1, 10, 11, ..., 2, 20, 21, ...
                    // Loop through the Frameworks.
                    for (let frameworkIterator = 0; frameworkIterator < allCustomControlsResponse.data.length; frameworkIterator++) {
                        // Sort the Groups.
                        allCustomControlsResponse.data[frameworkIterator].control_groups = allCustomControlsResponse.data[frameworkIterator].control_groups.sort((groupA, groupB) => {
                            // Loop through the Groups.
                            for (let groupIterator = 0; groupIterator < allCustomControlsResponse.data[frameworkIterator].control_groups.length; groupIterator++) {
                                // Sort the Controls.
                                allCustomControlsResponse.data[frameworkIterator].control_groups[groupIterator].controls.sort((controlA, controlB) => {
                                    return controlA.control_name.localeCompare(controlB.control_name, undefined, { numeric: true, sensitivity: 'base' });
                                });
                            }
                            return groupA.control_group_name.localeCompare(groupB.control_group_name, undefined, { numeric: true, sensitivity: 'base' });
                        });
                    }
                    setAllCustomControls(allCustomControlsResponse.data);
                }
            } catch (err) {
                handleRequestError(err);
            }
        };

        getCustomControls();
    }, [props.controlsApi]);

    useEffect(() => {
        getControlGroups();
        getCustomControls();
    }, [getControlGroups, getCustomControls]);

    useEffect(() => {
        const getFrameworkAlerts = async (): Promise<void> => {
            try {
                const alertsRequest: AlertRequest = { entity_id: props.framework.control_framework };
                const alertsAPIResponse = await props.alertsLimitsApi.getAllAlerts(alertsRequest);
                setAlertsResponse(alertsAPIResponse.data);
            } catch (err) {
                handleRequestError(err);
            }
        };
        getFrameworkAlerts();
    }, [props.alertsLimitsApi, props.framework.control_framework]);

    const handleRequestError = (err: Error): void => {
        console.error(err.message);
    };

    const addControlsToGraph = (controlResponses: ControlGroupResponse[]) => {
        let inactiveControls = 0;
        let failControls = 0;
        let weakControls = 0;
        let moderateControls = 0;
        let strongControls = 0;
        let robustControls = 0;
        for (const controlResponse of controlResponses) {
            if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.INACTIVE) {
                inactiveControls++;
            } else if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.FAIL) {
                failControls++;
            } else if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.WEAK) {
                weakControls++;
            } else if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.MODERATE) {
                moderateControls++;
            } else if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.STRONG) {
                strongControls++;
            } else if (numberAsEffectiveness(controlResponse.control_group_effectiveness) === Effectiveness.ROBUST) {
                robustControls++;
            }
        }
        setControlBarGraphProps({ fail: failControls, inactive: inactiveControls, moderate: moderateControls, robust: robustControls, strong: strongControls, weak: weakControls });
    };

    const onControlGraphClick = (item: string) => {
        if (effectivenessLabelAsNumber(item) !== undefined) {
            const filteredControlGroups = controlGroupResponse
                ?.filter((controlGroup) => effectivenessAsString(numberAsEffectiveness(controlGroup.control_group_effectiveness)) === item)
                .map((controlGroup) => {
                    return {
                        controlGroupIdentifier: `${controlGroup.control_framework}#${controlGroup.control_group_id}`,
                        controlGroupName: controlGroup.is_custom ? `${controlGroup.control_framework}: ${controlGroup.control_group_name}` : `${controlGroup.control_framework}: ${controlGroup.control_group_id}`,
                        isCustom: controlGroup.is_custom,
                    };
                });
            if (filteredControlGroups !== undefined) {
                setControlGroupsByEffectiveness(filteredControlGroups);
                setDisplayedModal(Modals.DisplayControlGroupByEffectiveness);
            }
        }
    };

    /**
     * Called when a Custom Control Group is created (refreshes the state of Control Groups and Custom Controls on the page.)
     */
    const onControlGroupCreated = useCallback(() => {
        getControlGroups();
        getCustomControls();
    }, [getControlGroups, getCustomControls]);

    const associatedControlGroupsModalProps: AssociatedControlGroupsModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        associatedControlGroups: controlGroupsByEffectiveness!, // Required, so before rendering the modal verify that controlGroupsByEffectiveness is not undefined.
    };

    const createControlGroupModalProps: CreateControlGroupModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        controlFramework: props.framework.control_framework,
        controlsApi: props.controlsApi,
        onControlGroupCreated: onControlGroupCreated,
    };

    const overdueAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        relatedControlAssessmentDetails: props.framework.assessment_schedule.overdue.map((details) => {
            const controlId = getOperationalControlIdentifierString(details.control_framework, details.control_group_id, details.control_id);
            return {
                controlId: controlId,
                label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                date: details.assessment_due_date,
            };
        }),
        title: 'Overdue Assessments',
    };

    const comingDueAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        relatedControlAssessmentDetails: props.framework.assessment_schedule.coming_due.map((details) => {
            const controlId = getOperationalControlIdentifierString(details.control_framework, details.control_group_id, details.control_id);
            return {
                controlId: controlId,
                label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                date: details.assessment_due_date,
            };
        }),
        title: 'Assessments Due in the Next 14 Days',
    };

    const recentlyCompletedAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        relatedControlAssessmentDetails: props.framework.assessment_schedule.completed_recently.map((details) => {
            const controlId = getOperationalControlIdentifierString(details.control_framework, details.control_group_id, details.control_id);
            return {
                controlId: controlId,
                label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                date: details.timestamp,
            };
        }),
        title: 'Assessments Completed in the Last 14 Days',
    };

    const progressBarValues = ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.framework.control_framework_effectiveness));

    return (
        <>
            {displayedModal === Modals.DisplayControlGroupByEffectiveness && controlGroupsByEffectiveness && <AssociatedControlGroupsModal {...associatedControlGroupsModalProps} />}
            {displayedModal === Modals.CreateControlGroupModal && <CreateControlGroupModal {...createControlGroupModalProps} />}
            {displayedModal === Modals.OverdueAssessmentModal && <RelatedControlAssessmentsModal {...overdueAssessmentsModalProps} />}
            {displayedModal === Modals.ComingDueAssessmentModal && <RelatedControlAssessmentsModal {...comingDueAssessmentsModalProps} />}
            {displayedModal === Modals.RecentlyCompletedAssessmentModal && <RelatedControlAssessmentsModal {...recentlyCompletedAssessmentsModalProps} />}
            <PageBackground color="blueMountains">
                <PageContent>
                    <div className={styles.controlGroupHeader}>
                        <Text color="white" variant="Header1">
                            {props.framework.control_framework}
                        </Text>
                        <RBACComponent roles={[Role.ADMIN]}>
                            <div className={styles.headerButtons}>
                                <Button variant="primary" onClick={() => setDisplayedModal(Modals.CreateControlGroupModal)} fontAwesomeImage={ICON_ADD_CREATE}>
                                    Create Control Group
                                </Button>
                                <Link variant="primaryButton" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${props.framework.control_framework}/${CONFIGURATION}`} fontAwesomeImage={ICON_SETTINGS}>
                                    Settings
                                </Link>
                            </div>
                        </RBACComponent>
                    </div>
                    <div className={styles.graphs}>
                        <Dashlet
                            title="Framework Effectiveness"
                            content={
                                <>
                                    <Text noStyles color="darkGray" variant="Text2">
                                        {numberAsEffectivenessString(props.framework.control_framework_effectiveness)}
                                    </Text>
                                    <div className={styles.progressBar}>
                                        <ProgressBarIndicator variant={progressBarValues.variant} size="large" percent={progressBarValues.percent} />
                                        {props.framework.target_effectiveness && <Text color="darkGray" variant="Text3">{`Target Effectiveness: ${numberAsEffectivenessString(props.framework.target_effectiveness)}`}</Text>}
                                    </div>
                                    <Text color="white" variant="Header2">
                                        Control Group Breakdown
                                    </Text>
                                    <div className={styles.graphContainer}>
                                        <ControlBarGraph controlBarData={controlBarGraphProps} yAxisLabel="Number of Control Groups" onItemClick={onControlGraphClick} />
                                    </div>
                                </>
                            }
                        />
                        <div className={styles.alertsAssessment}>
                            <Dashlet
                                title="Assessment Schedule"
                                content={
                                    <div className={styles.assessmentSchedule}>
                                        <div onClick={() => setDisplayedModal(Modals.OverdueAssessmentModal)} className={styles.assessmentScheduleBox}>
                                            <div className={styles.assessmentTitle}>
                                                <Text noStyles color={props.framework.assessment_schedule.overdue.length === 0 ? 'darkGray' : 'yellow'}>
                                                    Currently Overdue
                                                </Text>
                                            </div>
                                            <div className={props.framework.assessment_schedule.overdue.length === 0 ? styles.assessmentNumber : styles.overdueNumber}>{props.framework.assessment_schedule.overdue.length}</div>
                                        </div>
                                        <div onClick={() => setDisplayedModal(Modals.ComingDueAssessmentModal)} className={styles.assessmentScheduleBox}>
                                            <div className={styles.assessmentTitle}>
                                                <Text noStyles color="darkGray">
                                                    Coming Due Soon
                                                </Text>
                                            </div>
                                            <div className={styles.assessmentNumber}>{props.framework.assessment_schedule.coming_due.length}</div>
                                        </div>
                                        <div onClick={() => setDisplayedModal(Modals.RecentlyCompletedAssessmentModal)} className={styles.assessmentScheduleBox}>
                                            <div className={styles.assessmentTitle}>
                                                <Text noStyles color="darkGray">
                                                    Recently Completed
                                                </Text>
                                            </div>
                                            <div className={styles.assessmentNumber}>{props.framework.assessment_schedule.completed_recently.length}</div>
                                        </div>
                                    </div>
                                }
                            />
                            {/* The number of active limits may not be 0. Hardcoding it to 0 like this is more of a matter of tech debt than an actual desire to not show the active limits count. The Framework page does not currently make a request to retrieve its limits. */}
                            <Dashlet title="Alerts (0 active limits)" content={<AlertDashlet alerts={alertsResponse} />} />
                        </div>
                    </div>
                </PageContent>
            </PageBackground>
            <PageBackground color="white">
                {controlGroupResponse !== undefined ? (
                    props.framework.is_custom && allCustomControls ? (
                        <PrimaryTabs defaultActiveTab="independentControls">
                            <Tab title="Independent Controls" eventKey="independentControls">
                                <GridAndTable
                                    cards={controlGroupResponse.map((controlGroupResponse: ControlGroupResponse) => (
                                        <ControlCard key={controlGroupResponse.control_group_id} effectiveness={controlGroupResponse.control_group_effectiveness} description={controlGroupResponse.control_group_description ? controlGroupResponse.control_group_description : ''} link={getFrameworkGroupControlURL(`${controlGroupResponse.control_framework}#${controlGroupResponse.control_group_id}`)} linkText="VIEW CONTROL GROUP" title={controlGroupResponse.is_custom === true ? controlGroupResponse.control_group_name : `${controlGroupResponse.control_group_id}. ${controlGroupResponse.control_group_name}`} />
                                    ))}
                                    table={<ControlGroupListView controlGroups={controlGroupResponse} />}
                                    noContentMessage={controlGroupResponse.length === 0 ? 'There are no enabled control groups.' : undefined}
                                />
                            </Tab>
                            <Tab title="Custom Control Report" eventKey="customControlReport">
                                <PageContent>
                                    <CustomControlReport AllCustomControlsControlFrameworkResponse={allCustomControls} />
                                </PageContent>
                            </Tab>
                        </PrimaryTabs>
                    ) : (
                        <GridAndTable
                            cards={controlGroupResponse.map((controlGroupResponse: ControlGroupResponse) => (
                                <ControlCard key={controlGroupResponse.control_group_id} effectiveness={controlGroupResponse.control_group_effectiveness} description={controlGroupResponse.control_group_description ? controlGroupResponse.control_group_description : ''} link={getFrameworkGroupControlURL(`${controlGroupResponse.control_framework}#${controlGroupResponse.control_group_id}`)} linkText="VIEW CONTROL GROUP" title={controlGroupResponse.is_custom === true ? controlGroupResponse.control_group_name : `${controlGroupResponse.control_group_id}. ${controlGroupResponse.control_group_name}`} />
                            ))}
                            table={<ControlGroupListView controlGroups={controlGroupResponse} />}
                            noContentMessage={controlGroupResponse.length === 0 ? 'There are no enabled control groups.' : undefined}
                        />
                    )
                ) : (
                    <Placeholder />
                )}
            </PageBackground>
        </>
    );
};
