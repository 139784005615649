import type { JSX } from 'react';

import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

import styles from './ControlMappingListItem.module.css';
import { ControlMappingItem } from '../MultipleControlMapping';

export interface ControlMappingListItemProps {
    controlMappingItem: ControlMappingItem;
    handleSelected: (value: ControlMappingItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    selected?: boolean;
}

export const ControlMappingListItem = (props: ControlMappingListItemProps): JSX.Element => {
    return (
        <div className={`${styles.text} ${props.selected && styles.selected}`} onClick={(e) => props.handleSelected(props.controlMappingItem, e)}>
            <Text variant="Text2" noStyles>
                {props.controlMappingItem.title}
            </Text>
            <div className={styles.description}>
                <Text variant="Text4" noStyles>
                    {props.controlMappingItem.description}
                </Text>
                <FormFieldTooltip text={props.controlMappingItem.description} />
            </div>
        </div>
    );
};
