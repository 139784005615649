import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { SaveControlFormFields, SaveControlFromFieldsProps, useSaveControl } from 'Components/OperationalControls/SaveControl/SaveControl';
import { ICON_CLOSE } from 'Config/Icons';

export interface CreateControlModalProps {
    hideModal: () => void;
    onControlCreated: () => void;
    controlsApi: ControlsApi;
    controlFramework: string;
    controlGroupId: string;
}

export const CreateControlModal = (props: CreateControlModalProps) => {
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const { saveControlStatus, saveControlFormFieldsValues, error, saveControl, handleChange, handleSelectChange } = useSaveControl(props.controlFramework, props.controlGroupId, props.controlsApi, {
        controlName: '',
        controlText: [],
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await saveControl();
    };

    const { onControlCreated } = props;

    useEffect(() => {
        switch (saveControlStatus) {
            case 'saved':
                setSuccessMessage('Control created.');
                onControlCreated();
                break;
            case 'error':
                setFailureMessage(error?.message);
                break;
            default:
                setSuccessMessage(undefined);
                setFailureMessage(undefined);
        }
    }, [error, onControlCreated, saveControlStatus]);

    const saveControlFormFieldsProps: SaveControlFromFieldsProps = {
        saveControlFormFieldsValues: saveControlFormFieldsValues,
        handleChange: handleChange,
        handleSelectChange: handleSelectChange,
        disabled: saveControlStatus === 'saved',
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && error && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Create Custom Control" />
                    <SaveControlFormFields {...saveControlFormFieldsProps} />
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={saveControlStatus === 'saved'} isLoading={saveControlStatus === 'saving'} loadingText="Creating...">
                            CREATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
