import { type JSX, ReactNode } from 'react';
import { Accordion } from 'react-bootstrap';

import styles from './AccordionItem.module.css';

export interface AccordionItemProps {
    eventKey: string;
    children: ReactNode;
}

export const AccordionItem = (props: AccordionItemProps): JSX.Element => {
    return (
        <Accordion.Item className={styles.item} eventKey={props.eventKey}>
            {props.children}
        </Accordion.Item>
    );
};
