import { Chip as MuiChip } from '@mui/material';
import type { JSX } from 'react';

export interface ChipProps {
    children: string;
}

export const Chip = (props: ChipProps): JSX.Element => {
    const blueColor = getComputedStyle(document.documentElement).getPropertyValue('--hps-dark-blue');
    const font = getComputedStyle(document.documentElement).getPropertyValue('--hps-primary-font');

    return <MuiChip size="small" label={props.children} sx={{ backgroundColor: blueColor, color: '#FFFFFF', fontFamily: font, fontWeight: 'bold' }} />;
};
