import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * This component renders nothing. It scrolls to the top of the page any time the path (not fragment or query parameters) of the browser URL changes.
 * This component MUST NOT be used anywhere but in `index.tsx`.
 * Reference: https://v5.reactrouter.com/web/guides/scroll-restoration
 *
 * Why does it scroll to the top? Because Chromium-based browsers remember the scroll position for the current web page. For Single-Page Applications like ours, that means that the default behavior is to maintain the scroll position as the user clicks through the app. We don't want that.
 * Why is this effect defined in this component? Because if `App` were to contain the effect, it would need to use `useLocation`, which would make `App` a stateful component, which would make the entire app re-render every time the location changed. Because some of our components programmatically change the location, we would end up with an infinite loop of re-renders on some pages.
 */
export const ScrollToTopWhenPathChanges = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
