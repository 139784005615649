import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMThirdPartyExportRow } from 'Models/Exports';
import { ThirdPartyContact } from 'Models/TPRM';

export interface ThirdPartiesExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ThirdPartiesExport = (props: ThirdPartiesExportProps): JSX.Element => {
    const [thirdParties, setThirdParties] = useState<TPRMThirdPartyExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getThirdPartiesExport = async (): Promise<void> => {
            try {
                const thirdPartyResponse = await props.exportsApi.getThirdPartiesExport();
                setThirdParties(thirdPartyResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getThirdPartiesExport();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMThirdPartyExportRow>[] = [
        { field: 'name', headerName: 'Third-Party Name', width: 300 },
        { field: 'website', headerName: 'Website', width: 300 },
        { field: 'ein', headerName: 'Employer ID number', width: 300 },
        { field: 'country', headerName: 'Country/Region', width: 300 },
        { field: 'address_line_1', headerName: 'Address line 1', width: 300 },
        { field: 'address_line_2', headerName: 'Address line 2', width: 300 },
        { field: 'city', headerName: 'City', width: 300 },
        { field: 'state', headerName: 'State/territory', width: 300 },
        { field: 'zip_code', headerName: 'Zip Code', width: 300 },
        {
            field: 'third_party_contacts',
            headerName: 'Third-Party Contacts',
            width: 300,
            valueGetter: (value) =>
                value
                    // Only show the name for each contact. Omit their email address and phone number.
                    .filter((thirdPartyContact: ThirdPartyContact) => thirdPartyContact.name !== undefined)
                    .sort((thirdPartyContactA: ThirdPartyContact, thirdPartyContactB: ThirdPartyContact) => (thirdPartyContactA?.name || '').localeCompare(thirdPartyContactB?.name || ''))
                    .map((thirdPartyContact: ThirdPartyContact) => thirdPartyContact.name)
                    .join(', '),
        },
        { field: 'third_party_manager_department', headerName: 'Third-Party Manager Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.third_party_manager_user_id, cachedData.users) },
        { field: 'third_party_manager_user_id', headerName: 'Third-Party Manager', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
    ];

    if (thirdParties) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Third Parties</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Third Parties Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(thirdParty: TPRMThirdPartyExportRow) => thirdParty.id} rows={thirdParties} title="Third Parties Export Preview" fileName="Third Parties Export" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
