import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { Text } from 'Components/Text/Text';
import { ICON_DELETE_REMOVE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { RiskRating, Service, getRiskRatingVariantColor, numberAsRiskRatingString, numberAsRiskScore } from 'Models/TPRM';

import styles from './ServiceCard.module.css';

export interface ServiceCardProps {
    thirdPartyService: Service;
    selectedDeleteService: (service: Service) => void;
    selectedModifyService: (service: Service) => void;
    selectedServiceAssessmentDueDateService: (service: Service) => void;
    selectedSetInherentRiskQuestionnaireTargetCompletionDate: (service: Service) => void;
}

export const ServiceCard = (props: ServiceCardProps) => {
    const cachedData = useCachedData();

    const overflowMenuProps: OverflowMenuProps = {
        overflowItems: [
            {
                text: 'Update Service',
                onClickAction: () => props.selectedModifyService(props.thirdPartyService),
                icon: ICON_EDIT_MODIFY_UPDATE,
            },
            {
                text: 'Set Assessment Due Date',
                onClickAction: () => props.selectedServiceAssessmentDueDateService(props.thirdPartyService),
                icon: faCalendar,
            },
            {
                text: 'Set Inherent Risk Questionnaire Target Completion Date',
                onClickAction: () => props.selectedSetInherentRiskQuestionnaireTargetCompletionDate(props.thirdPartyService),
                icon: faCalendar,
            },
            {
                text: 'Delete Service',
                onClickAction: () => props.selectedDeleteService(props.thirdPartyService),
                icon: ICON_DELETE_REMOVE,
            },
        ],
        accessibilityTitle: `${props.thirdPartyService.vendor_name} - ${props.thirdPartyService.name} Menu`,
    };

    return (
        <Card className={styles.thirdPartyCard}>
            <div className={styles.cardHeader}>
                <div>
                    <Text variant="Header2" noStyles>
                        {props.thirdPartyService.name}
                    </Text>

                    <Text variant="Text3" color="darkGray">
                        {props.thirdPartyService.vendor_name}
                    </Text>
                </div>
                <div className={styles.overflowMenu}>
                    <OverflowMenu {...overflowMenuProps} />
                </div>
            </div>
            <div className={styles.horizontalLine} />
            <div className={styles.cardSection}>
                <div className={styles.row}>
                    <div>
                        <Text variant="Text4" color="darkGray" toUppercase>
                            Inherent Risk
                        </Text>
                        <div className={styles.riskRatingContainer}>
                            <CircleIndicator variant={getRiskRatingVariantColor(numberAsRiskScore(props.thirdPartyService.inherent_risk_score ? props.thirdPartyService.inherent_risk_score : RiskRating.INACTIVE))} />
                            <div className={styles.riskText}>
                                <Text variant="Text3">{numberAsRiskRatingString(props.thirdPartyService.inherent_risk_score ? props.thirdPartyService.inherent_risk_score : RiskRating.INACTIVE)}</Text>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Text variant="Text4" color="darkGray" toUppercase>
                            Residual Risk
                        </Text>
                        <div className={styles.riskRatingContainer}>
                            <CircleIndicator variant={getRiskRatingVariantColor(numberAsRiskScore(props.thirdPartyService.residual_risk_score ? props.thirdPartyService.residual_risk_score : RiskRating.INACTIVE))} />
                            <div className={styles.riskText}>
                                <Text variant="Text3">{numberAsRiskRatingString(props.thirdPartyService.residual_risk_score ? props.thirdPartyService.residual_risk_score : RiskRating.INACTIVE)}</Text>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <Text variant="Text4" color="darkGray" toUppercase>
                            Service Manager
                        </Text>
                        <div>
                            <Text variant="Text3">{getUserNameFromSubject(props.thirdPartyService.vendor_service_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                        </div>
                    </div>
                    <div>
                        <Text variant="Text4" color="darkGray" toUppercase>
                            Date Created
                        </Text>
                        <div>
                            <Text variant="Text3">{iso8601ToUsDateShort(props.thirdPartyService.created_time)}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <Text variant="Text4" color="darkGray" toUppercase>
                            Assessment Due Date
                        </Text>
                        <div>
                            <Text variant="Text3">{props.thirdPartyService.assessment_workflow_setup.due_date ? iso8601ToUsDateShort(props.thirdPartyService.assessment_workflow_setup.due_date) : '-'}</Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.horizontalLine} />
            <div className={styles.footerBlock}>
                <Link size="lg" to={`/${TPRM}/${THIRD_PARTIES}/${props.thirdPartyService.vendor_id}/${SERVICES}/${props.thirdPartyService.id}/${DASHBOARDS}`}>
                    MANAGE SERVICE
                </Link>
            </div>
        </Card>
    );
};
