import { Fragment, type JSX } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { Table, TableBody, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ICON_DELETE_REMOVE, ICON_DOWNLOAD } from 'Config/Icons';
import { downloadDocument } from 'Helpers/FileUtils';
import { FileState, UploadedFile } from 'Models/Files';

import styles from './DocumentListing.module.css';

type Actions = 'upload' | 'download' | 'delete';

export const ALLOW_ALL_ACTIONS: Actions[] = ['upload', 'download', 'delete'];

export interface DocumentListingProps {
    dragAndDropInputId: string;
    dragAndDropLabelText: string;
    documentApi: DocumentApi;
    existingDocuments: UploadedFile[];
    newDocuments: File[];
    actions: Actions[];
    disableFileUpload?: boolean;
    hideRemoveNewDocumentButton?: boolean;
    disableFileOverflow?: boolean;
    onSelectNewDocuments: (documents: File[]) => void;
    onDeselectNewDocument: (document: File) => void;
    onSelectExistingDocumentToDelete: (existingDocument: UploadedFile) => void;
}

export const DocumentListing = ({ disableFileUpload = false, disableFileOverflow = false, hideRemoveNewDocumentButton = false, ...props }: DocumentListingProps): JSX.Element => {
    const shouldShowUpload = props.actions.includes('upload');
    const shouldShowDownload = props.actions.includes('download');
    const shouldShowDelete = props.actions.includes('delete');

    const sortedDocuments = [...props.existingDocuments].sort((a, b) => {
        return a.filename.localeCompare(b.filename);
    });

    const downloadFile = async (file: UploadedFile): Promise<void> => {
        try {
            downloadDocument(props.documentApi, file);
        } catch (error) {
            console.log(error);
        }
    };

    const tableRow = (document: UploadedFile): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [],
            accessibilityTitle: `${document.filename} Menu`,
            disabled: disableFileOverflow,
        };

        // For the user's security, files that have failed validation cannot be downloaded.
        if (shouldShowDownload && document.file_state === FileState.PASSED) {
            overflowMenuProps.overflowItems.push({
                text: `Download ${document.filename}`,
                onClickAction: () => downloadFile(document),
                icon: ICON_DOWNLOAD,
            });
        }

        if (shouldShowDelete) {
            overflowMenuProps.overflowItems.push({
                text: `Delete ${document.filename}`,
                onClickAction: () => props.onSelectExistingDocumentToDelete(document),
                icon: ICON_DELETE_REMOVE,
            });
        }

        return (
            <TableRow key={document.file_id}>
                <TableCellDefaultText>
                    <UploadedFileAndState unclickable documentApi={props.documentApi} file={document} />
                </TableCellDefaultText>
                {overflowMenuProps.overflowItems.length > 0 && (
                    <TableOverflowCell>
                        <div className={styles.overflowMenu}>
                            <OverflowMenu {...overflowMenuProps} />
                        </div>
                    </TableOverflowCell>
                )}
            </TableRow>
        );
    };

    const fileDragAndDropProps: FileDragAndDropProps = {
        inputId: props.dragAndDropInputId,
        labelText: props.dragAndDropLabelText,
        files: props.newDocuments,
        onAddFiles: (files) => props.onSelectNewDocuments(files),
        onRemoveFile: (file) => props.onDeselectNewDocument(file),
        disabled: disableFileUpload,
        hideRemoveNewDocumentButton: hideRemoveNewDocumentButton,
    };

    return (
        <Fragment>
            <div className={styles.removeBottomBorderFromLastTableRow}>
                {shouldShowUpload ? <FileDragAndDrop {...fileDragAndDropProps} /> : <VisualLabel>{props.dragAndDropLabelText}</VisualLabel>}

                {sortedDocuments.length > 0 && (
                    <Table>
                        <TableBody>{sortedDocuments.map(tableRow)}</TableBody>
                    </Table>
                )}
            </div>
        </Fragment>
    );
};
