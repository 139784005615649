import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { EvidenceExportRow } from 'Models/Exports';
import { effectivenessAsString } from 'Models/OperationalControls';

export interface EvidenceControlProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const EvidenceControl = (props: EvidenceControlProps): JSX.Element => {
    const [error, setError] = useState<string>();
    const [controlEvidence, setControlEvidence] = useState<EvidenceExportRow[]>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getEvidenceResponse = async () => {
            try {
                const evidenceResponse = await props.exportsApi.getEvidenceControlExport();
                setControlEvidence(evidenceResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };
        getEvidenceResponse();
    }, [props.exportsApi]);

    const columns: GridColumn<EvidenceExportRow>[] = [
        dateColumn('submission_date', 'Submission Date'),
        {
            field: 'control',
            headerName: 'Control',
            width: 300,
            valueGetter: (value) => getHumanReadableControlIdFromControl(value),
            type: 'string',
        },
        { field: 'submitter', headerName: 'Submitter', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'submitter_department', headerName: 'Submitter Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.submitter, cachedData.users) },
        { field: 'file_name', headerName: 'File Name', width: 300 },
        { field: 'external_url', headerName: 'External URL', width: 300 },
        { field: 'comment', headerName: 'Comment', width: 300 },
        { field: 'evidence_effectiveness', headerName: 'Evidence Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
    ];

    if (controlEvidence) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Control Evidence </BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Control Evidence Export"
                // The same evidence item may exist for multiple different controls, so getRowId must include the control as well.
                body={[{ content: <DataGrid columns={columns} getRowId={(controlEvidence: EvidenceExportRow) => `${controlEvidence.id} ${controlEvidence.control.identifier}`} rows={controlEvidence} title="Control Evidence Export Preview" fileName="Control Evidence" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
