import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './CircleIndicator.module.css';
import { IndicatorVariant } from './Indicator';

interface CircleIndicatorProps {
    variant: IndicatorVariant;
}

export const CircleIndicator: React.FC<CircleIndicatorProps> = ({ variant }: CircleIndicatorProps) => {
    const applyVariantColor = (): string => {
        switch (variant) {
            case IndicatorVariant.RED:
                return styles.red;
            case IndicatorVariant.ORANGE:
                return styles.orange;
            case IndicatorVariant.YELLOW:
                return styles.yellow;
            case IndicatorVariant.LIGHT_GREEN:
                return styles.lightgreen;
            case IndicatorVariant.DARK_GREEN:
                return styles.darkgreen;
            case IndicatorVariant.BLUE:
                return styles.blue;
            case IndicatorVariant.GRAY:
                return styles.gray;
            case IndicatorVariant.LIGHTBLUE:
                return styles.lightblue;
            default:
                return '';
        }
    };

    return <FontAwesomeIcon icon={faCircle} className={applyVariantColor()} />;
};
