import { Gauge, gaugeClasses } from '@mui/x-charts';
import type { JSX } from 'react';
export interface CircularProgressIndicatorProps {
    percent: number;
}

export const CircularProgressIndicator = (props: CircularProgressIndicatorProps): JSX.Element => {
    return (
        <Gauge
            value={props.percent}
            width={40}
            height={35}
            sx={(_theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 0,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                    fill: '#052637',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                    fill: '#8fa4af',
                },
            })}
        />
    );
};
