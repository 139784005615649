import { Checkbox } from '@mui/material';
import type { JSX } from 'react';

import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

import styles from './MultipleChoiceSelectionGroup.module.css';
import { FormLabel } from '../FormLabel/FormLabel';

export interface MultipleChoiceSelectionGroupProps {
    formFieldId: string;
    formFieldLabel?: string;
    handleChange: (answers: number[]) => void;
    tooltip?: string;
    options: string[];
    selectedOptions: number[];
    disabled?: boolean;
    columns?: 1 | 2 | 3;
}

export const MultipleChoiceSelectionGroup = ({ disabled = false, ...props }: MultipleChoiceSelectionGroupProps): JSX.Element => {
    const handleCheckItem = (newItem: number) => {
        const selectedList = [...props.selectedOptions];

        if (!props.selectedOptions.includes(newItem)) {
            selectedList.push(newItem);
        } else {
            const index = selectedList.indexOf(newItem);
            selectedList.splice(index, 1);
        }
        props.handleChange(selectedList);
    };

    let columnsClass: string;

    switch (props.columns) {
        case 1:
            columnsClass = styles.oneColumn;
            break;
        case 2:
            columnsClass = styles.twoColumns;
            break;
        case 3:
            columnsClass = styles.threeColumns;
            break;
        default:
            columnsClass = styles.oneColumn;
    }

    return (
        <div className={styles.container}>
            {props.formFieldLabel && <FormLabel tooltip={props.tooltip ? <FormFieldTooltip text={props.tooltip} /> : undefined}>{props.formFieldLabel}</FormLabel>}
            <div className={styles.checkboxesContainer}>
                {props.options.map((option, index) => (
                    <div className={`${styles.checkboxContainer} ${columnsClass}`} key={index}>
                        <div>
                            <Checkbox disabled={disabled} name={option} checked={props.selectedOptions.includes(index)} onChange={(event: React.FormEvent<HTMLInputElement>) => handleCheckItem(index)} color="default" sx={{ color: 'var(--hps-blue)' }} />
                        </div>
                        <Text noStyles>{option}</Text>
                    </div>
                ))}
            </div>
        </div>
    );
};
