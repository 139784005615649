import { type JSX, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ArtificialIntelligenceApi } from 'Api/ArtificialIntelligence/ArtificialIntelligenceApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { FileDragAndDropSingleMode } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { AI_GOVERNANCE_DESCRIPTION, AI_GOVERNANCE_INPUT_FILE, AI_GOVERNANCE_REGULATION, AI_GOVERNANCE_TITLE, AI_GOVERNANCE_TYPE } from 'Config/Tooltips';
import { submitRequestWithFile } from 'Helpers/FileUtils';
import { useFileDragAndDropSingleMode } from 'Hooks/FileDragAndDrop';
import { AiGovernanceCreateRequest } from 'Models/ArtificialIntelligence';
import { Regulation } from 'Models/ComplianceRequirements';
import { ValidationError } from 'Models/ErrorTypes';
import { FileToBeUploaded } from 'Models/Files';
import { GovernanceType, GovernanceValuesSelectOptions } from 'Models/Governance';

import styles from './ArtificialIntelligenceGovernanceForm.module.css';

export interface ArtificialIntelligenceGovernanceFormProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    documentApi: DocumentApi;
    regulations: Regulation[];
    setFailureMessage: (message?: string) => void;
    setSuccessMessage: (message?: string) => void;
}

export const ArtificialIntelligenceGovernanceForm = (props: ArtificialIntelligenceGovernanceFormProps): JSX.Element => {
    // Form fields.
    const [description, setDescription] = useState<string>('');
    const [file, onSelectFile, onRemoveFile] = useFileDragAndDropSingleMode();
    const [selectedRegulation, setSelectedRegulation] = useState<Regulation>();
    const [title, setTitle] = useState<string>('');
    const [type, setType] = useState<GovernanceType>(GovernanceType.POLICY);
    // Form state.
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    /** Handler for when the submit button is clicked. Includes handling file submission, if applicable. */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.setFailureMessage(undefined);
        props.setSuccessMessage(undefined);
        setIsSubmitting(true);

        try {
            if (file) {
                await submitRequestWithFile(props.documentApi, { file: file }, submitRequest);
            } else {
                await submitRequest(undefined);
            }
            props.setSuccessMessage(`A ${type} is being generated. Refresh the listing page in a few minutes to see the new document.`);
            setIsSubmitted(true);
        } catch (error) {
            props.setFailureMessage(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    /** Submit the form to the back-end API to create a new GenAI Governance. */
    const submitRequest = async (newDocumentation: FileToBeUploaded | undefined) => {
        const request: AiGovernanceCreateRequest = {
            description: description,
            input_file_id: newDocumentation?.file_id,
            input_filename: newDocumentation?.filename,
            regulation: selectedRegulation,
            title: title,
            type: type,
        };
        validateRequest(request);
        await props.artificialIntelligenceApi.aiGovernanceCreate(request);
    };

    /** Validate the request before submitting it.*/
    const validateRequest = (request: AiGovernanceCreateRequest): void => {
        if (request.title === undefined || request.title.trim().length === 0) {
            throw new ValidationError('Title is required.');
        }

        if (request.type === undefined || request.type.trim().length === 0) {
            throw new ValidationError('Type is required.');
        }

        if (request.description === undefined || request.description.trim().length === 0) {
            throw new ValidationError('Description is required.');
        }
    };

    const disableForm = isSubmitting || isSubmitted;

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <FormFieldText formFieldId="title" formFieldLabel="Title" required tooltip={AI_GOVERNANCE_TITLE} handleChange={(event: React.FormEvent<HTMLInputElement>) => setTitle(event.currentTarget.value)} value={title} disabled={disableForm} />
            <FormFieldSelect formFieldId="type" formFieldLabel="Type" required tooltip={AI_GOVERNANCE_TYPE} handleChange={(value: ChangeEventType) => setType(value as GovernanceType)} options={GovernanceValuesSelectOptions} selectedOption={type} disabled={disableForm} />
            <FormFieldTextArea formFieldId="description" formFieldLabel="Description" required tooltip={AI_GOVERNANCE_DESCRIPTION} handleChange={(event: React.FormEvent<HTMLInputElement>) => setDescription(event.currentTarget.value)} value={description} disabled={disableForm} />
            <FileDragAndDropSingleMode inputId="inputFile" labelText="Reference Document" tooltip={<FormFieldTooltip text={AI_GOVERNANCE_INPUT_FILE} />} onSelectFile={onSelectFile} onRemoveFile={onRemoveFile} file={file} disabled={disableForm} />
            <FormFieldSelect formFieldId="regulation" formFieldLabel="Regulation" tooltip={AI_GOVERNANCE_REGULATION} handleChange={(value: ChangeEventType) => setSelectedRegulation(props.regulations.find((regulation) => regulation.name === value) as Regulation)} options={props.regulations.map((regulation) => ({ label: regulation.name, value: regulation.name }))} selectedOption={selectedRegulation?.name} disabled={disableForm} />
            <div className={styles.disclaimer}>
                <Text variant="Header3">Disclaimer</Text>
            </div>
            <Text>Suggestions are created using generative AI. The information, suggestions, or content provided by this generative AI model is for informational purposes only. The content generated may not reflect the most current legal, technical, or professional standards.</Text>
            <Text>No Professional Advice: This tool does not constitute legal, medical, financial, or professional advice, and should not be relied upon as such. For legal, medical, financial, or other specialized advice, users should consult a qualified professional in the relevant field.</Text>
            <Text>No Warranties: The information provided is offered "as is," without any warranties of any kind, either express or implied. The creators, operators, and developers of this AI model make no representations or warranties about the accuracy, completeness, reliability, or suitability of the content for any purpose.</Text>
            <Text>Limitation of Liability: Under no circumstances will the creators, operators, or developers of this AI model be liable for any damages, losses, or claims arising from the use of the content generated by this AI, including but not limited to direct, indirect, incidental, punitive, or consequential damages.</Text>
            <Text>User Responsibility: Users are responsible for verifying any information or content generated by this AI model and should use their own judgment before acting on any information provided.</Text>
            <div className={styles.submitButton}>
                <Button variant="submit" isLoading={isSubmitting} loadingText="Submitting..." disabled={disableForm}>
                    Submit
                </Button>
            </div>
        </Form>
    );
};
