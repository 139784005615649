import type { JSX } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Text } from 'Components/Text/Text';
import { EffectivenessLimit, Limit } from 'Models/Limits';

import styles from './EffectivenessLimitsTable.module.css';
import { EffectivenessLimitsTableRow, EffectivenessLimitsTableRowProps } from './EffectivenessLimitsTableRow/EffectivenessLimitsTableRow';

export interface EffectivenessLimitsTableProps {
    limitResponseList: EffectivenessLimit[];
    displayDeleteLimitModal: (deleteLimitId: string, deleteLimitTitle: string) => void;
    displayModifyLimitModal: (modifyLimit: Limit) => void;
    alertsLimitsApi: AlertsLimitsApi;
    addLimitButton?: JSX.Element;
}

export const EffectivenessLimitsTable = (props: EffectivenessLimitsTableProps) => {
    const tableRow = (limit: EffectivenessLimit): JSX.Element => {
        const limitsTableRowProps: EffectivenessLimitsTableRowProps = {
            limit: limit,
            displayDeleteLimitModal: props.displayDeleteLimitModal,
            displayModifyLimitModal: props.displayModifyLimitModal,
            alertsLimitsApi: props.alertsLimitsApi,
        };
        return <EffectivenessLimitsTableRow key={limit.id} {...limitsTableRowProps} />;
    };
    return (
        <div className={styles.tableContainer}>
            <div className={styles.pageSectionHeader}>
                <Text variant="Header2">Effectiveness</Text>
                {props.addLimitButton}
            </div>
            <div className={styles.headerContainer}>
                <div className={styles.toggleHeader}></div>
                <div className={styles.titleContainer}>
                    <Text>TITLE</Text>
                </div>
                <div className={styles.limitContainer}>
                    <Text>LIMIT</Text>
                </div>
                <div className={styles.overflowHeader}></div>
            </div>
            {props.limitResponseList.map(tableRow)}
        </div>
    );
};
