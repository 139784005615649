import type { JSX } from 'react';

import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';

import { Group, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';

export interface DeleteControlGroupModalProps extends QuestionnaireConfigurationModal {
    group: Group;
}

export const DeleteControlGroupModal = (props: DeleteControlGroupModalProps): JSX.Element => {
    const deleteGroup = async (): Promise<string> => {
        await props.tprmApi.deleteControlGroupConfiguration(props.group.framework, props.group.groupId);
        props.onModalActionComplete();
        return 'Control group deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Group Configuration" areYouSureText="Are you sure you want to delete the following group?" performOperation={deleteGroup} hideModal={props.hideModal}>
            <Text>{props.group.groupName}</Text>
        </ConfirmationModal>
    );
};
