import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { type JSX, RefObject, useEffect, useRef } from 'react';

import { CSSColors } from 'Components/Colors';

import { ChartAxis, ChartSeries, axisColor, chartColor, chartFont } from './charts.common';

export enum LineAndScatterChartType {
    LINE = 'line',
    SCATTER = 'scatter',
}

export interface LineAndScatterChartProps {
    onChartClick: (item: string) => void;
    xAxisLabels: string[];
    /**
     * If provided, these labels will be used within the hover/tooltip, rather than the labels in `xAxisLabels`.
     * For instance, if you want to show short dates on the x-axis and long dates in the tooltip, set this prop to the long dates.
     */
    xAxisLabelsForTooltips?: string[];
    yAxisInfo: ChartAxis[];
    series: ChartSeries[];
    type: LineAndScatterChartType;
    variant?: 'dark' | 'light';
    paddingVariant?: 'sm' | 'lg';
}

export const LineAndScatterChart = (props: LineAndScatterChartProps): JSX.Element => {
    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);
    const labelColor = props.variant === 'light' ? CSSColors.BLUE : getComputedStyle(document.documentElement).getPropertyValue(chartColor);

    const chartRef = useRef<ReactECharts>(null) as RefObject<ReactECharts>;

    // This ensures that the chart does not re-render when a new onclick is created in the prop
    useEffect(() => {
        const instance = chartRef.current.getEchartsInstance();
        instance.on('click', (params) => {
            props.onChartClick(`${params.name} | ${params.seriesName}`);
        });
    }, [props]);

    const getOption = (info: Omit<LineAndScatterChartProps, 'onChartClick'>, primaryFont: string, labelColor: string): EChartsOption => {
        // Add a non functional axis to complete the grid
        if (info.yAxisInfo.length < 2) {
            info.yAxisInfo.push({ name: '', position: 'right' });
        }

        return {
            aria: {
                show: true,
            },
            tooltip: {
                trigger: 'item',
            },
            legend: { show: props.series.length > 1, type: 'scroll' },
            textStyle: {
                fontFamily: primaryFont,
            },
            grid: {
                containLabel: true,
                left: props.paddingVariant === 'sm' ? '3%' : '10%',
                right: props.paddingVariant === 'sm' ? '3%' : '10%',
                top: '10%',
                bottom: '10%',
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: info.xAxisLabelsForTooltips ?? info.xAxisLabels,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: axisColor,
                        },
                    },
                    axisLabel: {
                        color: labelColor,
                        formatter: info.xAxisLabelsForTooltips ? (_value: string, index: number) => info.xAxisLabels[index] : undefined,
                    },
                },
            ],
            yAxis: info.yAxisInfo.map((yAxis) => ({
                type: 'value',
                name: yAxis.name,
                position: yAxis.position,
                min: yAxis.min,
                max: yAxis.max,
                minInterval: yAxis.minInterval,
                maxInterval: yAxis.maxInterval,
                interval: yAxis.interval,
                nameRotate: yAxis.position === 'left' ? 90 : 270,
                alignTicks: true,
                show: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: axisColor,
                    },
                },
                axisLabel: {
                    formatter: yAxis.labelFormatter,
                    color: labelColor,
                },
                nameLocation: 'middle',
                nameGap: 50,
                splitLine: {
                    lineStyle: {
                        color: axisColor,
                    },
                },
                nameTextStyle: {
                    color: labelColor,
                },
            })),
            series: info.series.map((line) => ({
                name: line.name,
                type: info.type,
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: line.color,
                },
                data: line.data,
                symbol: 'circle',
                symbolSize: 10,
            })),
        };
    };

    return (
        <ReactECharts
            option={getOption(props, primaryFont, labelColor)}
            style={{
                height: '100%',
                width: '100%',
                minHeight: '300px',
                minWidth: '400px',
                maxHeight: '300px',
            }}
            ref={chartRef}
        />
    );
};
