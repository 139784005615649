import styles from './PageContent.module.css';

export interface PageContentProps {
    children?: React.ReactNode;
    removePadding?: boolean;
}

/** DEPRECATED. Do not implement this on anything new. Use the PageLayout/ components. */
export const PageContent: React.FC<PageContentProps> = ({ children, removePadding }: PageContentProps) => {
    return <div className={`${styles.pageContent} ${removePadding === true && styles.removePadding}`}>{children}</div>;
};
