import { Gauge, gaugeClasses } from '@mui/x-charts';
import { FunctionComponent, type JSX } from 'react';

export interface HorseshoeProgressIndicatorProps {
    /**
     * Fill percentage 0-100
     */
    fill: number;
    text?: string;
}

export const HorseshoeProgressIndicator: FunctionComponent<HorseshoeProgressIndicatorProps> = (props): JSX.Element => {
    // Ocacsionally the number comming is is 0/0 whcih becomes NaN
    const fillValue = isNaN(props.fill) ? 0 : props.fill;

    return (
        <Gauge
            value={fillValue}
            startAngle={-120}
            endAngle={120}
            text={props.text ?? ''}
            aria-label={props.text}
            sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                    fontSize: '1.188rem',
                    wordWrap: 'break-word',
                    fontWeight: 600,
                    letterSpacing: '0.043em',
                    width: '50%',
                    '& tspan:nth-last-of-type(-n +2)': {
                        fontSize: '0.813rem',
                    },
                },
                [`& .${gaugeClasses.valueText} text`]: {
                    fill: '#FFF', // <-- change text color
                },
                [`& .${gaugeClasses.valueArc}`]: {
                    fill: '#8fa4af',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                    fill: theme.palette.text.disabled,
                },
            })}
        />
    );
};
