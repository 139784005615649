import { faArrowsV } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { AddOrUpdateTextModal, AddOrUpdateTextModalProps } from 'Components/Modal/AddOrUpdateTextModal/AddOrUpdateTextModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ReorderModal, ReorderModalProps } from 'Components/Modal/ReorderModal/ReorderModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ICON_ADD_CREATE, ICON_CLOSE, ICON_DELETE_REMOVE, ICON_EDIT_MODIFY_UPDATE, ICON_SAVE } from 'Config/Icons';
import { CONFIGURATION } from 'Config/Paths';
import { InherentRiskQuestionnaireAnswerConfiguration, InherentRiskQuestionnaireQuestionConfiguration, InherentRiskQuestionnaireSectionConfiguration, RiskRatingPointThresholds } from 'Models/TPRM';

import styles from './InherentRiskQuestionnaireConfiguration.module.css';
import { InherentRiskQuestionnaireConfigurationThresholds, InherentRiskQuestionnaireConfigurationThresholdsProps } from './InherentRiskQuestionnaireConfigurationThresholds/InherentRiskQuestionnaireConfigurationThresholds';
import { DueDiligenceQuestionnaireConfigurationRow } from '../DueDiligenceQuestionnaireConfiguration/DueDiligenceQuestionnaireConfigurationRow/DueDiligenceQuestionnaireConfigurationRow';

type ActiveModal =
    | {
          type: 'addSection';
      }
    | {
          type: 'addQuestion';
          section: InherentRiskQuestionnaireSectionConfiguration;
      }
    | {
          type: 'addOption';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
      }
    | {
          type: 'reorderSections';
      }
    | {
          type: 'reorderQuestions';
          section: InherentRiskQuestionnaireSectionConfiguration;
      }
    | {
          type: 'reorderOptions';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
      }
    | {
          type: 'renameSection';
          section: InherentRiskQuestionnaireSectionConfiguration;
      }
    | {
          type: 'renameQuestion';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
      }
    | {
          type: 'renameOption';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
          answer: InherentRiskQuestionnaireAnswerConfiguration;
      }
    | {
          type: 'deleteSection';
          section: InherentRiskQuestionnaireSectionConfiguration;
      }
    | {
          type: 'deleteQuestion';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
      }
    | {
          type: 'deleteOption';
          section: InherentRiskQuestionnaireSectionConfiguration;
          question: InherentRiskQuestionnaireQuestionConfiguration;
          answer: InherentRiskQuestionnaireAnswerConfiguration;
      };

export interface InherentRiskQuestionnaireConfigurationProps {
    tprmApi: TPRMApi;
}

export const InherentRiskQuestionnaireConfiguration = (props: InherentRiskQuestionnaireConfigurationProps) => {
    const [activeModal, setActiveModal] = useState<ActiveModal>();
    const [toastMessage, setToastMessage] = useState<{ message: string; type: 'success' | 'failure' }>();
    const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

    const [thresholds, setThresholds] = useState<RiskRatingPointThresholds>();
    const [sections, setSections] = useState<InherentRiskQuestionnaireSectionConfiguration[]>();
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);

    const [minimumPossibleScore, maximumPossibleScore] = useMemo(() => {
        if (!sections) {
            return [0, 0];
        }

        let minimum = 0;
        let maximum = 0;

        sections.forEach((section) => {
            section.questions.forEach((question) => {
                if (question.answers.length !== 0) {
                    const points = question.answers.map((answer) => answer.points);
                    const questionMinimum = Math.min(...points);
                    const questionMaximum = Math.max(...points);

                    minimum += questionMinimum;
                    maximum += questionMaximum;
                }
            });
        });

        return [minimum, maximum];
    }, [sections]);

    useEffect(() => {
        const fetchConfiguration = async () => {
            try {
                const configuration = (await props.tprmApi.getInherentRiskQuestionnaireConfiguration()).data;
                setThresholds(configuration.thresholds);
                setSections(configuration.sections);
            } catch (error) {
                console.log(error);
                setLoadingErrorOccurred(true);
            }
        };
        fetchConfiguration();
    }, [props.tprmApi]);

    // This MUST be a useCallback. The InherentRiskQuestionnaireConfigurationThresholds component includes this function as a useEffect dependency; if this function isn't memoized, an infinite loop will occur.
    const handleThresholdsChange = useCallback((newThresholds: number[]) => {
        setThresholds({
            low: newThresholds[0],
            low_moderate: newThresholds[1],
            moderate: newThresholds[2],
            moderate_high: newThresholds[3],
        });
    }, []);

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (!(thresholds && sections)) {
        return <Placeholder />;
    }

    const hideModal = () => setActiveModal(undefined);

    const addSectionProps: AddOrUpdateTextModalProps = {
        modalTitle: 'Add Section',
        fieldName: 'Section name',
        onConfirm: (sectionName: string) => {
            if (sections.some((s) => s.name === sectionName)) {
                return 'There is already a section with this name.';
            } else {
                setSections([...sections, { name: sectionName, questions: [] }]);
            }
        },
        onClose: hideModal,
    };

    const getAddQuestionProps = (section: InherentRiskQuestionnaireSectionConfiguration): AddOrUpdateTextModalProps => {
        return {
            modalTitle: 'Add Question',
            fieldName: 'Question text',
            onConfirm: (questionText: string) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: [...s.questions, { text: questionText, answers: [] }] } : s))]),
            onClose: hideModal,
        };
    };

    const getAddOptionProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration): AddOrUpdateTextModalProps => {
        return {
            modalTitle: 'Add Option',
            fieldName: 'Option text',
            onConfirm: (answerText: string) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, answers: [...q.answers, { text: answerText, points: 0 }] } : q)) } : s))]),
            onClose: hideModal,
        };
    };

    const reorderSectionsProps: ReorderModalProps<InherentRiskQuestionnaireSectionConfiguration> = {
        title: 'Reorder Sections',
        items: sections,
        getItemId: (section) => section.name,
        getItemDescription: (section) => section.name,
        onOrderSelected: (sections) => setSections([...sections]),
        onClose: hideModal,
    };

    const getReorderQuestionsProps = (section: InherentRiskQuestionnaireSectionConfiguration): ReorderModalProps<InherentRiskQuestionnaireQuestionConfiguration> => {
        return {
            title: 'Reorder Questions',
            items: section.questions,
            getItemId: (question) => question.text,
            getItemDescription: (question) => question.text,
            onOrderSelected: (questions) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions } : s))]),
            onClose: hideModal,
        };
    };

    const getReorderOptionsProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration): ReorderModalProps<InherentRiskQuestionnaireAnswerConfiguration> => {
        return {
            title: 'Reorder Options',
            items: question.answers,
            getItemId: (answer) => answer.text,
            getItemDescription: (answer) => answer.text,
            onOrderSelected: (answers) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, answers } : q)) } : s))]),
            onClose: hideModal,
        };
    };

    const getRenameSectionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration): AddOrUpdateTextModalProps => {
        return {
            modalTitle: 'Rename Section',
            fieldName: 'Section name',
            initialValue: section.name,
            onConfirm: (newName: string) => {
                if (sections.some((s) => s.name === newName)) {
                    return 'There is already a section with this name.';
                } else {
                    setSections(sections.map((s) => (s.name === section.name ? { ...s, name: newName } : s)));
                }
            },
            onClose: hideModal,
        };
    };

    const getRenameQuestionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration): AddOrUpdateTextModalProps => {
        return {
            modalTitle: 'Rename Question',
            fieldName: 'Question text',
            initialValue: question.text,
            onConfirm: (newText: string) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, text: newText } : q)) } : s))]),
            onClose: hideModal,
        };
    };

    const getRenameOptionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration, answer: InherentRiskQuestionnaireAnswerConfiguration): AddOrUpdateTextModalProps => {
        return {
            modalTitle: 'Rename Option',
            fieldName: 'Option text',
            initialValue: answer.text,
            onConfirm: (newText: string) => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, answers: q.answers.map((o) => (o.text === answer.text ? { ...o, text: newText } : o)) } : q)) } : s))]),
            onClose: hideModal,
        };
    };

    const getDeleteSectionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration): ConfirmDeleteModalProps => {
        return {
            headerText: 'Delete Section',
            confirmationText: `Are you sure you want to delete the section "${section.name}"?`,
            onConfirm: () => setSections(sections.filter((s) => s.name !== section.name)),
            hideModal: hideModal,
        };
    };

    const getDeleteQuestionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration): ConfirmDeleteModalProps => {
        return {
            headerText: 'Delete Question',
            confirmationText: `Are you sure you want to delete the question "${question.text}"?`,
            onConfirm: () => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.filter((q) => q.text !== question.text) } : s))]),
            hideModal: hideModal,
        };
    };

    const getDeleteOptionModalProps = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration, answer: InherentRiskQuestionnaireAnswerConfiguration): ConfirmDeleteModalProps => {
        return {
            headerText: 'Delete Option',
            confirmationText: `Are you sure you want to delete the answer "${answer.text}"?`,
            onConfirm: () => setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, answers: q.answers.filter((o) => o !== answer) } : q)) } : s))]),
            hideModal: hideModal,
        };
    };

    const getOverflowMenuPropsForSection = (section: InherentRiskQuestionnaireSectionConfiguration): OverflowMenuProps => {
        return {
            accessibilityTitle: `Menu for section "${section.name}"`,
            overflowItems: [
                { text: 'Add Question', icon: ICON_ADD_CREATE, onClickAction: () => setActiveModal({ type: 'addQuestion', section }) },
                { text: 'Reorder Questions', icon: faArrowsV, onClickAction: () => setActiveModal({ type: 'reorderQuestions', section }) },
                { text: 'Rename', icon: ICON_EDIT_MODIFY_UPDATE, onClickAction: () => setActiveModal({ type: 'renameSection', section }) },
                { text: 'Delete', icon: ICON_DELETE_REMOVE, onClickAction: () => setActiveModal({ type: 'deleteSection', section }) },
            ],
        };
    };

    const getOverflowMenuPropsForQuestion = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration): OverflowMenuProps => {
        return {
            accessibilityTitle: `Menu for question "${question.text}" in section "${section.name}"`,
            overflowItems: [
                { text: 'Add Option', icon: ICON_ADD_CREATE, onClickAction: () => setActiveModal({ type: 'addOption', section, question }) },
                { text: 'Reorder Options', icon: faArrowsV, onClickAction: () => setActiveModal({ type: 'reorderOptions', section, question }) },
                { text: 'Rename', icon: ICON_EDIT_MODIFY_UPDATE, onClickAction: () => setActiveModal({ type: 'renameQuestion', section, question }) },
                { text: 'Delete', icon: ICON_DELETE_REMOVE, onClickAction: () => setActiveModal({ type: 'deleteQuestion', section, question }) },
            ],
        };
    };

    const getOverflowMenuPropsForOption = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration, answer: InherentRiskQuestionnaireAnswerConfiguration): OverflowMenuProps => {
        return {
            accessibilityTitle: `Menu for answer "${answer.text}" for question "${question.text}" in section "${section.name}"`,
            overflowItems: [
                { text: 'Rename', icon: ICON_EDIT_MODIFY_UPDATE, onClickAction: () => setActiveModal({ type: 'renameOption', section, question, answer }) },
                { text: 'Delete', icon: ICON_DELETE_REMOVE, onClickAction: () => setActiveModal({ type: 'deleteOption', section, question, answer }) },
            ],
        };
    };

    const setPoints = (section: InherentRiskQuestionnaireSectionConfiguration, question: InherentRiskQuestionnaireQuestionConfiguration, answer: InherentRiskQuestionnaireAnswerConfiguration, points: number) => {
        setSections([...sections.map((s) => (s.name === section.name ? { ...s, questions: s.questions.map((q) => (q.text === question.text ? { ...q, answers: q.answers.map((o) => (o.text === answer.text ? { ...o, points } : o)) } : q)) } : s))]);
    };

    const validate = () => {
        if (sections.length === 0) {
            throw new Error('There must be at least one section.');
        }

        if (sections.some((section) => section.questions.length === 0)) {
            throw new Error('Each section must contain at least one question.');
        }

        if (sections.some((section) => section.questions.some((question) => question.answers.length === 0))) {
            throw new Error('Each question must contain at least one answer.');
        }
    };

    const saveConfiguration = async () => {
        try {
            validate();
            setToastMessage(undefined);
            setIsSubmittingRequest(true);
            const configuration = { thresholds, sections };
            await props.tprmApi.setInherentRiskQuestionnaireConfiguration(configuration);
            setToastMessage({ message: 'Configuration saved.', type: 'success' });
        } catch (error) {
            setToastMessage({ message: error.message, type: 'failure' });
        } finally {
            setIsSubmittingRequest(false);
        }
    };

    const inherentRiskQuestionnaireConfigurationThresholdsProps: InherentRiskQuestionnaireConfigurationThresholdsProps = {
        defaultValues: [thresholds.low, thresholds.low_moderate, thresholds.moderate, thresholds.moderate_high],
        minimum: minimumPossibleScore,
        maximum: maximumPossibleScore,
        onNewValidValues: handleThresholdsChange,
    };

    return (
        <RBACComponent roles={[Role.ADMIN]}>
            {toastMessage && <TextToast variant={toastMessage.type} clearToast={() => setToastMessage(undefined)} text={toastMessage.message} />}

            {activeModal?.type === 'addSection' && <AddOrUpdateTextModal {...addSectionProps} />}
            {activeModal?.type === 'addQuestion' && <AddOrUpdateTextModal {...getAddQuestionProps(activeModal.section)} />}
            {activeModal?.type === 'addOption' && <AddOrUpdateTextModal {...getAddOptionProps(activeModal.section, activeModal.question)} />}
            {activeModal?.type === 'reorderSections' && <ReorderModal {...reorderSectionsProps} />}
            {activeModal?.type === 'reorderQuestions' && <ReorderModal {...getReorderQuestionsProps(activeModal.section)} />}
            {activeModal?.type === 'reorderOptions' && <ReorderModal {...getReorderOptionsProps(activeModal.section, activeModal.question)} />}
            {activeModal?.type === 'renameSection' && <AddOrUpdateTextModal {...getRenameSectionModalProps(activeModal.section)} />}
            {activeModal?.type === 'renameQuestion' && <AddOrUpdateTextModal {...getRenameQuestionModalProps(activeModal.section, activeModal.question)} />}
            {activeModal?.type === 'renameOption' && <AddOrUpdateTextModal {...getRenameOptionModalProps(activeModal.section, activeModal.question, activeModal.answer)} />}
            {activeModal?.type === 'deleteSection' && <ConfirmDeleteModal {...getDeleteSectionModalProps(activeModal.section)} />}
            {activeModal?.type === 'deleteQuestion' && <ConfirmDeleteModal {...getDeleteQuestionModalProps(activeModal.section, activeModal.question)} />}
            {activeModal?.type === 'deleteOption' && <ConfirmDeleteModal {...getDeleteOptionModalProps(activeModal.section, activeModal.question, activeModal.answer)} />}

            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${CONFIGURATION}`}>Settings</BreadcrumbLink>
                        <BreadcrumbText>Inherent Risk Questionnaire Configuration</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Inherent Risk Questionnaire Configuration"
                headerButtons={
                    <>
                        <Button variant="secondary" onClick={() => setActiveModal({ type: 'reorderSections' })} fontAwesomeImage={faArrowsV}>
                            Reorder Sections
                        </Button>
                        <Button variant="secondary" onClick={() => setActiveModal({ type: 'addSection' })} fontAwesomeImage={ICON_ADD_CREATE}>
                            Add Section
                        </Button>
                    </>
                }
                // TODO: Reword this? Or, if keeping this, DRY it up (this text is also used on the Settings page).
                headerDescription="Configure the questions and scoring used to calculate inherent risk whenever a third-party service is assessed."
                body={[
                    {
                        content: (
                            <Form>
                                <InherentRiskQuestionnaireConfigurationThresholds {...inherentRiskQuestionnaireConfigurationThresholdsProps} />
                                <div className={styles.accordionHeaderContainer}>
                                    <div className={styles.headerSectionsQuestionsAndAnswers}>
                                        <Text variant="Text3" noStyles>
                                            SECTIONS, QUESTIONS, AND ANSWERS
                                        </Text>
                                    </div>
                                    <div className={styles.headerPoints}>
                                        <Text noStyles>POINTS</Text>
                                    </div>
                                </div>
                                {sections.map((section) => (
                                    <Accordion key={section.name}>
                                        <DueDiligenceQuestionnaireConfigurationRow level={0} eventKey={section.name} accessibilityTitle={section.name}>
                                            <div className={styles.accordionRow}>
                                                <div>
                                                    <Text noStyles>{section.name}</Text>
                                                </div>
                                                <OverflowMenu {...getOverflowMenuPropsForSection(section)} />
                                            </div>
                                            <hr className={styles.hrModifier} />
                                        </DueDiligenceQuestionnaireConfigurationRow>
                                        <AccordionCollapse eventKey={section.name} accordionFor={section.name}>
                                            <div data-testid={`sectionContent-${section.name}`}>
                                                {section.questions.map((question) => (
                                                    <Accordion key={question.text}>
                                                        <DueDiligenceQuestionnaireConfigurationRow level={1} eventKey={question.text} accessibilityTitle={question.text}>
                                                            <div className={styles.accordionRow}>
                                                                <div>
                                                                    <Text noStyles>{question.text}</Text>
                                                                </div>
                                                                <OverflowMenu {...getOverflowMenuPropsForQuestion(section, question)} />
                                                            </div>
                                                            <hr className={styles.hrModifier} />
                                                        </DueDiligenceQuestionnaireConfigurationRow>
                                                        <AccordionCollapse eventKey={question.text} accordionFor={question.text}>
                                                            <div data-testid={`questionContent-${section.name}-${question.text}`}>
                                                                {question.answers.map((answer) => (
                                                                    <Accordion key={answer.text}>
                                                                        <DueDiligenceQuestionnaireConfigurationRow level={2}>
                                                                            <div className={styles.accordionRow}>
                                                                                <div>
                                                                                    <label htmlFor={`points-${section.name}-${question.text}-${answer.text}`}>
                                                                                        <Text noStyles>{answer.text}</Text>
                                                                                    </label>
                                                                                </div>
                                                                                <div className={styles.menuAndPointsContainer}>
                                                                                    <div className={styles.pointsContainer}>
                                                                                        <FormFieldText formFieldId={`points-${section.name}-${question.text}-${answer.text}`} formFieldType="number" value={answer.points} handleChange={(event: React.FormEvent<HTMLInputElement>) => setPoints(section, question, answer, Number(event.currentTarget.value))} step={1} />
                                                                                    </div>
                                                                                    <OverflowMenu {...getOverflowMenuPropsForOption(section, question, answer)} />
                                                                                </div>
                                                                            </div>
                                                                        </DueDiligenceQuestionnaireConfigurationRow>
                                                                    </Accordion>
                                                                ))}
                                                            </div>
                                                        </AccordionCollapse>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </AccordionCollapse>
                                    </Accordion>
                                ))}
                                <div className={styles.saveButton}>
                                    <Button variant="primary" onClick={saveConfiguration} fontAwesomeImage={ICON_SAVE} loadingText="Saving..." isLoading={isSubmittingRequest}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        ),
                    },
                ]}
            />
        </RBACComponent>
    );
};

interface ConfirmDeleteModalProps {
    headerText: string;
    confirmationText: string;
    onConfirm: () => void;
    hideModal: () => void;
}

// TODO [TPRM - IRQ]: If possible, use `ConfirmationModal`. Maybe update it so that it can take a Promise-based callback OR a non-Promise-based callback?
const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <Form noValidate>
                    <ModalHeader text={props.headerText} />
                    <Text>{props.confirmationText}</Text>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                props.onConfirm();
                                props.hideModal();
                            }}
                            fontAwesomeImage={ICON_DELETE_REMOVE}
                        >
                            Delete
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
