import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { CreateEffectivenessLimitRequest, EffectivenessLimitsSelectOptions, LimitType } from 'Models/Limits';

import styles from './CreateEffectivenessLimitModal.module.css';

export interface CreateEffectivenessLimitModalProps {
    hideModal: () => void;
    alertsLimitsApi: AlertsLimitsApi;
    onLimitCreated: () => void;
    controlIdentifier: string;
}

export const CreateEffectivenessLimitModal = (props: CreateEffectivenessLimitModalProps) => {
    const [isCreatingLimit, setIsCreatingLimit] = useState(false);
    const [effectiveness, setEffectiveness] = useState<number>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const createLimit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);
        if (effectiveness) {
            setIsCreatingLimit(true);
            try {
                const createLimitRequest: CreateEffectivenessLimitRequest = {
                    alert_limit: effectiveness,
                    enabled: true,
                    entity_id: props.controlIdentifier,
                    _type: LimitType.EFFECTIVENESS,
                };
                await props.alertsLimitsApi.createLimit(createLimitRequest);
                props.onLimitCreated();
                setSuccessMessage('Limit created.');
            } catch (err) {
                handleRequestError(err);
            } finally {
                setIsCreatingLimit(false);
            }
        } else {
            setFailureMessage('Please select an Effectiveness Limit');
        }
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setEffectiveness(value as number);
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isCreatingLimit) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={createLimit}>
                    <ModalHeader text="Create an Effectiveness Limit" />
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={EffectivenessLimitsSelectOptions} handleChange={handleSelectChange} formFieldId="effectiveness" formFieldLabel="Effectiveness" tooltip="Generate a notification if the effectiveness falls below the limit. Subsequent notifications will not be generated as long as the effectiveness stays below the limit." />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE} disabled={isCreatingLimit}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={successMessage ? true : false} isLoading={isCreatingLimit} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
