import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiIconButton from '@mui/material/IconButton';
import { type JSX, useState } from 'react';
import { BlockPicker, ColorChangeHandler, ColorResult } from 'react-color';

import styles from './ColorPicker.module.css';

const MAROON = '#800000';
const BROWN = '#9A6324';
const RED = '#e6194B';
const ORANGE = '#f58231';
const YELLOW = '#ffe119';
const LIME = '#bfef45';
const GREEN = '#3cb44b';
const PURPLE = '#911eb4';
const MAGENTA = '#f032e6';
const LAVENDER = '#dcbeff';
const CYAN = '#42d4f4';
const BLUE = '#4363d8';
const BEIGE = '#fffac8';
const GREY = '#a9a9a9';
const BLACK = '#000000';

export const COLOR_PALETTE = [
    [MAROON, BROWN, RED, ORANGE, YELLOW],
    [LIME, GREEN, PURPLE, MAGENTA, LAVENDER],
    [CYAN, BLUE, BEIGE, GREY, BLACK],
].flat();

export interface ColorPickerProps {
    initialColor?: string;
    onColorChange: (color: string) => void;
    accessibilityTitle: string;
}

export const ColorPicker = (props: ColorPickerProps): JSX.Element => {
    const [color, setColor] = useState<string>(props.initialColor ? props.initialColor : '#fff');
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);

    const colorChangeHandler: ColorChangeHandler = (color: ColorResult) => {
        setColor(color.hex);
        props.onColorChange(color.hex);
    };

    return (
        <div>
            <div>
                <MuiIconButton aria-label={props.accessibilityTitle} sx={{ color: color }} onClick={() => setPickerVisible(!pickerVisible)}>
                    <div>
                        <FontAwesomeIcon icon={faSquare} />
                    </div>
                </MuiIconButton>
            </div>
            {pickerVisible && (
                <div className={styles.popover}>
                    <div className={styles.cover} onClick={() => setPickerVisible(false)} />
                    <BlockPicker colors={COLOR_PALETTE} onChangeComplete={colorChangeHandler} color={color} />
                </div>
            )}
        </div>
    );
};
