import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';

import { Text } from 'Components/Text/Text';

import { MaterialFormLabel } from '../FormLabel/FormLabel';

export interface RadioButtonGroupBaseProps<T> {
    /**
     * The default option to show as selected.
     */
    defaultValue?: T;
    /**
     * If `true`, all radio buttons in the radio group will be disabled.
     */
    disabled?: boolean;

    /**
     * A label for the radio group input component. This will be displayed above the radio buttons.
     */
    formFieldLabel: string;

    /**
     * The _unique_ ID for the radio group's label. This must be a valid HTML ID (be careful not to include whitespace).
     * The label is uniquely identified, rather than the input itself, because `aria-labelledby` appears to be the only way to semantically link the label to the radio group; `for` does not work, since the radio group is an unlabelable `div`.
     */
    formFieldLabelId: string;

    /**
     * An array of options for the radio group. Each option should have a `label` and a `value`. The `value` must be unique among the options.
     * `value` is generic. You can provide any type you want, but you will most commonly want to provide a string, enum, or number (index of the option).
     */
    options: { label: string; value: T }[];
}

export interface RadioButtonGroupClearableProps<T> extends RadioButtonGroupBaseProps<T> {
    clearable: true;

    /**
     * Called whenever a new option is selected (i.e., whenever an un-selected radio button is clicked by the user), and wheenever an option is un-selected (i.e., whenever a selected radio button is re-clicked by the user).
     */
    onChange: (selectedValue: T | undefined) => void;
}

export interface RadioButtonGroupNotClearableProps<T> extends RadioButtonGroupBaseProps<T> {
    clearable: false;

    /**
     * Called whenever a new option is selected (i.e., whenever an un-selected radio button is clicked by the user).
     */
    onChange: (selectedValue: T) => void;
}

export type RadioButtonGroupProps<T> = RadioButtonGroupClearableProps<T> | RadioButtonGroupNotClearableProps<T>;

/**
 * Renders a group of radio buttons (i.e., multiple choice select one).
 * Uses a generic interface so that any value can be provided for the `value` of each option.
 *
 * NOTE: Tooltips are currently not able to be included, because A) Our tooltips come from Bootstrap, and we should avoid mixing MUI and Bootstrap components, and B) At the time of writing, none of our radio groups use tooltips.
 * * If the option for a tooltip for the entire group is added in the future, it should be "bundled" with the `formFieldLabel` prop, since our tooltips and labels are displayed side-by-side.
 * * If the option for tooltips for individual radio buttons is added in the future, a new `tooltip` property should be added to the `options` type and used in the `label` attribute of the `FormControlLabel`.
 */
export const RadioButtonGroup = <T,>(props: RadioButtonGroupProps<T>) => {
    // `null` is used to ensure that the radio group starts as a controlled component even if a `defaultValue` is not provided.
    const [selectedValue, setSelectedValue] = useState<T | null>(props.defaultValue ?? null);

    return (
        <FormControl>
            <MaterialFormLabel id={props.formFieldLabelId}>{props.formFieldLabel}</MaterialFormLabel>
            <RadioGroup aria-labelledby={props.formFieldLabelId} value={selectedValue}>
                {props.options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option.value}
                        label={<Text noStyles>{option.label}</Text>}
                        control={
                            <Radio
                                disabled={props.disabled}
                                onClick={() => {
                                    if (option.value === selectedValue) {
                                        if (props.clearable) {
                                            setSelectedValue(null);
                                            props.onChange(undefined);
                                        }
                                    } else {
                                        setSelectedValue(option.value);
                                        props.onChange(option.value);
                                    }
                                }}
                                disableRipple
                                color="default"
                                sx={{
                                    '&.Mui-checked': {
                                        color: 'var(--hps-blue)',
                                    },
                                }}
                            />
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
