const endpoint = import.meta.env.VITE_AWS_ENDPOINT;
const oidc_redirect_uri = import.meta.env.VITE_OIDC_REDIRECT_URI;
const oidc_org_url = import.meta.env.VITE_OIDC_ORG_URL;
const oidc_client_id = import.meta.env.VITE_OIDC_CLIENT_ID;

export const AWSConfig = {
    apiGateway: {
        URL: endpoint,
        NAME: 'SummITSecurityAPI',
    },
    oidc: {
        REDIRECT_URI: oidc_redirect_uri,
        ORG_URL: oidc_org_url,
        CLIENT_ID: oidc_client_id,
        PASSWORD_RESET_URL: `${oidc_org_url}/signin/forgot-password`,
        PASSWORD_CHANGE_URL: `${oidc_org_url}/enduser/settings`,
    },
};
