import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Text } from 'Components/Text/Text';

import styles from './Tooltips.module.css';

export enum IconColor {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED',
    WHITE = 'WHITE',
    GRAY = 'GRAY',
}

interface GenericTooltipProps {
    text: string;
    width?: number;
    height?: number;
    fontAwesomeIcon?: IconDefinition;
    iconColor?: IconColor;
    title?: string;
}

export const GenericTooltip = (props: GenericTooltipProps): JSX.Element => {
    const getIconColor = (): string => {
        switch (props.iconColor) {
            case IconColor.GREEN:
                return styles.tooltipColorGreen;
            case IconColor.YELLOW:
                return styles.tooltipColorYellow;
            case IconColor.WHITE:
                return styles.tooltipColorWhite;
            case IconColor.GRAY:
                return styles.tooltipColorGray;
            default:
                return styles.tooltipColorRed;
        }
    };

    const renderIcon = (): JSX.Element => {
        if (props.fontAwesomeIcon) {
            return <FontAwesomeIcon title={props.title} icon={props.fontAwesomeIcon} className={getIconColor()} />;
        } else {
            return <img src={'/i_toolTip.svg'} width={props.width || 15} height={props.height || 15} alt="tooltip" />;
        }
    };

    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 200 }}
            overlay={
                <Tooltip id="information">
                    <Text color="white" noStyles={true}>
                        {props.text}
                    </Text>
                </Tooltip>
            }
        >
            {renderIcon()}
        </OverlayTrigger>
    );
};
