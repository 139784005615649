import { isArray } from 'lodash-es';
import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { titleCaseRequirementStatus } from 'Models/ComplianceRequirements';
import { ComplianceRequirementAssessmentExport, ControlAssessmentExportRow } from 'Models/Exports';
import { assessmentStateAsString, effectivenessAsString } from 'Models/OperationalControls';

export interface ControlAssessmentsExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ControlAssessmentsExport = (props: ControlAssessmentsExportProps): JSX.Element => {
    const [assessments, setAssessments] = useState<ControlAssessmentExportRow[]>();
    const [error, setError] = useState<string>();

    const columns: GridColumn<ControlAssessmentExportRow>[] = [
        dateColumn('assessment_timestamp', 'Assessment Date'),
        dateColumn('assessment_due_date', 'Assessment Due Date'),
        {
            field: 'control',
            headerName: 'Control',
            width: 300,
            // I guess MUI Data Grid is anti-TypeScript? param is type OperationalControl. Access it via the .value member.
            valueGetter: (value, row) => getHumanReadableControlIdFromControl(value),
            type: 'string',
        },
        { field: 'assessment_effectiveness', headerName: 'Assessment Effectiveness', width: 300, valueGetter: (value, row) => effectivenessAsString(value) },
        { field: 'assessment_state', headerName: 'Assessment State', width: 300, valueGetter: (value) => assessmentStateAsString(value) },
        {
            field: 'regulatory_requirements',
            headerName: 'Compliance Requirements',
            width: 300,
            valueGetter: (value) => (isArray(value) ? value.map((regReq: ComplianceRequirementAssessmentExport) => `${regReq.name}: ${titleCaseRequirementStatus(regReq.status)}`).join(', ') : undefined),
        },
        { field: 'owner_comments', headerName: 'Owner Comments', width: 300, valueGetter: (value) => (isArray(value) ? value.join(', ') : undefined) },
        { field: 'reviewer_comment', headerName: 'Reviewer Comments', width: 300 },
    ];

    useEffect(() => {
        const getControlAssessmentsResponse = async () => {
            try {
                const assessmentsResponse = await props.exportsApi.getControlAssessmentsExport();
                setAssessments(assessmentsResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getControlAssessmentsResponse();
    }, [props.exportsApi]);

    if (assessments) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Control Assessments</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Control Assessments Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(assessment: ControlAssessmentExportRow) => `${assessment.control.identifier}#${assessment.assessment_timestamp}`} rows={assessments} title="Control Assessments Export Preview" fileName="Control Assessments" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else {
        return <Placeholder />;
    }
};
