import { ReactElement } from 'react';

import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';

import styles from './CustomControlReportRow.module.css';

export interface CustomControlReportRowProps {
    eventKey?: string;
    level: number;
    'aria-label': string;
    children?: React.ReactNode;
}

export const CustomControlReportRow = (props: CustomControlReportRowProps): ReactElement => {
    const getStyleClassArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.indentLevel0;
            case 2:
                return styles.indentLevel1;
            case 3:
                return styles.indentLevel2;
            case 4:
                return styles.indentLevel3;
            default:
                return styles.indentLevel4;
        }
    };

    const getStyleClassNoArrow = (): string => {
        switch (props.level) {
            case 1:
                return styles.indentLevel1;
            case 2:
                return styles.indentLevel2;
            case 3:
                return styles.indentLevel3;
            case 4:
                return styles.indentLevel4;
            default:
                return styles.indentLevel5;
        }
    };

    if (props.eventKey) {
        return (
            <AccordionItem eventKey={props.eventKey}>
                <div className={`${styles.rowText} ${getStyleClassArrow()}`} data-cy="accordionToggleContainer">
                    <AccordionToggle eventKey={props.eventKey} ariaLabelSuffix={props['aria-label']} />
                    {props.children}
                </div>
                <hr className={styles.hrModifier} />
            </AccordionItem>
        );
    }
    return (
        <>
            <div className={`${styles.rowText} ${getStyleClassNoArrow()}`}>{props.children}</div>
            <hr className={styles.hrModifier} />
        </>
    );
};
