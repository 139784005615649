import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

export const Microsoft = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    const [azureClientId, setAzureClientId] = useState<string>(); // This has nothing to do with the SummIT Security "Client ID". This is the client (application) ID of the application registered in Azure AD (Active Directory) in the customer's Azure tenant (within Azure AD "app registrations") that will allow SummIT Security to query the customer's Azure tenant.
    const [clientSecret, setClientSecret] = useState<string>();
    const [tenantId, setTenantId] = useState<string>();

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.MICROSOFT);
            setSuccessMessage(`${IntegrationName.MICROSOFT} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(azureClientId, clientSecret, tenantId);
            await props.externalIntegrationsApi.setExternalIntegration(IntegrationName.MICROSOFT, {
                azure_client_id: azureClientId!,
                client_secret: clientSecret!,
                tenant_id: tenantId!,
            });
            setSuccessMessage(`${IntegrationName.MICROSOFT} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    const validateForm = (azureClientId?: string, clientSecret?: string, tenantId?: string): void => {
        if (!azureClientId || azureClientId.length === 0) {
            throw new ValidationError('Invalid client ID.');
        }
        if (!clientSecret || clientSecret.length === 0) {
            throw new ValidationError('Invalid client secret.');
        }
        if (!tenantId || tenantId.length === 0) {
            throw new ValidationError('Invalid tenant ID.');
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={`Configure ${IntegrationName.MICROSOFT} Integration`} />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="azureClientId" formFieldLabel="Client ID" required tooltip="The client (application) ID for the application registered in Azure AD that allows SummIT Security access to your Azure tenant." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setAzureClientId(event.currentTarget.value)} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="clientSecret" formFieldLabel="Client Secret" formFieldType="password" required tooltip="The client secret that corresponds to the application registered in Azure AD that allows SummIT Security access to your Azure tenant." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setClientSecret(event.currentTarget.value)} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="tenantId" formFieldLabel="Tenant ID" required tooltip="The ID of your Azure tenant." handleChange={(event: React.FormEvent<HTMLInputElement>): void => setTenantId(event.currentTarget.value)} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting || isSubmitting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={ICON_DELETE_REMOVE}>
                            Delete
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={ICON_CLOSE}>
                                    Close
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting || isSubmitting} isLoading={isSubmitting} loadingText="Saving...">
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
