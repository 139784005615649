import { Checkbox } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowItem, OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { RadioButtonGroup } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { GENERIC_ERROR_MESSAGE, UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_DELETE_REMOVE, ICON_DOWNLOAD, ICON_SAVE, ICON_SUBMIT } from 'Config/Icons';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT, TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE } from 'Config/Tooltips';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { downloadDocument } from 'Helpers/FileUtils';
import { validateFileForUpload } from 'Helpers/InputValidation';
import { Navigator } from 'Helpers/Navigator';
import { addFiles, removeFiles } from 'Hooks/FileDragAndDrop';
import { SignedUploadResponse, UploadedFile } from 'Models/Files';
import { InherentRiskQuestionnaireQuestion, InherentRiskQuestionnaireResponse, InherentRiskQuestionnaireSection, RiskRating, RiskRatingPointThresholds, RiskRatingSelectOptions, SaveOrSubmitInherentRiskQuestionnaireRequest, Service, ServiceAssessmentState, ThirdPartyContact, riskRatingAsString } from 'Models/TPRM';

import { ConfirmSubmitIrqModal, ConfirmSubmitIrqModalProps } from './ConfirmSubmitIrqModal/ConfirmSubmitIrqModal';
import styles from './InherentRiskQuestionnaire.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export enum Modals {
    ClearIRQModal,
    ConfirmSubmitIrqModal,
    None,
}

export interface InherentRiskQuestionnaireProps {
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
    navigator: Navigator;
}

type ActiveInherentRisks = Exclude<RiskRating, RiskRating.INACTIVE>;
type DDQQuestionCount = Record<ActiveInherentRisks, number>;

// IMPORTANT: If you add a new field to the form, make sure you manually disable it when the form is supposed to be disabled. `fieldSet` can't be used to wrap the entire form: when the form is disabled / unable to be submitted, the user still needs to be able to click the overflow menus for files to be able to download them.
export const InherentRiskQuestionnaire = (props: InherentRiskQuestionnaireProps) => {
    const params = useParams<keyof UrlParams>() as UrlParams;

    const [displayedModal, setDisplayedModal] = useState<Modals>();
    const [toastMessage, setToastMessage] = useState<{ message: string; type: 'success' | 'failure' }>();

    // Related to the initial data required to load the page.
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();

    // Related to the save/submit request that gets sent when the user completes the form.
    const [requestState, setRequestState] = useState<'idle' | 'saving' | 'submitting'>('idle');

    const [selectedRiskRating, setSelectedRiskRating] = useState<RiskRating>();
    const [serviceResponse, setServiceResponse] = useState<Service>();

    const [thirdPartyServiceTitle, setThirdPartyServiceTitle] = useState<string>();

    const [questionnaire, setQuestionnaire] = useState<InherentRiskQuestionnaireResponse>();
    const [fileInfoBySection, setFileInfoBySection] = useState<{ newFiles: File[]; existingFilesToDelete: string[] }[]>();

    const [dueDiligenceQuestionCount, setDueDiligenceQuestionCount] = useState<DDQQuestionCount>();

    const formDisabled = requestState !== 'idle' || serviceResponse?.assessment_workflow_data.state !== ServiceAssessmentState.EVALUATING_INHERENT_RISK;

    const recommendedRiskRating = useMemo(() => {
        return questionnaire ? calculateRecommendedInherentRiskScore(questionnaire.sections, questionnaire.thresholds) : undefined;
    }, [questionnaire]);

    const getServiceAndQuestionnaire = useCallback(async (): Promise<void> => {
        try {
            const service = (await props.tprmApi.getServiceDetails(params.third_party_id, params.service_id)).data;
            setServiceResponse(service);
            setThirdPartyServiceTitle(`${service.vendor_name} - ${service.name}`);

            const questionnaire = (await props.tprmApi.getInherentRiskQuestionnaire(params.third_party_id, params.service_id)).data!;
            setQuestionnaire(questionnaire);
            setFileInfoBySection(questionnaire.sections.map(() => ({ newFiles: [], existingFilesToDelete: [] })));
            setSelectedRiskRating(questionnaire.inherent_risk_score);

            const dueDiligenceQuestionnaireConfiguration = (await props.tprmApi.getQuestionnaireConfiguration()).data;

            const questionCountMap = { [RiskRating.LOW]: 0, [RiskRating.LOW_MODERATE]: 0, [RiskRating.MODERATE]: 0, [RiskRating.MODERATE_HIGH]: 0, [RiskRating.HIGH]: 0 };

            const questionCountByRiskRating = dueDiligenceQuestionnaireConfiguration.control_frameworks
                .flatMap((framework) => framework.control_groups.flatMap((controlGroup) => controlGroup.controls.flatMap((control) => control.questions.flatMap((question) => question.mapped_risk_ratings))))
                .reduce((acc, curr) => {
                    if (curr !== RiskRating.INACTIVE) {
                        acc[curr]++;
                    }
                    return acc;
                }, questionCountMap);

            setDueDiligenceQuestionCount(questionCountByRiskRating);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                setLoadingErrorOccurred(true);
            }
        }
    }, [params.third_party_id, params.service_id, props.tprmApi]);

    useEffect(() => {
        getServiceAndQuestionnaire();
    }, [getServiceAndQuestionnaire]);

    if (tprmAccessDenied) {
        return (
            <PageBackground color="white">
                <PageContent>
                    <div className={styles.zeroStateContainer}>
                        <Text>{UNAUTHORIZED_MESSAGE}</Text>
                    </div>
                </PageContent>
            </PageBackground>
        );
    }

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (!(questionnaire && fileInfoBySection && serviceResponse && thirdPartyServiceTitle && dueDiligenceQuestionCount)) {
        return <Placeholder />;
    }

    const handleSubmitClicked = () => {
        if (!selectedRiskRating) {
            setToastMessage({ type: 'failure', message: 'Select an inherent risk rating before submitting the inherent risk questionnaire.' });
            return;
        }

        const allQuestionsAnswered = questionnaire.sections.every((section) => {
            return !section.is_applicable || section.questions.every((question) => question.selected_answer_index !== undefined);
        });

        if (!allQuestionsAnswered) {
            setToastMessage({ type: 'failure', message: 'All questions must be answered before submitting the inherent risk questionnaire.' });
            return;
        }

        setDisplayedModal(Modals.ConfirmSubmitIrqModal);
    };

    /**
     * Set the inherent risk rating for the Vendor Service.
     * @param submit Indicates whether the IRQ should be saved as in-progress or submitted as complete.
     * @param vendorContacts A list of Vendor Service contacts to notify about the Due Diligence Questionnaire (DDQ).
     */
    const saveQuestionnaire = async (submit: boolean, vendorContacts: ThirdPartyContact[]): Promise<void> => {
        setRequestState(submit ? 'submitting' : 'saving');
        setToastMessage(undefined);

        try {
            // Validate each file before uploading.
            for (const file of fileInfoBySection.flatMap((section) => section.newFiles)) {
                validateFileForUpload(file);
            }

            const pendingDocumentUploadsBySection: {
                signedUpload: SignedUploadResponse;
                file: File;
            }[][] = [];

            try {
                for (const [index, fileInfo] of fileInfoBySection.entries()) {
                    if (!questionnaire.sections[index].is_applicable) {
                        pendingDocumentUploadsBySection.push([]);
                        continue;
                    }

                    const pendingDocumentUploads: {
                        signedUpload: SignedUploadResponse;
                        file: File;
                    }[] = [];

                    for (const file of fileInfo.newFiles) {
                        const signedUploadResponse = (await props.documentApi.getSignedUpload(file.name)).data;
                        pendingDocumentUploads.push({ signedUpload: signedUploadResponse, file: file });
                    }

                    pendingDocumentUploadsBySection.push(pendingDocumentUploads);
                }
            } catch (error) {
                console.log(error);
                throw new Error('Something went wrong when trying to upload files.');
            }

            // Submit the request to our backend API.
            const request: SaveOrSubmitInherentRiskQuestionnaireRequest = {
                submit: submit,
                inherent_risk_score: selectedRiskRating,
                section_selections: questionnaire.sections.map((section, sectionIndex) => {
                    // If a section does not apply, no responses are included, as the backend will ignore them (i.e., not persist them) even if included.
                    return {
                        is_applicable: section.is_applicable,
                        answers: section.is_applicable ? section.questions.map((question) => question.selected_answer_index ?? null) : undefined,
                        text: section.is_applicable ? section.text : undefined,
                        file_updates: section.is_applicable
                            ? {
                                  existing_files_to_delete: fileInfoBySection[sectionIndex].existingFilesToDelete,
                                  new_files: pendingDocumentUploadsBySection[sectionIndex].map((pendingUpload) => ({
                                      filename: pendingUpload.file.name,
                                      file_id: pendingUpload.signedUpload.file_id,
                                  })),
                              }
                            : {},
                    };
                }),
                third_party_contacts: vendorContacts,
            };

            await props.tprmApi.saveInherentRiskQuestionnaire(params.third_party_id, params.service_id, request);

            const failedFileNames: string[] = [];
            // Upload each document to S3. If any error occurs, remember it for below, but continue trying to upload other documents.
            for (const pendingUpload of pendingDocumentUploadsBySection.flat()) {
                try {
                    await props.documentApi.uploadDocument(pendingUpload.signedUpload.url, pendingUpload.signedUpload.fields, pendingUpload.file);
                } catch (error) {
                    console.log(error);
                    failedFileNames.push(pendingUpload.file.name);
                }
            }

            if (failedFileNames.length > 0) {
                throw new Error(`Something went wrong when trying to upload: ${failedFileNames.join(', ')}.`);
            }

            await getServiceAndQuestionnaire();
            setToastMessage({ message: `Inherent risk questionnaire ${submit ? 'submitted' : 'saved'}.`, type: 'success' });

            // Reset "pending" files (files to upload and files to delete). Uploaded files (`existingFiles`) have already been updated with the data re-fetched by `getServiceDetails`.
            setFileInfoBySection(questionnaire.sections.map(() => ({ newFiles: [], existingFilesToDelete: [] })));
        } catch (error) {
            setToastMessage({ message: error.message, type: 'failure' });
        } finally {
            setRequestState('idle');
        }
    };

    const handleChangeSelectedRiskRating = (value: ChangeEventType): void => {
        setSelectedRiskRating(value as RiskRating);
    };

    const onConfirmBeginRiskWorkflow = (vendorContacts: ThirdPartyContact[]) => {
        setDisplayedModal(Modals.None);
        saveQuestionnaire(true, vendorContacts);
    };

    const confirmSubmitIrqModalProps: ConfirmSubmitIrqModalProps = {
        inherentRiskRating: selectedRiskRating!,
        hideModal: () => setDisplayedModal(Modals.None),
        service: serviceResponse,
        onConfirm: onConfirmBeginRiskWorkflow,
        hasValidDDQConfiguration: selectedRiskRating !== RiskRating.INACTIVE && dueDiligenceQuestionCount[selectedRiskRating!] > 0 ? true : false,
    };

    const alertForDisabledIrq = (() => {
        switch (serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT} />
                        </div>
                    </Alert>
                );
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE} />
                        </div>
                    </Alert>
                );
            default:
                return undefined;
        }
    })();

    return (
        <>
            {toastMessage && <TextToast variant={toastMessage.type} clearToast={() => setToastMessage(undefined)} text={toastMessage.message} />}
            {displayedModal === Modals.ConfirmSubmitIrqModal && <ConfirmSubmitIrqModal {...confirmSubmitIrqModalProps} />}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.vendor_id}/${SERVICES}/${serviceResponse.id}/${DASHBOARDS}`}>{thirdPartyServiceTitle}</BreadcrumbLink>
                        <BreadcrumbText>Inherent Risk Questionnaire</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Inherent Risk Questionnaire"
                headerDescription="The recommended inherent risk rating will be updated as questions are answered. The inherent risk rating drives prioritization of controls to reduce organizational risk. Questions are based on the inherent risk questionnaire configuration at the time when this third-party service's assessment workflow was started, and therefore may differ from the current inherent risk questionnaire configuration."
                body={[
                    {
                        content: (
                            <Form noValidate>
                                <div className={styles.sections}>
                                    {alertForDisabledIrq}
                                    {questionnaire.sections.map((section, sectionIndex) => {
                                        return (
                                            <SectionDisplay
                                                key={section.name}
                                                disabled={formDisabled}
                                                documentApi={props.documentApi}
                                                section={section}
                                                sectionIndex={sectionIndex}
                                                onUpdateSection={(updatedSection) => {
                                                    const newSections = [...questionnaire.sections];
                                                    newSections[sectionIndex] = updatedSection;
                                                    setQuestionnaire({ ...questionnaire, sections: newSections });
                                                }}
                                                newFiles={fileInfoBySection[sectionIndex].newFiles}
                                                onAddNewFiles={(files) => {
                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].newFiles = addFiles(newFileInfo[sectionIndex].newFiles, files);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                                onRemoveNewFile={(file) => {
                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].newFiles = removeFiles(newFileInfo[sectionIndex].newFiles, file);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                                deleteExistingFile={(file) => {
                                                    const newSections = [...questionnaire.sections];
                                                    newSections[sectionIndex] = { ...section, files: section.files.filter((f) => f.file_id !== file.file_id) };
                                                    setQuestionnaire({ ...questionnaire, sections: newSections });

                                                    const newFileInfo = [...fileInfoBySection];
                                                    newFileInfo[sectionIndex].existingFilesToDelete.push(file.file_id);
                                                    setFileInfoBySection(newFileInfo);
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                                <fieldset disabled={formDisabled}>
                                    <div className={styles.sectionHeader}>
                                        <div className={styles.sectionNameAndUserSelectContainer}>
                                            <Text variant="Header2" noStyles>
                                                Overall
                                            </Text>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className={styles.questions}>
                                        <FormFieldSelect formFieldId="selectedRiskRating" formFieldLabel="Inherent Risk Rating" options={RiskRatingSelectOptions} selectedOption={selectedRiskRating} handleChange={handleChangeSelectedRiskRating} required badge={recommendedRiskRating ? `Recommendation: ${riskRatingAsString(recommendedRiskRating)}` : undefined} />
                                    </div>
                                    <div className={styles.buttonsContainer}>
                                        <Button fontAwesomeImage={ICON_SAVE} variant="secondary" isLoading={requestState === 'saving'} loadingText="Saving..." onClick={() => saveQuestionnaire(false, [])}>
                                            Save
                                        </Button>
                                        <Button fontAwesomeImage={ICON_SUBMIT} variant="primary" isLoading={requestState === 'submitting'} loadingText="Submitting..." onClick={handleSubmitClicked}>
                                            Submit
                                        </Button>
                                    </div>
                                </fieldset>
                            </Form>
                        ),
                    },
                ]}
            />
        </>
    );
};

interface SectionDisplayProps {
    documentApi: DocumentApi;
    section: InherentRiskQuestionnaireSection;
    sectionIndex: number;
    disabled: boolean;
    onUpdateSection: (updatedSection: InherentRiskQuestionnaireSection) => void;
    newFiles: File[];
    deleteExistingFile: (file: UploadedFile) => void;
    onAddNewFiles: (files: File[]) => void;
    onRemoveNewFile: (file: File) => void;
}

const SectionDisplay = (props: SectionDisplayProps) => {
    const fileDragAndDropProps: FileDragAndDropProps = {
        inputId: `${props.section.name} file input`,
        labelText: 'Files',
        onAddFiles: props.onAddNewFiles,
        onRemoveFile: props.onRemoveNewFile,
        files: props.newFiles,
        disabled: props.disabled,
    };

    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader}>
                <div className={styles.sectionNameAndCheckboxContainer}>
                    <Text variant="Header2" noStyles>
                        {props.section.name}
                    </Text>
                    <div className={styles.checkboxContainer}>
                        <Checkbox
                            disabled={props.disabled}
                            checked={props.section.is_applicable}
                            color="default"
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, is_applicable: event.currentTarget.checked });
                            }}
                        />
                        <Text noStyles>Relevant to this service</Text>
                        <FormFieldTooltip text="Indicates whether the questions in this section are applicable to the third-party service. If unchecked, responses in this section will not be required for this service." />
                    </div>
                </div>
                <hr />
            </div>
            {props.section.is_applicable && (
                <>
                    {props.section.questions.map((question, questionIndex) => {
                        const questionDisplayProps: QuestionDisplayProps = {
                            question,
                            section: props.section,
                            sectionIndex: props.sectionIndex,
                            questionIndex,
                            onUpdateQuestion: (updatedQuestion) => {
                                props.onUpdateSection({ ...props.section, questions: props.section.questions.map((q, i) => (i === questionIndex ? updatedQuestion : q)) });
                            },
                            disabled: props.disabled,
                        };
                        return <QuestionDisplay key={question.text} {...questionDisplayProps} />;
                    })}
                    <div className={styles.textEvidenceContainer}>
                        <FormFieldTextArea
                            formFieldId={`${props.section.name} additional information`}
                            formFieldLabel="Additional Information"
                            handleChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, text: event.currentTarget.value });
                            }}
                            value={props.section.text}
                            disabled={props.disabled}
                        />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FileDragAndDrop {...fileDragAndDropProps} />
                        {props.section.files.length > 0 && (
                            <>
                                <VisualLabel>Existing Files</VisualLabel>
                                <Table>
                                    <TableBody>
                                        {[...props.section.files]
                                            .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                            .map((file) => {
                                                const overflowItems: OverflowItem[] = [
                                                    {
                                                        text: 'Download file',
                                                        onClickAction: () => downloadDocument(props.documentApi, file),
                                                        icon: ICON_DOWNLOAD,
                                                    },
                                                ];

                                                if (!props.disabled) {
                                                    overflowItems.push({
                                                        text: 'Delete file',
                                                        onClickAction: () => props.deleteExistingFile(file),
                                                        icon: ICON_DELETE_REMOVE,
                                                    });
                                                }

                                                return (
                                                    <TableRow key={file.file_id}>
                                                        <TableCell>
                                                            <Text noStyles>{file.filename}</Text>
                                                        </TableCell>
                                                        <TableOverflowCell>
                                                            <div className={styles.overflowContainer}>
                                                                <OverflowMenu overflowItems={overflowItems} accessibilityTitle={`Menu for ${file.filename}`} />
                                                            </div>
                                                        </TableOverflowCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

interface QuestionDisplayProps {
    question: InherentRiskQuestionnaireQuestion;
    section: InherentRiskQuestionnaireSection;
    sectionIndex: number;
    questionIndex: number;
    onUpdateQuestion: (updatedQuestion: InherentRiskQuestionnaireQuestion) => void;
    disabled: boolean;
}

const QuestionDisplay = (props: QuestionDisplayProps) => {
    return (
        <div className={styles.question}>
            <Text variant="Text2">{`${props.questionIndex + 1}. ${props.question.text}`}</Text>
            <RadioButtonGroup
                clearable
                defaultValue={props.question.selected_answer_index}
                disabled={props.disabled}
                formFieldLabelId={`section${props.sectionIndex}Question${props.questionIndex}AnswerLabel`}
                formFieldLabel="Answer"
                options={props.question.answers.map((answer, index) => ({
                    label: answer.text,
                    value: index,
                }))}
                onChange={(selectedIndex) => props.onUpdateQuestion({ ...props.question, selected_answer_index: selectedIndex })}
            />
        </div>
    );
};

const calculateRecommendedInherentRiskScore = (sections: InherentRiskQuestionnaireSection[], thresholds: RiskRatingPointThresholds): RiskRating | undefined => {
    let totalPoints = 0;
    for (const section of sections) {
        if (!section.is_applicable) {
            continue;
        }

        for (const question of section.questions) {
            if (question.selected_answer_index === undefined) {
                return undefined;
            }

            totalPoints += question.answers[question.selected_answer_index].points;
        }
    }

    if (totalPoints <= thresholds.low) {
        return RiskRating.LOW;
    } else if (totalPoints <= thresholds.low_moderate) {
        return RiskRating.LOW_MODERATE;
    } else if (totalPoints <= thresholds.moderate) {
        return RiskRating.MODERATE;
    } else if (totalPoints <= thresholds.moderate_high) {
        return RiskRating.MODERATE_HIGH;
    } else {
        return RiskRating.HIGH;
    }
};
