import { isNull } from 'lodash-es';
import { type JSX, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { ScheduleFrequency, ScheduleFrequencySelectOptionsV2 } from 'Models/ScheduleFrequency';

import styles from './InherentRiskQuestionnaireTargetCompletionModal.module.css';

export interface InherentRiskQuestionnaireTargetCompletionModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
}

interface FormState {
    isLoadingInitialData: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const InherentRiskQuestionnaireTargetCompletionModal = (props: InherentRiskQuestionnaireTargetCompletionModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({ isLoadingInitialData: true, isSubmitting: false });
    const [scheduleNumber, setScheduleNumber] = useState<string>(''); // Need to have this be a string in state and do some hacky Number() and toString() calls in a few places because React doesn't like variables changing from undefined to a number ("A component is changing an uncontrolled input to be controlled..."), .
    const [scheduleFrequency, setScheduleFrequency] = useState<ScheduleFrequency | null>(null);

    useEffect(() => {
        const getInherentRiskQuestionnaireTargetCompletion = async () => {
            try {
                const response = await props.tprmApi.getInherentRiskQuestionnaireTargetCompletion();
                if (response.data) {
                    setScheduleNumber(response.data.schedule_number.toString());
                    setScheduleFrequency(response.data.schedule_frequency);
                }
            } catch (error) {
                setFormState((prevState) => ({ ...prevState, failureMessage: error.message }));
            } finally {
                setFormState((prevState) => ({ ...prevState, isLoadingInitialData: false }));
            }
        };

        getInherentRiskQuestionnaireTargetCompletion();
    }, [props.tprmApi]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormState((prevState) => ({ ...prevState, isSubmitting: true, successMessage: undefined, failureMessage: undefined }));

        try {
            // If both fields are empty, clear the target completion.
            if (scheduleNumber.trim() === '' && isNull(scheduleFrequency)) {
                await props.tprmApi.deleteInherentRiskQuestionnaireTargetCompletion();
                setFormState((prevState) => ({
                    ...prevState,
                    isSubmitting: false,
                    successMessage: 'Target completion cleared.',
                }));
            }
            // Else, verify both fields are valid and save the target completion.
            else {
                const scheduleNumberAsNumber = Number(scheduleNumber);

                if (isNaN(scheduleNumberAsNumber) || scheduleNumberAsNumber === 0) {
                    throw new ValidationError('"Number" must be a valid number greater than 0.');
                }

                if (isNull(scheduleFrequency) || !Object.values(ScheduleFrequency).includes(scheduleFrequency)) {
                    throw new ValidationError('"Frequency" must be one of the following: ' + Object.values(ScheduleFrequency).join(', '));
                }

                await props.tprmApi.setInherentRiskQuestionnaireTargetCompletion({ schedule_number: scheduleNumberAsNumber, schedule_frequency: scheduleFrequency });

                setFormState((prevState) => ({
                    ...prevState,
                    isSubmitting: false,
                    successMessage: 'Target completion saved.',
                }));
            }
        } catch (error) {
            setFormState((prevState) => ({
                ...prevState,
                isSubmitting: false,
                failureMessage: error.message,
            }));
        }
    };

    const disableForm = formState.isLoadingInitialData || formState.isSubmitting;

    if (formState.isLoadingInitialData) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Inherent Risk Questionnaire Target Completion" secondaryText="Set the default organization-wide target completion time frame for third-party inherent risk questionnaires once the third-party service assessment workflow has started." />
                    <div className={styles.schedule}>
                        <div className={styles.scheduleNumber}>
                            <FormFieldText formFieldId={'scheduleNumber'} formFieldLabel="Number" handleChange={(event: React.FormEvent<HTMLInputElement>) => setScheduleNumber(event.currentTarget.value)} value={scheduleNumber} disabled={disableForm} required={false} formFieldType="text" />
                        </div>
                        <div className={styles.scheduleFrequency}>
                            <FormFieldSelect formFieldId={'scheduleFrequency'} formFieldLabel="Frequency" handleChange={(value: ChangeEventType) => setScheduleFrequency(value as ScheduleFrequency)} options={ScheduleFrequencySelectOptionsV2} selectedOption={scheduleFrequency} disabled={disableForm} />
                        </div>
                        <div className={styles.clearIcon}>
                            <IconButton
                                aria-label={'clearSchedule'}
                                fontAwesomeImage={ICON_DELETE_REMOVE}
                                onClick={() => {
                                    setScheduleNumber('');
                                    setScheduleFrequency(null);
                                }}
                            />
                        </div>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" disabled={disableForm} onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={disableForm} isLoading={formState.isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
