import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

const INTEGRATION_NAME = IntegrationName.PROOFPOINT_SECURITY_AWARENESS_TRAINING;

export const ProofpointSecurityAwarenessTraining = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    const [apiTokenKey, setApiTokenKey] = useState<string>();
    const [region, setRegion] = useState<string>();

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm(`Are you sure you want to delete ${INTEGRATION_NAME}? \r\n\r\n All limits created for this integration will also be deleted.`);

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(INTEGRATION_NAME);
            setSuccessMessage(`${INTEGRATION_NAME} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(apiTokenKey, region);
            await props.externalIntegrationsApi.setExternalIntegration(INTEGRATION_NAME, {
                api_token_key: apiTokenKey!,
                region: region!,
            });
            setSuccessMessage(`${INTEGRATION_NAME} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    const validateForm = (apiTokenKey?: string, region?: string): void => {
        if (!apiTokenKey || apiTokenKey.length === 0) {
            throw new ValidationError('Invalid API token key.');
        }
        // Case-insensitive validation because the SummIT Security API and will force the region to lower-case when constructing the URL.
        if (!region || (region.toUpperCase() !== 'AP' && region.toUpperCase() !== 'EU' && region.toUpperCase() !== 'US')) {
            throw new ValidationError('Invalid region.');
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={`Configure ${INTEGRATION_NAME} Integration`} />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="apiTokenKey" formFieldLabel="API Token Key" formFieldType="password" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setApiTokenKey(event.currentTarget.value)} required tooltip={`The API token key provided by ${INTEGRATION_NAME}.`} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="region" formFieldLabel="Region" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setRegion(event.currentTarget.value)} required tooltip={`The ${INTEGRATION_NAME} API region your organization uses. Valid values are AP, EU, and US.`} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting || isSubmitting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={ICON_DELETE_REMOVE}>
                            Delete
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={ICON_CLOSE}>
                                    Close
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting || isSubmitting} isLoading={isSubmitting} loadingText="Saving...">
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
