import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ActionExportRow } from 'Models/Exports';

export interface ActionsExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ActionsExport = (props: ActionsExportProps): JSX.Element => {
    const [actions, setActions] = useState<ActionExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getActions = async (): Promise<void> => {
            try {
                const thirdPartyResponse = await props.exportsApi.getActionsExport();
                setActions(thirdPartyResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getActions();
    }, [props.exportsApi]);

    const columns: GridColumn<ActionExportRow>[] = [
        { field: 'title', headerName: 'Action Title', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'status', headerName: 'Status', width: 300 },
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('created_timestamp', 'Created'),
        { field: 'created_by', headerName: 'Created By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('last_updated', 'Last Updated'),
        { field: 'last_updated_by', headerName: 'Last Updated By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('closed_timestamp', 'Closed'),
        { field: 'closed_by', headerName: 'Closed By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'documents', headerName: 'Documents', width: 300 },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 300,
            valueGetter: (value, row) => row.tags.sort().join(', '),
        },
        dateColumn('due_date', 'Due Date'),
        { field: 'comments', headerName: 'Comments', width: 300 },
        { field: 'link', headerName: 'Reference', width: 300 },
        {
            field: 'associated_risks',
            headerName: 'Associated Risks',
            width: 300,
            valueGetter: (value, row) => row.associated_risks.sort().join(', '),
        },
    ];

    if (actions) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Actions</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Actions Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(action: ActionExportRow) => action.id} rows={actions} title="Actions Export Preview" fileName="Actions Export" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
