import type { JSX } from 'react';
import { Card } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { Text } from 'Components/Text/Text';
import { ellipt } from 'Helpers/StringUtils';
import { ControlEffectivenessProgressBarVariantAndPercentage, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import styles from './ControlCard.module.css';

export interface ControlCardProps {
    description: string;
    effectiveness: number;
    link: string;
    linkText: string;
    title: string;
}

/**
 * A component for rendering Control Groups and Controls in a "card" style.
 * @param {string} description The description of the Control or Control Group.
 * @param {number} effectiveness The effectiveness rating of the Control or Control Group.
 * @param {string} link The location in the webpage the user will navigate to on click.
 * @param {string} linkText The text to display for the link.
 * @param {string} title The title of the Control or Control Group.
 */
export const ControlCard = (props: ControlCardProps): JSX.Element => {
    const progressBarValues = ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.effectiveness));

    return (
        <Card className={styles.controlCard}>
            <div className={styles.titleContainer}>
                <Text variant="Header2">{ellipt(props.title, 45)}</Text>
            </div>
            <div className={styles.descriptionContainer}>
                <Text color="darkGray">{ellipt(props.description, 160)}</Text>
            </div>
            <Text color="darkGray" variant="Header3">
                Effectiveness
            </Text>
            <ProgressBarIndicator variant={progressBarValues.variant} percent={progressBarValues.percent} size="small" />
            <div className={styles.effectivenessTextContainer}>
                <Text variant="Text1">{numberAsEffectivenessString(props.effectiveness)}</Text>
            </div>
            <div className={styles.horizontalLine}></div>
            <div className={styles.linkContainer}>
                <Link size="lg" to={props.link}>
                    {props.linkText}
                </Link>
            </div>
        </Card>
    );
};
