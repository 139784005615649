import { type JSX, useEffect, useRef } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import styles from 'Components/FormField/FormField.module.css';

export interface ClickAwayTextboxProps {
    onClickAway: (value: string) => void;
    formFieldId: string;
    formFieldType?: string;
    required?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    pattern?: string;
    handleChange?: any;
    value?: string;
    disabled?: boolean;
    invalidMessage?: string;
    onInput?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback]);

    return ref;
};

export const ClickAwayTextbox = (props: ClickAwayTextboxProps): JSX.Element => {
    const textboxRef = useOutsideClick(() => {
        if (textboxRef.current) {
            props.onClickAway(textboxRef.current.value);
        }
    });

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <FormControl autoFocus={props.autoFocus} onKeyDown={props.onInput} ref={textboxRef} name={props.formFieldId} className={styles.customFormControl} type={props.formFieldType || 'text'} required={props.required} placeholder={props.placeholder} pattern={props.pattern} onChange={props.handleChange} value={props.value} disabled={props.disabled} />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};
