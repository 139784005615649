/*
    ProofpointTargetedAttackProtection.tsx -- Modal for configuring the Proofpoint Targeted Attack Protection integration.
*/

import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

const INTEGRATION_NAME = IntegrationName.PROOFPOINT_TARGETED_ATTACK_PROTECTION;

export const ProofpointTargetedAttackProtection = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [principal, setPrincipal] = useState<string | undefined>(undefined);
    const [secret, setSecret] = useState<string | undefined>(undefined);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const { name, value } = event.currentTarget;
        if (name === 'principal') setPrincipal(value);
        if (name === 'secret') setSecret(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(principal, secret);
            await props.externalIntegrationsApi.setExternalIntegration(INTEGRATION_NAME, {
                principal: principal!,
                secret: secret!,
            });
            setSuccessMessage(`${INTEGRATION_NAME} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const validateForm = (principal?: string, secret?: string): void => {
        if (!principal || principal.length === 0) {
            throw new ValidationError('Invalid principal.');
        }
        if (!secret || secret.length === 0) {
            throw new ValidationError('Invalid secret.');
        }
    };

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(INTEGRATION_NAME);
            setSuccessMessage(`${INTEGRATION_NAME} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={`Configure ${INTEGRATION_NAME} Integration`} />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText formFieldId="principal" formFieldLabel="Principal" required tooltip={`The API principal provided by ${INTEGRATION_NAME}.`} invalidMessage={`Please enter a valid ${INTEGRATION_NAME} API principal.`} handleChange={handleChange} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText formFieldId="secret" formFieldLabel="Secret" formFieldType="password" required tooltip={`The API secret provided by ${INTEGRATION_NAME}.`} invalidMessage={`Please enter a valid ${INTEGRATION_NAME} API secret.`} handleChange={handleChange} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={ICON_DELETE_REMOVE}>
                            Delete
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={ICON_CLOSE}>
                                    Close
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting} isLoading={isSubmitting} loadingText="Saving...">
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
