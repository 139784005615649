import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { GovernanceExportRow } from 'Models/Exports';
import { getHumanReadableGovernanceContentType, getHumanReadableGovernanceType } from 'Models/Governance';
import { OperationalControl } from 'Models/OperationalControls';

export interface GovernanceExportProps {
    exportsApi: ExportsApi;
    disableVirtualizationForDataGrid?: boolean;
}

export const GovernanceExport = (props: GovernanceExportProps): JSX.Element => {
    const [governances, setGovernances] = useState<GovernanceExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getGovernances = async (): Promise<void> => {
            try {
                const governances = (await props.exportsApi.getGovernanceExport()).data;
                setGovernances(governances);
            } catch (error) {
                setError(error.message);
            }
        };

        getGovernances();
    }, [props.exportsApi]);

    const columns: GridColumn<GovernanceExportRow>[] = [
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'effective_status', headerName: 'Status', width: 300 },
        dateColumn('effective_date', 'Effective Date'),
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'last_updated_by', headerName: 'Last Updated By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('last_updated', 'Last Updated'),
        { field: 'changelog', headerName: 'Changelog', width: 300 },
        {
            field: 'associated_controls',
            headerName: 'Associated Controls',
            width: 300,
            valueGetter: (_value, row) =>
                row.associated_controls
                    .sort(controlComparator)
                    .map((control: OperationalControl) => getHumanReadableControlIdFromControl(control))
                    .join(', '),
        },
        { field: 'type', headerName: 'Type', width: 300, valueGetter: (value) => getHumanReadableGovernanceType(value) },
        { field: 'content_type', headerName: 'Content Type', width: 300, valueGetter: (value) => getHumanReadableGovernanceContentType(value) },
        { field: 'filename', headerName: 'Filename', width: 300 },
        { field: 'external_url', headerName: 'External URL', width: 300 },
        { field: 'text', headerName: 'Definition', width: 300 },
    ];

    if (governances) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Governance Integration</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Governance Integration Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(governance: GovernanceExportRow) => governance.id} rows={governances} title="Governance Integration Export Preview" fileName="Governance" disableVirtualization={props.disableVirtualizationForDataGrid} /> }]}
            />
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
