import type { JSX } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { Service } from 'Models/TPRM';

import styles from './ThirdPartyListingServiceListing.module.css';

export interface ThirdPartyListingServiceListingProps {
    thirdPartyId: string;
    services: Service[];
}

/**
 *
 * Renders a list of services as LinkButtons that link to the service dashboard.
 *
 * A maximum of 4 services are returned. If there are more than 4 services, text is shown to indicate there are more services.
 *
 * @param thirdPartyId The id of the parent third party
 * @param services The list of services
 * @returns JSX.Element
 */
export const ThirdPartyListingServiceListing = ({ thirdPartyId, services }: ThirdPartyListingServiceListingProps): JSX.Element => {
    return (
        <div className={styles.servicesList}>
            {[...services]
                .sort((serviceA, serviceB) => serviceA.name.localeCompare(serviceB.name))
                .slice(0, 4)
                .map((service) => (
                    <Link key={service.id} size="sm" to={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${service.id}/${DASHBOARDS}`}>
                        {service.name}
                    </Link>
                ))}
            {services.length > 4 && <Text noStyles>{`${services.length - 4} more service${services.length - 4 > 1 ? 's' : ''}...`}</Text>}
        </div>
    );
};
