/**
 * Some of the icons defined below can be ambiguous (e.g, when to use ICON_ADD_CREATE vs. ICON_SAVE). To differentiate between them, the following keywords and standards are used:
 * NAVIGATIONAL icons are used to open a page/form/modal. They are not used to save/submit.
 * TRANSACTIONAL icons are used to save/submit. They are not used for navigation.
 */

import { faAngleRight, faChevronCircleRight, faCog, faDownload, faEdit, faEllipsisV, faExclamationTriangle, faEye, faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

/** NAVIGATIONAL - Navigate to a UI where something new can be created. */
export const ICON_ADD_CREATE = faPlus;

/** Call attention to something, such as a warning or alert. */
export const ICON_ALERT_WARNING = faExclamationTriangle;

/** NAVIGATIONAL - Close a page, window, modal, etc. */
export const ICON_CLOSE = faTimes;

/** TRANSACTIONAL - Delete or remove something. */
export const ICON_DELETE_REMOVE = faTrash;

/** Download a file. */
export const ICON_DOWNLOAD = faDownload;

/** NAVIGATIONAL - Navigate to a UI where something existing can be edited/modified/updated. */
export const ICON_EDIT_MODIFY_UPDATE = faEdit;

/** Expand or collapse an element of section of the page. */
export const ICON_EXPAND_COLLAPSE = faAngleRight;

/** NAVIGATIONAL - Open an overflow menu. */
export const ICON_OVERFLOW = faEllipsisV;

/** TRANSACTIONAL - Save changes to something existing, such as an entity, setting, or configuration. Also used to save a "draft" version of an entity. */
export const ICON_SAVE = faSave;

/**
 * NAVIGATIONAL - Navigate to a UI where settings/configurations for something can be updated.
 * This is Similar to ICON_EDIT_MODIFY_UPDATE, but this is more related to changing settings (either globally or entity settings), whereas ICON_EDIT_MODIFY_UPDATE is more related to modifying an entity itself.
 */
export const ICON_SETTINGS = faCog;

/** TRANSACTIONAL - Submit a form to create something new. */
export const ICON_SUBMIT = faChevronCircleRight;

/** View something in a read-only mode. */
export const ICON_VIEW = faEye;
