import { type JSX } from 'react';
import { Carousel as BSCarousel, CarouselItemProps as BSCarouselItemProps } from 'react-bootstrap';

export interface CarouselItemProps {
    children: JSX.Element;
}

export const CarouselItem = ({ children, ...props }: CarouselItemProps & BSCarouselItemProps) => {
    return <BSCarousel.Item {...props}>{children}</BSCarousel.Item>;
};
