import { cloneDeep, isUndefined } from 'lodash-es';
import { type JSX, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { ICON_CLOSE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { ScheduleFrequencyKeys } from 'Models/ScheduleFrequency';
import { Schedule, ServiceAssessmentSchedule } from 'Models/TPRM';

import { RiskRatingSchedule, RiskRatingScheduleProps } from './RiskRatingSchedule/RiskRatingSchedule';

export interface ServiceAssessmentScheduleModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
}

interface FormState {
    isLoadingInitialData: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    failureMessage?: string;
}

type EditableServiceAssessmentSchedule = {
    [Property in keyof ServiceAssessmentSchedule]: Partial<Schedule>;
};

export const ServiceAssessmentScheduleModal = (props: ServiceAssessmentScheduleModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({ isLoadingInitialData: true, isSubmitting: false });
    const [editableServiceAssessmentSchedule, setEditableServiceAssessmentSchedule] = useState<EditableServiceAssessmentSchedule>({});

    const onInherentRiskRatingScheduleChange = (inherentRiskRating: keyof ServiceAssessmentSchedule, scheduleNumber?: string, scheduleFrequency?: ScheduleFrequencyKeys) => {
        if (scheduleNumber && !/^[0-9]*$/.test(scheduleNumber)) return;

        // This object must be declared explicitly.
        // TypeScript does not type check computed properties and this object is set using a computed property within newRiskRatingToSchedule.
        const newSchedule: Partial<Schedule> = {
            schedule_number: scheduleNumber ? parseInt(scheduleNumber) : undefined,
            schedule_frequency: scheduleFrequency,
        };

        const newRiskRatingToSchedule: EditableServiceAssessmentSchedule = {
            [inherentRiskRating]: newSchedule,
        };

        setEditableServiceAssessmentSchedule({ ...editableServiceAssessmentSchedule, ...newRiskRatingToSchedule });
    };

    const onInherentRiskRatingScheduleClear = (inherentRiskRating: keyof ServiceAssessmentSchedule) => {
        const copiedServiceAssessmentSchedule = cloneDeep(editableServiceAssessmentSchedule);
        delete copiedServiceAssessmentSchedule[inherentRiskRating];
        setEditableServiceAssessmentSchedule(copiedServiceAssessmentSchedule);
    };

    useEffect(() => {
        const getServiceAssessmentSchedule = async () => {
            try {
                const serviceAssessmentScheduleResponse = await props.tprmApi.getServiceAssessmentSchedule();
                setEditableServiceAssessmentSchedule(serviceAssessmentScheduleResponse.data);
                setFormState({ isLoadingInitialData: false, isSubmitting: false });
            } catch (error) {
                handleRequestError(error);
            }
        };
        getServiceAssessmentSchedule();
    }, [props.tprmApi]);

    const handleRequestError = (error: Error) => {
        setFormState({ failureMessage: error.message, isLoadingInitialData: false, isSubmitting: false });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            setFormState({ isLoadingInitialData: false, isSubmitting: true });
            validate();
            // The validate function ensures this cast is safe by asserting that all child Schedule objects have values for both scheduleNumber and scheduleFrequency
            const validServiceAssessmentSchedule = editableServiceAssessmentSchedule as ServiceAssessmentSchedule;
            await props.tprmApi.saveServiceAssessmentSchedule(validServiceAssessmentSchedule);
            setFormState({ successMessage: 'Service assessment schedule saved.', isLoadingInitialData: false, isSubmitting: false });
        } catch (error) {
            handleRequestError(error);
        }
    };

    const validate = () => {
        for (const [, schedule] of Object.entries(editableServiceAssessmentSchedule)) {
            if ((!isUndefined(schedule.schedule_number) && isUndefined(schedule.schedule_frequency)) || (!isUndefined(schedule.schedule_frequency) && isUndefined(schedule.schedule_number))) {
                throw new ValidationError('If either frequency or interval is set for any risk rating, then both frequency and interval must be set.');
            }
        }
    };

    const commonRiskRatingScheduleProps: Pick<RiskRatingScheduleProps, 'onChange' | 'onClear' | 'disabled'> = {
        onChange: onInherentRiskRatingScheduleChange,
        onClear: onInherentRiskRatingScheduleClear,
        disabled: formState.isLoadingInitialData || formState.isSubmitting,
    };

    if (formState.isLoadingInitialData) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Service Assessment Schedule" secondaryText="Configure assessment schedule recommendations, based on inherent risk rating." />
                    <RiskRatingSchedule {...commonRiskRatingScheduleProps} label="Low" inherentRiskRating="low" scheduleNumber={editableServiceAssessmentSchedule.low?.schedule_number?.toString()} scheduleFrequency={editableServiceAssessmentSchedule.low?.schedule_frequency} />
                    <RiskRatingSchedule {...commonRiskRatingScheduleProps} label="Low/Moderate" inherentRiskRating="low_moderate" scheduleNumber={editableServiceAssessmentSchedule.low_moderate?.schedule_number?.toString()} scheduleFrequency={editableServiceAssessmentSchedule.low_moderate?.schedule_frequency} />
                    <RiskRatingSchedule {...commonRiskRatingScheduleProps} label="Moderate" inherentRiskRating="moderate" scheduleNumber={editableServiceAssessmentSchedule.moderate?.schedule_number?.toString()} scheduleFrequency={editableServiceAssessmentSchedule.moderate?.schedule_frequency} />
                    <RiskRatingSchedule {...commonRiskRatingScheduleProps} label="Moderate/High" inherentRiskRating="moderate_high" scheduleNumber={editableServiceAssessmentSchedule.moderate_high?.schedule_number?.toString()} scheduleFrequency={editableServiceAssessmentSchedule.moderate_high?.schedule_frequency} />
                    <RiskRatingSchedule {...commonRiskRatingScheduleProps} label="High" inherentRiskRating="high" scheduleNumber={editableServiceAssessmentSchedule.high?.schedule_number?.toString()} scheduleFrequency={editableServiceAssessmentSchedule.high?.schedule_frequency} />
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" isLoading={formState.isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
