import { faCheck, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type JSX, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { DDQApi } from 'Api/TPRM/DDQApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { UploadedFile } from 'Models/Files';
import { EffectivenessSelectOptions } from 'Models/OperationalControls';
import { Answer, QuestionType } from 'Models/TPRM';
import { ALLOW_ALL_ACTIONS, DocumentListing, DocumentListingProps } from 'Pages/TPRM/Components/DocumentListing/DocumentListing';

import { ControlKey, DDQStateAction, DDQStateActionControlFileDeselected, DDQStateActionControlFilesSelected, DDQStateActionControlScrolledOver, DDQStateActionFormFieldUpdated, DDQStateActionQuestionUpdated, DDQStateActionType, DDQStateControl, DDQStateControlSaveState, DeleteAnswerDocumentModalPayload, DeleteControlAssessmentDocumentModalPayload, QuestionKey, STICKY_HEADER_HEIGHT, getControlDisplayName, getFormIdForControl } from '../DueDiligenceQuestionnaire';
import styles from '../DueDiligenceQuestionnaire.module.css';
import { DDQQuestionProps, DocumentUploadDDQQuestion, FreeformDDQQuestion, MultiChoiceDDQQuestion, SingleSelectDDQQuestion } from './DDQQuestion/DDQQuestion';

export interface DDQControlProps {
    ddqApi: DDQApi;
    documentApi: DocumentApi;
    thirdPartyId: string;
    serviceId: string;
    control: DDQStateControl;
    isThirdParty: boolean;
    dueDiligenceIsNotInProgress: boolean;
    disabled: boolean;
    isStickyHeaderVisible: boolean;
    stickyHeaderKey?: ControlKey | QuestionKey;
    thirdPartySaveControl: (control: DDQStateControl, isManualSave: boolean) => void;
    clientSaveControl: (control: DDQStateControl, isManualSave: boolean) => void;
    dispatchDdqStateChange: (action: DDQStateAction) => void;
    showSuccessToast: (successMessage: string) => void;
    showFailureToast: (failureMessage: string) => void;
    onSelectAnswerDocumentToDelete: (payload: DeleteAnswerDocumentModalPayload) => void;
    onSelectControlAssessmentDocumentToDelete: (payload: DeleteControlAssessmentDocumentModalPayload) => void;
}

export const DDQControl = (props: DDQControlProps): JSX.Element => {
    const controlContainerElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (controlContainerElement && controlContainerElement.current) {
            const controlContainerElementTop = controlContainerElement.current.offsetTop;
            const controlContainerElementBottom = controlContainerElement.current.offsetTop + controlContainerElement.current.offsetHeight;
            const stickyHeaderOffset = props.isStickyHeaderVisible ? STICKY_HEADER_HEIGHT : 0;

            const onScroll = (event: Event) => {
                const action: DDQStateActionControlScrolledOver = {
                    type: DDQStateActionType.ControlScrolledOver,
                    payload: {
                        framework: props.control.framework,
                        groupId: props.control.groupId,
                        controlId: props.control.id,
                    },
                };

                // The top of the viewport plus space for the sticky header.
                const visiblePageYOffset = window.pageYOffset + stickyHeaderOffset;

                // Whether or not the control's information is already displayed within the sticky header. This prevents a dispatch from happening on every scroll within the control's area.
                const controlHeaderIsCurrentlySticky = props.stickyHeaderKey?.framework === props.control.framework && props.stickyHeaderKey?.groupId === props.control.groupId && props.stickyHeaderKey?.controlId === props.control.id;

                // Whether or not the top of the viewport is within the control's area.
                const visiblePageYOffsetIsInsideControl = visiblePageYOffset > controlContainerElementTop && visiblePageYOffset < controlContainerElementBottom;

                if (visiblePageYOffsetIsInsideControl && !controlHeaderIsCurrentlySticky) {
                    props.dispatchDdqStateChange(action);
                }
            };

            window.addEventListener('scroll', onScroll);

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, [props.isStickyHeaderVisible, props]);

    const onAnswerChanged = (questionId: string, answer?: Answer, additionalInformation?: string): void => {
        const action: DDQStateActionQuestionUpdated = {
            type: DDQStateActionType.QuestionUpdated,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                questionId: questionId,
                answer: answer,
                additionalInformation: additionalInformation,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (props.isThirdParty) {
            props.thirdPartySaveControl(props.control, true);
        } else {
            props.clientSaveControl(props.control, true);
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        const action: DDQStateActionFormFieldUpdated = {
            type: DDQStateActionType.FormFieldUpdated,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                fieldId: 'additionalInfo',
                value: event.currentTarget.value,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        const action: DDQStateActionFormFieldUpdated = {
            type: DDQStateActionType.FormFieldUpdated,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                fieldId: 'effectiveness',
                value: value as number,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const onSelectNewDocumentation = (files: File[]) => {
        const action: DDQStateActionControlFilesSelected = {
            type: DDQStateActionType.ControlFilesSelected,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                files: files,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const onDeselectNewDocumentation = (file: File) => {
        const action: DDQStateActionControlFileDeselected = {
            type: DDQStateActionType.ControlFileDeselected,
            payload: {
                framework: props.control.framework,
                groupId: props.control.groupId,
                controlId: props.control.id,
                file: file,
            },
        };

        props.dispatchDdqStateChange(action);
    };

    const onSelectControlAssessmentDocumentToDelete = (existingDocument: UploadedFile) => {
        props.onSelectControlAssessmentDocumentToDelete({
            document: existingDocument,
            controlFramework: props.control.framework,
            controlGroupId: props.control.groupId,
            controlId: props.control.id,
        });
    };

    const documentListingProps: DocumentListingProps = {
        dragAndDropInputId: `${props.control.id}-files`,
        dragAndDropLabelText: 'SUPPORTING DOCUMENTATION',
        documentApi: props.documentApi,
        existingDocuments: props.control.documents,
        newDocuments: props.control.newDocuments,
        actions: props.dueDiligenceIsNotInProgress ? ['download'] : ALLOW_ALL_ACTIONS,
        disableFileUpload: props.disabled,
        hideRemoveNewDocumentButton: true,
        disableFileOverflow: !props.dueDiligenceIsNotInProgress && props.disabled, // When a control is disabled because of the risk workflow is not in progress, the user should still be able to open a file overflow menu to download documents.
        onSelectNewDocuments: (files) => onSelectNewDocumentation(files),
        onDeselectNewDocument: (file) => onDeselectNewDocumentation(file),
        onSelectExistingDocumentToDelete: onSelectControlAssessmentDocumentToDelete,
    };

    const submitButtonDisabled = props.control.saveState === DDQStateControlSaveState.SAVING_AUTOMATICALLY || props.control.saveState === DDQStateControlSaveState.SAVING_MANUALLY || props.disabled;

    return (
        <div ref={controlContainerElement}>
            <div className={styles.controlHeaderContainer}>
                <div className={styles.controlHeaderContent}>
                    <div>
                        <div className={styles.controlIdAndStatus}>
                            <Text variant="Header2">{getControlDisplayName(props.control)}</Text>

                            {props.control.saveState !== DDQStateControlSaveState.UNCHANGED && (
                                <div className={styles.controlSaveStateIcon}>
                                    {props.control.saveState === DDQStateControlSaveState.CHANGED && <FontAwesomeIcon className={styles.edited} icon={ICON_EDIT_MODIFY_UPDATE} />}
                                    {(props.control.saveState === DDQStateControlSaveState.SAVING_AUTOMATICALLY || props.control.saveState === DDQStateControlSaveState.SAVING_MANUALLY) && <FontAwesomeIcon className={styles.saving} icon={faSync} />}
                                    {props.control.saveState === DDQStateControlSaveState.SAVED && <FontAwesomeIcon className={styles.saved} icon={faCheck} />}
                                    {props.control.saveState === DDQStateControlSaveState.ERROR && <FontAwesomeIcon className={styles.error} icon={ICON_CLOSE} />}
                                </div>
                            )}
                        </div>
                        <Text>{controlTextToString(props.control.controlText)}</Text>
                    </div>
                    <Button variant="submit" form={getFormIdForControl(props.control)} isLoading={props.control.saveState === DDQStateControlSaveState.SAVING_MANUALLY} disabled={submitButtonDisabled} loadingText="Saving...">
                        Save
                    </Button>
                </div>
                <hr />
            </div>
            <div>
                <Form id={getFormIdForControl(props.control)} onSubmit={handleSubmit}>
                    <div>
                        <PageCell>
                            <Text variant="Header2">Questions</Text>
                            <hr />
                            {Array.from(props.control.questions.values()).map((question, index) => {
                                const questionProps: DDQQuestionProps = {
                                    control: props.control,
                                    question: question,
                                    questionNumber: index,
                                    documentApi: props.documentApi,
                                    isThirdParty: props.isThirdParty,
                                    riskWorkflowIsNotInProgress: props.dueDiligenceIsNotInProgress,
                                    disabled: !props.isThirdParty || props.disabled,
                                    isStickyHeaderVisible: props.isStickyHeaderVisible,
                                    isScrolledOverQuestion: props.stickyHeaderKey !== undefined && 'questionId' in props.stickyHeaderKey && props.stickyHeaderKey.questionId === question.id,
                                    onAnswerChanged: onAnswerChanged,
                                    dispatchDdqStateChange: props.dispatchDdqStateChange,
                                    onSelectDocumentToDelete: props.onSelectAnswerDocumentToDelete,
                                };
                                if (question._type === QuestionType.FREEFORM) {
                                    return <FreeformDDQQuestion key={index} {...questionProps} />;
                                } else if (question._type === QuestionType.MULTIPLE_SELECT) {
                                    return <MultiChoiceDDQQuestion key={index} {...questionProps} />;
                                } else if (question._type === QuestionType.SINGLE_SELECT) {
                                    return <SingleSelectDDQQuestion key={index} {...questionProps} />;
                                } else {
                                    return <DocumentUploadDDQQuestion key={index} {...questionProps} />;
                                }
                            })}
                        </PageCell>
                    </div>
                    {!props.isThirdParty && (
                        <div>
                            <PageCell>
                                <Text variant="Header2">Assessment</Text>
                                <hr />
                                <Text variant="Header3">{`What is the overall effectiveness of ${props.control.isCustom ? props.control.controlName : props.control.id}?`}</Text>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldSelect disabled={props.disabled} options={EffectivenessSelectOptions} handleChange={handleSelectChange} formFieldId={`assessmentEffectiveness-${props.control.id}`} selectedOption={Number(props.control.effectiveness?.current)} formFieldLabel="Effectiveness" />;
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <DocumentListing {...documentListingProps} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldTextArea disabled={props.disabled} value={props.control.additionalInfo?.current} handleChange={handleChange} formFieldId={`additionalInfo-${props.control.id}`} formFieldLabel="Additional Information" />
                                </div>
                            </PageCell>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
};
