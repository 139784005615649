import { type JSX, useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { controlComparator, undefinedComparator } from 'Helpers/Compare';
import { controlTextToString, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ellipt } from 'Helpers/StringUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { OperationalControl, effectivenessAsString, getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';

import styles from './ControlTable.module.css';

export interface ControlTableProps {
    controls: OperationalControl[];
    limit?: number;
}

enum SortOption {
    CONTROL = 'CONTROL',
    EFFECTIVENESS = 'EFFECTIVENESS',
    LAST_UPDATED_DATE = 'LAST_UPDATED_DATE',
}

/**
 * Renders a sortable table of controls with "Control", "Effectiveness", and "Last Review Date" columns.
 */
export const ControlTable = (props: ControlTableProps): JSX.Element => {
    const [sortBy, setSortBy] = useState<SortOption>(SortOption.CONTROL);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const sortedControls = [...props.controls].sort((controlA, controlB) => {
        let sortResult: number;

        switch (sortBy) {
            case SortOption.CONTROL:
                sortResult = controlComparator(controlA, controlB);
                break;
            case SortOption.EFFECTIVENESS:
                sortResult = controlA.effectiveness.control_effectiveness > controlB.effectiveness.control_effectiveness ? 1 : -1;
                break;
            case SortOption.LAST_UPDATED_DATE:
                sortResult = undefinedComparator(controlA.effectiveness.last_updated, controlB.effectiveness.last_updated, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                break;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
    });

    const controlsToDisplay = props.limit !== undefined ? sortedControls.slice(0, props.limit) : sortedControls;

    const headerValues: HeaderData[] = [
        { dataKey: SortOption.CONTROL, label: 'CONTROL' },
        { dataKey: SortOption.EFFECTIVENESS, label: 'EFFECTIVENESS' },
        { dataKey: SortOption.LAST_UPDATED_DATE, label: 'LAST REVIEW DATE' },
    ];

    const tableHeaderProps: SortableTableHeaderProps = {
        headers: headerValues,
        setSortColumnAndDirection: (sortProperty: string, sortDirection: SortDirection) => {
            setSortBy(sortProperty as SortOption);
            setSortDirection(sortDirection);
        },
        currentSort: sortBy,
        currentSortDirection: sortDirection,
        tableIncludesOverflowMenu: false,
    };

    return (
        <Table>
            <SortableTableHeader {...tableHeaderProps} />
            <TableBody>
                {controlsToDisplay.map((control) => {
                    const controlIdentifier = control.identifier;

                    return (
                        <TableRow key={controlIdentifier}>
                            <TableCellDefaultText>
                                <Link size="sm" to={getFrameworkGroupControlURL(controlIdentifier)}>
                                    {getHumanReadableControlIdFromControl(control)}
                                </Link>
                                <Text noStyles variant="Text4">
                                    {ellipt(controlTextToString(control.metadata.control_text), 250)}
                                </Text>
                            </TableCellDefaultText>
                            <TableCell>
                                <div className={styles.controlEnvironmentEffectivenessContainer}>
                                    <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(control.effectiveness.control_effectiveness))} />
                                    <Text noStyles>{effectivenessAsString(control.effectiveness.control_effectiveness)}</Text>
                                </div>
                            </TableCell>
                            <TableCellDefaultText>{control.effectiveness.last_updated !== undefined && <Text noStyles>{iso8601ToUsDateShort(control.effectiveness.last_updated)}</Text>}</TableCellDefaultText>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
