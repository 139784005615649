import { isUndefined } from 'lodash-es';
import moment from 'moment';
import { type JSX, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { ICON_SAVE } from 'Config/Icons';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { submitRequestWithFiles } from 'Helpers/FileUtils';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { UploadedFile } from 'Models/Files';
import { ControlEffectivenessProgressBarVariantAndPercentage, ControlText, Effectiveness, EffectivenessSelectOptions, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { ScheduleFrequencyKeys } from 'Models/ScheduleFrequency';
import { Answer, MultipleSelectQuestionInstance, QuestionType, QuestionnaireReport, RiskRatingSelectOptions, Service, ServiceAssessmentState, SingleSelectQuestionInstance, UpdateServiceAssessmentRequest, riskRatingAsString } from 'Models/TPRM';
import { ALLOW_ALL_ACTIONS, DocumentListing, DocumentListingProps } from 'Pages/TPRM/Components/DocumentListing/DocumentListing';

import styles from './AssessmentAndReportTab.module.css';
import { ControlAssessmentAccordionControl, ControlAssessmentAccordionFramework, ControlAssessmentAccordionGroup, ControlAssessmentAccordionQuestion } from './ControlAssessmentAccordion/ControlAssessmentAccordion';
import { SubmitThirdPartyServiceAssessmentConfirmationModal, SubmitThirdPartyServiceAssessmentConfirmationModalProps } from './SubmitThirdPartyServiceAssessmentConfirmationModal/SubmitThirdPartyServiceAssessmentConfirmationModal';

export enum Modals {
    ConfirmationModal,
    None,
}

// TODO [TPRM - Final Review]: UIState is a tech-debt pattern. It introduces a pointless layer of abstraction and isn't implemented outside of TPRM.
interface UIState {
    thirdPartyId: string;
    serviceId: string;
    thirdPartyServiceTitle: string;
    effectivenessProgressBar: ProgressBarIndicatorProps;
    averageControlEffectiveness: string;
    report: Map<string, Framework>;
    inherentRisk: string;
    isNotInProgress: boolean;
}

export interface Framework {
    framework: string;
    frameworkName: string;
    frameworkVersion: string;
    groups: Map<string, Group>;
    effectiveness: Effectiveness;
}

export interface Group {
    framework: string;
    groupId: string;
    groupName: string;
    groupDescription?: string;
    controls: Map<string, Control>;
    displayText: string;
    effectiveness: Effectiveness;
}

export interface Control {
    framework: string;
    groupId: string;
    controlId: string;
    controlText: ControlText[];
    controlName?: string;
    questions: Map<number, Question>;
    displayText: string;
    effectiveness: Effectiveness;
}
export interface Question {
    _type: QuestionType;
    text: string;
    options?: string[];
    answerText?: Answer;
    answerIndex?: number;
    answerIndexes?: number[];
    answerDocuments: UploadedFile[];
}

interface FormFieldsState {
    additionalInformation?: string;
    overallEffectiveness?: number;
    residualRisk?: number;
    [index: string]: string | number | undefined;
}

interface FormState {
    isSaving: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export interface AssessmentAndReportTabProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
    serviceResponse: Service;
    questionnaireReport: QuestionnaireReport;
    onSaved: () => void;
    onDocumentDeleted: () => void;
}

const calculateRelativeDueDate = (scheduleNumber: string, scheduleFrequency: ScheduleFrequencyKeys): Date => {
    let momentKey: 'days' | 'months' | 'years';
    switch (scheduleFrequency) {
        case 'DAYS':
            momentKey = 'days';
            break;
        case 'MONTHS':
            momentKey = 'months';
            break;
        case 'YEARS':
            momentKey = 'years';
            break;
    }

    return moment().add(scheduleNumber, momentKey).toDate();
};

/**
 * Renders the "Assessment" tab on the "Final Review" page for a vendor service.
 * Includes the form to submit the final review, plus a "Control Assessment" section that has an accordion full of controls and questions, with corresponding vendor answers and control effectiveness ratings.
 */
export const AssessmentAndReportTab = (props: AssessmentAndReportTabProps): JSX.Element => {
    const [uiState, setUiState] = useState<UIState>();
    const [formFieldState, setFormFieldState] = useState<FormFieldsState>();
    const [formState, setFormState] = useState<FormState>({ isSaving: false, isSubmitting: false });
    const [nextAssessmentDate, setNextAssessmentDate] = useState<Date | undefined>(props.serviceResponse.assessment_workflow_setup.schedule ? calculateRelativeDueDate(props.serviceResponse.assessment_workflow_setup.schedule.schedule_number.toString(), props.serviceResponse.assessment_workflow_setup.schedule.schedule_frequency) : undefined);
    // You might wonder: if the user submits the form multiple times in a row, is it possible that the same document could be requested to be deleted multiple times in a row? The answer is "yes". But that's okay, because the backend doesn't care if things that no longer exist are requested to be deleted. So, there's no need to complicate state by resetting certain things but not others.
    const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);
    const [selectedDocuments, onSelectNewDocuments, onDeselectNewDocument, clearDocuments] = useFileDragAndDrop();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);

    const existingDocumentsToDisplay = props.serviceResponse.assessment_workflow_data.final_review_documents.filter((document) => !documentsToDelete.includes(document.file_id));

    // TODO [TPRM - Final Review]: This should probably be a useMemo, if we need this pattern at all. See the above note about UIState being tech debt.
    useEffect(() => {
        const formState: FormFieldsState = {
            additionalInformation: props.serviceResponse.assessment_workflow_data.final_review_additional_information ?? '',
            overallEffectiveness: props.serviceResponse.assessment_workflow_data.final_review_control_effectiveness ?? 0,
            residualRisk: props.serviceResponse.assessment_workflow_data.final_review_residual_risk_score,
        };
        setFormFieldState(formState);

        const report = new Map(
            props.questionnaireReport.control_frameworks.map((frameworkResponse) => {
                const groups = new Map(
                    frameworkResponse.control_groups.map((groupResponse) => {
                        const controls = new Map(
                            groupResponse.controls.map((controlResponse) => {
                                const controlDisplayText = controlResponse.is_custom ? controlResponse.control_name! : `${controlResponse.control_id}. ${controlTextToString(controlResponse.control_text)}`;
                                const questions = new Map(
                                    controlResponse.questions.map((questionResponse, index) => {
                                        const question: Question = {
                                            _type: questionResponse._type,
                                            text: questionResponse.text,
                                            answerText: questionResponse.answer_text,
                                            answerDocuments: questionResponse.answer_documents,
                                        };
                                        if (question._type === QuestionType.MULTIPLE_SELECT) {
                                            question.answerIndexes = (questionResponse as MultipleSelectQuestionInstance).answer_indexes;
                                            question.options = (questionResponse as MultipleSelectQuestionInstance).options;
                                        } else if (question._type === QuestionType.SINGLE_SELECT) {
                                            question.answerIndex = (questionResponse as SingleSelectQuestionInstance).answer_index;
                                            question.options = (questionResponse as SingleSelectQuestionInstance).options;
                                        }
                                        return [index, question];
                                    })
                                );
                                const control: Control = {
                                    framework: controlResponse.control_framework,
                                    groupId: controlResponse.control_group_id,
                                    controlId: controlResponse.control_id,
                                    controlText: controlResponse.control_text,
                                    controlName: controlResponse.control_name,
                                    questions: questions,
                                    effectiveness: controlResponse.control_assessment_effectiveness ?? Effectiveness.INACTIVE,
                                    displayText: controlDisplayText,
                                };

                                return [controlResponse.control_id, control];
                            })
                        );
                        const groupDisplayText = groupResponse.is_custom ? groupResponse.control_group_name : `${groupResponse.control_group_id}. ${groupResponse.control_group_name}`;
                        const group: Group = {
                            framework: groupResponse.control_framework,
                            groupId: groupResponse.control_group_id,
                            groupName: groupResponse.control_group_name,
                            groupDescription: groupResponse.control_group_description,
                            controls: controls,
                            displayText: groupDisplayText,
                            effectiveness: groupResponse.control_group_effectiveness,
                        };

                        return [groupResponse.control_group_id, group];
                    })
                );

                const framework: Framework = {
                    framework: frameworkResponse.control_framework,
                    frameworkName: frameworkResponse.control_framework_name,
                    frameworkVersion: frameworkResponse.control_framework_version,
                    groups: groups,
                    effectiveness: frameworkResponse.control_framework_effectiveness,
                };

                return [frameworkResponse.control_framework, framework];
            })
        );

        const uiState: UIState = {
            thirdPartyId: props.serviceResponse.vendor_id,
            serviceId: props.serviceResponse.id,
            thirdPartyServiceTitle: `${props.serviceResponse.vendor_name} - ${props.serviceResponse.name}`,
            effectivenessProgressBar: {
                ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.questionnaireReport.average_control_effectiveness)),
                size: 'large',
            },
            averageControlEffectiveness: numberAsEffectivenessString(props.questionnaireReport.average_control_effectiveness),
            report: report,
            inherentRisk: riskRatingAsString(props.serviceResponse.assessment_workflow_data.irq_inherent_risk_score ?? 0),
            isNotInProgress: props.serviceResponse.assessment_workflow_data.state !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
        };
        setUiState(uiState);
    }, [props.serviceResponse, props.questionnaireReport]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setFormFieldState({ ...formFieldState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, [formFieldId]: value as number });
    };

    const validateAndDisplayConfirmationModal = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formFieldState?.residualRisk) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: 'Residual risk is required.' });
            return;
        } else {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: undefined });
        }

        setDisplayedModal(Modals.ConfirmationModal);
    };

    const submitAssessment = () => {
        setFormState({ isSaving: false, isSubmitting: true });
        setDisplayedModal(Modals.None);
        updateAssessment(true);
    };

    const handleSave = () => {
        setFormState({ isSaving: true, isSubmitting: false });
        updateAssessment(false);
    };

    const updateAssessment = async (isSubmit: boolean) => {
        try {
            await submitRequestWithFiles(
                props.documentApi,
                selectedDocuments.map((document) => ({ file: document })),
                async (newDocumentation) => {
                    const modifyServiceRequest: UpdateServiceAssessmentRequest = {
                        is_submit: isSubmit,
                        file_updates: {
                            new_files: newDocumentation,
                            existing_files_to_delete: documentsToDelete,
                        },
                        assessment_additional_information: formFieldState?.additionalInformation,
                        assessment_control_effectiveness: formFieldState?.overallEffectiveness,
                        assessment_residual_risk_score: formFieldState?.residualRisk,
                        new_assessment_due_date: !isUndefined(nextAssessmentDate) && isSubmit ? jsDateToIso8601(nextAssessmentDate) : undefined,
                    };

                    await props.tprmApi.modifyServiceAssessment(modifyServiceRequest, props.serviceResponse.vendor_id, props.serviceResponse.id);
                }
            );

            setFormState({ isSaving: false, isSubmitting: false, successMessage: 'Assessment updated.' });
            clearDocuments();
            props.onSaved();
        } catch (error) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: error.message });
        }
    };

    if (formFieldState && uiState) {
        const documentListingProps: DocumentListingProps = {
            documentApi: props.documentApi,
            dragAndDropInputId: 'service-assessment-files',
            dragAndDropLabelText: 'SUPPORTING DOCUMENTATION',
            existingDocuments: existingDocumentsToDisplay,
            newDocuments: selectedDocuments,
            actions: uiState.isNotInProgress ? ['download'] : ALLOW_ALL_ACTIONS,
            onSelectNewDocuments: onSelectNewDocuments,
            onDeselectNewDocument: onDeselectNewDocument,
            onSelectExistingDocumentToDelete: (document) => setDocumentsToDelete([...documentsToDelete, document.file_id]),
        };

        const confirmationModalProps: SubmitThirdPartyServiceAssessmentConfirmationModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            onServiceAssessmentDueDateChanged: (dueDate?: Date) => setNextAssessmentDate(dueDate),
            serviceAssessmentDueDate: nextAssessmentDate,
            onConfirm: () => submitAssessment(),
            service: props.serviceResponse,
        };

        return (
            <>
                <PageCell>
                    {displayedModal === Modals.ConfirmationModal && <SubmitThirdPartyServiceAssessmentConfirmationModal {...confirmationModalProps} />}
                    {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                    {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                    {uiState.isNotInProgress && <Alert variant="warning">To make changes, first submit the inherent risk questionnaire.</Alert>}
                    <Text variant="Header2">Service Assessment</Text>
                    <hr />
                    <Text variant="Header3">This assessment will assign the following chosen effectiveness rating to the service.</Text>
                    <Form onSubmit={validateAndDisplayConfirmationModal}>
                        <div className={styles.margin10}>
                            <DocumentListing {...documentListingProps} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldTextArea disabled={uiState.isNotInProgress} formFieldId="additionalInformation" handleChange={handleChange} formFieldLabel="Additional Information" value={formFieldState.additionalInformation} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldSelect disabled={uiState.isNotInProgress} options={EffectivenessSelectOptions} handleChange={handleSelectChange} formFieldId={'overallEffectiveness'} selectedOption={formFieldState.overallEffectiveness} formFieldLabel={`Overall Control Effectiveness${props.serviceResponse.assessment_workflow_setup.common_assessment_parent ? ' (from common assessment)' : ''}`} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldSelect required disabled={uiState.isNotInProgress} options={RiskRatingSelectOptions} handleChange={handleSelectChange} formFieldId={'residualRisk'} selectedOption={formFieldState.residualRisk} formFieldLabel="Residual Risk" />
                        </div>
                        <div className={styles.buttons}>
                            <Button variant="secondary" onClick={handleSave} fontAwesomeImage={ICON_SAVE} disabled={formState.isSaving || formState.isSubmitting || uiState.isNotInProgress} isLoading={formState.isSaving} loadingText="Saving...">
                                Save
                            </Button>
                            <Button variant="submit" disabled={formState.isSaving || formState.isSubmitting || uiState.isNotInProgress} isLoading={formState.isSubmitting} loadingText="Submitting...">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </PageCell>
                <div className={styles.marginTop10}>
                    <PageCell>
                        <Text variant="Header2">{`Control Assessment${props.serviceResponse.assessment_workflow_setup.common_assessment_parent ? ' (from common assessment)' : ''}`}</Text>
                        <hr />
                        <div className={styles.accordionHeaderContainer}>
                            <Text variant="Text3" noStyles>
                                Framework/Control
                            </Text>
                            <div className={styles.accordionRightContent}>
                                <Text variant="Text3" noStyles>
                                    Effectiveness
                                </Text>
                            </div>
                        </div>
                        {Array.from(uiState.report.values()).map((framework: Framework, index: number) => (
                            <ControlAssessmentAccordionFramework key={index} framework={framework}>
                                {Array.from(framework.groups.values()).map((group: Group, index: number) => (
                                    <ControlAssessmentAccordionGroup key={index} group={group}>
                                        {Array.from(group.controls.values()).map((control: Control, index: number) => (
                                            <ControlAssessmentAccordionControl key={index} control={control}>
                                                {Array.from(control.questions.values()).map((question: Question, index: number) => (
                                                    <ControlAssessmentAccordionQuestion key={index} documentApi={props.documentApi} question={question} questionNumber={index} />
                                                ))}
                                            </ControlAssessmentAccordionControl>
                                        ))}
                                    </ControlAssessmentAccordionGroup>
                                ))}
                            </ControlAssessmentAccordionFramework>
                        ))}
                    </PageCell>
                </div>
            </>
        );
    } else {
        return <Placeholder />;
    }
};
