import { FormLabel as MUIFormLabel } from '@mui/material';
import type { JSX } from 'react';
import { FormLabel as BootstrapFormLabel } from 'react-bootstrap';

import { Colors } from 'Components/Colors';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';

interface FormLabelProps {
    color?: Colors;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: JSX.Element;
    children: string;
}

export const FormLabel = (props: FormLabelProps): JSX.Element => {
    return (
        <BootstrapFormLabel>
            <VisualLabel color={props.color} required={props.required && !props.hideRequiredSymbol} tooltip={props.tooltip}>
                {props.children}
            </VisualLabel>
        </BootstrapFormLabel>
    );
};

export interface MaterialFormLabelProps {
    children: string;
    color?: Colors;
    id?: string;
    required?: boolean;
}

/**
 * Renders a **semantic** label that labels a specific form field.
 * Differs from `FormLabel` in that this component is intended to be used with Material UI form fields.
 *
 * NOTE: Tooltips are currently not able to be included, because A) Our tooltips come from Bootstrap, and we should avoid mixing MUI and Bootstrap components, and B) At the time of writing, none of our components that use this flavor of label require tooltips.
 * * If tooltips are added to this component in the future, avoid passing in tooltips that use components from Bootstrap.
 */
export const MaterialFormLabel = (props: MaterialFormLabelProps): JSX.Element => {
    return (
        <MUIFormLabel id={props.id}>
            <VisualLabel color={props.color} required={props.required}>
                {props.children}
            </VisualLabel>
        </MUIFormLabel>
    );
};
