/*
	RiskRecon.tsx -- Modal for configuring the RiskRecon integration.
*/

import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

const INTEGRATION_NAME = IntegrationName.RISKRECON;

export interface ConfigureIntegrationRiskReconProps extends ConfigureIntegrationProps {
    clientName: string;
}

export const RiskRecon = (props: ConfigureIntegrationRiskReconProps): JSX.Element => {
    // Form fields.
    const [apiKey, setApiKey] = useState<string>();
    const [clientName, setClientName] = useState<string>(props.clientName);
    // Form states.
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    // Success/failure messages.
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm(`Are you sure you want to delete ${INTEGRATION_NAME}? \r\n\r\n All limits created for this integration will also be deleted.`);

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(INTEGRATION_NAME);
            setSuccessMessage(`${INTEGRATION_NAME} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(apiKey, clientName);
            await props.externalIntegrationsApi.setExternalIntegration(INTEGRATION_NAME, {
                api_key: apiKey!,
                client_name: clientName,
            });
            setSuccessMessage(`${INTEGRATION_NAME} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    const validateForm = (apiKey?: string, clientName?: string): void => {
        if (!apiKey || !/^([A-Za-z0-9-_=]+\.){2}[A-Za-z0-9-_=]+$/.test(apiKey)) {
            throw new ValidationError('Invalid API key.');
        }
        if (!clientName || clientName.length === 0) {
            throw new ValidationError('Invalid company name.');
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Configure RiskRecon Integration" />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="apiKey" formFieldLabel="API Key" formFieldType="password" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setApiKey(event.currentTarget.value)} required tooltip="The API key provided by RiskRecon." />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="clientName" formFieldLabel="Company Name" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setClientName(event.currentTarget.value)} required tooltip="This must match the name of your company as it appears in the RiskRecon portal so that SummIT Security can differentiate your company from your third parties in the RiskRecon data. It is pre-filled for convenience with your company's name as it appears in SummIT Security." value={clientName} />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={ICON_DELETE_REMOVE}>
                            Delete
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={ICON_CLOSE}>
                                    Close
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting} isLoading={isSubmitting} loadingText="Saving...">
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
