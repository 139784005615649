/*
	ControlListView.tsx presentation for a control rendered as a "list."
*/

import { type JSX, useEffect, useState } from 'react';

import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody } from 'Components/Table/Table/Table';
import { controlComparator } from 'Helpers/Compare';
import { OperationalControl } from 'Models/OperationalControls';

import { ControlTableRow } from './ControlTableRow/ControlTableRow';

const COLUMN_CONTROL_ID = 'COLUMN_CONTROL_ID';
const COLUMN_GROUP = 'COLUMN_GROUP';
const COLUMN_EFFECTIVENESS = 'COLUMN_EFFECTIVENESS';

export interface ControlListViewProps {
    controls: OperationalControl[];
}

export const ControlListView = (props: ControlListViewProps) => {
    const [sortBy, setSortBy] = useState(COLUMN_CONTROL_ID);
    const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
    const [sortedControls, setSortedControls] = useState<OperationalControl[]>([]);

    useEffect(() => {
        setSortedControls(
            [...props.controls].sort((controlA, controlB) => {
                let sortResult = 0;

                switch (sortBy) {
                    case COLUMN_CONTROL_ID:
                        sortResult = controlComparator(controlA, controlB);
                        break;
                    case COLUMN_EFFECTIVENESS:
                        sortResult = controlA.effectiveness.control_effectiveness > controlB.effectiveness.control_effectiveness ? 1 : -1;
                        break;
                }

                return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
            })
        );
    }, [props.controls, sortBy, sortDirection]);

    const headerValues: HeaderData[] = [
        { dataKey: COLUMN_CONTROL_ID, label: 'CONTROL ID' },
        { dataKey: COLUMN_GROUP, label: 'GROUP', disableSort: true },
        { dataKey: COLUMN_EFFECTIVENESS, label: 'EFFECTIVENESS' },
    ];

    const tableRow = (control: OperationalControl, index: number): JSX.Element => {
        return <ControlTableRow key={index} control={control} />;
    };

    const setSortColumnAndDirection = (sortBy: string, sortDirection: SortDirection): void => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        setSortColumnAndDirection: setSortColumnAndDirection,
        currentSort: sortBy,
        currentSortDirection: sortDirection,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortedControls.map(tableRow)}</TableBody>
        </Table>
    );
};
