import { faCheck, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { Fragment, type JSX, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { ArtificialIntelligenceApi } from 'Api/ArtificialIntelligence/ArtificialIntelligenceApi';
import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button, Link } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { clientHasAuthorizedLicense, useCachedData } from 'Components/Context/CachedDataContext';
import { ExceptionsListing, ExceptionsListingProps } from 'Components/Exceptions/ExceptionsListing/ExceptionsListing';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ControlBarData, ControlBarGraph } from 'Components/Graphs/ControlBarGraph';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { HorseshoeProgressIndicator } from 'Components/Indicator/HorseshoeProgressIndicator';
import { ProgressBarIndicator, ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { IssuesListing, IssuesListingProps } from 'Components/Issues/IssuesListing/IssuesListing';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDashboard } from 'Components/PageLayout/PageLayoutDashboard';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Step, StepLabel, Stepper } from 'Components/Stepper/Stepper';
import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_DELETE_REMOVE } from 'Config/Icons';
import { ASSESSMENTS, DASHBOARDS, DDQ, IRQ, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { TPRM_CONTROL_ASSESSMENT_NOT_READY_EXPLANATION, TPRM_CONTROL_ASSESSMENT_ZERO_CONTROLS_EXPLANATION, TPRM_DDQ_NOT_READY_EXPLANATION, TPRM_DDQ_ZERO_QUESTIONS_EXPLANATION, TPRM_FINAL_REVIEW_NOT_READY_EXPLANATION, TPRM_IRQ_TARGET_COMPLETION_DATE_START_ASSESSMENT_MODAL } from 'Config/Tooltips';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { iso8601ToJsDate, iso8601ToUsDateLong, iso8601ToUsDateShort, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getNextDueDate } from 'Helpers/ScheduleFrequency/ScheduleFrequency';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useThirdPartyMonitoringMetricValues } from 'Hooks/ExternalIntegrations';
import { License } from 'Models/ClientDetailsModel';
import { ExceptionResponse } from 'Models/Exceptions';
import { IssueResponse, IssuesExceptionsModule } from 'Models/Issues';
import { ControlEffectivenessProgressBarVariantAndPercentage, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { InherentRiskQuestionnaireProgress, InherentRiskQuestionnaireResponse, QuestionnaireAggregationResponse, RiskRating, ScheduleV2, Service, ServiceAssessmentState, ThirdPartyContact, ThirdPartyResponse, getRiskRatingVariantColor, numberAsRiskRatingString, numberAsRiskScore, riskRatingAsString } from 'Models/TPRM';

import { CommonAssessmentSelectionModal, CommonAssessmentSelectionModalProps } from './CommonAssessmentSelectionModal/CommonAssessmentSelectionModal';
import { ConfirmResetAndNotifyThirdPartyModal, ConfirmResetAndNotifyThirdPartyModalProps } from './ConfirmResetAndNotifyThirdPartyModal/ConfirmResetAndNotifyThirdPartyModal';
import { ConfirmUnlinkServicesModal, ConfirmUnlinkServicesModalProps } from './ConfirmUnlinkServicesModal/ConfirmUnlinkServicesModal';
import styles from './ServiceDashboard.module.css';
import { ThirdPartyContactModal } from './ThirdPartyContactModal/ThirdPartyContactModal';
import { ThirdPartyLocation } from './ThirdPartyLocation/ThirdPartyLocation';
import { AdvertiseAiTprmServiceAssessmentModal, ConfirmAiTprmServiceAssessmentModal } from './aiTprmServiceAssessmentModal/aiTprmServiceAssessmentModal';
import { CommonAssessment } from '../Components/CommonAssessment/CommonAssessment';
import { ThirdPartyMonitoring } from '../Components/ThirdPartyMonitoring/ThirdPartyMonitoring';

export enum PageElements {
    AdvertiseAiTprmServiceAssessmentModal,
    ConfirmAiTprmServiceAssessmentModal,
    ConfirmNotificationEmail,
    ConfirmStartNewWorkflowModal,
    ConfirmRestartWorkflowModal,
    ConfirmClearWorkflowModal,
    CommonAssessmentSelectionModal,
    UnlinkCommonAssessmentModal,
    ThirdPartyContactModal,
    ThirdPartyServiceContactModal,
    None,
}

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export interface ServiceDashboardRouteState {
    showConfirmNotificationEmailModal?: boolean;
}

export interface ServiceDashboardProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    tprmApi: TPRMApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    externalIntegrationsApi: ExternalIntegrationsApi;
    clientDetailsApi: ClientDetailsApi;
}

export const ServiceDashboard = (props: ServiceDashboardProps): JSX.Element => {
    const { service_id: serviceIdUrlParam, third_party_id: thirdPartyIdUrlParam } = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();
    const navigate = useNavigate();

    const cachedData = useCachedData();
    const [thirdPartyMonitoringMetricValues, thirdPartyMonitoringMetricValuesError] = useThirdPartyMonitoringMetricValues(thirdPartyIdUrlParam, props.externalIntegrationsApi);

    // These states will contain `null` if the request for data has completed, but no data exists.
    const [irqTargetCompletionSetting, setIrqTargetCompletionSetting] = useState<ScheduleV2 | null>();
    const [irqProgress, setIrqProgress] = useState<InherentRiskQuestionnaireProgress | null>();

    const [serviceResponse, setServiceResponse] = useState<Service>();
    const [thirdPartyResponse, setThirdPartyResponse] = useState<ThirdPartyResponse>();
    const [questionnaireAggregationResponse, setQuestionnaireAggregationResponse] = useState<QuestionnaireAggregationResponse>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [zeroStateText, setZeroStateText] = useState<string>();
    const [displayedElement, setDisplayedElement] = useState<PageElements>(PageElements.None);
    const [selectedItem, setSelectedItem] = useState<IssueResponse | ExceptionResponse | Service>();
    const [questionnaireAggregationIsLoaded, setQuestionnaireAggregationIsLoaded] = useState(false);
    const [selectedIrqTargetCompletionDate, setSelectedIrqTargetCompletionDate] = useState<Date>();

    const getInherentRiskQuestionnaireTargetCompletion = useCallback(async () => {
        try {
            const response = await props.tprmApi.getInherentRiskQuestionnaireTargetCompletion();
            setIrqTargetCompletionSetting(response.data ?? null);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [props.tprmApi]);

    const getServiceDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedServiceResponse = await props.tprmApi.getServiceDetails(thirdPartyIdUrlParam, serviceIdUrlParam);
            setServiceResponse(detailedServiceResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [serviceIdUrlParam, thirdPartyIdUrlParam, props.tprmApi]);

    const getThirdPartyDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(thirdPartyIdUrlParam);
            setThirdPartyResponse(detailedThirdPartyResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [props.tprmApi, thirdPartyIdUrlParam]);

    const getIrqProgress = useCallback(async (): Promise<void> => {
        try {
            const irqResponse = await props.tprmApi.getInherentRiskQuestionnaire(thirdPartyIdUrlParam, serviceIdUrlParam);
            setIrqProgress(irqResponse.data ? calculateInherentRiskQuestionnaireProgress(irqResponse.data) : null);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [serviceIdUrlParam, thirdPartyIdUrlParam, props.tprmApi]);

    /**
     * Initial network calls
     */
    useEffect(() => {
        getInherentRiskQuestionnaireTargetCompletion();
        getThirdPartyDetails();
        getServiceDetails();
        getIrqProgress();
    }, [getInherentRiskQuestionnaireTargetCompletion, getServiceDetails, serviceIdUrlParam, thirdPartyIdUrlParam, props.tprmApi, getThirdPartyDetails, getIrqProgress]);

    useEffect(() => {
        if (serviceResponse && serviceResponse.id !== serviceIdUrlParam) {
            /**
             * Reset state when service id params change.
             * This prop changes when a user clicks a link that redirects them to a different service dashboard.
             * React Router does not fully re-initialize the component. i.e. state is not re-initialized.
             * Resetting the values causes the placeholder animation to show while network calls are being made and state is being updated.
             * The client details and associated third party don't change when navigating between linked services. If a user navigates to a service associated with a different third party, the component is re-initialized.
             */
            setServiceResponse(undefined);
            setQuestionnaireAggregationIsLoaded(false);
        }

        // Show confirm notification email modal when contact third-party service button clicked from a child service.
        if (location.state && location.state.showConfirmNotificationEmailModal) {
            // Prevent showing the modal every time the page is reloaded.
            navigate(location.pathname, { replace: true });

            setDisplayedElement(PageElements.ConfirmNotificationEmail);
        }
    }, [location.pathname, location.state, navigate, serviceIdUrlParam, serviceResponse]);

    /**
     * Get questionnaire aggregation whenever service details are retrieved.
     * If a service has a common assessment parent, the parent's aggregation is retrieved.
     */
    useEffect(() => {
        const getQuestionnaireAggregation = async (): Promise<void> => {
            if (!serviceResponse) {
                return;
            }

            try {
                const thirdPartyId = serviceResponse.assessment_workflow_setup.common_assessment_parent?.vendor_id ?? serviceResponse.vendor_id;
                const serviceId = serviceResponse.assessment_workflow_setup.common_assessment_parent?.id ?? serviceResponse.id;

                const questionnaireAggregationResponse = await props.tprmApi.getQuestionnaireAggregation(thirdPartyId, serviceId);
                if (questionnaireAggregationResponse && 'data' in questionnaireAggregationResponse) {
                    setQuestionnaireAggregationResponse(questionnaireAggregationResponse.data);
                }
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTprmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            } finally {
                setQuestionnaireAggregationIsLoaded(true);
            }
        };

        getQuestionnaireAggregation();
    }, [serviceResponse, props.tprmApi]);

    const startNewAssessmentWorkflow = async (): Promise<string> => {
        await props.tprmApi.startNewAssessmentWorkflow(thirdPartyIdUrlParam, serviceIdUrlParam, { irq_target_completion_date: selectedIrqTargetCompletionDate ? jsDateToIso8601(selectedIrqTargetCompletionDate) : undefined });
        await getServiceDetails();
        await getIrqProgress();
        return 'Assessment workflow started.';
    };

    const restartAssessmentWorkflow = async (): Promise<string> => {
        await props.tprmApi.restartAssessmentWorkflow(thirdPartyIdUrlParam, serviceIdUrlParam, { irq_target_completion_date: selectedIrqTargetCompletionDate ? jsDateToIso8601(selectedIrqTargetCompletionDate) : undefined });
        await getServiceDetails();
        await getIrqProgress();
        return 'Assessment workflow restarted.';
    };

    const clearAssessmentWorkflow = async (): Promise<string> => {
        await props.tprmApi.clearAssessmentWorkflow(thirdPartyIdUrlParam, serviceIdUrlParam);
        await getServiceDetails();
        await getIrqProgress();
        return 'Assessment workflow cleared.';
    };

    const handleRequestError = (error: Error): void => setZeroStateText(error.message);

    const hideModal = () => {
        setDisplayedElement(PageElements.None);
        setSelectedItem(undefined);
    };

    const commonAssessmentSelectionModalProps = (service: Service): CommonAssessmentSelectionModalProps => {
        return {
            tprmApi: props.tprmApi,
            service: service,
            hideModal: hideModal,
            onComplete: getServiceDetails,
        };
    };

    const confirmUnlinkServicesModalProps = (service: Service): ConfirmUnlinkServicesModalProps => {
        return {
            tprmApi: props.tprmApi,
            service: service,
            hideModal: hideModal,
            onComplete: getServiceDetails,
        };
    };

    const displayControlsClicked = (item: string) => {
        return;
    };

    const aiTprmServiceAssessment = async (includeClientResponses: boolean, includeThirdPartyResponses: boolean): Promise<string> => {
        await props.artificialIntelligenceApi.aiTprmServiceAssessment({ include_client_responses: includeClientResponses, include_third_party_responses: includeThirdPartyResponses }, thirdPartyIdUrlParam, serviceIdUrlParam);
        return 'Control assessment suggestions are being created.';
    };

    /**
     * Renders the contents of the ThirdParty/ThirdParty Service information widget.
     */
    const renderThirdPartyAndThirdPartyServiceInformationWidget = (thirdParty: ThirdPartyResponse, thirdPartyService: Service): JSX.Element => {
        /**
         * Determine which ThirdParty contact information to display, based on how many contacts there are and what information is available.
         */
        const determineThirdPartyContactInformationToDisplay = (modalToOpen: PageElements.ThirdPartyContactModal | PageElements.ThirdPartyServiceContactModal, thirdPartyContacts: ThirdPartyContact[]): JSX.Element | JSX.Element[] => {
            // If there are no ThirdParty contacts, display a dash.
            if (thirdPartyContacts.length === 0) {
                return (
                    <Text variant="Text2" color="white">
                        -
                    </Text>
                );
            }

            // If there are is one ThirdParty contact, display all of its available contact information.
            else if (thirdPartyContacts.length === 1) {
                const contactInfo = [];
                if (thirdPartyContacts[0].name) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].name}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].name}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                if (thirdPartyContacts[0].email_address) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].email_address}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].email_address}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                if (thirdPartyContacts[0].phone_number) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].phone_number}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].phone_number}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                return <div className={styles.thirdPartyContactInformation}>{contactInfo}</div>;
            }

            // If there is more than one ThirdParty contact, display a link to a modal that contains all the contacts' information.
            else {
                return (
                    <Text>
                        <Button variant="linkText" size="lg" onClick={() => setDisplayedElement(modalToOpen)}>
                            {`Show ${thirdPartyContacts.length} Contacts`}
                        </Button>
                    </Text>
                );
            }
        };

        return (
            <div className={styles.smallSection}>
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    Third-Party Contact Information
                </Text>
                {determineThirdPartyContactInformationToDisplay(PageElements.ThirdPartyContactModal, thirdParty.vendor_contacts)}
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    Service Contact Information
                </Text>
                {determineThirdPartyContactInformationToDisplay(PageElements.ThirdPartyServiceContactModal, thirdPartyService.vendor_contacts)}
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    Service Description
                </Text>
                <Text variant="Text2" color="white">
                    {thirdPartyService.description}
                </Text>
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    Third-Party Location
                </Text>
                <ThirdPartyLocation {...thirdParty} />
                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                    Third-Party Website
                </Text>
                {thirdParty.website ? (
                    <Link openInNewTab size="lg" to={thirdParty.website}>
                        {thirdParty.website}
                    </Link>
                ) : (
                    <Text variant="Text2" color="white">
                        -
                    </Text>
                )}
            </div>
        );
    };

    /**
     * If Third-Party Risk Scoring is available, render it.
     * If an error was returned when querying Third-Party Risk Scoring data, show the error.
     * If neither, show nothing.
     */
    const renderThirdPartyMonitoringWidget = () => {
        if (thirdPartyMonitoringMetricValues && thirdPartyMonitoringMetricValues.length > 0) {
            return (
                <>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <ThirdPartyMonitoring thirdPartyRiskScores={thirdPartyMonitoringMetricValues} />
                </>
            );
        } else if (thirdPartyMonitoringMetricValuesError) {
            return (
                <>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <Text color="white">{thirdPartyMonitoringMetricValuesError.message}</Text>
                </>
            );
        }
    };

    if (tprmAccessDenied) {
        return <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }

    if (zeroStateText) {
        return <Text>{zeroStateText}</Text>;
    }

    // The service ids are compared to prevent any stale data being shown when the service id parameter changes.
    if (!(serviceResponse && serviceResponse.id === serviceIdUrlParam && thirdPartyResponse && questionnaireAggregationIsLoaded && irqProgress !== undefined && irqTargetCompletionSetting !== undefined)) {
        return <Placeholder />;
    }

    const calculateRecommendedIrqTargetCompletionDate = (irqTargetCompletionDateSetting: ScheduleV2 | null): Date | undefined => {
        if (!irqTargetCompletionDateSetting) {
            return undefined;
        }
        return iso8601ToJsDate(getNextDueDate(new Date(), irqTargetCompletionDateSetting.schedule_number.toString(), irqTargetCompletionDateSetting.schedule_frequency));
    };

    const populateRecommendedIrqTargetCompletionDateAsDefault = () => setSelectedIrqTargetCompletionDate(calculateRecommendedIrqTargetCompletionDate(irqTargetCompletionSetting));

    const confirmNotificationModalProps: ConfirmResetAndNotifyThirdPartyModalProps = {
        tprmApi: props.tprmApi,
        service: serviceResponse,
        hideModal: () => setDisplayedElement(PageElements.None),
        questionnaireHasQuestions: questionnaireAggregationResponse?.number_of_questions !== undefined && questionnaireAggregationResponse.number_of_questions > 0,
    };

    const onSelectLinkToParent = () => {
        setSelectedItem(cloneDeep(serviceResponse));
        setDisplayedElement(PageElements.CommonAssessmentSelectionModal);
    };

    const onSelectUnlinkFromParent = () => {
        setSelectedItem(cloneDeep(serviceResponse));
        setDisplayedElement(PageElements.UnlinkCommonAssessmentModal);
    };

    // IMPORTANT: This menu is referenced by the tooltips on the Inherent Risk Questionnaire page. If the functionality in this block of code is ever moved to somewhere else in the app, update those tooltips in `Tooltips.tsx`.
    const riskWorkflowOverflowMenu = (() => {
        if (serviceResponse.assessment_workflow_data.state === ServiceAssessmentState.AWAITING_ASSESSMENT) {
            return undefined;
        }

        return (
            <OverflowMenu
                overflowItems={[
                    {
                        text: 'Clear workflow',
                        onClickAction: () => {
                            populateRecommendedIrqTargetCompletionDateAsDefault();
                            setDisplayedElement(PageElements.ConfirmClearWorkflowModal);
                        },
                        icon: ICON_DELETE_REMOVE,
                    },
                    {
                        text: 'Restart workflow',
                        onClickAction: () => {
                            populateRecommendedIrqTargetCompletionDateAsDefault();
                            setDisplayedElement(PageElements.ConfirmRestartWorkflowModal);
                        },
                        icon: faRotateRight,
                    },
                ]}
                accessibilityTitle="Assessment Workflow Menu"
                overflowDotsColor="white"
            />
        );
    })();

    // TODO [TPRM - DDQ]: this logic probably needs to change once the vendor has the ability to notify that they're done with the DDQ.
    const getRiskWorkflowStep = (serviceResponse: Service, questionnaireAggregationResponse?: QuestionnaireAggregationResponse): number => {
        const irqHasBeenCompleted = serviceResponse.assessment_workflow_data.irq_completed_time !== undefined;
        const ddqHasBeenCompleted = questionnaireAggregationResponse && questionnaireAggregationResponse.number_of_questions === questionnaireAggregationResponse.number_of_questions_completed;
        const controlAssessmentHasBeenCompleted = questionnaireAggregationResponse && questionnaireAggregationResponse.number_of_controls === questionnaireAggregationResponse.number_of_control_assessments_completed;

        if (irqHasBeenCompleted && ddqHasBeenCompleted && controlAssessmentHasBeenCompleted) {
            return 3;
        } else if (irqHasBeenCompleted && ddqHasBeenCompleted) {
            return 2;
        } else if (irqHasBeenCompleted) {
            return 1;
        } else {
            return 0;
        }
    };

    const getEffectivenessSummary = (questionnaireAggregationResponse?: QuestionnaireAggregationResponse): ControlBarData => {
        return {
            inactive: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['0'] : 0,
            fail: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['1'] : 0,
            weak: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['2'] : 0,
            moderate: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['3'] : 0,
            strong: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['4'] : 0,
            robust: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['5'] : 0,
        };
    };

    const inherent_risk_score = serviceResponse.inherent_risk_score ?? 0;
    const thirdPartyServiceTitle = `${serviceResponse.vendor_name} - ${serviceResponse.name}`;
    const assessmentDueDateText = serviceResponse.assessment_workflow_setup.due_date ? iso8601ToUsDateLong(serviceResponse.assessment_workflow_setup.due_date) : '-';
    const lastAssessedDateText = serviceResponse.last_assessed ? iso8601ToUsDateLong(serviceResponse.last_assessed) : '-';
    const irqTargetCompletionDateText = serviceResponse.assessment_workflow_setup.irq_target_completion_date ? iso8601ToUsDateLong(serviceResponse.assessment_workflow_setup.irq_target_completion_date) : '-';
    const inherentRisk = numberAsRiskRatingString(inherent_risk_score);
    const inhereRiskVariant = getRiskRatingVariantColor(numberAsRiskScore(inherent_risk_score));
    const controlEffectivenessProgressBar: ProgressBarIndicatorProps | undefined = serviceResponse.assessment_workflow_data.final_review_control_effectiveness
        ? {
              ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(serviceResponse.assessment_workflow_data.final_review_control_effectiveness)),
              size: 'large',
          }
        : undefined;
    const controlEffectiveness = serviceResponse.control_effectiveness ? numberAsEffectivenessString(serviceResponse.control_effectiveness) : undefined;
    const residualRisk = serviceResponse.residual_risk_score ? numberAsRiskRatingString(serviceResponse.residual_risk_score) : undefined;
    const residualRiskVariant = serviceResponse.residual_risk_score ? getRiskRatingVariantColor(numberAsRiskScore(serviceResponse.residual_risk_score)) : undefined;
    const ddqPath = serviceResponse.assessment_workflow_setup.common_assessment_parent ? `/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.assessment_workflow_setup.common_assessment_parent.id}/${DDQ}` : `/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${DDQ}`;
    const thirdPartyManager = getUserNameFromSubject(thirdPartyResponse.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)!;
    const serviceManager = getUserNameFromSubject(serviceResponse.vendor_service_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)!;
    const riskWorkflowStep = getRiskWorkflowStep(serviceResponse, questionnaireAggregationResponse);

    const irqStatusLabel = (() => {
        switch (serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                return 'Not Ready';
            case ServiceAssessmentState.EVALUATING_INHERENT_RISK:
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return irqProgress ? `${irqProgress.numberOfQuestionsAnswered}/${irqProgress.numberOfQuestions} \n Questions \n Answered` : 'Not Ready';
        }
    })();

    const irqPercentComplete = irqProgress ? (irqProgress.numberOfQuestionsAnswered / irqProgress.numberOfQuestions) * 100 : 0;

    const irqDate = (() => {
        switch (serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                return undefined;
            case ServiceAssessmentState.EVALUATING_INHERENT_RISK:
                return iso8601ToUsDateShort(serviceResponse.assessment_workflow_data.irq_started_time!);
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return iso8601ToUsDateShort(serviceResponse.assessment_workflow_data.irq_completed_time!);
        }
    })();

    const ddqStatusLabel = serviceResponse.assessment_workflow_data.state === ServiceAssessmentState.PERFORMING_DUE_DILIGENCE && questionnaireAggregationResponse !== undefined ? `${questionnaireAggregationResponse.number_of_questions_completed}/${questionnaireAggregationResponse.number_of_questions} \n Questions \n Answered` : 'Not Ready';
    const ddqPercentComplete = questionnaireAggregationResponse ? (questionnaireAggregationResponse.number_of_questions_completed / questionnaireAggregationResponse.number_of_questions) * 100 : 0;
    const ddqDateStarted = questionnaireAggregationResponse?.due_diligence_started_time ? iso8601ToUsDateShort(questionnaireAggregationResponse.due_diligence_started_time) : '-';

    const hasParent = serviceResponse.assessment_workflow_setup.common_assessment_parent ? true : false;

    const assessmentStatusLabel = serviceResponse.assessment_workflow_data.state === ServiceAssessmentState.PERFORMING_DUE_DILIGENCE && questionnaireAggregationResponse !== undefined ? `${questionnaireAggregationResponse.number_of_control_assessments_completed}/${questionnaireAggregationResponse.number_of_controls} \n Controls \n Assessed` : 'Not Ready';
    const assessmentPercentComplete = questionnaireAggregationResponse ? (questionnaireAggregationResponse.number_of_control_assessments_completed / questionnaireAggregationResponse.number_of_controls) * 100 : 0;
    const assessmentDateStarted = questionnaireAggregationResponse?.control_assessment_started_time ? iso8601ToUsDateShort(questionnaireAggregationResponse.control_assessment_started_time) : '-';
    const reportPercentComplete = serviceResponse.last_assessed ? 100 : 0;

    const reportStatus = (() => {
        if (serviceResponse.assessment_workflow_data.state !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE) {
            return 'Not Ready';
        } else if (serviceResponse.assessment_workflow_data.final_review_additional_information !== undefined || serviceResponse.assessment_workflow_data.final_review_control_effectiveness !== undefined || serviceResponse.assessment_workflow_data.final_review_documents.length > 0 || serviceResponse.assessment_workflow_data.final_review_residual_risk_score !== undefined) {
            return 'In Progress';
        } else {
            return 'Not Started';
        }
    })();
    const reportDateCompleted = serviceResponse.last_assessed ? iso8601ToUsDateShort(serviceResponse.last_assessed) : '-';
    const effectivenessSummary = getEffectivenessSummary(questionnaireAggregationResponse);
    const issues = thirdPartyResponse.issues;
    const exceptions = thirdPartyResponse.exceptions;

    const issuesListingProps: IssuesListingProps = {
        type: IssuesExceptionsModule.TPRM,
        issues: issues,
        colorTheme: 'dark',
        disableThirdPartyFilter: true,
        preselectedThirdPartyIdForCreate: thirdPartyResponse?.id,
        preselectedThirdPartyIdForFilter: thirdPartyResponse?.id,
        thirdParties: thirdPartyResponse ? [thirdPartyResponse] : [],
    };

    const exceptionsListingProps: ExceptionsListingProps = {
        type: IssuesExceptionsModule.TPRM,
        exceptions: exceptions,
        colorTheme: 'dark',
        disableThirdPartyFilter: true,
        preselectedThirdPartyIdForCreate: thirdPartyResponse?.id,
        preselectedThirdPartyIdForFilter: thirdPartyResponse?.id,
        thirdParties: thirdPartyResponse ? [thirdPartyResponse] : [],
    };

    // TODO [TPRM - Service Dashboard]: These are pointless layers of abstraction, and "Not Ready" is a "magic string". Ideally, move all the risk workflow UI to a child component, and simply pass in the couple objects it needs to determine all of its display data.
    const controlAssessmentHasZeroControls = questionnaireAggregationResponse !== undefined && questionnaireAggregationResponse.number_of_controls === 0;
    const thirdPartyQuestionnaireHasZeroQuestions = questionnaireAggregationResponse !== undefined && questionnaireAggregationResponse.number_of_questions === 0;
    const ddqNotReady = ddqStatusLabel === 'Not Ready';
    const controlAssessmentNotReady = assessmentStatusLabel === 'Not Ready';
    const finalReviewNotReady = reportStatus === 'Not Ready';

    const riskWorkflow: JSX.Element = (() => {
        const startNewWorkflowButton = (
            <Button
                variant="linkText"
                size="sm"
                onClick={() => {
                    populateRecommendedIrqTargetCompletionDateAsDefault();
                    setDisplayedElement(PageElements.ConfirmStartNewWorkflowModal);
                }}
            >
                Start New Assessment Workflow
            </Button>
        );

        switch (serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                // TODO [TPRM - Service Dashboard]: This is hacky, but it is true that if the service has a last assessed date but no inherent risk, its workflow must have been cleared. Hiding these links doesn't solve the bug that has existed for years, where the user can't view past assessments if the workflow has been cleared. But it's better than showing the user links that lead only to errors.
                const wasCleared = serviceResponse.assessment_workflow_data.irq_inherent_risk_score === undefined;

                return (
                    <>
                        {startNewWorkflowButton}
                        {!wasCleared && serviceResponse.last_assessed && (
                            <>
                                <Link size="sm" to={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${IRQ}`}>
                                    View Inherent Risk Questionnaire
                                </Link>
                                <Link size="sm" to={ddqPath}>
                                    View Due Diligence Questionnaire / Control Assessment
                                </Link>
                                <Link size="sm" to={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${ASSESSMENTS}#reports`}>
                                    View Past Assessments
                                </Link>
                            </>
                        )}
                    </>
                );
            case ServiceAssessmentState.EVALUATING_INHERENT_RISK:
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return (
                    <>
                        <div>
                            <Stepper activeStep={riskWorkflowStep}>
                                <Step>
                                    <StepLabel>
                                        <div className={styles.stepLabelLinkButton}>
                                            <Link size="lg" to={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${IRQ}`}>
                                                Inherent Risk Questionnaire
                                            </Link>
                                        </div>
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        <div className={styles.stepLabelText}>
                                            <Text color="white" noStyles variant="Text2">
                                                Third-Party Questionnaire
                                            </Text>
                                            <div className={styles.questionStatus}>
                                                {ddqNotReady && <FormFieldTooltip text={TPRM_DDQ_NOT_READY_EXPLANATION} />}
                                                {thirdPartyQuestionnaireHasZeroQuestions && <FormFieldTooltip text={TPRM_DDQ_ZERO_QUESTIONS_EXPLANATION} />}
                                            </div>
                                        </div>
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        {!controlAssessmentHasZeroControls ? (
                                            <div className={styles.stepLabelLinkButton}>
                                                <Link size="lg" to={ddqPath} disabled>
                                                    Control Assessment
                                                </Link>
                                                <div className={styles.questionStatus}>
                                                    {controlAssessmentNotReady && <FormFieldTooltip text={TPRM_CONTROL_ASSESSMENT_NOT_READY_EXPLANATION} />}
                                                    {controlAssessmentHasZeroControls && <FormFieldTooltip text={TPRM_CONTROL_ASSESSMENT_ZERO_CONTROLS_EXPLANATION} />}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.stepLabelText}>
                                                <Text color="white" noStyles variant="Text2">
                                                    Control Assessment
                                                </Text>
                                                <div className={styles.questionStatus}>
                                                    {controlAssessmentNotReady && <FormFieldTooltip text={TPRM_CONTROL_ASSESSMENT_NOT_READY_EXPLANATION} />}
                                                    {controlAssessmentHasZeroControls && <FormFieldTooltip text={TPRM_CONTROL_ASSESSMENT_ZERO_CONTROLS_EXPLANATION} />}
                                                </div>
                                            </div>
                                        )}
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>
                                        <div className={styles.stepLabelLinkButton}>
                                            <Link size="lg" to={`/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${ASSESSMENTS}`}>
                                                Final Review
                                            </Link>
                                            <div className={styles.questionStatus}>{finalReviewNotReady && <FormFieldTooltip text={TPRM_FINAL_REVIEW_NOT_READY_EXPLANATION} />}</div>
                                        </div>
                                    </StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                        <div className={styles.workflow}>
                            <div className={styles.workflowSection}>
                                <div className={styles.progressBarContainer}>
                                    <HorseshoeProgressIndicator fill={irqPercentComplete} text={irqStatusLabel} />
                                </div>
                                {irqDate && (
                                    <div className={styles.workflowSection}>
                                        <Text variant="Header3" color="white">
                                            {serviceResponse.assessment_workflow_data.state === ServiceAssessmentState.EVALUATING_INHERENT_RISK ? 'Date Started' : 'Date Completed'}
                                        </Text>
                                        <Text variant="Text2" color="white">
                                            {irqDate}
                                        </Text>
                                    </div>
                                )}
                            </div>
                            <div className={styles.verticalLine}></div>
                            <div className={styles.workflowSection}>
                                <div className={`${styles.progressBarContainer}`}>
                                    <HorseshoeProgressIndicator fill={ddqPercentComplete} text={ddqStatusLabel} />
                                </div>
                                <div className={styles.workflowSection}>
                                    <Text variant="Header3" color="white">
                                        Date Started
                                    </Text>
                                    <Text variant="Text2" color="white">
                                        {ddqDateStarted}
                                    </Text>
                                </div>
                                {irqDate && (
                                    <div className={styles.notifyVSCLinkButton}>
                                        {hasParent && (
                                            <Link size="sm" to={{ pathname: `/${TPRM}/${THIRD_PARTIES}/${serviceResponse?.assessment_workflow_setup.common_assessment_parent?.vendor_id}/${SERVICES}/${serviceResponse?.assessment_workflow_setup.common_assessment_parent?.id}/${DASHBOARDS}` }} state={{ showConfirmNotificationEmailModal: true }}>
                                                Invite Common Assessment Third-Party Service Contact
                                            </Link>
                                        )}
                                        {serviceResponse.assessment_workflow_data.state === ServiceAssessmentState.PERFORMING_DUE_DILIGENCE && !hasParent && serviceResponse.vendor_contacts.some((third_party_contact) => third_party_contact.email_address) && (
                                            <Button variant="linkText" size="sm" onClick={() => setDisplayedElement(PageElements.ConfirmNotificationEmail)}>
                                                Invite Third-Party Service Contact
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={styles.workflowSection}>
                                <div className={`${styles.progressBarContainer}`}>
                                    <HorseshoeProgressIndicator fill={assessmentPercentComplete} text={assessmentStatusLabel} />
                                </div>
                                <div className={styles.workflowSection}>
                                    <Text variant="Header3" color="white">
                                        Date Started
                                    </Text>
                                    <Text variant="Text2" color="white">
                                        {assessmentDateStarted}
                                    </Text>
                                </div>
                                {cachedData && !controlAssessmentNotReady && (
                                    <Button variant="linkText" size="sm" onClick={clientHasAuthorizedLicense([License.AI_TPRM_SERVICE_ASSESSMENT], cachedData.clientDetails.licenses) ? () => setDisplayedElement(PageElements.ConfirmAiTprmServiceAssessmentModal) : () => setDisplayedElement(PageElements.AdvertiseAiTprmServiceAssessmentModal)}>
                                        SummIT Guide™
                                    </Button>
                                )}
                            </div>
                            <div className={styles.verticalLine}></div>
                            <div className={styles.workflowSection}>
                                <div className={styles.progressBarContainer}>
                                    <HorseshoeProgressIndicator fill={reportPercentComplete} text={`${reportStatus}`} />
                                </div>
                                <div className={styles.workflowSection}>
                                    <Text variant="Header3" color="white">
                                        Last Submitted
                                    </Text>
                                    <Text variant="Text2" color="white">
                                        {reportDateCompleted}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </>
                );
        }
    })();

    const irqTargetCompletionDatePicker = (
        <>
            <div className={styles.datePickerAndClearIcon}>
                <div className={styles.datePicker}>
                    <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={selectedIrqTargetCompletionDate} handleChange={setSelectedIrqTargetCompletionDate} formFieldId="nextAssessmentDate" formFieldLabel="Inherent Risk Questionnaire Target Completion Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} tooltip={TPRM_IRQ_TARGET_COMPLETION_DATE_START_ASSESSMENT_MODAL} />
                </div>
                <div className={styles.clearIcon}>
                    <IconButton aria-label="clear-date" onClick={() => setSelectedIrqTargetCompletionDate(undefined)} fontAwesomeImage={ICON_DELETE_REMOVE} />
                </div>
            </div>
        </>
    );

    return (
        <>
            {displayedElement === PageElements.AdvertiseAiTprmServiceAssessmentModal && <AdvertiseAiTprmServiceAssessmentModal hideModal={() => setDisplayedElement(PageElements.None)} />}
            {displayedElement === PageElements.ConfirmAiTprmServiceAssessmentModal && <ConfirmAiTprmServiceAssessmentModal aiTprmServiceAssessment={aiTprmServiceAssessment} hideModal={() => setDisplayedElement(PageElements.None)} />}
            {displayedElement === PageElements.ConfirmNotificationEmail && <ConfirmResetAndNotifyThirdPartyModal {...confirmNotificationModalProps} />}
            {displayedElement === PageElements.ConfirmStartNewWorkflowModal && (
                <ConfirmationModal operationType="nonDestructive" headerText="Start New Assessment Workflow" areYouSureText="Are you sure you want to start the third-party service assessment workflow?" buttonText="START" buttonLoadingText="Starting..." buttonIcon={faCheck} performOperation={startNewAssessmentWorkflow} hideModal={() => setDisplayedElement(PageElements.None)}>
                    <Text>This will generate a new inherent risk questionnaire based on the latest configuration.</Text>
                    {irqTargetCompletionDatePicker}
                </ConfirmationModal>
            )}
            {displayedElement === PageElements.ConfirmRestartWorkflowModal && (
                <ConfirmationModal operationType="nonDestructive" headerText="Restart Assessment Workflow" areYouSureText="Are you sure you want to restart the third-party service assessment workflow?" buttonText="RESTART" buttonLoadingText="Restarting..." buttonIcon={faRotateRight} performOperation={restartAssessmentWorkflow} hideModal={() => setDisplayedElement(PageElements.None)}>
                    <Text>This will return the assessment workflow to the first step (evaluating inherent risk), and the latest configuration will be applied to the inherent risk questionnaire. Third-party service contacts will lose access to the third-party questionnaire.</Text>
                    {irqTargetCompletionDatePicker}
                </ConfirmationModal>
            )}
            {displayedElement === PageElements.ConfirmClearWorkflowModal && (
                <ConfirmationModal operationType="destructive" headerText="Clear Assessment Workflow" areYouSureText="Are you sure you want to clear the third-party service assessment workflow?" buttonText="CLEAR" buttonLoadingText="Clearing..." buttonIcon={ICON_DELETE_REMOVE} performOperation={clearAssessmentWorkflow} hideModal={() => setDisplayedElement(PageElements.None)}>
                    <Text>All responses to the inherent risk questionnaire, third-party questionnaire, control assessment, and final review will be lost. The inherent risk rating, overall control effectiveness, and residual risk rating for the service will be cleared.</Text>
                </ConfirmationModal>
            )}
            {displayedElement === PageElements.CommonAssessmentSelectionModal && <CommonAssessmentSelectionModal {...commonAssessmentSelectionModalProps(selectedItem as Service)} />}
            {displayedElement === PageElements.UnlinkCommonAssessmentModal && <ConfirmUnlinkServicesModal {...confirmUnlinkServicesModalProps(selectedItem as Service)} />}
            {displayedElement === PageElements.ThirdPartyContactModal && <ThirdPartyContactModal header="Third-Party Contacts" hideModal={hideModal} thirdPartyContacts={thirdPartyResponse.vendor_contacts} />}
            {displayedElement === PageElements.ThirdPartyServiceContactModal && <ThirdPartyContactModal header="Third-Party Service Contacts" hideModal={hideModal} thirdPartyContacts={serviceResponse.vendor_contacts} />}

            <PageLayoutDashboard
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                        <BreadcrumbText>{thirdPartyServiceTitle}</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={thirdPartyServiceTitle}
                dashlets={[
                    {
                        dashlets: [
                            {
                                title: 'Assessment Summary',
                                content: (
                                    <div className={styles.riskAndEffectiveness}>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                INHERENT RISK
                                            </Text>
                                            {inherentRisk && inherentRisk !== riskRatingAsString(RiskRating.INACTIVE) ? (
                                                <div className={styles.riskScoreTextContainer}>
                                                    <CircleIndicator variant={inhereRiskVariant} />
                                                    <Text variant="Text1" color="white" noStyles>
                                                        {inherentRisk}
                                                    </Text>
                                                </div>
                                            ) : (
                                                <Text variant="Text3" color="white" noStyles>
                                                    Complete the inherent risk questionnaire to establish the inherent risk rating.
                                                </Text>
                                            )}
                                        </div>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                OVERALL CONTROL EFFECTIVENESS
                                            </Text>
                                            {controlEffectivenessProgressBar && <ProgressBarIndicator {...controlEffectivenessProgressBar} />}
                                            {controlEffectiveness ? (
                                                <Text variant="Text1" color="white" noStyles>
                                                    {controlEffectiveness}
                                                </Text>
                                            ) : (
                                                <Text variant="Text3" color="white" noStyles>
                                                    Complete the final review to establish the overall control effectiveness.
                                                </Text>
                                            )}
                                        </div>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                RESIDUAL RISK
                                            </Text>
                                            {residualRisk && residualRiskVariant ? (
                                                <div className={styles.riskScoreTextContainer}>
                                                    <CircleIndicator variant={residualRiskVariant} />
                                                    <Text variant="Text1" color="white" noStyles>
                                                        {residualRisk}
                                                    </Text>
                                                </div>
                                            ) : (
                                                <Text variant="Text3" color="white" noStyles>
                                                    Complete the final review to establish the residual risk rating.
                                                </Text>
                                            )}
                                        </div>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                Inherent Risk Questionnaire Target Completion Date
                                            </Text>
                                            <Text variant="Text2" color="white" noStyles>
                                                {irqTargetCompletionDateText}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                Assessment Due Date
                                            </Text>
                                            <Text variant="Text2" color="white" noStyles>
                                                {assessmentDueDateText}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                Last Assessed
                                            </Text>
                                            <Text variant="Text2" color="white" noStyles>
                                                {lastAssessedDateText}
                                            </Text>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                title: 'Contacts and Service Details',
                                content: renderThirdPartyAndThirdPartyServiceInformationWidget(thirdPartyResponse, serviceResponse),
                            },
                            {
                                dashlets: [
                                    {
                                        title: 'Internal Contacts',
                                        content: (
                                            <>
                                                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                    Third-Party Manager
                                                </Text>
                                                <Text variant="Text2" color="white">
                                                    {thirdPartyManager}
                                                </Text>
                                                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                                    Service Manager
                                                </Text>
                                                <Text variant="Text2" color="white">
                                                    {serviceManager}
                                                </Text>
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Common Assessment',
                                        content: <CommonAssessment service={serviceResponse} onSelectLinkToParent={onSelectLinkToParent} onSelectUnlinkFromParent={onSelectUnlinkFromParent} />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        content: (
                            <>
                                <div className={styles.assessmentWorkflowHeader}>
                                    <Text noStyles variant="Header2" color="white">
                                        Assessment Workflow
                                    </Text>
                                    {riskWorkflowOverflowMenu}
                                </div>
                                {riskWorkflow}
                            </>
                        ),
                    },
                    // TODO [TPRM - Service Dashboard]: Ideally, every widget in this Dashboard should be a separate component that makes it easy to interpret the structure defined here. Use Control Effectiveness Breakdown as a pristine example.
                    {
                        title: 'Control Effectiveness Breakdown',
                        content: <div className={styles.barGraphContainer}>{questionnaireAggregationResponse ? <ControlBarGraph controlBarData={effectivenessSummary} yAxisLabel="NUMBER OF CONTROLS" onItemClick={displayControlsClicked} /> : <Text color="white">The inherent risk questionnaire must be completed before you can view a summary of control effectiveness.</Text>}</div>,
                    },
                    // This hideous syntax is used to prevent rendering the Vendor Monitoring widget if there is no data to display.
                    ...(thirdPartyMonitoringMetricValues && thirdPartyMonitoringMetricValues.length > 0
                        ? [
                              {
                                  content: renderThirdPartyMonitoringWidget(),
                              },
                          ]
                        : []),
                    {
                        content: <IssuesListing {...issuesListingProps} />,
                    },
                    {
                        content: <ExceptionsListing {...exceptionsListingProps} />,
                    },
                ]}
            />
        </>
    );
};

export const calculateInherentRiskQuestionnaireProgress = (irq: InherentRiskQuestionnaireResponse): InherentRiskQuestionnaireProgress => {
    let numberOfQuestions = 0;
    let numberOfQuestionsAnswered = 0;

    irq.sections.forEach((section) => {
        if (!section.is_applicable) {
            numberOfQuestions += section.questions.length;
            numberOfQuestionsAnswered += section.questions.length;
        } else {
            section.questions.forEach((question) => {
                numberOfQuestions++;
                if (question.selected_answer_index !== undefined) {
                    numberOfQuestionsAnswered++;
                }
            });
        }
    });

    return {
        numberOfQuestions,
        numberOfQuestionsAnswered,
    };
};

export default ServiceDashboard;
