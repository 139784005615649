import { useState } from 'react';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ControlText, ControlTypeNatureValues, ControlTypeNatureValuesSelectOptions, ControlTypeTimingValues, ControlTypeTimingValuesSelectOptions, SaveControlRequest } from 'Models/OperationalControls';

import styles from './SaveControl.module.css';

export type SaveControlStatus = 'idle' | 'saving' | 'saved' | 'error';

interface SaveControlFormFieldsValues {
    controlName?: string;
    controlText?: ControlText[];
    controlTypeNature?: ControlTypeNatureValues;
    controlTypeTiming?: ControlTypeTimingValues;
}

export const useSaveControl = (controlFramework: string, controlGroupId: string, controlsApi: ControlsApi, defaultSaveControlFormFieldsValues: SaveControlFormFieldsValues, existingControlId?: string) => {
    const [saveControlStatus, setSaveControlStatus] = useState<SaveControlStatus>('idle');
    const [error, setError] = useState<Error>();
    const [saveControlFormFieldsValues, setSaveControlFormFieldsValues] = useState<SaveControlFormFieldsValues>({ ...defaultSaveControlFormFieldsValues });

    const save = async () => {
        setSaveControlStatus('saving');
        try {
            if (saveControlFormFieldsValues.controlName && saveControlFormFieldsValues.controlText && saveControlFormFieldsValues.controlTypeNature && saveControlFormFieldsValues.controlTypeTiming) {
                const createControlRequest: SaveControlRequest = {
                    control_name: saveControlFormFieldsValues.controlName,
                    control_text: saveControlFormFieldsValues.controlText,
                    control_type_nature: saveControlFormFieldsValues.controlTypeNature,
                    control_type_timing: saveControlFormFieldsValues.controlTypeTiming,
                };
                await controlsApi.saveCustomControl(controlFramework, controlGroupId, createControlRequest, existingControlId);
                setSaveControlStatus('saved');
            } else {
                setSaveControlStatus('error');
                setError(new Error('Error: Not all required fields have values.'));
            }
        } catch (err) {
            handleRequestError(err);
        }
    };

    const handleRequestError = (error: Error) => {
        setSaveControlStatus('error');
        setError(error);
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setSaveControlFormFieldsValues({ ...saveControlFormFieldsValues, [formFieldId]: value });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        if (event.currentTarget.name === 'controlText') {
            setSaveControlFormFieldsValues({
                ...saveControlFormFieldsValues,
                controlText: [{ text: event.currentTarget.value, children: [] }],
            });
        } else {
            setSaveControlFormFieldsValues({ ...saveControlFormFieldsValues, [event.currentTarget.name]: event.currentTarget.value });
        }
    };

    return {
        saveControlStatus: saveControlStatus,
        saveControlFormFieldsValues: saveControlFormFieldsValues,
        error: error,
        saveControl: save,
        handleChange: handleChange,
        handleSelectChange: handleSelectChange,
    };
};

export interface SaveControlFromFieldsProps {
    saveControlFormFieldsValues: SaveControlFormFieldsValues;
    handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
    handleSelectChange: (value: ChangeEventType, formFieldId: string) => void;
    disabled?: boolean;
}

export const SaveControlFormFields = ({ disabled = false, ...props }: SaveControlFromFieldsProps) => {
    const controlNatureTooltip = (
        <div className={styles.tooltip}>
            <div>
                <b>Administrative:</b> The control is a policy or procedure.
            </div>
            <br />
            <div>
                <b>Physical:</b> The control is a physical safeguard.
            </div>
            <br />
            <div>
                <b>Technical:</b> The control is a logical safeguard.
            </div>
        </div>
    );

    const controlTimingTooltip = (
        <div className={styles.tooltip}>
            <div>
                <b>Preventive:</b> The control will stop a security incident from occurring.
            </div>
            <br />
            <div>
                <b>Detective:</b> The control will identify a security incident that is occurring or has occurred.
            </div>
            <br />
            <div>
                <b>Corrective:</b> The control will limit damage caused by a security incident or allow recovery from a security incident.
            </div>
        </div>
    );

    return (
        <>
            <div className={styles.formFieldContainer}>
                <FormFieldText disabled={disabled} formFieldId="controlName" value={props.saveControlFormFieldsValues.controlName} formFieldLabel="Name" handleChange={props.handleChange} tooltip="The name used to identify this control." required />
            </div>
            <div className={styles.formFieldContainer}>
                <FormFieldTextArea disabled={disabled} formFieldId="controlText" value={props.saveControlFormFieldsValues.controlText?.[0]?.text} formFieldLabel="Description" handleChange={props.handleChange} tooltip="A description of what is assessed by this control." required />
            </div>
            <div className={styles.formContainerDetails}>
                <div className={styles.formFieldContainer}>
                    <FormFieldSelect disabled={disabled} formFieldId="controlTypeNature" selectedOption={props.saveControlFormFieldsValues.controlTypeNature} options={ControlTypeNatureValuesSelectOptions} handleChange={props.handleSelectChange} formFieldLabel="Control Nature" required tooltip={controlNatureTooltip} />
                </div>
                <div className={styles.formFieldContainer}>
                    <FormFieldSelect disabled={disabled} formFieldId="controlTypeTiming" selectedOption={props.saveControlFormFieldsValues.controlTypeTiming} options={ControlTypeTimingValuesSelectOptions} handleChange={props.handleSelectChange} formFieldLabel="Control Timing" required tooltip={controlTimingTooltip} />
                </div>
            </div>
        </>
    );
};
