import { cloneDeep } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE } from 'Config/Icons';
import { QuestionConfiguration, QuestionType, UpdateControlConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';
import { EditableQuestionOptions } from 'Pages/TPRM/Components/EditableQuestionOptions/EditableQuestionOptions';

import { Control, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface CreateQuestionModalProps extends QuestionnaireConfigurationModal {
    control: Control;
}

const ServiceListingSortOptions: OptionType[] = [
    {
        label: 'Freeform',
        value: QuestionType.FREEFORM,
    },
    {
        label: 'Single Select',
        value: QuestionType.SINGLE_SELECT,
    },
    {
        label: 'Multi Select',
        value: QuestionType.MULTIPLE_SELECT,
    },
    {
        label: 'Document Upload',
        value: QuestionType.DOCUMENT_UPLOAD,
    },
];

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    controlToUpdate?: UpdateControlConfigurationRequest;
    complete: boolean;
}

export const CreateQuestionModal = (props: CreateQuestionModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<Partial<QuestionConfiguration>>({ text: '', _type: QuestionType.FREEFORM });
    const [isValid, setIsValid] = useState<boolean>();
    const [newOptionText, setNewOptionText] = useState('');

    useEffect(() => {
        if (formFieldsState?.text) {
            if ((formFieldsState._type === QuestionType.SINGLE_SELECT || formFieldsState._type === QuestionType.MULTIPLE_SELECT) && (!formFieldsState.options || formFieldsState.options.length < 2)) {
                setIsValid(false);
                return;
            }
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState]);

    useEffect(() => {
        // Questions are added by appending to the existing list of questions on a control
        const updateControl = async (request: UpdateControlConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, props.control.framework, props.control.groupId, props.control.controlId)]));
                setFormState({ isLoading: false, successMessage: 'Question created.', complete: true });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.controlToUpdate && isValid) {
            updateControl(formState.controlToUpdate);
        }
    }, [props.tprmApi, formState.controlToUpdate, isValid, props.control, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.text && formFieldsState._type) {
            const controlToUpdate: UpdateControlConfigurationRequest = {
                questions: [
                    ...Array.from(props.control.questions.values()).map((question) => {
                        return {
                            _type: question._type,
                            text: question.text,
                            mapped_risk_ratings: question.riskRatings,
                            options: question.options,
                        };
                    }),
                    {
                        _type: formFieldsState._type,
                        text: formFieldsState.text,
                        mapped_risk_ratings: [],
                        options: formFieldsState.options,
                    },
                ],
            };
            setFormState({ isLoading: false, controlToUpdate: controlToUpdate, complete: false });
        }
    };

    const addNewOption = (): void => {
        const multipleChoiceItems = formFieldsState.options ? cloneDeep(formFieldsState.options) : [];
        if (newOptionText) {
            multipleChoiceItems.push(newOptionText);
        }
        setFormFieldsState({ ...formFieldsState, options: multipleChoiceItems });
        setNewOptionText('');
    };

    const removeOption = (itemToRemove: string): void => {
        const multipleChoiceItems = cloneDeep(formFieldsState.options);
        if (multipleChoiceItems) {
            const index = multipleChoiceItems.indexOf(itemToRemove);
            multipleChoiceItems.splice(index, 1);
        }
        setFormFieldsState({ ...formFieldsState, options: multipleChoiceItems });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Create Question Configuration" />
                    <FormFieldSelect options={ServiceListingSortOptions} handleChange={handleSelectChange} formFieldId="_type" formFieldLabel="Question Type" required selectedOption={formFieldsState._type} />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.text} formFieldId="text" formFieldLabel="Text" required handleChange={handleChange} />
                    </div>
                    {(formFieldsState._type === QuestionType.SINGLE_SELECT || formFieldsState._type === QuestionType.MULTIPLE_SELECT) && <EditableQuestionOptions options={formFieldsState.options} newOptionText={newOptionText} newOptionTextChanged={(text: string) => setNewOptionText(text)} removeOption={removeOption} addNewOption={addNewOption} />}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" disabled={!isValid || formState.complete} isLoading={formState.isLoading} loadingText="Creating...">
                            CREATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
