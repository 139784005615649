import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

import { ICON_EXPAND_COLLAPSE } from 'Config/Icons';

import styles from './AccordionToggle.module.css';

export interface AccordionToggleProps {
    eventKey: string;
    ariaLabelSuffix: string;
}

export const AccordionToggle = (props: AccordionToggleProps) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(props.eventKey);

    const directionStyle = activeEventKey ? styles.down : styles.right;
    const ariaLabelPrefix = activeEventKey ? 'collapse' : 'expand';

    return (
        <button type="button" onClick={decoratedOnClick} className={`${styles.icon} ${directionStyle}`} aria-label={`${ariaLabelPrefix} ${props.ariaLabelSuffix}`}>
            <FontAwesomeIcon icon={ICON_EXPAND_COLLAPSE} />
        </button>
    );
};
