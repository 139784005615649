/*
	RequirementListing.tsx -- Allows a user to view a list of Requirements for a specific Regulation.
*/

import type { JSX } from 'react';

import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { Text } from 'Components/Text/Text';
import { ComplianceRequirement, ComplianceRequirementsForRegulationResponse } from 'Models/ComplianceRequirements';

import styles from './Listing.module.css';
import { RequirementRow, RequirementRowProps } from './RequirementRow';

export interface RequirementListingProps {
    displayMappedControlsModal: (complianceRequirement: ComplianceRequirement) => void;
    regulationName: string;
    complianceRequirements?: ComplianceRequirementsForRegulationResponse;
}

export const RequirementListing = (props: RequirementListingProps): JSX.Element => {
    /**
     * The recursive function to display the nested accordion of Compliance Requirements.
     * @param requirementData The nested dict that contains all of the Compliance Requirements.
     * @param level Used to keep track of the current nesting level. It is pass-by-value so that the value "reverts" as the recursive function returns (to itself).
     * @param eventKey Unlike the pass-by-value parameter "level", eventKey is an object in order to force pass-by-reference. It is used to hold a unique identifier for each accordion that is created (Accordion.Toggle targets Accordion.Collapse via a unique eventKey).
     * @returns JSX.Element[]
     */
    const parseComplianceRequirements = (requirementData: ComplianceRequirementsForRegulationResponse, level: number, eventKey: Record<string, any>): JSX.Element[] => {
        level = level + 1;
        eventKey.value = eventKey.value + 1;
        const elements = [];

        // Loop through each Compliance Requirement at the current level.
        for (const key in requirementData) {
            // Set the props that the RequirementRow component needs.
            const requirementRowProps: RequirementRowProps = {
                displayMappedControlsModal: props.displayMappedControlsModal,
                requirementData: requirementData[key],
                eventKey: eventKey.value,
                indentLevel: level,
                regulationName: props.regulationName,
            };

            // Render the current Compliance Requirement and recurse if it has children.
            elements.push(
                <Accordion key={`${props.regulationName}-${requirementData[key]['attributes']['identifier']}`}>
                    <RequirementRow {...requirementRowProps}></RequirementRow>
                    {Object.keys(requirementData[key]['children']).length > 0 && (
                        <AccordionCollapse eventKey={eventKey.value}>
                            <div>{parseComplianceRequirements(requirementData[key]['children'], level, eventKey)}</div>
                        </AccordionCollapse>
                    )}
                </Accordion>
            );
        }

        return elements;
    };

    return (
        <div>
            <div className={styles.headerContainer}>
                <div className={styles.headerRequirement}>
                    <Text variant="Text3" noStyles>
                        REQUIREMENT
                    </Text>
                </div>
                <div className={styles.headerStatus}>
                    <Text variant="Text3" noStyles>
                        STATUS
                    </Text>
                </div>
                <div className={styles.headerLastAssessed}>
                    <Text variant="Text3" noStyles>
                        LAST UPDATED
                    </Text>
                </div>
                <div className={styles.headerControl}>
                    <Text variant="Text3" noStyles>
                        CONTROLS
                    </Text>
                </div>
                <div className={styles.headerOverflow} />
            </div>
            {props.complianceRequirements && parseComplianceRequirements(props.complianceRequirements, 0, { value: 0 })}
        </div>
    );
};
