import { faExclamationCircle, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import { Fragment, type JSX } from 'react';

import { FileState } from 'Models/Files';

import { GenericTooltip, IconColor } from './GenericTooltip';

interface FileStateToolTipProps {
    fileState?: FileState;
}

export const FileStateToolTip = (props: FileStateToolTipProps): JSX.Element => {
    switch (props.fileState) {
        case FileState.PROCESSING:
            return <GenericTooltip title="File Processing" text="This file is being scanned for malware. Please refresh the webpage in a few minutes to view the file." fontAwesomeIcon={faHourglassEnd} iconColor={IconColor.YELLOW} />;
        case FileState.FAILED_SIZE:
            return <GenericTooltip title="File Failed Size" text="This file failed to upload due to being empty or exceeding the file size limit. Please upload a different file." fontAwesomeIcon={faExclamationCircle} iconColor={IconColor.RED} />;
        case FileState.FAILED_MALWARE:
            return <GenericTooltip title="File Failed Malware" text="This file has been quarantined. Please contact High Peaks Solutions Support." fontAwesomeIcon={faExclamationCircle} iconColor={IconColor.RED} />;
        case FileState.FAILED_TYPE:
            return <GenericTooltip title="File Failed Type" text="This file failed to upload because it is not a valid type. Please upload a different file." fontAwesomeIcon={faExclamationCircle} iconColor={IconColor.RED} />;
        case FileState.FAILED_NAME:
            return <GenericTooltip title="File Failed Name" text="This file failed to upload because it contains invalid characters in the name. Valid characters include letters, numbers, spaces, and any of !_.'(),-" fontAwesomeIcon={faExclamationCircle} iconColor={IconColor.RED} />;
        case FileState.PASSED:
        default:
            return <Fragment />;
    }
};
