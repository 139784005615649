/*
	AwsConfig.tsx -- Modal for configuring the AWS Config integration.
*/

import { type JSX, useCallback, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { MultipleChoiceSelectionGroup } from 'Components/FormField/MultipleChoiceSelectionGroup/MultipleChoiceSelectionGroup';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { ValidationError } from 'Models/ErrorTypes';
import { AwsConfigIntegrationRequest, IntegrationName, awsRegions, awsRegionsSelectOptions } from 'Models/ExternalIntegrations';

import styles from '../ConfigureIntegration.module.css';

enum RequestsStatus {
    READY,
    CURRENT_INTEGRATION_REQUESTED,
    CURRENT_INTEGRATION_REQUEST_FAILED,
    UPDATE_REQUESTED,
    DELETE_REQUESTED,
}

interface FormFieldsState {
    managementAccountId: string;
    ouIdsCommaSeparated: string;
    homeRegion: string;
    selectedRegionIndexes: number[];
}

interface FormState {
    requestsStatus: RequestsStatus;
    successMessage?: string;
    failureMessage?: string;
}

const managementAccountIdFormLabel = 'AWS Management Account ID';
const ouIdsFormLabel = 'AWS Organizational Unit IDs';
const homeRegionFormLabel = 'AWS Home Region';
const regionsFormLabel = 'AWS Regions';

const regionOptions = awsRegions.map((region) => `${region.code} (${region.name})`);

export interface AwsConfigProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    hideModal: () => void;
}

export const AwsConfig = (props: AwsConfigProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({
        requestsStatus: RequestsStatus.CURRENT_INTEGRATION_REQUESTED,
    });
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({
        managementAccountId: '',
        ouIdsCommaSeparated: '',
        homeRegion: '',
        selectedRegionIndexes: [],
    });

    const handleRequestError = useCallback((err: Error): void => {
        setFormState({ requestsStatus: RequestsStatus.READY, failureMessage: err.message });
    }, []);

    useEffect(() => {
        const getIntegration = async (): Promise<void> => {
            try {
                const integrationAwsConfig = (await props.externalIntegrationsApi.getIntegrationAwsConfig()).data;
                setFormState({ requestsStatus: RequestsStatus.READY });

                if (integrationAwsConfig) {
                    const selectedRegionIndexes: number[] = [];

                    awsRegions.forEach((region, index) => {
                        if (integrationAwsConfig.regions.includes(region.code)) {
                            selectedRegionIndexes.push(index);
                        }
                    });

                    setFormFieldsState({ managementAccountId: integrationAwsConfig.management_account_id, ouIdsCommaSeparated: integrationAwsConfig.ou_ids.join(','), homeRegion: integrationAwsConfig.home_region, selectedRegionIndexes: selectedRegionIndexes });
                }
            } catch (err) {
                setFormState({ requestsStatus: RequestsStatus.CURRENT_INTEGRATION_REQUEST_FAILED, failureMessage: err.message });
            }
        };

        getIntegration();
    }, [handleRequestError, props.externalIntegrationsApi]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setFormState({ requestsStatus: RequestsStatus.UPDATE_REQUESTED });

        try {
            validateForm();

            const request: AwsConfigIntegrationRequest = {
                management_account_id: formFieldsState.managementAccountId!,
                ou_ids: formFieldsState.ouIdsCommaSeparated!.replace(/\s+/g, '').split(','),
                home_region: formFieldsState.homeRegion!,
                regions: formFieldsState.selectedRegionIndexes.map((index) => awsRegions[index].code),
            };

            await props.externalIntegrationsApi.setExternalIntegration(IntegrationName.AWS_CONFIG, request);
            setFormState({ requestsStatus: RequestsStatus.READY, successMessage: 'AWS Config integration configured.' });
        } catch (err) {
            setFormState({ requestsStatus: RequestsStatus.READY, failureMessage: err.message });
        }
    };

    const validateForm = (): void => {
        // Existence is checked here; regexes are checked server-side.
        if (!formFieldsState.managementAccountId) {
            throw new ValidationError(`Invalid ${managementAccountIdFormLabel}.`);
        }
        if (!formFieldsState.ouIdsCommaSeparated) {
            throw new ValidationError(`Invalid ${ouIdsFormLabel}.`);
        }
        if (!formFieldsState.homeRegion) {
            throw new ValidationError(`Invalid ${homeRegionFormLabel}.`);
        }
        if (formFieldsState.selectedRegionIndexes.length === 0) {
            throw new ValidationError(`${regionsFormLabel} must include at least one selection.`);
        }
    };

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        setFormState({ requestsStatus: RequestsStatus.DELETE_REQUESTED });
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.AWS_CONFIG);
            setFormState({ requestsStatus: RequestsStatus.READY, successMessage: 'AWS Config integration deleted.' });
        } catch (err) {
            handleRequestError(err);
        }
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    const onMultipleChoiceAnswerChange = (answers: number[]): void => {
        setFormFieldsState({ ...formFieldsState, selectedRegionIndexes: answers });
    };

    if (formState.requestsStatus === RequestsStatus.CURRENT_INTEGRATION_REQUESTED) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    const displayForm = formState.requestsStatus !== RequestsStatus.CURRENT_INTEGRATION_REQUEST_FAILED;

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <div>
                    {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                    {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                    <Form noValidate onSubmit={handleSubmit}>
                        <ModalHeader text="Configure AWS Config Integration" />

                        {displayForm && (
                            <>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText value={formFieldsState.managementAccountId} formFieldId="managementAccountId" formFieldLabel={managementAccountIdFormLabel} tooltip="The ID of your AWS Organizations management account. This account will be used to deploy AWS Config resources." handleChange={handleChange} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText value={formFieldsState.ouIdsCommaSeparated} formFieldId="ouIdsCommaSeparated" formFieldLabel={ouIdsFormLabel} tooltip="A comma-separated list of IDs of your AWS Organizations Organizational Units (OUs). All accounts that are part of these OUs will be polled for AWS Config metrics data." handleChange={handleChange} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldSelect options={awsRegionsSelectOptions} handleChange={handleSelectChange} tooltip="The AWS region where you do the most work. If you are using AWS Control Tower, this should be the same region as your AWS Control Tower home region." formFieldId={'homeRegion'} selectedOption={formFieldsState.homeRegion} formFieldLabel={homeRegionFormLabel} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <MultipleChoiceSelectionGroup columns={3} formFieldId="regions" formFieldLabel={regionsFormLabel} tooltip="The AWS regions where data for AWS Config metrics should be gathered. For global resources, us-east-1 region must be selected." options={regionOptions} selectedOptions={formFieldsState.selectedRegionIndexes} handleChange={onMultipleChoiceAnswerChange} />
                                </div>
                            </>
                        )}

                        <div className={styles.buttonRowContainer}>
                            {displayForm && (
                                <Button variant="danger" disabled={formState.requestsStatus !== RequestsStatus.READY} onClick={handleDelete} loadingText={'Deleting...'} isLoading={formState.requestsStatus === RequestsStatus.DELETE_REQUESTED} fontAwesomeImage={ICON_DELETE_REMOVE}>
                                    Delete
                                </Button>
                            )}
                            <div className={styles.buttonsRightContainer}>
                                <div className={styles.buttonPadding}>
                                    <Button variant="secondary" onClick={props.hideModal} disabled={formState.requestsStatus !== RequestsStatus.READY && formState.requestsStatus !== RequestsStatus.CURRENT_INTEGRATION_REQUEST_FAILED} fontAwesomeImage={ICON_CLOSE}>
                                        Close
                                    </Button>
                                </div>
                                {displayForm && (
                                    <Button variant="submit" disabled={formState.requestsStatus !== RequestsStatus.READY} isLoading={formState.requestsStatus === RequestsStatus.UPDATE_REQUESTED} loadingText="Saving...">
                                        Save
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
