import type { JSX } from 'react';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { FolderVersion } from 'Models/TPRM';

export interface DeleteFolderModalProps {
    tprmApi: TPRMApi;
    folder: FolderVersion;
    hideModal: () => void;
    thirdPartyId: string;
    onFolderDeleted: () => void;
}

export const DeleteFolderModal = (props: DeleteFolderModalProps): JSX.Element => {
    const deleteFolder = async (): Promise<string> => {
        try {
            await props.tprmApi.deleteFolder(props.thirdPartyId, props.folder.id);
            props.onFolderDeleted();
        } catch (error) {
            return error.message;
        }
        return 'Folder deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Folder" areYouSureText={`Are you sure you want to delete "${props.folder.name}"?`} performOperation={deleteFolder} hideModal={props.hideModal}>
            <Text>All documents within the folder will be deleted.</Text>
        </ConfirmationModal>
    );
};
