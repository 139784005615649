import { type JSX, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button, Link } from 'Components/Buttons/Buttons';
import { FileDragAndDropSingleMode, FileDragAndDropSingleModeProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping, MultipleControlMappingProps } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { CONFIGURATION } from 'Config/Paths';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { submitRequestWithFile } from 'Helpers/FileUtils';
import { validateUrl } from 'Helpers/InputValidation';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { useFileDragAndDropSingleMode } from 'Hooks/FileDragAndDrop';
import { AddEvidenceRequest } from 'Models/ControlEvidence';
import { ValidationError } from 'Models/ErrorTypes';
import { FileToBeUploaded } from 'Models/Files';
import { EffectivenessSelectOptions } from 'Models/OperationalControls';

import styles from './UploadControlEvidence.module.css';

export interface UploadControlEvidenceProps {
    controlsApi: ControlsApi;
    documentApi: DocumentApi;
}

export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

interface FormState {
    isAddingEvidence: boolean;
    evidenceAdded: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const UploadControlEvidence = (props: UploadControlEvidenceProps): JSX.Element => {
    const { controlFramework, controlGroupId, controlId } = useParams<keyof UrlParams>() as UrlParams;
    const controlIdentifier = getOperationalControlIdentifierString(controlFramework, controlGroupId, controlId);
    const [controlMappingItems, controlMappingItemsError, initialAssociatedControl] = useControlMappingItems(props.controlsApi, controlIdentifier);
    const [file, onSelectFile, onRemoveFile] = useFileDragAndDropSingleMode();

    const [formState, setFormState] = useState<FormState>({ isAddingEvidence: false, evidenceAdded: false });
    const [comment, setComment] = useState<string>();
    const [effectiveness, setEffectiveness] = useState<number | undefined>();
    const [externalUrl, setExternalUrl] = useState<string>();
    const [controlsToAssociate, setControlsToAssociate] = useState<string[]>([controlIdentifier]);

    const multipleControlMappingProps: MultipleControlMappingProps | undefined = useMemo(
        () =>
            controlMappingItems
                ? {
                      controls: controlMappingItems,
                      handleControlChange: (controls: string[]) => setControlsToAssociate(controls),
                      currentMappedControlIdentifiers: controlsToAssociate,
                  }
                : undefined,
        [controlMappingItems, controlsToAssociate]
    );

    const submitRequest = async (newDocumentation: FileToBeUploaded | undefined) => {
        const request: AddEvidenceRequest = {
            associated_controls: controlsToAssociate,
            evidence_comment: comment,
            evidence_effectiveness: effectiveness,
            external_url: externalUrl && externalUrl.length > 0 ? externalUrl : undefined,
            file_id: newDocumentation?.file_id,
            filename: newDocumentation?.filename,
        };

        validateRequest(request);

        await props.controlsApi.addEvidence(controlFramework, controlGroupId, controlId, request);
    };

    const addEvidence = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFormState({ isAddingEvidence: true, evidenceAdded: false, successMessage: undefined, failureMessage: undefined });

        try {
            if (file) {
                await submitRequestWithFile(props.documentApi, { file: file }, submitRequest);
            } else {
                await submitRequest(undefined);
            }

            setFormState({ successMessage: 'Evidence uploaded.', failureMessage: undefined, evidenceAdded: true, isAddingEvidence: false });
        } catch (error) {
            setFormState({ successMessage: undefined, failureMessage: error.message, evidenceAdded: false, isAddingEvidence: false });
        }
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setEffectiveness(value as number);
    };

    const disableEffectiveness = (): boolean => !(comment || file);

    /**
     * Validate the request before submitting it.
     */
    const validateRequest = (request: AddEvidenceRequest): void => {
        if (request.external_url && request.external_url.length > 0) {
            const validateUrlResult = validateUrl(request.external_url);
            if (validateUrlResult['valid'] !== true) {
                throw new ValidationError(validateUrlResult['message']);
            }
        }
    };

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (controlMappingItems && controlMappingItems.length === 0) {
        return (
            <div className={styles.textButtonContainer}>
                <Text noStyles>You must enable a control to upload control evidence.</Text>;
                <Link size={'sm'} to={`/${CONFIGURATION}#companySettings`}>
                    Enable Controls
                </Link>
            </div>
        );
    } else if (multipleControlMappingProps && initialAssociatedControl && controlMappingItems && controlMappingItems.length > 0) {
        const fileDragAndDropProps: FileDragAndDropSingleModeProps = {
            labelText: 'File',
            inputId: 'controlEvidence',
            onSelectFile: onSelectFile,
            onRemoveFile: onRemoveFile,
            file: file,
        };

        return (
            <>
                {formState.successMessage && <LinkButtonToast variant="success" clearToast={() => setFormState({ isAddingEvidence: false, successMessage: undefined, evidenceAdded: formState.evidenceAdded })} linkButtonText={'Return to control'} linkButtonTo={`${getFrameworkGroupControlURL(initialAssociatedControl.identifier)}#evidence`} text={formState.successMessage} />}
                {formState.failureMessage && <TextToast clearToast={() => setFormState({ isAddingEvidence: false, failureMessage: undefined, evidenceAdded: formState.evidenceAdded })} text={formState.failureMessage} variant="failure" />}
                <PageLayoutDefault
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                            <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{initialAssociatedControl!.metadata.control_group_name}</BreadcrumbLink>
                            <BreadcrumbLink link={`${getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}#${controlId}`)}#evidence`}>{initialAssociatedControl.metadata.is_custom && initialAssociatedControl.metadata.control_name ? initialAssociatedControl.metadata.control_name : controlId}</BreadcrumbLink>
                            <BreadcrumbText>Evidence</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle="Submit Evidence"
                    body={[
                        {
                            title: 'Evidence Details',
                            content: (
                                <Form noValidate onSubmit={addEvidence}>
                                    <FileDragAndDropSingleMode {...fileDragAndDropProps} />
                                    <div>
                                        <FormFieldTextArea value={comment} handleChange={(event: React.FormEvent<HTMLInputElement>): void => setComment(event.currentTarget.value)} formFieldId="comment" formFieldLabel="Comment" />
                                    </div>
                                    <div>
                                        <FormFieldText handleChange={(event: React.FormEvent<HTMLInputElement>): void => setExternalUrl(event.currentTarget.value)} formFieldId="externalUrl" formFieldLabel="Link" tooltip={`An optional hyperlink to the evidence.`} value={externalUrl ?? ''} />
                                    </div>
                                    <div className={styles.effectivenessContainer}>
                                        <FormFieldSelect disabled={disableEffectiveness()} options={EffectivenessSelectOptions} handleChange={handleSelectChange} formFieldId="effectiveness" selectedOption={effectiveness} formFieldLabel="Effectiveness" tooltip={'Effectiveness of the control based on this evidence. This will not set the effectiveness of the control but will inform the reviewer in determining the control effectiveness'} />
                                    </div>
                                    <ModalHeader text="Map Evidence to Controls" />
                                    <MultipleControlMapping {...multipleControlMappingProps} />
                                    <div className={styles.submitButton}>
                                        <Button variant="submit" disabled={formState.evidenceAdded} isLoading={formState.isAddingEvidence} loadingText="Saving...">
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else return <Placeholder />;
};
