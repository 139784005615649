import { type JSX, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { ExceptionsListing, ExceptionsListingProps } from 'Components/Exceptions/ExceptionsListing/ExceptionsListing';
import { ProgressBarIndicator, ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { IssuesListing, IssuesListingProps } from 'Components/Issues/IssuesListing/IssuesListing';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { useThirdPartyMonitoringMetricValues } from 'Hooks/ExternalIntegrations';
import { sortGenericControlHierarchy } from 'Models/ControlHierarchy';
import { ThirdPartyExceptionResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule, ThirdPartyIssueResponse } from 'Models/Issues';
import { ControlEffectivenessProgressBarVariantAndPercentage, Effectiveness, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { QuestionnaireReport, RiskWorkflowArchive, Service, ThirdPartyResponse, riskRatingAsString } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import { AssessmentAndReportTab } from './AssessmentAndReportTab/AssessmentAndReportTab';
import { ReportListingTab } from './ReportListingTab/ReportListingTab';
import styles from './ServiceControlAssessment.module.css';
import { ThirdPartyMonitoring } from '../Components/ThirdPartyMonitoring/ThirdPartyMonitoring';

export enum PageElements {
    ManageExceptionModal,
    RequestExceptionClosureModal,
    ClosedExceptionModal,
    None,
}
export interface UrlParams {
    service_id: string;
    third_party_id: string;
}
export interface ServiceControlAssessmentProps {
    tprmApi: TPRMApi;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    exceptionsApi: ExceptionsApi;
    externalIntegrationsApi: ExternalIntegrationsApi;
}

interface UIState {
    thirdPartyId: string;
    serviceId: string;
    thirdPartyServiceTitle: string;
    effectivenessProgressBar: ProgressBarIndicatorProps;
    serviceEffectiveness: string;
    inherentRisk: string;
    users: UserResponse[];
    issues: ThirdPartyIssueResponse[];
    exceptions: ThirdPartyExceptionResponse[];
}

const tabKeys = ['assessment', 'issues', 'reports'];

export const ServiceControlAssessment = (props: ServiceControlAssessmentProps): JSX.Element => {
    const { service_id, third_party_id } = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();
    const navigate = useNavigate();

    const [serviceResponse, setServiceResponse] = useState<Service>();
    const [thirdPartyResponse, setThirdPartyResponse] = useState<ThirdPartyResponse>();
    const [controlAssessmentReportResponse, setControlAssessmentReportResponse] = useState<RiskWorkflowArchive[]>();

    const [questionnaireReport, setQuestionnaireReport] = useState<QuestionnaireReport>();
    const cachedData = useCachedData();
    const [thirdPartyMonitoringMetricValues, thirdPartyMonitoringMetricValuesError] = useThirdPartyMonitoringMetricValues(third_party_id, props.externalIntegrationsApi);

    const [uiState, setUiState] = useState<UIState>();
    const [selectedTab, setSelectedTab] = useState<string>(() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (tabKeys.includes(strippedHash)) {
                return strippedHash;
            }
        }

        return 'assessment';
    });
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [zeroStateText, setZeroStateText] = useState<string>();

    const getServiceDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedServiceResponse = await props.tprmApi.getServiceDetails(third_party_id, service_id);
            setServiceResponse(detailedServiceResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [service_id, third_party_id, props.tprmApi]);

    const getAssessmentSummaryReports = useCallback(async () => {
        try {
            const assessmentSummaryReports = await props.tprmApi.getAllAssessmentSummaryReports(third_party_id, service_id);
            setControlAssessmentReportResponse(assessmentSummaryReports.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.tprmApi, service_id, third_party_id]);

    useEffect(() => {
        getServiceDetails();
        getAssessmentSummaryReports();
    }, [getServiceDetails, getAssessmentSummaryReports, props.tprmApi]);

    const getThirdPartyDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(third_party_id);
            setThirdPartyResponse(detailedThirdPartyResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTprmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [props.tprmApi, third_party_id]);

    useEffect(() => {
        getThirdPartyDetails();
    }, [getThirdPartyDetails, props.tprmApi, third_party_id]);

    useEffect(() => {
        const getQuestionnaireReport = async (): Promise<void> => {
            try {
                const thirdPartyId = serviceResponse!.assessment_workflow_setup.common_assessment_parent?.vendor_id ?? serviceResponse!.vendor_id;
                const serviceId = serviceResponse!.assessment_workflow_setup.common_assessment_parent?.id ?? serviceResponse!.id;

                const questionnaireReport = await props.tprmApi.getQuestionnaireReport(thirdPartyId, serviceId);

                if (questionnaireReport.data !== undefined) {
                    sortGenericControlHierarchy(questionnaireReport.data.control_frameworks);
                    setQuestionnaireReport(questionnaireReport.data);
                } else {
                    throw new Error('Either there is no inherent risk rating for this service, or the third-party questionnaire has not been configured.');
                }
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTprmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            }
        };

        if (serviceResponse) {
            getQuestionnaireReport();
        }
    }, [serviceResponse, props.tprmApi]);

    useEffect(() => {
        const buildState = (serviceResponse: Service, thirdPartyResponse: ThirdPartyResponse, questionnaireReport: QuestionnaireReport, users: UserResponse[]) => {
            const serviceEffectiveness = serviceResponse.assessment_workflow_data.final_review_control_effectiveness ?? Effectiveness.INACTIVE;

            const uiState: UIState = {
                thirdPartyId: serviceResponse.vendor_id,
                serviceId: serviceResponse.id,
                thirdPartyServiceTitle: `${serviceResponse.vendor_name} - ${serviceResponse.name}`,
                effectivenessProgressBar: {
                    ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(serviceEffectiveness)),
                    size: 'large',
                },
                serviceEffectiveness: numberAsEffectivenessString(serviceEffectiveness),
                inherentRisk: riskRatingAsString(serviceResponse.assessment_workflow_data.irq_inherent_risk_score ?? 0),
                issues: thirdPartyResponse.issues,
                exceptions: thirdPartyResponse.exceptions,
                users: users,
            };
            setUiState(uiState);
        };
        if (serviceResponse && thirdPartyResponse && questionnaireReport && cachedData.users) {
            buildState(serviceResponse, thirdPartyResponse, questionnaireReport, cachedData.users);
        }
    }, [thirdPartyResponse, serviceResponse, questionnaireReport, cachedData.users]);

    useEffect(() => {
        navigate(`${location.pathname}#${selectedTab}`, { replace: true });
    }, [location.pathname, navigate, selectedTab]);

    const handleRequestError = (error: Error): void => setZeroStateText(error.message);

    const onTabSelected = (key?: string): void => {
        if (key) {
            setSelectedTab(key);
        }
    };

    if (tprmAccessDenied) {
        return <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }
    if (zeroStateText) {
        return <Text>{zeroStateText}</Text>;
    }

    /**
     * If Third-Party Risk Scoring is available, render it.
     * If an error was returned when querying Third-Party Risk Scoring data, show the error.
     * If neither, show nothing.
     */
    const renderThirdPartyMonitoringWidget = () => {
        if (thirdPartyMonitoringMetricValues && thirdPartyMonitoringMetricValues.length > 0) {
            return (
                <div className={styles.dashboardSection}>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <PageCell variant="transparentBlue">
                        <ThirdPartyMonitoring thirdPartyRiskScores={thirdPartyMonitoringMetricValues} />
                    </PageCell>
                </div>
            );
        } else if (thirdPartyMonitoringMetricValuesError) {
            return (
                <div className={styles.dashboardSection}>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <PageCell variant="transparentBlue">
                        <Text color="white">{thirdPartyMonitoringMetricValuesError.message}</Text>
                    </PageCell>
                </div>
            );
        }
    };

    if (uiState && questionnaireReport && serviceResponse && thirdPartyResponse && controlAssessmentReportResponse) {
        const issuesListingProps: IssuesListingProps = {
            type: IssuesExceptionsModule.TPRM,
            issues: uiState.issues,
            thirdParties: [thirdPartyResponse],
            preselectedThirdPartyIdForFilter: thirdPartyResponse.id,
            preselectedThirdPartyIdForCreate: thirdPartyResponse.id,
            disableThirdPartyFilter: true,
        };

        const exceptionsListingProps: ExceptionsListingProps = {
            type: IssuesExceptionsModule.TPRM,
            exceptions: uiState.exceptions,
            thirdParties: [thirdPartyResponse],
            preselectedThirdPartyIdForFilter: thirdPartyResponse.id,
            preselectedThirdPartyIdForCreate: thirdPartyResponse.id,
            disableThirdPartyFilter: true,
        };

        return (
            <>
                <PageBackground color="blueMountains">
                    <div className={styles.paddingBottom80}>
                        <PageContent>
                            <Breadcrumb textColor="white">
                                <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                                <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${uiState.thirdPartyId}/${SERVICES}/${uiState.serviceId}/${DASHBOARDS}`}>{uiState.thirdPartyServiceTitle}</BreadcrumbLink>
                                <BreadcrumbText>Final Review</BreadcrumbText>
                            </Breadcrumb>
                            <Text variant="Header1" color="white">
                                Final Review
                            </Text>
                            <div className={styles.header}>
                                <div className={`${styles.serviceEffectivenessCell} ${styles.margin10}`}>
                                    <PageCell variant="transparentBlue">
                                        <Text variant="Header3" color="white">
                                            Overall Control Effectiveness
                                        </Text>
                                        <ProgressBarIndicator {...uiState.effectivenessProgressBar} />
                                        <Text variant="Text1" color="white">
                                            {uiState.serviceEffectiveness}
                                        </Text>
                                    </PageCell>
                                </div>
                                <div className={styles.smallHeaderCell}>
                                    <PageCell variant="transparentBlue">
                                        <div className={styles.smallHeaderContent}>
                                            <Text variant="Text4" color="white" toUppercase>
                                                INHERENT RISK
                                            </Text>
                                            <Text variant="Text1" color="white">
                                                {uiState.inherentRisk}
                                            </Text>
                                        </div>
                                    </PageCell>
                                </div>
                                <div className={styles.smallHeaderCell}>
                                    <PageCell variant="transparentBlue">
                                        <div className={styles.smallHeaderContent}>
                                            <Text variant="Text4" color="white" toUppercase>
                                                ISSUES
                                            </Text>
                                            <Text variant="Text1" color="white">
                                                {uiState.issues.length}
                                            </Text>
                                        </div>
                                    </PageCell>
                                </div>
                                <div className={styles.smallHeaderCell}>
                                    <PageCell variant="transparentBlue">
                                        <div className={styles.smallHeaderContent}>
                                            <Text variant="Text4" color="white" toUppercase>
                                                EXCEPTIONS
                                            </Text>
                                            <Text variant="Text1" color="white">
                                                {uiState.exceptions.length}
                                            </Text>
                                        </div>
                                    </PageCell>
                                </div>
                            </div>
                            <div>{renderThirdPartyMonitoringWidget()}</div>
                        </PageContent>
                    </div>
                </PageBackground>
                <PageBackground color="white">
                    <PrimaryTabs defaultActiveTab={selectedTab} onSelect={onTabSelected}>
                        <Tab eventKey="assessment" title="Assessment">
                            <PageContent>
                                <AssessmentAndReportTab
                                    tprmApi={props.tprmApi}
                                    documentApi={props.documentApi}
                                    serviceResponse={serviceResponse}
                                    questionnaireReport={questionnaireReport}
                                    onSaved={() => {
                                        getServiceDetails();
                                        getAssessmentSummaryReports();
                                    }}
                                    onDocumentDeleted={getServiceDetails}
                                />
                            </PageContent>
                        </Tab>
                        <Tab eventKey="issues" title="Issues and Exceptions">
                            <PageContent>
                                <>
                                    <IssuesListing {...issuesListingProps} />
                                    <div className={styles.exceptionsContainer}>
                                        <ExceptionsListing {...exceptionsListingProps} />
                                    </div>
                                </>
                            </PageContent>
                        </Tab>
                        <Tab eventKey="reports" title="Past Assessments">
                            <PageContent>
                                <Text variant="Header2">Past Assessments</Text>
                                <ReportListingTab controlAssessmentReports={controlAssessmentReportResponse} />
                            </PageContent>
                        </Tab>
                    </PrimaryTabs>
                </PageBackground>
            </>
        );
    } else {
        return <Placeholder />;
    }
};
