import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { PageLayoutDefaultGridAndTable } from 'Components/PageLayout/PageLayoutDefaultGridAndTable';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { FOLDERS, SERVICES, THIRD_PARTIES, THIRD_PARTY_ID, TPRM } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { ThirdPartyListingSortProperty, ThirdPartyResponseWithServices } from 'Models/TPRM';
import { Filter, Filters } from 'Models/Types/GlobalType';

import { DeleteTPRMThirdPartyModal, DeleteTPRMThirdPartyModalProps } from './DeleteTPRMThirdPartyModal/DeleteTPRMThirdPartyModal';
import styles from './ManageTPRMThirdParties.module.css';
import { SaveTPRMThirdPartyModal, SaveTPRMThirdPartyModalProps } from './SaveTPRMThirdPartyModal/SaveTPRMThirdPartyModal';
import { ManageTPRMServicesRouteState } from '../ManageTPRMServices/ManageTPRMServices';
import { ThirdPartyCard } from './ThirdPartyListing/ThirdPartyCard/ThirdPartyCard';
import { ThirdPartyListingBodyToolbar, ThirdPartyListingBodyToolbarProps } from './ThirdPartyListing/ThirdPartyListingBodyToolbar/ThirdPartyListingBodyToolbar';
import { ThirdPartyListingTableView, ThirdPartyListingTableViewProps } from './ThirdPartyListing/ThirdPartyListingTableView/ThirdPartyListingTableView';

export interface ManageTPRMThirdPartiesProps {
    tprmApi: TPRMApi;
}

export enum Modal {
    DeleteTPRMThirdPartyModal,
    SaveTPRMThirdPartyModal,
    None,
}

export const ManageTPRMThirdParties = (props: ManageTPRMThirdPartiesProps) => {
    const cachedData = useCachedData();
    const navigate = useNavigate();

    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [currentlySortedBy, setCurrentlySortedBy] = useState<string>(ThirdPartyListingSortProperty.NAME);
    const [displayedModal, setDisplayedModal] = useState<Modal>();
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>({});
    const [selectedThirdParty, setSelectedThirdParty] = useState<ThirdPartyResponseWithServices>();
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();

    const getThirdParties = useCallback(async (): Promise<void> => {
        try {
            const thirdPartyResponse = await props.tprmApi.getThirdParties();
            setThirdParties(thirdPartyResponse.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.tprmApi]);

    useEffect(() => {
        getThirdParties();
    }, [getThirdParties]);

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentlySortedBy(newSort as ThirdPartyListingSortProperty);
        setCurrentSortDirection(newSortDirection);
    };

    const setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const newSelectedFilterOptions = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    newSelectedFilterOptions[filter.key] = filter;
                } else {
                    delete newSelectedFilterOptions[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                newSelectedFilterOptions[updatedFilter.key] = updatedFilter;
            } else {
                delete newSelectedFilterOptions[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(newSelectedFilterOptions);
    };

    const filterAndSortThirdParties = (thirdParties: ThirdPartyResponseWithServices[]): ThirdPartyResponseWithServices[] => {
        let filteredThirdParties = thirdParties;
        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                filteredThirdParties = filteredThirdParties.filter((thirdParty) => {
                    return filter.value.includes(thirdParty[filter.key]);
                });
            }
        }
        if (!currentlySortedBy) {
            return filteredThirdParties;
        }

        return filteredThirdParties.sort((thirdPartyA, thirdPartyB) => {
            let sortResult = 0;
            switch (currentlySortedBy) {
                case ThirdPartyListingSortProperty.NAME:
                    sortResult = thirdPartyA.name.localeCompare(thirdPartyB.name);
                    break;
                case ThirdPartyListingSortProperty.CREATED_TIME:
                    sortResult = thirdPartyA.created_time < thirdPartyB.created_time ? 1 : -1;
                    break;
                case ThirdPartyListingSortProperty.THIRD_PARTY_MANAGER_USER_ID:
                    sortResult = compareUsersBySubjectForSorting(thirdPartyA.vendor_manager_user_id, thirdPartyB.vendor_manager_user_id, cachedData.users);
                    break;
                case ThirdPartyListingSortProperty.WEBSITE:
                    sortResult = (thirdPartyA.website ?? '').localeCompare(thirdPartyB.website ?? '');
                    break;
                default:
                    break;
            }

            return currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const hideModal = (): void => {
        setDisplayedModal(Modal.None);
        setSelectedThirdParty(undefined);
    };

    const selectedModifyThirdParty = (thirdPartyId: string): void => {
        if (thirdParties) {
            const thirdParty = thirdParties.find((v) => v.id === thirdPartyId);
            setDisplayedModal(Modal.SaveTPRMThirdPartyModal);
            setSelectedThirdParty(thirdParty);
        }
    };

    const selectedDeleteThirdParty = (thirdPartyId: string): void => {
        if (thirdParties) {
            const thirdParty = thirdParties.find((v) => v.id === thirdPartyId);
            setDisplayedModal(Modal.DeleteTPRMThirdPartyModal);
            setSelectedThirdParty(thirdParty);
        }
    };

    const getDeleteTPRMThirdPartyModalProps = (): DeleteTPRMThirdPartyModalProps => {
        const deleteTPRMThirdPartyModalProps: DeleteTPRMThirdPartyModalProps = {
            hideModal: hideModal,
            thirdPartyDeleted: () => getThirdParties(),
            tprmApi: props.tprmApi,
            thirdParty: selectedThirdParty!,
        };

        return deleteTPRMThirdPartyModalProps;
    };

    const createServiceForThirdParty = (thirdParty: ThirdPartyResponseWithServices): void => {
        const state: ManageTPRMServicesRouteState = { createServiceForThirdParty: thirdParty };
        navigate(`/${TPRM}/${SERVICES}`, { state: state });
    };

    const viewAllServicesForThirdParty = (thirdParty: ThirdPartyResponseWithServices): void => {
        navigate(`/${TPRM}/${SERVICES}?${THIRD_PARTY_ID}=${thirdParty.id}`);
    };

    const viewFolders = (thirdPartyId: string): void => {
        navigate(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}`);
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (thirdParties) {
        const filteredAndSortedThirdParties = filterAndSortThirdParties(thirdParties);

        const thirdPartyListingBodyToolbarProps: ThirdPartyListingBodyToolbarProps = {
            filterThirdParties: setCurrentFilterSelection,
            sortCardsBy: setCurrentSort,
            currentlySortedBy,
        };

        const saveTPRMThirdPartyModalProps: SaveTPRMThirdPartyModalProps = {
            hideModal: hideModal,
            tprmApi: props.tprmApi,
            thirdParty: selectedThirdParty,
            thirdPartySaved: () => getThirdParties(),
        };

        const thirdPartyListingTableViewProps: ThirdPartyListingTableViewProps = {
            thirdParties: filteredAndSortedThirdParties,
            setSortColumnAndDirection: setCurrentSort,
            currentlySortedBy: currentlySortedBy,
            currentSortDirection: currentSortDirection,
            selectedModifyThirdParty: selectedModifyThirdParty,
            selectedDeleteThirdParty: selectedDeleteThirdParty,
            selectedCreateServiceForThirdParty: createServiceForThirdParty,
            selectedViewAllServicesForThirdParty: viewAllServicesForThirdParty,
            selectedViewFolders: viewFolders,
        };

        return (
            <>
                {displayedModal === Modal.SaveTPRMThirdPartyModal && <SaveTPRMThirdPartyModal {...saveTPRMThirdPartyModalProps} />}
                {displayedModal === Modal.DeleteTPRMThirdPartyModal && <DeleteTPRMThirdPartyModal {...getDeleteTPRMThirdPartyModalProps()} />}
                <PageLayoutDefaultGridAndTable
                    headerTitle="Manage Third Parties"
                    headerButtons={
                        <Button variant="primary" onClick={() => setDisplayedModal(Modal.SaveTPRMThirdPartyModal)} fontAwesomeImage={ICON_ADD_CREATE}>
                            Create New Third Party
                        </Button>
                    }
                    bodyFilters={<ThirdPartyListingBodyToolbar {...thirdPartyListingBodyToolbarProps} />}
                    bodyCards={filteredAndSortedThirdParties.map((thirdParty) => (
                        <ThirdPartyCard key={thirdParty.id} thirdParty={thirdParty} selectedCreateServiceForThirdParty={createServiceForThirdParty} selectedModifyThirdParty={selectedModifyThirdParty} selectedDeleteThirdParty={selectedDeleteThirdParty} selectedViewFolders={viewFolders} />
                    ))}
                    bodyTable={<ThirdPartyListingTableView {...thirdPartyListingTableViewProps} />}
                />
            </>
        );
    }

    return <Placeholder />;
};
