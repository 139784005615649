import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { type JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Link } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { ControlTable } from 'Components/ControlTable/ControlTable';
import { IssueHistoryTable, IssueHistoryTableProps } from 'Components/Issues/IssueHistoryTable/IssueHistoryTable';
import { AssociatedControlsModal } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { ThirdPartySummary } from 'Components/ThirdPartySummary/ThirdPartySummary';
import { ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { ISSUES, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUpdateIssueUrl } from 'Helpers/URLBuilder/URLBuilder';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlIssueHistoryResponse, IssueHistoryResponse, IssueResponse, IssueStatus, IssuesExceptionsModule, ThirdPartyIssueHistoryResponse } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import styles from 'Styles/DetailsPage.module.css';

import { IssueDetailsSnapshot } from '../Components/IssueDetailsSnapshot/IssueDetailsSnapshot';
import { IssueHistoryModal } from '../Components/IssueHistoryModal/IssueHistoryModal';

enum IssueTab {
    CONTROLS = 'Controls',
    THIRD_PARTY = 'Third Party',
    HISTORY = 'History',
}

interface UrlParams {
    issueId: string;
}

interface IssueDetailsOperationalControlsProps {
    type: IssuesExceptionsModule.CONTROLS;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    tprmApi?: never; // Typed like this so that `tprmApi` can be used unconditionally as a `useEffect` dependency.
}

interface IssueDetailsTprmProps {
    type: IssuesExceptionsModule.TPRM;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

export type IssueDetailsProps = IssueDetailsOperationalControlsProps | IssueDetailsTprmProps;

/**
 * Renders a page of detailed information for a specific issue.
 * This includes: current details; a tab containing a table of historical snapshots; and, depending on the issue type, a tab containing either a table of impacted controls or a summary of the impacted third party.
 */
export const IssueDetails = (props: IssueDetailsProps) => {
    const cachedData = useCachedData();
    const { issueId } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [issue, setIssue] = useState<IssueResponse>();
    const [thirdPartyIdToThirdPartyMap, setThirdPartyIdToThirdPartyMap] = useState<Map<string, ThirdPartyResponseWithServices>>(); // This isn't actually used when the issue belongs to the Operational Controls module.
    const [issueHistory, setIssueHistory] = useState<IssueHistoryResponse[]>();
    const [issueHistoryToShowInModal, setIssueHistoryToShowInModal] = useState<IssueHistoryResponse>();
    const [impactedControlsToShow, setImpactedControlsToShow] = useState<OperationalControl[]>();

    useEffect(() => {
        const getIssueDetails = async (issueId: string): Promise<void> => {
            try {
                const getIssueDetailsResponse = await props.issuesApi.getIssue(issueId);
                const issue = getIssueDetailsResponse.data;
                setIssue(issue);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getIssueDetails(issueId);
    }, [issueId, props.issuesApi]);

    useEffect(() => {
        const getIssueHistory = async (issueId: string): Promise<void> => {
            try {
                const response = await props.issuesApi.getIssueHistory(issueId);
                setIssueHistory(response.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getIssueHistory(issueId);
    }, [issueId, props.issuesApi]);

    useEffect(() => {
        const getThirdParties = async (): Promise<void> => {
            if (props.type !== IssuesExceptionsModule.TPRM) {
                setThirdPartyIdToThirdPartyMap(new Map());
                return;
            }

            try {
                // TODO: `getThirdPartyDetails` should be used if/when `ThirdPartyResponseWithServices` and `ThirdPartyResponse` are consolidated.
                const response = await props.tprmApi.getThirdParties();
                setThirdPartyIdToThirdPartyMap(new Map(response.data.map((thirdParty) => [thirdParty.id, thirdParty])));
            } catch (error) {
                handleRequestError(error);
            }
        };

        getThirdParties();
    }, [props.tprmApi, props.type]);

    const handleRequestError = (error: Error): void => setErrorMessage(error.message);

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    }

    if (!(issue && issueHistory && thirdPartyIdToThirdPartyMap)) {
        return <Placeholder />;
    }

    const buttons: JSX.Element = (() => {
        switch (issue.status) {
            case IssueStatus.DRAFT_OPEN:
                return (
                    <Link variant="primaryButton" to={getUpdateIssueUrl(issue.id, issue.type, false)} fontAwesomeImage={ICON_EDIT_MODIFY_UPDATE}>
                        MANAGE ISSUE
                    </Link>
                );
            case IssueStatus.OPEN:
                return (
                    <>
                        <Link variant="secondaryButton" to={getUpdateIssueUrl(issue.id, issue.type, false)} fontAwesomeImage={ICON_EDIT_MODIFY_UPDATE}>
                            MANAGE ISSUE
                        </Link>
                        <Link variant="primaryButton" to={getUpdateIssueUrl(issue.id, issue.type, true)} fontAwesomeImage={faCheck}>
                            Close Issue
                        </Link>
                    </>
                );
            case IssueStatus.CLOSED:
                return <></>;
        }
    })();

    const getThirdPartyName = (thirdPartyId: string) => thirdPartyIdToThirdPartyMap.get(thirdPartyId)!.name;

    const issueHistoryTableProps: IssueHistoryTableProps = (() => {
        switch (issue.type) {
            case IssuesExceptionsModule.CONTROLS:
                return { type: IssuesExceptionsModule.CONTROLS, histories: issueHistory as ControlIssueHistoryResponse[], displayMappedControlsModal: setImpactedControlsToShow, onSelectHistory: (history: IssueHistoryResponse) => setIssueHistoryToShowInModal(history) };
            case IssuesExceptionsModule.TPRM:
                return { type: IssuesExceptionsModule.TPRM, histories: issueHistory as ThirdPartyIssueHistoryResponse[], getThirdPartyName: getThirdPartyName, onSelectHistory: (history: IssueHistoryResponse) => setIssueHistoryToShowInModal(history) };
        }
    })();

    return (
        <>
            {issueHistoryToShowInModal && <IssueHistoryModal history={issueHistoryToShowInModal} documentApi={props.documentApi} hideModal={() => setIssueHistoryToShowInModal(undefined)} />}
            {impactedControlsToShow && <AssociatedControlsModal associatedControls={impactedControlsToShow} hideModal={() => setImpactedControlsToShow(undefined)} />}
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${issue.type === IssuesExceptionsModule.TPRM ? `${TPRM}/` : ''}${ISSUES_EXCEPTIONS}#${ISSUES}`}>Issues</BreadcrumbLink>
                        <BreadcrumbText>{issue.title}</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={issue.title}
                headerButtons={buttons}
                headerDescription={issue.status === IssueStatus.CLOSED ? `Closed: ${iso8601ToUsDateShort(issue.closed_timestamp)} by ${getUserNameFromSubject(issue.closed_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}` : `Last updated: ${iso8601ToUsDateShort(issue.last_updated_timestamp)} by ${getUserNameFromSubject(issue.last_updated_by, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}`}
                body={[
                    {
                        title: 'Issue Details',
                        content: <IssueDetailsSnapshot issue={issue} documentApi={props.documentApi} />,
                    },
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab={issue.type === IssuesExceptionsModule.CONTROLS ? IssueTab.CONTROLS : IssueTab.THIRD_PARTY} removePadding transparent>
                                {issue.type === IssuesExceptionsModule.CONTROLS ? (
                                    <Tab eventKey={IssueTab.CONTROLS} title={IssueTab.CONTROLS}>
                                        <div className={styles.tabContent}>
                                            <ControlTable controls={issue.impacted_controls} />
                                        </div>
                                    </Tab>
                                ) : (
                                    <Tab eventKey={IssueTab.THIRD_PARTY} title={IssueTab.THIRD_PARTY}>
                                        <div className={styles.tabContent}>
                                            <Text variant="Header2" color="darkBlue" noStyles>
                                                {thirdPartyIdToThirdPartyMap.get(issue.impacted_vendor)!.name}
                                            </Text>
                                            <hr />
                                            <ThirdPartySummary third_party={thirdPartyIdToThirdPartyMap.get(issue.impacted_vendor)!} />
                                        </div>
                                    </Tab>
                                )}
                                <Tab eventKey={IssueTab.HISTORY} title={IssueTab.HISTORY}>
                                    <div className={styles.tabContent} data-testid="issueHistoryTable">
                                        {issue.status !== IssueStatus.DRAFT_OPEN ? <IssueHistoryTable {...issueHistoryTableProps} /> : <Text>History will not be recorded until the issue is opened.</Text>}
                                    </div>
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        </>
    );
};
