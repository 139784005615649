import { Checkbox } from '@mui/material';
import { type JSX, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ICON_ADD_CREATE, ICON_DELETE_REMOVE, ICON_DOWNLOAD } from 'Config/Icons';
import { FOLDERS, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument, submitRequestWithFiles } from 'Helpers/FileUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { ValidationError } from 'Models/ErrorTypes';
import { UploadedFile } from 'Models/Files';
import { ResponseModel } from 'Models/ResponseModel';
import { CreateNewFolderRequest, FolderTypes, FolderVersion, ThirdPartyResponse } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';

import styles from './ManageFolders.module.css';

interface UrlParams {
    third_party_id: string;
    folder_id: string;
}

export enum TprmFolderPageType {
    CREATE_NEW,
    ADD_VERSION,
    MANAGE,
}
export interface AddNewFolderVersionProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
    pageType: TprmFolderPageType;
}
interface FormFieldState {
    name: string;
    type: string;
    third_party_manager_notifications_enabled: boolean;
    effectiveDate?: Date;
    expirationDate?: Date;
    comments?: string;
    files?: UploadedFile[];
}

export const ManageFolders = (props: AddNewFolderVersionProps) => {
    const { third_party_id, folder_id } = useParams<keyof UrlParams>() as UrlParams;
    const versionExistsAlready = folder_id !== undefined;
    const [files, onAddFiles, onRemoveFile] = useFileDragAndDrop();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [thirdPartyResponse, setThirdPartyResponse] = useState<ThirdPartyResponse>();
    const [folderTypes, setFolderTypes] = useState<FolderTypes>();
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [folderId, setFolderId] = useState<string | undefined>(versionExistsAlready ? folder_id : undefined);
    const cachedData = useCachedData();
    const [toastErrorMessage, setToastErrorMessage] = useState<string>();
    const [toastSuccessMessage, setToastSuccessMessage] = useState<string>();
    const [formFieldsState, setFormFieldsState] = useState<FormFieldState>({ name: '', type: '', third_party_manager_notifications_enabled: false });
    const [currentVersion, setCurrentVersion] = useState<FolderVersion | null | undefined>(versionExistsAlready ? undefined : null);
    const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
    const [folderTypeOptions, setFolderTypeOptions] = useState<OptionType[]>();

    useEffect(() => {
        const getFolderTypes = async (): Promise<void> => {
            try {
                const response: ResponseModel<FolderTypes> = await props.tprmApi.getAllFolderTypes();
                setFolderTypes(response.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        const getThirdPartyDetails = async (): Promise<void> => {
            try {
                const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(third_party_id);
                setThirdPartyResponse(detailedThirdPartyResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getFolderTypes();
        getThirdPartyDetails();
    }, [props.tprmApi, third_party_id]);

    useEffect(() => {
        if (cachedData.users === undefined) {
            return;
        }

        if (!versionExistsAlready) {
            setCurrentVersion(null);
            return;
        }

        const getEffectiveVersion = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getAllFolderVersions(third_party_id, folder_id);
                const currentVersion = response.data.current_version;
                setCurrentVersion(currentVersion);
                setCurrentFiles(currentVersion.files ?? []);
                if (currentVersion) {
                    setFormFieldsState({
                        name: currentVersion.name,
                        type: currentVersion.type,
                        third_party_manager_notifications_enabled: currentVersion.vendor_manager_notifications_enabled,
                        comments: currentVersion.comments,
                        effectiveDate: currentVersion.effective_date ? iso8601ToJsDate(currentVersion.effective_date) : undefined,
                        expirationDate: currentVersion.expiration_date ? iso8601ToJsDate(currentVersion.expiration_date) : undefined,
                        files: currentVersion.files,
                    });
                }
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getEffectiveVersion();
    }, [props.tprmApi, third_party_id, folder_id, cachedData.users, versionExistsAlready]);

    /**
     * Constructs an array of Folder Type options.
     * The Folder Type options remain undefined (causing the page to show the placeholder) until both the current version (if it exists) and the client's configured Type options are fetched.
     *
     * The client's configured Types are always selectable.
     * The current Type of the Folder (if an existing Folder is being managed); if no Types are configured at the client level, then the existing Type is the only option.
     */
    useEffect(() => {
        if (currentVersion === undefined || folderTypes === undefined) {
            return;
        }

        const currentFolderType = currentVersion?.type;
        const clientFolderTypes = folderTypes ? folderTypes.type_set : [];
        let choices: string[];

        if (currentFolderType) {
            choices = clientFolderTypes.includes(currentFolderType) ? clientFolderTypes : [currentFolderType, ...clientFolderTypes];
        } else {
            choices = clientFolderTypes;
        }

        setFolderTypeOptions(choices.map((folderType) => ({ value: folderType, label: folderType })).sort((a, b) => (a.label > b.label ? 1 : -1)));
    }, [currentVersion, folderTypes]);

    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Files',
        onAddFiles: onAddFiles,
        onRemoveFile: onRemoveFile,
        files: files,
    };

    const validateFormInput = () => {
        if (formFieldsState.name.length === 0) {
            throw new ValidationError('Name is required.');
        }
        if (!formFieldsState.type) {
            throw new ValidationError('Type is required.');
        }
    };

    const folderRequest = async (): Promise<void> => {
        setToastErrorMessage(undefined);
        setToastSuccessMessage(undefined);
        setIsSaving(true);

        try {
            validateFormInput();

            await submitRequestWithFiles(
                props.documentApi,
                files.map((file) => ({ file: file })),
                async (newDocumentation) => {
                    const request: CreateNewFolderRequest = {
                        name: formFieldsState.name,
                        type: formFieldsState.type,
                        files: [...newDocumentation, ...currentFiles],
                        third_party_manager_notifications_enabled: formFieldsState.third_party_manager_notifications_enabled,
                        effective_date: formFieldsState.effectiveDate ? jsDateToIso8601(formFieldsState.effectiveDate) : undefined,
                        expiration_date: formFieldsState.expirationDate ? jsDateToIso8601(formFieldsState.expirationDate) : undefined,
                        comments: formFieldsState.comments,
                    };

                    if (folder_id && currentVersion) {
                        if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                            await props.tprmApi.createNewFolderVersion(third_party_id, folder_id, request);
                        } else {
                            await props.tprmApi.manageFolder(third_party_id, folder_id, request);
                        }
                    } else {
                        const createNewFolderResponse = await props.tprmApi.createNewFolder(third_party_id, request);
                        setFolderId(createNewFolderResponse.data);
                    }
                }
            );

            setIsSaved(true);
            setToastSuccessMessage('Folder saved.');
        } catch (error) {
            setToastErrorMessage(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    const buildBreadcrumb = (thirdPartyName: string, currentVersion?: FolderVersion | null): JSX.Element => {
        if (folder_id && currentVersion) {
            if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}`}>Third-Party Documents</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}`}>{currentVersion.name}</BreadcrumbLink>
                        <BreadcrumbText>Create New Folder Version</BreadcrumbText>
                    </Breadcrumb>
                );
            } else {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}`}>{currentVersion.name}</BreadcrumbLink>
                        <BreadcrumbText>Manage Folder</BreadcrumbText>
                    </Breadcrumb>
                );
            }
        } else {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                    <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}`}>Third-Party Documents</BreadcrumbLink>
                    <BreadcrumbText>Create New Folder</BreadcrumbText>
                </Breadcrumb>
            );
        }
    };

    const getPageTitle = (currentVersion?: FolderVersion | null): string => {
        if (folder_id && currentVersion) {
            if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                return 'Create New Folder Version';
            } else {
                return 'Manage Folder';
            }
        } else {
            return 'Create New Folder';
        }
    };

    const deleteFile = (file: UploadedFile) => {
        const existingFilesCopy: UploadedFile[] = [...currentFiles];
        existingFilesCopy.splice(currentFiles.indexOf(file), 1);
        setCurrentFiles(existingFilesCopy);
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    const handleChangeEffectiveDate = (date: Date | undefined): void => {
        setFormFieldsState({ ...formFieldsState, effectiveDate: date });
    };

    const handleChangeExpirationDate = (date: Date | undefined): void => {
        const notificationsEnabled = date ? formFieldsState.third_party_manager_notifications_enabled : false;
        setFormFieldsState({ ...formFieldsState, expirationDate: date, third_party_manager_notifications_enabled: notificationsEnabled });
    };

    const handleCheckSelect = (event: React.FormEvent<HTMLInputElement>) => {
        setFormFieldsState({ ...formFieldsState, third_party_manager_notifications_enabled: event.currentTarget.checked });
    };

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else if (!(thirdPartyResponse && cachedData.users && folderTypeOptions)) {
        return <Placeholder />;
    } else if (folderTypeOptions.length === 0) {
        return <Text>At least one folder type must be defined in "Settings" before a folder can be created.</Text>;
    } else {
        return (
            <>
                {toastSuccessMessage && <LinkButtonToast variant="success" clearToast={() => setToastSuccessMessage(undefined)} text={toastSuccessMessage} linkButtonText={'View Folder'} linkButtonTo={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folderId}`} />}
                {toastErrorMessage && <TextToast variant="failure" clearToast={() => setToastErrorMessage(undefined)} text={toastErrorMessage} />}
                <PageLayoutDefault
                    headerBreadcrumb={buildBreadcrumb(thirdPartyResponse.name, currentVersion)}
                    headerTitle={getPageTitle(currentVersion)}
                    body={[
                        {
                            title: 'Folder Details',
                            content: (
                                <Form>
                                    <div className={styles.formFieldsContainer}>
                                        <div className={styles.fieldGroupContainer}>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldText formFieldLabel="Name" formFieldId="name" handleChange={handleChange} value={formFieldsState.name} required />
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldSelect formFieldLabel="Type" formFieldId="type" options={folderTypeOptions} handleChange={handleSelectChange} required selectedOption={formFieldsState.type} />
                                            </div>
                                        </div>
                                        <div className={styles.fieldGroupContainer}>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldDatePicker formFieldLabel="Effective Date" formFieldId="effectiveDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} selected={formFieldsState.effectiveDate} handleChange={handleChangeEffectiveDate} />
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldDatePicker formFieldLabel="Expiration Date" formFieldId="expirationDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} handleChange={handleChangeExpirationDate} selected={formFieldsState.expirationDate} />
                                            </div>
                                        </div>
                                        <div className={styles.dragAndDrop}>
                                            <FileDragAndDrop {...fileDragAndDropProps} />
                                            {currentVersion?.files && currentVersion.files.length > 0 && (
                                                <>
                                                    <VisualLabel>Existing Files</VisualLabel>
                                                    <Table>
                                                        <TableBody>
                                                            {[...currentFiles]
                                                                .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                                                .map((file) => (
                                                                    <TableRow key={file.file_id}>
                                                                        <TableCell>
                                                                            <Text noStyles>{file.filename}</Text>
                                                                        </TableCell>
                                                                        <TableOverflowCell>
                                                                            <div className={styles.overflowContainer}>
                                                                                <OverflowMenu
                                                                                    overflowItems={[
                                                                                        {
                                                                                            text: 'Download file',
                                                                                            onClickAction: () => downloadDocument(props.documentApi, file),
                                                                                            icon: ICON_DOWNLOAD,
                                                                                        },
                                                                                        {
                                                                                            text: 'Delete file',
                                                                                            onClickAction: () => deleteFile(file),
                                                                                            icon: ICON_DELETE_REMOVE,
                                                                                        },
                                                                                    ]}
                                                                                    accessibilityTitle={file.filename}
                                                                                />
                                                                            </div>
                                                                        </TableOverflowCell>
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.fieldContainer}>
                                            <FormFieldTextArea formFieldId="comments" formFieldLabel="Comments" handleChange={handleChange} value={formFieldsState.comments} />
                                        </div>
                                    </div>
                                    <VisualLabel>Notifications</VisualLabel>
                                    <div className={styles.checkboxContainer}>
                                        <Checkbox checked={formFieldsState.third_party_manager_notifications_enabled} color="default" onChange={handleCheckSelect} disabled={!formFieldsState.expirationDate} />
                                        <Text noStyles color={formFieldsState.expirationDate ? 'blue' : 'darkGray'}>{`Third-Party Manager - ${getUserNameFromSubject(thirdPartyResponse.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}`}</Text>
                                        <FormFieldTooltip text={!formFieldsState.expirationDate ? 'Please enter an expiration date to enable notifications.' : 'Third-Party Manager will be notified when expiration date is approaching.'} />
                                    </div>
                                    <div className={styles.buttonContainer}>
                                        <Button variant="primary" onClick={() => folderRequest()} disabled={isSaved} fontAwesomeImage={ICON_ADD_CREATE} isLoading={isSaving} loadingText="Submitting...">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            ),
                        },
                    ]}
                />
            </>
        );
    }
};
