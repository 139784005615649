import { type JSX, useContext } from 'react';
import { Nav as BootstrapNav, NavItem as BootstrapNavItem, NavLink as BootstrapNavLink, TabContainer as BootstrapTabContainer, TabContent as BootstrapTabContent, TabPane as BootstrapTabPane } from 'react-bootstrap';

import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { RBACComponent, RBACComponentProps } from 'Components/Context/RBACComponent';
import { RBACContext, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { Text, TextProps } from 'Components/Text/Text';
import './PrimaryTab.css';

export interface PrimaryTabsProps {
    defaultActiveTab: string;
    onSelect?: (eventKey?: string) => void;
    children: JSX.Element | JSX.Element[];
    transparent?: boolean;
    removePadding?: boolean;
    tabButtonTextColor?: TextProps['color'];
}

/**
 *
 * @param {string} defaultActiveTab eventKey of active/open tab at initial render
 * @param {function} onSelect callback after tab is selected
 * @param {object} children Children must be Tab component, RBAC component, or the JSX.Element will render permanently
 */
export const PrimaryTabs = (props: PrimaryTabsProps): JSX.Element => {
    const rbacContext = useContext(RBACContext);
    const onSelect = (eventKey: string | null, e: React.SyntheticEvent<unknown>) => {
        e.preventDefault();
        if (eventKey !== null && props.onSelect) {
            props.onSelect(eventKey);
        }
    };

    const buildTabsHeader = (children: JSX.Element | JSX.Element[]) => {
        if (children instanceof Array) {
            return children.map((child: React.ReactElement<TabProps> | React.ReactElement<RBACComponentProps>) => {
                return createTab(child);
            });
        } else {
            return createTab(children);
        }
    };

    const createTab = (child: JSX.Element): JSX.Element | null => {
        if (child.type === RBACComponent) {
            const rbacChild = child as React.ReactElement<RBACComponentProps>;
            if (rbacContext && rbacChild.props.roles && userHasAuthorizedRole(rbacContext!.roles, rbacChild.props.roles)) {
                const tabChild = child.props.children as React.ReactElement<TabProps>;
                return (
                    <BootstrapNavItem key={tabChild.props.title}>
                        <BootstrapNavLink eventKey={tabChild.props.eventKey}>
                            <Text variant="Text2" noStyles>
                                {tabChild.props.title}
                            </Text>
                        </BootstrapNavLink>
                    </BootstrapNavItem>
                );
            } else return null;
        } else if (child.type === Tab) {
            const tabChild = child as React.ReactElement<TabProps>;
            return (
                <BootstrapNavItem key={tabChild.props.title}>
                    <BootstrapNavLink eventKey={tabChild.props.eventKey}>
                        <Text variant="Text2" noStyles color={props.tabButtonTextColor}>
                            {tabChild.props.title}
                        </Text>
                    </BootstrapNavLink>
                </BootstrapNavItem>
            );
        } else return null;
    };

    return (
        <BootstrapTabContainer defaultActiveKey={props.defaultActiveTab} onSelect={onSelect} transition={false}>
            <PageContent removePadding={props.removePadding}>
                <BootstrapNav bsPrefix="primary-tab">{buildTabsHeader(props.children)}</BootstrapNav>
            </PageContent>
            <BootstrapTabContent className={props.transparent ? 'transparentTab' : undefined}>{props.children}</BootstrapTabContent>
        </BootstrapTabContainer>
    );
};

export interface TabProps {
    title: string;
    eventKey: string;
    children: JSX.Element[] | JSX.Element;
    unmountOnExit?: boolean;
}

/**
 *
 * @param {string} title eventKey of active/open tab at initial render
 * @param {string} eventKey must be a unique item. Allows for selection of tab outside of component.
 * @param {object} children JSX.Elements that conditionally render when tab is selected
 */
export const Tab = (props: TabProps): JSX.Element => {
    return (
        <BootstrapTabPane eventKey={props.eventKey} unmountOnExit={props.unmountOnExit !== undefined ? props.unmountOnExit : undefined}>
            {props.children}
        </BootstrapTabPane>
    );
};
