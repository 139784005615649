import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { SaveControlGroupFormFields, SaveControlGroupFromFieldsProps, useSaveControlGroup } from 'Components/OperationalControls/SaveControlGroup/SaveControlGroup';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { ICON_DELETE_REMOVE } from 'Config/Icons';
import { FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { DetailedControlGroupResponse } from 'Models/OperationalControls';

import styles from './ControlGroupConfigurationTab.module.css';
import { ControlGroupDeleted } from '../ControlGroupSettings';

export interface ControlGroupConfigurationTabProps {
    onControlGroupDeleted: () => void;
    onControlGroupSaved: () => void;
    controlsApi: ControlsApi;
    detailedControlGroupResponse: DetailedControlGroupResponse;
}

export const ControlGroupConfigurationTab = ({ controlGroupIsDeleted = false, ...props }: ControlGroupConfigurationTabProps & ControlGroupDeleted) => {
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const { saveControlGroupStatus, saveControlGroupFormFieldsValues, error, saveControlGroup, handleChange } = useSaveControlGroup(
        props.detailedControlGroupResponse.control_framework,
        props.controlsApi,
        {
            customControlGroupName: props.detailedControlGroupResponse.control_group_name,
            customControlGroupDescription: props.detailedControlGroupResponse.control_group_description,
        },
        props.detailedControlGroupResponse.control_group_id
    );
    const [shouldDisplayDeleteConfirmationModal, setShouldDisplayDeleteConfirmationModal] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await saveControlGroup();
    };

    const saveControlGroupFormFieldsProps: SaveControlGroupFromFieldsProps = {
        saveControlGroupFormFieldsValues: saveControlGroupFormFieldsValues,
        handleChange: handleChange,
        disabled: controlGroupIsDeleted,
    };

    const { onControlGroupSaved } = props;

    useEffect(() => {
        switch (saveControlGroupStatus) {
            case 'saved':
                setSuccessMessage('Control group configuration updated.');
                onControlGroupSaved();
                break;
            case 'error':
                setFailureMessage(error?.message);
                break;
            default:
                setSuccessMessage(undefined);
                setFailureMessage(undefined);
        }
    }, [error, onControlGroupSaved, saveControlGroupStatus]);

    const deleteCustomControlGroup = async () => {
        await props.controlsApi.deleteControlGroup(props.detailedControlGroupResponse.control_framework, props.detailedControlGroupResponse.control_group_id);
        props.onControlGroupDeleted();
        return 'Control group deleted.';
    };

    const displayDeleteConfirmationModal = () => {
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setShouldDisplayDeleteConfirmationModal(true);
    };

    return (
        <>
            {successMessage && <LinkButtonToast variant="success" clearToast={() => setSuccessMessage(undefined)} linkButtonText={'Return to control group'} linkButtonTo={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${props.detailedControlGroupResponse.control_framework}/${GROUPS}/${props.detailedControlGroupResponse.control_group_id}`} text={successMessage} />}
            {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
            {shouldDisplayDeleteConfirmationModal && (
                <ConfirmationModal operationType="delete" headerText="Delete Custom Control Group" areYouSureText={`Are you sure you want to delete ${props.detailedControlGroupResponse.control_group_name}?`} performOperation={deleteCustomControlGroup} hideModal={() => setShouldDisplayDeleteConfirmationModal(false)}>
                    <Text>All controls associated with this control group must be deleted before the control group can be deleted. The assessment configuration, limits, and alerts will also be deleted.</Text>
                </ConfirmationModal>
            )}
            <Form noValidate onSubmit={handleSubmit}>
                <SaveControlGroupFormFields {...saveControlGroupFormFieldsProps} />
                <div className={styles.buttonContainer}>
                    <Button variant="submit" disabled={controlGroupIsDeleted} isLoading={saveControlGroupStatus === 'saving'} loadingText="Saving...">
                        Save
                    </Button>

                    <Button variant="danger" disabled={controlGroupIsDeleted} onClick={displayDeleteConfirmationModal} fontAwesomeImage={ICON_DELETE_REMOVE}>
                        Delete
                    </Button>
                </div>
            </Form>
        </>
    );
};
