import { ARTIFICIAL_INTELLIGENCE, ASSESSMENTS, GOVERNANCE, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { AiGovernance, AiGovernanceCreateRequest, AiTprmServiceAssessmentRequest } from 'Models/ArtificialIntelligence';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { ArtificialIntelligenceApi } from './ArtificialIntelligenceApi';

export class AWSArtificialIntelligenceApi implements ArtificialIntelligenceApi {
    // AI - Governance.
    aiGovernanceCreate = (request: AiGovernanceCreateRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${ARTIFICIAL_INTELLIGENCE}/${GOVERNANCE}`, request);
    };
    aiGovernanceDelete = (aiGovernance: AiGovernance): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${ARTIFICIAL_INTELLIGENCE}/${GOVERNANCE}/${aiGovernance.id}`);
    };
    aiGovernanceGetAll = (): Promise<ResponseModel<AiGovernance[]>> => {
        return restApiGet(`/${ARTIFICIAL_INTELLIGENCE}/${GOVERNANCE}`);
    };

    // AI - TPRM.
    aiTprmServiceAssessment = (request: AiTprmServiceAssessmentRequest, thirdPartyId: string, thirdPartyServiceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${ARTIFICIAL_INTELLIGENCE}/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${thirdPartyServiceId}/${ASSESSMENTS}`, request);
    };
}
