import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import type { JSX } from 'react';

import { Text } from 'Components/Text/Text';

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

const VARIANT_DEFAULT = 'standard';

export interface SortableTableHeaderProps {
    headers: HeaderData[];
    currentSort: string;
    currentSortDirection: SortDirection;
    setSortColumnAndDirection: (sortProperty: string, sortDirection: SortDirection) => void;
    tableIncludesOverflowMenu?: boolean;
    variant?: 'standard' | 'dashboard';
}

export interface HeaderData {
    label: string;
    dataKey: string;
    disableSort?: boolean;
}

const standardTableStyles = {
    backgroundColor: '#E6EDF1',
    borderTop: '1px solid #dee2e6',
    maxHeight: '30px',
    borderRadius: '9px',
    width: '100%',
} as const;

const dashboardTableStyles = {
    borderTop: 'hidden',
    maxHeight: '30px',
    borderRadius: '9px',
    width: '100%',
} as const;

export const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({ headers, currentSort, currentSortDirection, setSortColumnAndDirection, tableIncludesOverflowMenu, variant = VARIANT_DEFAULT }: SortableTableHeaderProps) => {
    const applySortingHandler = (newSort: string) => (): void => {
        let newSortDirection: SortDirection;

        if (newSort === currentSort) {
            if (currentSortDirection === SortDirection.ASC) {
                newSortDirection = SortDirection.DESC;
            } else {
                newSortDirection = SortDirection.ASC;
            }
        } else {
            newSortDirection = SortDirection.ASC;
        }

        setSortColumnAndDirection(newSort, newSortDirection);
    };

    const createHeaderCell = (header: HeaderData, index: number): JSX.Element => {
        const textColor = variant === VARIANT_DEFAULT ? 'blue' : 'darkGray';
        if (header.disableSort === true) {
            return (
                <TableCell key={index}>
                    <Text variant="Text4" color={textColor} noStyles toUppercase>
                        {header.label}
                    </Text>
                </TableCell>
            );
        } else {
            return (
                <TableCell key={index} sortDirection={currentSort === header.dataKey ? 'desc' : false}>
                    <TableSortLabel active={currentSort === header.dataKey} direction={currentSortDirection ? currentSortDirection : 'desc'} onClick={applySortingHandler(header.dataKey)}>
                        <Text variant="Text4" color={textColor} noStyles toUppercase>
                            {header.label}
                        </Text>
                    </TableSortLabel>
                </TableCell>
            );
        }
    };

    const rootStyle = variant === VARIANT_DEFAULT ? standardTableStyles : dashboardTableStyles;

    return (
        <TableHead sx={rootStyle}>
            <TableRow sx={{ '& th': { padding: '.5rem 12px' } }}>
                {headers.map((header, index) => createHeaderCell(header, index))}
                {tableIncludesOverflowMenu === true && <TableCell></TableCell>}
            </TableRow>
        </TableHead>
    );
};
