// TODO: As is reasonable, please add more comments/JSDoc to this file and related files. They are extremely difficult to understand via the code alone.
import { faCheck, faSignOutAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { produce } from 'immer';
import { isEmpty, isEqual } from 'lodash-es';
import { type JSX, Reducer, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { DDQApi } from 'Api/TPRM/DDQApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useNav } from 'Components/Context/NavContext';
import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { ProgressBarIndicator } from 'Components/Indicator/ProgressBarIndicator';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_CLOSE, ICON_EDIT_MODIFY_UPDATE } from 'Config/Icons';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { BasicAuthHandler } from 'Helpers/Auth/BasicAuth/BasicAuthHandler';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { Scroller } from 'Helpers/Scroller';
import { addFiles, removeFiles } from 'Hooks/FileDragAndDrop';
import { AuthState } from 'Models/Auth';
import { controlComparator, sortGenericControlHierarchy } from 'Models/ControlHierarchy';
import { FileState, FileToBeUploaded, SignedUploadResponse, UploadedFile } from 'Models/Files';
import { ControlText, Effectiveness } from 'Models/OperationalControls';
import { Answer, ControlFrameworkInstanceResponse, ControlGroupInstanceResponse, ControlInstanceResponse, MultipleSelectQuestionInstance, QuestionType, QuestionTypes, QuestionUpdates, Service, ServiceAssessmentState, SingleSelectQuestionInstance, UpdateControlInstanceQuestionsRequest, UpdateControlInstanceRequest } from 'Models/TPRM';

import { DDQControl, DDQControlProps } from './DDQControl/DDQControl';
import { DDQGroupTab, DDQGroupTabProps } from './DDQGroupTab/DDQGroupTab';
import { DeleteAnswerDocumentModal } from './DeleteDocumentationModal/DeleteAnswerDocumentModal';
import { DeleteControlAssessmentDocumentModal } from './DeleteDocumentationModal/DeleteControlAssessmentDocumentModal';
import styles from './DueDiligenceQuestionnaire.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}
export interface DueDiligenceQuestionnaireProps {
    ddqApi: DDQApi;
    documentApi: DocumentApi;
    scroller: Scroller;
    basicAuthHandler?: BasicAuthHandler;
    isThirdParty?: boolean;
}

enum Modal {
    DeleteAnswerDocument,
    DeleteControlAssessmentDocument,
}

export interface DeleteAnswerDocumentModalPayload {
    answerDocument: UploadedFile;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
    questionId: string;
}

export interface DeleteControlAssessmentDocumentModalPayload {
    document: UploadedFile;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

type ModalPayload = DeleteAnswerDocumentModalPayload | DeleteControlAssessmentDocumentModalPayload;

/**
 * Used in state to determine which modal (if any) is currently displayed.
 * Note that because `ModalPayload` is not a discriminated union, `as`es are needed below.
 */
interface ModalState {
    modal: Modal;
    payload: ModalPayload;
}

interface DDQState {
    frameworks: Map<string, DDQStateFramework>;
    selectedFramework: string;
    selectedGroupId: string;
    allQuestionsAnswered: boolean;
    focusedKey?: ControlKey | QuestionKey;
    stickyHeaderKey?: ControlKey | QuestionKey;
}

export interface ControlKey {
    framework: string;
    groupId: string;
    controlId: string;
}

export interface QuestionKey extends ControlKey {
    questionId: string;
    questionNumber?: number;
}

export interface DDQStateFramework {
    framework: string;
    groups: Map<string, DDQStateGroup>;
}

export interface DDQStateGroup {
    framework: string;
    id: string;
    name: string;
    description?: string;
    controlAssessmentsCompleted: number;
    numberOfControls: number;
    questionsCompleted: number;
    numberOfQuestions: number;
    isCustom: boolean;
    controls: Map<string, DDQStateControl>;
}

export interface DDQStateControl {
    id: string;
    groupId: string;
    framework: string;
    controlText: ControlText[];
    isCustom: boolean;
    controlName?: string;
    effectiveness?: TrackedChanges<Effectiveness>;
    additionalInfo?: TrackedChanges<string>;
    questions: Map<string, DDQStateQuestion>;
    documents: UploadedFile[];
    newDocuments: File[];
    saveState: DDQStateControlSaveState;
    [index: string]: any;
}

export enum DDQStateControlSaveState {
    UNCHANGED,
    CHANGED,
    SAVING_MANUALLY,
    SAVING_AUTOMATICALLY,
    SAVED,
    ERROR,
}

export interface DDQStateQuestion {
    _type: QuestionType;
    id: string;
    text: string;
    answer: TrackedChanges<Answer>;
    answerDocuments: UploadedFile[];
    newDocuments: File[];
    options?: string[];
    additionalInformation: TrackedChanges<string>;
}

const isQuestionAnswered = (question: DDQStateQuestion): boolean => {
    const hasAnswer = question.answer.current !== undefined;
    const hasDocuments = question.answerDocuments.length > 0;
    const hasAdditionalInformation = question.additionalInformation.current !== undefined;

    return hasAnswer || hasDocuments || hasAdditionalInformation;
};

interface TrackedChanges<T> {
    initial?: T;
    current?: T;
}

export const isChanged = (trackedChanges?: TrackedChanges<any>): boolean => !isEqual(trackedChanges?.initial, trackedChanges?.current);

export enum DDQStateActionType {
    FrameworksLoaded,
    ControlsLoaded,
    QuestionUpdated,
    FormFieldUpdated,
    ControlSaved,
    ControlSaving,
    ControlSaveStateChanged,
    GroupSelectedAction,
    ControlFilesSelected,
    ControlFileDeselected,
    QuestionFilesSelected,
    QuestionFileDeselected,
    ControlAssessmentDocumentDeleted,
    AnswerDocumentDeleted,
    ControlScrolledOver,
}

export interface DDQStateActionFrameworksLoaded {
    type: DDQStateActionType;
    payload: DDQState;
}

/**
 * Dispatched after the user selects the tab for a control group, and that group has not already been fetched via network, so a fetch occurs.
 * The reducer saves (caches) the controls for the group in state.
 */
export interface DDQStateActionControlsLoaded {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controls: Map<string, DDQStateControl>;
    };
}

/**
 * Dispatched by `DDQControl` (or arguably indirectly by `DDQQuestion`) whenever a question's _answer_ or _additional information_ is updated.
 * This does NOT include changes to the question's files, even in the case of a `DocumentUploadDDQQuestion`.
 * The reducer tracks whether current answers differ from answers since the last save, so it can send only changed fields to the backend.
 */
export interface DDQStateActionQuestionUpdated {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        questionId: string;
        answer: Answer;
        additionalInformation?: string;
    };
}

/**
 * Dispatched by `DDQControl` whenever any form field is updated.
 * The reducer tracks whether current form field values differ from the values since the last save, so it can send only changed fields to the backend.
 */
export interface DDQStateActionFormFieldUpdated {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        fieldId: string;
        value: string | number;
    };
}

/**
 * Dispatched after the successful save of a control.
 * This impacts various values calculated by the reducer, such as which files have been uploaded vs. only dropped into the file dropzone UI.
 */
export interface DDQStateActionControlSaved {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        newControlDocuments: UploadedFile[];
        newDocumentsByQuestionId: Map<string, UploadedFile[]>;
    };
}

export interface DDQStateActionControlSaveStateChanged {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        newState: DDQStateControlSaveState;
    };
}

export interface DDQStateActionGroupSelected {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
    };
}

export interface DDQStateActionControlFilesSelected {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        files: File[];
    };
}

export interface DDQStateActionControlFileDeselected {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        file: File;
    };
}

export interface DDQStateActionQuestionFilesSelected {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        questionId: string;
        files: File[];
    };
}

export interface DDQStateActionQuestionFileDeselected {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        questionId: string;
        file: File;
    };
}

export interface DDQStateActionControlAssessmentDocumentDeleted {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        documentation: UploadedFile;
    };
}

export interface DDQStateActionAnswerDocumentDeleted {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        questionId: string;
        documentation: UploadedFile;
    };
}

export interface DDQStateActionControlScrolledOver {
    type: DDQStateActionType;
    payload: {
        framework: string;
        groupId: string;
        controlId: string;
        questionId?: string;
        questionNumber?: number;
    };
}

export type DDQStateAction = DDQStateActionFrameworksLoaded | DDQStateActionControlsLoaded | DDQStateActionQuestionUpdated | DDQStateActionFormFieldUpdated | DDQStateActionControlSaved | DDQStateActionControlSaveStateChanged | DDQStateActionGroupSelected | DDQStateActionControlFilesSelected | DDQStateActionControlFileDeselected | DDQStateActionQuestionFilesSelected | DDQStateActionQuestionFileDeselected | DDQStateActionControlScrolledOver;

const countAssessmentsCompletedAndQuestionsAnswered = (group: DDQStateGroup): [number, number] => {
    let controlAssessmentsCompleted = 0;
    let questionsCompleted = 0;

    for (const [, control] of group.controls) {
        if (control.effectiveness?.current !== undefined) {
            controlAssessmentsCompleted = controlAssessmentsCompleted + 1;
        }

        for (const [, question] of control.questions) {
            if (isQuestionAnswered(question)) {
                questionsCompleted = questionsCompleted + 1;
            }
        }
    }

    return [controlAssessmentsCompleted, questionsCompleted];
};

const areAllQuestionsAnswered = (frameworks: Map<string, DDQStateFramework>): boolean => {
    for (const frameworkObj of frameworks.values()) {
        for (const groupObj of frameworkObj.groups.values()) {
            if (groupObj.questionsCompleted < groupObj.numberOfQuestions) {
                return false;
            }
        }
    }
    return true;
};

const reducer: Reducer<DDQState | undefined, DDQStateAction> = (state: DDQState | undefined, action: DDQStateAction): DDQState | undefined => {
    switch (action.type) {
        case DDQStateActionType.FrameworksLoaded: {
            const ddqStateAction = action as DDQStateActionFrameworksLoaded;
            return ddqStateAction.payload as DDQState;
        }
        case DDQStateActionType.ControlsLoaded: {
            const controlsLoadedAction = action as DDQStateActionControlsLoaded;
            const payload = controlsLoadedAction.payload;

            const newState = produce(state, (draft: DDQState) => {
                draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls = payload.controls;
            });
            return newState;
        }
        case DDQStateActionType.QuestionUpdated: {
            const questionUpdatedAction = action as DDQStateActionQuestionUpdated;
            const payload = questionUpdatedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                const question = control.questions.get(payload.questionId)!;
                question.answer.current = payload.answer;
                question.additionalInformation.current = payload.additionalInformation;
                control.saveState = DDQStateControlSaveState.CHANGED;

                draft.focusedKey = {
                    framework: control.framework,
                    groupId: control.groupId,
                    controlId: control.id,
                    questionId: question.id,
                };
            });

            return newState;
        }
        case DDQStateActionType.FormFieldUpdated: {
            const formFieldUpdatedAction = action as DDQStateActionFormFieldUpdated;
            const payload = formFieldUpdatedAction.payload;

            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                (control[payload.fieldId] as TrackedChanges<string | Effectiveness>).current = payload.value;
                control.saveState = DDQStateControlSaveState.CHANGED;
                draft.focusedKey = {
                    framework: control.framework,
                    groupId: control.groupId,
                    controlId: control.id,
                };
            });
            return newState;
        }

        case DDQStateActionType.ControlSaved: {
            const controlSavedAction = action as DDQStateActionControlSaved;
            const payload = controlSavedAction.payload;

            const newState = produce(state, (draft: DDQState): void => {
                const group = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!;
                const controls = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls;
                const savedControl = controls.get(payload.controlId)!;

                if (savedControl.effectiveness) {
                    savedControl.effectiveness.initial = savedControl.effectiveness.current;
                }

                if (savedControl.additionalInfo) {
                    savedControl.additionalInfo.initial = savedControl.additionalInfo.current;
                }

                savedControl.documents.push(...payload.newControlDocuments);
                savedControl.newDocuments = [];

                for (const [, question] of savedControl.questions) {
                    const newDocuments = payload.newDocumentsByQuestionId.get(question.id) ?? [];
                    question.answerDocuments.push(...newDocuments);

                    question.newDocuments = [];
                    question.answer.initial = question.answer.current;
                    question.additionalInformation.initial = question.additionalInformation.current;
                }

                const [controlAssessmentsCompleted, questionsCompleted] = countAssessmentsCompletedAndQuestionsAnswered(group);
                group.controlAssessmentsCompleted = controlAssessmentsCompleted;
                group.questionsCompleted = questionsCompleted;
                draft.allQuestionsAnswered = areAllQuestionsAnswered(draft.frameworks);
                savedControl.saveState = DDQStateControlSaveState.SAVED;
                draft.focusedKey = undefined;
            });
            return newState;
        }

        case DDQStateActionType.ControlSaveStateChanged: {
            const controlSaveStateChangedAction = action as DDQStateActionControlSaveStateChanged;
            const payload = controlSaveStateChangedAction.payload;

            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;

                control.saveState = payload.newState;

                if (control.saveState === DDQStateControlSaveState.ERROR) {
                    draft.focusedKey = undefined;

                    // We don't know whether the error occurred when trying to save the control or when trying to upload files.
                    // To be safe, clear out all dragged-and-dropped files so that files are not duplicated on subsequent attempts to save.
                    control.newDocuments = [];
                    control.questions.forEach((question) => {
                        question.newDocuments = [];
                    });
                }
            });
            return newState;
        }

        case DDQStateActionType.GroupSelectedAction: {
            const groupSelectedAction = action as DDQStateActionGroupSelected;
            const payload = groupSelectedAction.payload;

            const newState = produce(state, (draft: DDQState): void => {
                draft.selectedFramework = payload.framework;
                draft.selectedGroupId = payload.groupId;
            });
            return newState;
        }

        case DDQStateActionType.QuestionFilesSelected: {
            const selectedAction = action as DDQStateActionQuestionFilesSelected;
            const payload = selectedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                const question = control.questions.get(payload.questionId)!;
                question.newDocuments = addFiles(question.newDocuments, payload.files);
                control.saveState = DDQStateControlSaveState.CHANGED;
                draft.focusedKey = {
                    framework: control.framework,
                    groupId: control.groupId,
                    controlId: control.id,
                    questionId: question.id,
                };
            });
            return newState;
        }

        case DDQStateActionType.ControlFilesSelected: {
            const selectedAction = action as DDQStateActionControlFilesSelected;
            const payload = selectedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                control.newDocuments = addFiles(control.newDocuments, payload.files);
                control.saveState = DDQStateControlSaveState.CHANGED;
                draft.focusedKey = {
                    framework: control.framework,
                    groupId: control.groupId,
                    controlId: control.id,
                };
            });
            return newState;
        }

        case DDQStateActionType.ControlFileDeselected: {
            const deselectedAction = action as DDQStateActionControlFileDeselected;
            const payload = deselectedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                control.newDocuments = removeFiles(control.newDocuments, payload.file);
            });
            return newState;
        }

        case DDQStateActionType.ControlAssessmentDocumentDeleted: {
            const deletedAction = action as DDQStateActionControlAssessmentDocumentDeleted;
            const payload = deletedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                control.documents = control.documents?.filter((doc) => doc.file_id !== payload.documentation.file_id);
            });
            return newState;
        }

        case DDQStateActionType.QuestionFileDeselected: {
            const deselectedAction = action as DDQStateActionQuestionFileDeselected;
            const payload = deselectedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;
                const question = control.questions.get(payload.questionId)!;
                question.newDocuments = removeFiles(question.newDocuments, payload.file);
            });
            return newState;
        }

        case DDQStateActionType.AnswerDocumentDeleted: {
            const deletedAction = action as DDQStateActionAnswerDocumentDeleted;
            const payload = deletedAction.payload;
            const newState = produce(state, (draft: DDQState): void => {
                const group = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!;
                const question = group.controls.get(payload.controlId)!.questions.get(payload.questionId)!;
                question.answerDocuments = question.answerDocuments?.filter((doc) => doc.file_id !== payload.documentation.file_id);

                const [controlAssessmentsCompleted, questionsCompleted] = countAssessmentsCompletedAndQuestionsAnswered(group);
                group.controlAssessmentsCompleted = controlAssessmentsCompleted;
                group.questionsCompleted = questionsCompleted;
            });
            return newState;
        }

        case DDQStateActionType.ControlScrolledOver: {
            const changedVisibilityAction = action as DDQStateActionControlScrolledOver;
            const payload = changedVisibilityAction.payload;

            const newState = produce(state, (draft: DDQState): void => {
                const control = draft.frameworks.get(payload.framework)!.groups.get(payload.groupId)!.controls.get(payload.controlId)!;

                draft.stickyHeaderKey = {
                    framework: control.framework,
                    groupId: control.groupId,
                    controlId: control.id,
                    questionId: payload.questionId,
                    questionNumber: payload.questionNumber,
                };
            });
            return newState;
        }
    }
};

export const DueDiligenceQuestionnaire = ({ isThirdParty = false, ...props }: DueDiligenceQuestionnaireProps): JSX.Element => {
    // DANGER: If the third party is using this component, this is not a `Service`. It is what the backend calls a `PublicService`.
    const [serviceResponse, setServiceResponse] = useState<Service>();

    // `uncachedGroup` is set whenever the user selects the tab for a control group. The group may or may not actually be cached yet. For some reason, this state is just a layer of indirection that triggers a `useEffect` below to load (either via network or via cache) the selected group.
    const [uncachedGroup, setUncachedGroup] = useState<{ framework: string; id: string }>();

    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [zeroStateText, setZeroStateText] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [shouldShowStickyHeader, setShouldShowStickyHeader] = useState(false);
    const [shouldMakeGroupsSticky, setShouldMakeGroupsSticky] = useState(false);
    const [modalState, setModalState] = useState<ModalState>();

    // `ddqState` holds all of the "main" state for this component, including the "cached" controls that are lazily loaded as the user clicks through control group tabs.
    const [ddqState, dispatchDdqStateChange] = useReducer<DDQState | undefined, [DDQStateAction]>(reducer, undefined);
    const location = useLocation();
    const navigate = useNavigate();
    const { service_id, third_party_id } = useParams<keyof UrlParams>() as UrlParams;
    const questionnaireHeaderElement = useRef<HTMLDivElement>(null);
    const groupTabsContainerElement = useRef<HTMLDivElement>(null);
    const { isOpen: navIsOpen } = useNav();

    const thirdPartyServiceTitle = `${serviceResponse?.vendor_name} - ${serviceResponse?.name}`;

    useEffect(() => {
        if (questionnaireHeaderElement && questionnaireHeaderElement.current) {
            const questionnaireHeaderElementBottom = questionnaireHeaderElement.current.offsetTop + questionnaireHeaderElement.current.offsetHeight;

            const onScroll = (event: Event) => {
                if (window.pageYOffset > questionnaireHeaderElementBottom) {
                    setShouldShowStickyHeader(true);
                } else {
                    setShouldShowStickyHeader(false);
                }
            };

            window.addEventListener('scroll', onScroll);

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, [serviceResponse, thirdPartyServiceTitle, ddqState]);

    useEffect(() => {
        if (groupTabsContainerElement && groupTabsContainerElement.current) {
            const groupTabsContainerElementTop = groupTabsContainerElement.current.offsetTop;
            const onScroll = (event: Event) => {
                if (window.pageYOffset > groupTabsContainerElementTop) {
                    setShouldMakeGroupsSticky(true);
                } else {
                    setShouldMakeGroupsSticky(false);
                }
            };

            window.addEventListener('scroll', onScroll);

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, [serviceResponse, thirdPartyServiceTitle, ddqState]);

    useEffect(() => {
        const getServiceDetails = async (): Promise<void> => {
            try {
                const detailedServiceResponse = await props.ddqApi.getServiceDetails(third_party_id, service_id);
                setServiceResponse(detailedServiceResponse.data);
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTprmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            }
        };

        getServiceDetails();
    }, [props.ddqApi, service_id, third_party_id]);

    /**
     * Runs once on mount. Fetches the control frameworks and control groups that are part of the DDQ.
     * Controls within groups will be fetched lazily; see the useEffect directly below.
     */
    useEffect(() => {
        const getQuestionnaireFrameworks = async (): Promise<void> => {
            try {
                const wrappedQuestionnaireFrameworksResponse = await props.ddqApi.getQuestionnaireFrameworks(third_party_id, service_id);
                const questionnaireFrameworksResponse = wrappedQuestionnaireFrameworksResponse.data;

                sortGenericControlHierarchy(questionnaireFrameworksResponse);

                if (questionnaireFrameworksResponse.length > 0) {
                    const frameworks = new Map(
                        questionnaireFrameworksResponse.map((frameworkResponse) => {
                            const frameworkState: DDQStateFramework = frameworkInstanceToFrameworkState(frameworkResponse);
                            frameworkState.groups = new Map(
                                frameworkResponse.control_groups.map((groupResponse) => {
                                    const groupState: DDQStateGroup = groupInstanceToGroupState(groupResponse);
                                    return [groupState.id, groupState];
                                })
                            );

                            return [frameworkState.framework, frameworkState];
                        })
                    );

                    // The size of the map is determined above before the maps creation by checking the question array from the API. If there are no values, this line is not reachable.
                    let selectedFramework: DDQStateFramework = frameworks.values().next().value!;
                    let selectedGroup: DDQStateGroup = selectedFramework.groups.values().next().value!;

                    if (location.hash && location.hash.length > 0) {
                        const splitHash = location.hash.split('#');
                        const frameworkHash = decodeURIComponent(splitHash[1]);
                        const groupHash = decodeURIComponent(splitHash[2]);

                        if (frameworks.get(frameworkHash)?.groups.has(groupHash)) {
                            selectedFramework = frameworks.get(frameworkHash)!;
                            selectedGroup = selectedFramework.groups.get(groupHash)!;
                        }
                    }

                    setUncachedGroup({ framework: selectedGroup.framework, id: selectedGroup.id });

                    const action: DDQStateActionFrameworksLoaded = {
                        type: DDQStateActionType.FrameworksLoaded,
                        payload: {
                            frameworks: frameworks,
                            selectedFramework: selectedFramework.framework,
                            selectedGroupId: selectedGroup.id,
                            allQuestionsAnswered: areAllQuestionsAnswered(frameworks),
                        },
                    };
                    dispatchDdqStateChange(action);
                } else {
                    throw new Error("Either there is no inherent risk rating for this service, or the third-party questionnaire has not been configured with questions that apply to this service's inherent risk rating.");
                }
            } catch (error) {
                handleRequestError(error);
            }
        };

        // If the ddq state has already been defined, don't re-request data.
        // We already have the data and this code is just re-running because the url(location) hash has changed.
        if (ddqState === undefined) {
            getQuestionnaireFrameworks();
        }
    }, [props.ddqApi, third_party_id, service_id, location.hash, ddqState]);

    /**
     * Triggered whenever the user selects the tab for a control group.
     * If the group is already cached in state, `uncachedGroup` won't be set, so this useEffect won't do anything. Otherwise, this useEffect will fetch the controls for the group via the network.
     * Look at both `onGroupTabSelected` and `uncachedGroup` to understand what's going on here. For good measure, also look at how `uncachedGroup` is set in the `useEffect` directly above.
     */
    useEffect(() => {
        const getControlsForGroup = async (uncachedGroup: { framework: string; id: string }): Promise<void> => {
            try {
                const wrappedQuestionnaireGroupControlsResponse = await props.ddqApi.getQuestionnaireGroupControls(third_party_id, service_id, uncachedGroup.framework, uncachedGroup.id);
                const questionnaireGroupControlsResponse = wrappedQuestionnaireGroupControlsResponse.data;

                questionnaireGroupControlsResponse.controls.sort(controlComparator);

                const controls = new Map(
                    questionnaireGroupControlsResponse.controls.map((controlResponse) => {
                        const controlState: DDQStateControl = controlInstanceToControlState(controlResponse);

                        return [controlState.id, controlState];
                    })
                );
                setUncachedGroup(undefined);

                const action: DDQStateActionControlsLoaded = {
                    type: DDQStateActionType.ControlsLoaded,
                    payload: { framework: uncachedGroup.framework, groupId: uncachedGroup.id, controls: controls },
                };

                dispatchDdqStateChange(action);
            } catch (error) {
                handleRequestError(error);
            }
        };

        if (uncachedGroup) {
            getControlsForGroup(uncachedGroup);
        }
    }, [props.ddqApi, third_party_id, service_id, uncachedGroup]);

    /**
     * Whenever the user selects a new control group, update the URL hash to navigate to the group (which will trigger a new request for the controls in that group if the group has not already been loaded lazily since page load).
     * When switching to the new group, scroll to the top and reset the group tabs position. This ensures users have a consistent experience switching between groups that have and have not been lazily loaded.
     */
    useEffect(() => {
        if (ddqState?.selectedFramework && ddqState?.selectedGroupId) {
            navigate(`${location.pathname}#${ddqState.selectedFramework}#${ddqState?.selectedGroupId}`, { replace: true });

            props.scroller.scrollTo(0, 0);
            setShouldMakeGroupsSticky(false);
        }
    }, [location.pathname, ddqState?.selectedGroupId, ddqState?.selectedFramework, props.scroller, navigate]);

    /**
     * Save a control for a user signed in as the client (AKA SummIT user).
     */
    const clientSaveControl = useCallback(
        async (control: DDQStateControl, isManualSave = false): Promise<void> => {
            try {
                dispatchDdqStateChange(buildControlStateChangedAction(control, isManualSave ? DDQStateControlSaveState.SAVING_MANUALLY : DDQStateControlSaveState.SAVING_AUTOMATICALLY));

                const updates: Partial<ControlInstanceResponse> = {};

                if (isChanged(control.additionalInfo) || isChanged(control.effectiveness)) {
                    if (isChanged(control.additionalInfo)) {
                        updates.control_assessment_comment = control.additionalInfo?.current;
                    }

                    if (isChanged(control.effectiveness)) {
                        updates.control_assessment_effectiveness = control.effectiveness?.current;
                    }
                }

                // Get a presigned upload URL for each new document.
                const pendingUploads: [SignedUploadResponse, File][] = [];
                for (const document of control.newDocuments) {
                    const signedUploadResponse = (await props.documentApi.getSignedUpload(document.name)).data;
                    pendingUploads.push([signedUploadResponse, document]);
                }

                const newDocumentation = pendingUploads.map((tuple) => {
                    const [uploadResponse, doc] = tuple;
                    return {
                        filename: doc.name,
                        file_id: uploadResponse.file_id,
                    };
                });

                const request: UpdateControlInstanceRequest = {
                    updates: isEmpty(updates) ? undefined : updates,
                    new_documentation: newDocumentation.length > 0 ? newDocumentation : undefined,
                };

                await props.ddqApi.clientSaveControl(third_party_id, service_id, control.framework, control.groupId, control.id, request);

                // Execute pending file uploads.
                const newControlDocuments: UploadedFile[] = [];
                for (const [uploadResponse, file] of pendingUploads) {
                    await props.documentApi.uploadDocument(uploadResponse.url, uploadResponse.fields, file);
                    newControlDocuments.push({
                        file_id: uploadResponse.file_id,
                        filename: file.name,
                        file_state: FileState.PROCESSING,
                    });
                }

                const action: DDQStateActionControlSaved = {
                    type: DDQStateActionType.ControlSaved,
                    payload: { framework: control.framework, groupId: control.groupId, controlId: control.id, newControlDocuments: newControlDocuments, newDocumentsByQuestionId: new Map() },
                };

                // This action will update the save state of the control.
                dispatchDdqStateChange(action);
                showSuccessToast(`Control ${getControlDisplayName(control)} saved.`);
            } catch (error) {
                showFailureToast(`There was a problem saving control ${getControlDisplayName(control)}: ${error.message}`);
                dispatchDdqStateChange(buildControlStateChangedAction(control, DDQStateControlSaveState.ERROR));
            }
        },
        [props.ddqApi, props.documentApi, service_id, third_party_id]
    );

    /**
     * Save a control for a user signed in as a third-party (AKA a non-SummIT user).
     */
    const thirdPartySaveControl = useCallback(
        async (control: DDQStateControl, isManualSave = false): Promise<void> => {
            try {
                dispatchDdqStateChange(buildControlStateChangedAction(control, isManualSave ? DDQStateControlSaveState.SAVING_MANUALLY : DDQStateControlSaveState.SAVING_AUTOMATICALLY));

                // Get a presigned upload URL for each new document for the control's questions.
                const pendingUploadsByQuestion = new Map<string, [SignedUploadResponse, File][]>();

                const updates: { [key: string]: QuestionUpdates } = {};

                for (const question of Array.from(control.questions.values())) {
                    const updatesAndDocuments: QuestionUpdates = {};

                    if (isChanged(question.answer) || isChanged(question.additionalInformation)) {
                        updatesAndDocuments.updates = {};

                        switch (question._type) {
                            case QuestionType.FREEFORM:
                                if (isChanged(question.answer)) updatesAndDocuments.updates.answer_text = question.answer.current as string;
                                break;
                            case QuestionType.SINGLE_SELECT:
                                if (isChanged(question.additionalInformation)) updatesAndDocuments.updates.answer_text = question.additionalInformation.current as string;
                                if (isChanged(question.answer)) (updatesAndDocuments.updates as SingleSelectQuestionInstance).answer_index = question.answer.current as number;
                                break;
                            case QuestionType.MULTIPLE_SELECT:
                                if (isChanged(question.additionalInformation)) updatesAndDocuments.updates.answer_text = question.additionalInformation.current as string;
                                if (isChanged(question.answer)) (updatesAndDocuments.updates as MultipleSelectQuestionInstance).answer_indexes = question.answer.current as number[];
                                break;
                            case QuestionType.DOCUMENT_UPLOAD:
                                if (isChanged(question.additionalInformation)) updatesAndDocuments.updates.answer_text = question.additionalInformation.current as string;
                                break;
                            default:
                                throw new Error(`Unexpected QuestionType: ${question._type}`);
                        }
                    }

                    const pendingUploadsForThisQuestion: [SignedUploadResponse, File][] = [];
                    const newDocumentation: FileToBeUploaded[] = [];

                    for (const document of question.newDocuments) {
                        const signedUploadResponse = (await props.documentApi.getSignedUpload(document.name)).data;
                        pendingUploadsForThisQuestion.push([signedUploadResponse, document]);

                        newDocumentation.push({
                            filename: document.name,
                            file_id: signedUploadResponse.file_id,
                        });
                    }

                    if (newDocumentation.length > 0) {
                        updatesAndDocuments.new_documentation = newDocumentation;
                    }

                    if (!isEmpty(updatesAndDocuments.updates) || newDocumentation.length > 0) {
                        updates[question.id] = updatesAndDocuments;
                    }

                    pendingUploadsByQuestion.set(question.id, pendingUploadsForThisQuestion);
                }

                const request: UpdateControlInstanceQuestionsRequest = { questions_updates: isEmpty(updates) ? undefined : updates };

                await props.ddqApi.thirdPartySaveControl(third_party_id, service_id, control.framework, control.groupId, control.id, request);

                // Execute pending file uploads.
                const newDocumentsByQuestionId = new Map<string, UploadedFile[]>();
                for (const [questionId, tuple] of pendingUploadsByQuestion) {
                    const uploadedFileEvidenceForQuestion: UploadedFile[] = [];
                    for (const [uploadResponse, file] of tuple) {
                        await props.documentApi.uploadDocument(uploadResponse.url, uploadResponse.fields, file);
                        uploadedFileEvidenceForQuestion.push({
                            file_id: uploadResponse.file_id,
                            filename: file.name,
                            file_state: FileState.PROCESSING,
                        });
                    }
                    newDocumentsByQuestionId.set(questionId, uploadedFileEvidenceForQuestion);
                }

                const action: DDQStateActionControlSaved = {
                    type: DDQStateActionType.ControlSaved,
                    payload: { framework: control.framework, groupId: control.groupId, controlId: control.id, newControlDocuments: [], newDocumentsByQuestionId: newDocumentsByQuestionId },
                };

                // This action will update the save state of the control.
                dispatchDdqStateChange(action);
                showSuccessToast(`Control ${getControlDisplayName(control)} saved.`);
            } catch (error) {
                showFailureToast(`There was a problem saving control ${getControlDisplayName(control)}: ${error.message}`);
                dispatchDdqStateChange(buildControlStateChangedAction(control, DDQStateControlSaveState.ERROR));
            }
        },
        [props.ddqApi, props.documentApi, service_id, third_party_id]
    );

    /**
     * Whenever state changes, save the control that most recently had focus.
     */
    useEffect(() => {
        const saveControl = (control: DDQStateControl, isThirdParty: boolean) => {
            if (control.saveState !== DDQStateControlSaveState.SAVING_AUTOMATICALLY && control.saveState !== DDQStateControlSaveState.SAVING_MANUALLY) {
                if (isThirdParty) {
                    thirdPartySaveControl(control);
                } else {
                    clientSaveControl(control);
                }
            }
        };

        const key = ddqState?.focusedKey;

        if (key) {
            const focusedControl = ddqState!.frameworks.get(key.framework)!.groups.get(key.groupId)!.controls.get(key.controlId)!;

            if (focusedControl && focusedControl.saveState === DDQStateControlSaveState.CHANGED) {
                let focusedQuestion;

                if ('questionId' in key) {
                    focusedQuestion = focusedControl.questions.get(key.questionId);
                }
                const documentsWereAdded = focusedControl.newDocuments.length > 0 || (focusedQuestion !== undefined && focusedQuestion.newDocuments.length > 0);

                // If documents were added (either to the control by the client, or to a question by the third party), save the control immediately. Otherwise, wait 5 seconds before saving the control.
                if (documentsWereAdded) {
                    saveControl(focusedControl, isThirdParty);
                } else {
                    const timer = setTimeout(() => {
                        saveControl(focusedControl, isThirdParty);
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            }
        }
    }, [clientSaveControl, ddqState, isThirdParty, thirdPartySaveControl]);

    const handleRequestError = (error: Error): void => setZeroStateText(error.message);

    const showSuccessToast = (successMessage: string): void => {
        setSuccessMessage(successMessage);
    };

    const showFailureToast = (failureMessage: string): void => {
        setFailureMessage(failureMessage);
    };

    /**
     * When the tab for a control group is selected, store the selected group as the active group in state, and dispatch a change to the reducer.
     * I don't think there's a good reason for responding to this user event partially here and partially in the reducer.
     */
    const onGroupTabSelected = (framework: string, groupId: string): void => {
        const selectedGroup = ddqState?.frameworks.get(framework)?.groups.get(groupId);

        if (selectedGroup && selectedGroup.controls.size < 1) {
            setUncachedGroup({ framework: selectedGroup.framework, id: selectedGroup.id });
        }

        const action: DDQStateActionGroupSelected = {
            type: DDQStateActionType.GroupSelectedAction,
            payload: { framework: framework, groupId: groupId },
        };

        dispatchDdqStateChange(action);
    };

    const onSelectControlAssessmentDocumentToDelete = (payload: DeleteControlAssessmentDocumentModalPayload) => {
        setModalState({
            modal: Modal.DeleteControlAssessmentDocument,
            payload: payload,
        });
    };

    const onSelectAnswerDocumentToDelete = (payload: DeleteAnswerDocumentModalPayload) => {
        setModalState({
            modal: Modal.DeleteAnswerDocument,
            payload: payload,
        });
    };

    const deleteControlAssessmentDocumentProps =
        modalState && modalState.modal === Modal.DeleteControlAssessmentDocument
            ? {
                  hideModal: () => setModalState(undefined),
                  dispatchDdqStateChange: dispatchDdqStateChange,
                  ddqApi: props.ddqApi,
                  thirdPartyId: third_party_id,
                  serviceId: service_id,
                  ...(modalState.payload as DeleteControlAssessmentDocumentModalPayload),
              }
            : undefined;

    const deleteAnswerDocumentProps =
        modalState && modalState.modal === Modal.DeleteAnswerDocument
            ? {
                  hideModal: () => setModalState(undefined),
                  dispatchDdqStateChange: dispatchDdqStateChange,
                  ddqApi: props.ddqApi,
                  thirdPartyId: third_party_id,
                  serviceId: service_id,
                  ...(modalState.payload as DeleteAnswerDocumentModalPayload),
              }
            : undefined;

    const handleLogOutClick = () => {
        props.basicAuthHandler?.logout(AuthState.LOGGED_OUT);
    };

    if (tprmAccessDenied) {
        <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }
    if (zeroStateText) {
        return <Text>{zeroStateText}</Text>;
    }

    if (serviceResponse && thirdPartyServiceTitle && ddqState) {
        const selectedGroup = ddqState.frameworks.get(ddqState.selectedFramework)!.groups.get(ddqState.selectedGroupId)!;

        const groupStyle = (() => {
            let style = '';

            if (shouldMakeGroupsSticky) {
                style = `${style} ${styles.stickyGroups}`;
            }

            if (shouldShowStickyHeader) {
                style = `${style} ${styles.sticky}`;
            }

            return style;
        })();

        const stickyHeaderStyle = (() => {
            let style = styles.stickyHeader;

            if (shouldShowStickyHeader) {
                style += ' ' + styles.sticky;
            }

            // The public DDQ does not have a nav bar, so the nav will never be open when the DDQ is viewed by a third party.
            if (navIsOpen && !isThirdParty) {
                style += ' ' + styles.navOpen;
            }

            return style;
        })();

        const stickyHeaderControl = ddqState.stickyHeaderKey ? ddqState.frameworks.get(ddqState.stickyHeaderKey.framework)!.groups.get(ddqState.stickyHeaderKey.groupId)!.controls.get(ddqState.stickyHeaderKey.controlId) : undefined;
        const stickyHeaderKeyHasQuestionKey = stickyHeaderControl && ddqState.stickyHeaderKey && 'questionId' in ddqState.stickyHeaderKey && 'questionNumber' in ddqState.stickyHeaderKey;
        const stickyHeaderQuestion = stickyHeaderKeyHasQuestionKey ? stickyHeaderControl!.questions.get((ddqState.stickyHeaderKey as QuestionKey).questionId) : undefined;
        const stickyHeaderSubmitButtonDisabled = stickyHeaderControl?.saveState === DDQStateControlSaveState.SAVING_AUTOMATICALLY || stickyHeaderControl?.saveState === DDQStateControlSaveState.SAVING_MANUALLY;

        // [Incorrect typing] This disgusting line is a band-aid over the fact that sometimes what we have in state is not a `Service`, but what the backend calls a `PublicService`. This has to stay here, and MUST be used whenever we reference assessment state, until we fix all of the incorrect typing in this component, in `TPRMApi`, and in `DDQApi`.
        const assessmentState: ServiceAssessmentState = !isThirdParty ? serviceResponse.assessment_workflow_data.state : (serviceResponse as any).assessment_state;

        const shouldDisableControl = (control: DDQStateControl) => {
            const focusedKey = ddqState?.focusedKey;
            const focusedControl = focusedKey ? ddqState!.frameworks.get(focusedKey.framework)!.groups.get(focusedKey.groupId)!.controls.get(focusedKey.controlId)! : undefined;
            const otherControlIsFocused = focusedControl && control.id !== focusedControl.id;

            return assessmentState !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE || otherControlIsFocused || control?.saveState === DDQStateControlSaveState.SAVING_AUTOMATICALLY || control.saveState === DDQStateControlSaveState.SAVING_MANUALLY;
        };

        return (
            <>
                {successMessage && <TextToast variant="success" clearToast={() => setSuccessMessage(undefined)} autoHide text={successMessage} />}
                {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} autoHide text={failureMessage} />}

                <div className={stickyHeaderStyle}>
                    <PageContent>
                        {stickyHeaderControl && (
                            <div className={styles.stickyHeaderContent}>
                                <div className={styles.stickyHeaderControlContent}>
                                    <div>
                                        <div className={styles.stickyHeaderControlId}>
                                            <Text color="blue" variant="Header2" noStyles>
                                                {stickyHeaderControl.controlName ? stickyHeaderControl.controlName : stickyHeaderControl.id}
                                            </Text>
                                            {stickyHeaderControl.saveState !== DDQStateControlSaveState.UNCHANGED && (
                                                <div className={styles.icon}>
                                                    {stickyHeaderControl.saveState === DDQStateControlSaveState.CHANGED && <FontAwesomeIcon className={styles.edited} icon={ICON_EDIT_MODIFY_UPDATE} />}
                                                    {(stickyHeaderControl.saveState === DDQStateControlSaveState.SAVING_AUTOMATICALLY || stickyHeaderControl.saveState === DDQStateControlSaveState.SAVING_MANUALLY) && <FontAwesomeIcon className={styles.saving} icon={faSync} />}
                                                    {stickyHeaderControl.saveState === DDQStateControlSaveState.SAVED && <FontAwesomeIcon className={styles.saved} icon={faCheck} />}
                                                    {stickyHeaderControl.saveState === DDQStateControlSaveState.ERROR && <FontAwesomeIcon className={styles.error} icon={ICON_CLOSE} />}
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.stickyHeaderControlText}>
                                            <Text color="darkGray" variant="Text3">
                                                {controlTextToString(stickyHeaderControl.controlText)}
                                            </Text>
                                        </div>
                                    </div>
                                    <Button variant="submit" form={getFormIdForControl(stickyHeaderControl)} isLoading={stickyHeaderControl.saveState === DDQStateControlSaveState.SAVING_MANUALLY} disabled={stickyHeaderSubmitButtonDisabled} loadingText="Saving...">
                                        Save
                                    </Button>
                                </div>
                                {stickyHeaderQuestion && (
                                    <div className={styles.stickyHeaderQuestionText}>
                                        <Text color="blue" variant="Text2">
                                            {`${(ddqState.stickyHeaderKey as QuestionKey).questionNumber! + 1}. ${stickyHeaderQuestion.text}`}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        )}
                    </PageContent>
                </div>

                <PageBackground color="blueMountains">
                    <PageContent>
                        <div className={styles.header}>
                            <div>
                                {deleteControlAssessmentDocumentProps && <DeleteControlAssessmentDocumentModal {...deleteControlAssessmentDocumentProps} />}
                                {deleteAnswerDocumentProps && <DeleteAnswerDocumentModal {...deleteAnswerDocumentProps} />}
                                {!isThirdParty && (
                                    <Breadcrumb textColor="white">
                                        <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.vendor_id}/${SERVICES}/${serviceResponse.id}/${DASHBOARDS}`}>{thirdPartyServiceTitle}</BreadcrumbLink>
                                        <BreadcrumbText>Control Assessment</BreadcrumbText>
                                    </Breadcrumb>
                                )}
                                <Text color="white" variant="Header1">
                                    {thirdPartyServiceTitle}
                                </Text>
                                {isThirdParty && !ddqState.allQuestionsAnswered && (
                                    <Text color="darkGray" variant="Header2">
                                        Provide answers to the questions below. Questions may be organized into multiple tabs on the right.
                                    </Text>
                                )}
                                {isThirdParty && ddqState.allQuestionsAnswered && (
                                    <Text color="darkGray" variant="Header2">
                                        All questions have been answered. You may continue to update responses until the assessment is approved.
                                    </Text>
                                )}
                                {!isThirdParty && (
                                    <Text color="darkGray" variant="Header2">
                                        Answers to the questions below are populated as the third-party service contact responds to the third-party questionnaire. Complete the control assessment(s) following the question(s).
                                    </Text>
                                )}
                                {!isThirdParty && serviceResponse.assessment_workflow_setup.common_assessment_children.length > 0 && (
                                    <Text color="darkGray" variant="Header2">
                                        This third-party questionnaire is shared by multiple services. See the service dashboard for a list of services impacted by this third-party questionnaire.
                                    </Text>
                                )}
                            </div>
                            {isThirdParty && (
                                <Button variant="primary" onClick={handleLogOutClick} fontAwesomeImage={faSignOutAlt}>
                                    Log Out
                                </Button>
                            )}
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <div className={styles.questionnaire}>
                            <div className={styles.controls}>
                                <div ref={questionnaireHeaderElement}>
                                    <PageCell>
                                        {assessmentState !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE && <Alert variant="warning">To make changes to this questionnaire, begin the risk workflow from the service dashboard and complete the inherent risk questionnaire.</Alert>}
                                        <div className={styles.questionsAnsweredText}>
                                            <Text color="blue" variant="Text1">
                                                {selectedGroup.isCustom ? `${selectedGroup.name}` : `${selectedGroup.id}: ${selectedGroup.name}`}
                                            </Text>
                                            <Text>{selectedGroup.description ? selectedGroup.description : ''}</Text>
                                            <hr />
                                            <Text color="blue" variant="Text1">
                                                {questionsCompletedText(selectedGroup)}
                                            </Text>
                                        </div>
                                        <ProgressBarIndicator percent={questionsCompletedPercentage(selectedGroup)} size="large" variant={IndicatorVariant.BLUE} />
                                    </PageCell>
                                </div>
                                {Array.from(selectedGroup.controls.values()).map((control) => {
                                    const controlProps: DDQControlProps = {
                                        ddqApi: props.ddqApi,
                                        documentApi: props.documentApi,
                                        thirdPartyId: third_party_id,
                                        serviceId: service_id,
                                        control: control,
                                        isThirdParty: isThirdParty,
                                        dueDiligenceIsNotInProgress: assessmentState !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
                                        disabled: shouldDisableControl(control),
                                        isStickyHeaderVisible: shouldShowStickyHeader,
                                        stickyHeaderKey: ddqState.stickyHeaderKey,
                                        thirdPartySaveControl: thirdPartySaveControl,
                                        clientSaveControl: clientSaveControl,
                                        dispatchDdqStateChange: dispatchDdqStateChange,
                                        showSuccessToast: showSuccessToast,
                                        showFailureToast: showFailureToast,
                                        onSelectControlAssessmentDocumentToDelete: onSelectControlAssessmentDocumentToDelete,
                                        onSelectAnswerDocumentToDelete: onSelectAnswerDocumentToDelete,
                                    };
                                    return <DDQControl key={control.id} {...controlProps} />;
                                })}
                            </div>

                            <div ref={groupTabsContainerElement} className={styles.groups}>
                                <div className={groupStyle}>
                                    {Array.from(ddqState.frameworks.values())
                                        .flatMap((framework) => {
                                            return Array.from(framework.groups.values());
                                        })
                                        .map((instance, index, array) => {
                                            const nextSibling = array.length - 1 >= index ? array[index + 1] : undefined;
                                            const nextSiblingSelected = nextSibling && nextSibling.id === selectedGroup.id ? true : false;

                                            const selected = instance.id === selectedGroup.id;

                                            const groupTabProps: DDQGroupTabProps = {
                                                group: instance,
                                                selected: selected,
                                                hideHorizontalRule: nextSiblingSelected || selected,
                                                isThirdParty: isThirdParty,
                                                onGroupTabSelected: () => onGroupTabSelected(instance.framework, instance.id),
                                            };
                                            return <DDQGroupTab key={instance.id} {...groupTabProps}></DDQGroupTab>;
                                        })}
                                </div>
                            </div>
                        </div>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else {
        return <Placeholder />;
    }
};

// This must be in sync with the `--sticky-header-height` CSS custom property.
export const STICKY_HEADER_HEIGHT = 125;

export const getFormIdForControl = (control: DDQStateControl): string => `control-form-${control.framework}-${control.groupId}-${control.id}`;

export const questionsCompletedText = (group: DDQStateGroup): string => `Questions Answered ${group.questionsCompleted}/${group.numberOfQuestions}`;
export const questionsCompletedPercentage = (group: DDQStateGroup): number => (group.questionsCompleted / group.numberOfQuestions) * 100;
export const assessmentsCompletedText = (group: DDQStateGroup): string => `Assessments Completed ${group.controlAssessmentsCompleted}/${group.numberOfControls}`;
export const assessmentsCompletedPercentage = (group: DDQStateGroup): number => (group.controlAssessmentsCompleted / group.numberOfControls) * 100;
export const getControlDisplayName = (control: DDQStateControl): string => (control.controlName ? control.controlName : control.id);

const buildControlStateChangedAction = (control: DDQStateControl, state: DDQStateControlSaveState): DDQStateActionControlSaveStateChanged => {
    return {
        type: DDQStateActionType.ControlSaveStateChanged,
        payload: {
            framework: control.framework,
            groupId: control.groupId,
            controlId: control.id,
            newState: state,
        },
    };
};

const frameworkInstanceToFrameworkState = (frameworkInstance: ControlFrameworkInstanceResponse): DDQStateFramework => {
    return {
        framework: frameworkInstance.control_framework,
        groups: new Map([]),
    };
};

const groupInstanceToGroupState = (groupInstance: ControlGroupInstanceResponse): DDQStateGroup => {
    return {
        framework: groupInstance.control_framework,
        id: groupInstance.control_group_id,
        name: groupInstance.control_group_name,
        description: groupInstance.control_group_description,
        controlAssessmentsCompleted: groupInstance.control_assessments_completed,
        numberOfControls: groupInstance.number_of_controls,
        questionsCompleted: groupInstance.questions_completed,
        numberOfQuestions: groupInstance.number_of_questions,
        isCustom: groupInstance.is_custom,
        controls: new Map([]),
    };
};

const controlInstanceToControlState = (controlInstance: ControlInstanceResponse): DDQStateControl => {
    const mapQuestions = (questionInstance: QuestionTypes): [string, DDQStateQuestion] => {
        const questionStateBase = {
            _type: questionInstance._type,
            id: questionInstance.id,
            text: questionInstance.text,
            answerDocuments: questionInstance.answer_documents,
            newDocuments: [],
            additionalInformation: {
                initial: questionInstance.answer_text,
                current: questionInstance.answer_text,
            },
        };

        switch (questionInstance._type) {
            case QuestionType.SINGLE_SELECT:
                return [
                    questionStateBase.id,
                    {
                        ...questionStateBase,
                        answer: {
                            current: questionInstance.answer_index,
                            initial: questionInstance.answer_index,
                        },
                        options: questionInstance.options,
                    },
                ];
            case QuestionType.MULTIPLE_SELECT:
                return [
                    questionStateBase.id,
                    {
                        ...questionStateBase,
                        answer: {
                            current: questionInstance.answer_indexes,
                            initial: questionInstance.answer_indexes,
                        },
                        options: questionInstance.options,
                    },
                ];
            case QuestionType.FREEFORM:
                return [
                    questionStateBase.id,
                    {
                        ...questionStateBase,
                        answer: {
                            current: questionInstance.answer_text,
                            initial: questionInstance.answer_text,
                        },
                    },
                ];
            // Document uploads will not have an answer. They will only have values for documents and additional information.
            case QuestionType.DOCUMENT_UPLOAD:
                return [
                    questionStateBase.id,
                    {
                        ...questionStateBase,
                        answer: {
                            current: undefined,
                            initial: undefined,
                        },
                    },
                ];
        }
    };
    return {
        id: controlInstance.control_id,
        groupId: controlInstance.control_group_id,
        framework: controlInstance.control_framework,
        controlText: controlInstance.control_text,
        isCustom: controlInstance.is_custom,
        controlName: controlInstance.control_name,
        effectiveness: {
            initial: controlInstance.control_assessment_effectiveness,
            current: controlInstance.control_assessment_effectiveness,
        },
        additionalInfo: {
            initial: controlInstance.control_assessment_comment,
            current: controlInstance.control_assessment_comment,
        },
        documents: controlInstance.control_assessment_documents ? controlInstance.control_assessment_documents : [],
        newDocuments: [],
        questions: new Map(controlInstance.questions.map(mapQuestions)),
        saveState: DDQStateControlSaveState.UNCHANGED,
    };
};
