import { faPaperclip, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { type JSX, useState } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { Switch } from 'Components/Buttons/Switch';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { IntegrationName, Metric as MetricModel, MetricName } from 'Models/ExternalIntegrations';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './Metric.module.css';
import { Modal } from '../../ExternalIntegrations';

export interface MetricProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    displayAssociatedControls: (metric: MetricModel) => void;
    displayControlAssociation: (controlAssociationTitleSecondary: string, controlAssociationIdentifier: any, control_mapping: OperationalControl[]) => void;
    displayMetricModal: (modal: Modal, metric: MetricModel) => void;
    setSuccessFailureMessages: (newSuccessMessage: string | undefined, newFailureMessage: string | undefined) => void;
    integrationName: IntegrationName;
    metric: MetricModel;
}

export const Metric = (props: MetricProps): JSX.Element => {
    const [enabled, setEnabled] = useState(props.metric.enabled ?? false);

    const toggleMetricHandler = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
        props.setSuccessFailureMessages(undefined, undefined);

        const newEnabledValue = !enabled;
        setEnabled(newEnabledValue);
        try {
            await props.externalIntegrationsApi.toggleMetric(props.integrationName, props.metric.metric_name, { enabled: newEnabledValue });
        } catch (err) {
            props.setSuccessFailureMessages(undefined, err.message);
        }
    };

    const renderControlMapping = (): JSX.Element => {
        if (props.metric.metric_name === MetricName.THIRD_PARTY_MONITORING) {
            return <Text noStyles>N/A</Text>;
        } else {
            return <Button variant="linkText" size="sm" onClick={() => props.displayAssociatedControls(props.metric)}>{`${props.metric.control_mapping?.length || '0'} ${props.metric.control_mapping && props.metric.control_mapping.length === 1 ? 'Control' : 'Controls'}`}</Button>;
        }
    };

    const getOverflowMenuProps = (): OverflowMenuProps => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Description',
                    onClickAction: () => props.displayMetricModal(Modal.MetricDescription, props.metric),
                    icon: faQuestion,
                },
            ],
            accessibilityTitle: `${props.metric.metric_name} Menu`,
        };

        if (props.metric.metric_name !== MetricName.THIRD_PARTY_MONITORING) {
            overflowMenuProps.overflowItems.push({
                text: 'Map to Control',
                onClickAction: () => props.displayControlAssociation(`${props.integrationName}: ${props.metric.metric_name}`, { integrationName: props.integrationName, metricName: props.metric.metric_name }, props.metric.control_mapping ?? []),
                icon: faPaperclip,
            });
        }

        return overflowMenuProps;
    };

    return (
        <div className={styles.metricContainer}>
            <Switch checked={enabled} onChange={toggleMetricHandler} />
            <div className={styles.metricName}>
                <Text noStyles>{props.metric.metric_name}</Text>
            </div>
            <div className={styles.metricLastPoll}>
                <Text noStyles>Last Poll:</Text>
                {props.metric.last_poll_success === true && (
                    <Text noStyles color="darkGreen">
                        &nbsp;SUCCESS&nbsp;
                    </Text>
                )}
                {props.metric.last_poll_success === false && (
                    <Text noStyles color="red">
                        &nbsp;FAIL&nbsp;
                    </Text>
                )}
                {props.metric.last_poll_success === undefined && <Text noStyles>&nbsp;N/A&nbsp;</Text>}
                {props.metric.last_poll_timestamp && <Text noStyles>{`(${iso8601ToUsTimestampLong(props.metric.last_poll_timestamp)})`}</Text>}
            </div>
            <div className={styles.metricControlMapping}>{renderControlMapping()}</div>
            <OverflowMenu {...getOverflowMenuProps()} />
        </div>
    );
};
