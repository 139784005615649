import { type JSX, useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMServiceAssessmentExportRow } from 'Models/Exports';
import { UploadedFile } from 'Models/Files';
import { effectivenessAsString } from 'Models/OperationalControls';
import { numberAsRiskRatingString } from 'Models/TPRM';

interface ThirdPartyServiceAssessmentsProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ThirdPartyServiceAssessments = (props: ThirdPartyServiceAssessmentsProps): JSX.Element => {
    const [thirdPartyAssessments, setThirdPartyAssessments] = useState<TPRMServiceAssessmentExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getThirdPartyServiceAssessmentsResponse = async (): Promise<void> => {
            try {
                const thirdPartyServiceAssessmentsResponse = await props.exportsApi.getThirdPartyServiceAssessmentsExport();
                setThirdPartyAssessments(thirdPartyServiceAssessmentsResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getThirdPartyServiceAssessmentsResponse();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMServiceAssessmentExportRow>[] = [
        { field: 'third_party_name', headerName: 'Third-Party Name', width: 300 },
        { field: 'service_name', headerName: 'Service Name', width: 300 },
        { field: 'status', headerName: 'Status', width: 300 },
        { field: 'submitter_department', headerName: 'Submitter Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.submitter, cachedData.users) },
        { field: 'submitter', headerName: 'Submitter', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('completion_time', 'Completion Date'),
        { field: 'inherent_risk', headerName: 'Inherent Risk', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'assessment_documents', headerName: 'Assessment Documents', width: 300, valueGetter: (_value, row) => row.assessment_documents.map((file?: UploadedFile) => file?.filename).join(', ') },
        { field: 'control_effectiveness', headerName: 'Control Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
        { field: 'residual_risk_score', headerName: 'Residual Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'additional_info', headerName: 'Additional Info', width: 300 },
        { field: 'common_assessment_parent', headerName: 'Common Assessment Parent', width: 300, valueGetter: (_value, row) => (row.common_assessment_parent ? row.common_assessment_parent.name : '') },
        dateColumn('assessment_due_date', 'Assessment Due Date'),
    ];

    if (thirdPartyAssessments) {
        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                        <BreadcrumbText>Third-Party Service Assessments</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Third-Party Service Assessments Export"
                body={[{ content: <DataGrid columns={columns} getRowId={(thirdPartyServiceAssessment: TPRMServiceAssessmentExportRow) => thirdPartyServiceAssessment.id} rows={thirdPartyAssessments} title="Third-Party Service Assessments Export Preview" fileName="Third-Party Services Assessments" disableVirtualization={props.disableVirtualization} /> }]}
            />
        );
    } else if (error) return <Text color="darkBlue">{error}</Text>;
    else return <Placeholder />;
};
